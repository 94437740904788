import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useUser } from '../../providers/UserProvider';
import { OrganizationListItem } from '../../models/organizations/OrganizationsListResponse';
import { useEntityContext } from '../../providers/EntityContextProvider';

type Props = {
    organization: OrganizationListItem;
};

export function OrganizationLineItem({ organization }: Props) {
    const { setActiveOrganization } = useUser();
    const { changeEntityContext } = useEntityContext();

    const handleClick = () => {
        setActiveOrganization(organization.id);
        changeEntityContext({ organizationSlug: organization.id });
    };

    return (
        <Grid item xs={12}>
            <Typography component="span" onClick={() => handleClick()} sx={{ cursor: 'pointer' }}>
                <Paper sx={{ padding: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} display="flex" alignItems="center">
                            <Typography sx={{ flexDirection: 'column', justifyContent: 'center' }}>
                                {`${organization.name}`}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Typography>
        </Grid>
    );
}
