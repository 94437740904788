import { Box, Container } from '@mui/material';
import CardSelection from '../../../components/elements/CardSelection';
import { PageDialogInstance } from '../../../components/elements/PageDialog/PageDialogInstance';

function NewResourceDialog() {
    return (
        <Box height="100%" display="flex" flexDirection="column" justifyContent="center" paddingBottom={8}>
            <Container>
                <CardSelection
                    commonHeight="28rem"
                    cards={[
                        {
                            centerHorizontally: true,
                            imageSrc: '/assets/images/product.png',
                            imageHeight: '15rem',
                            title: 'Product',
                            children:
                                'A product is the primary driver of customer acquisition, retention, and expansion. A product is used to group how value is delivered to customers.',
                            to: '#new-product',
                        },
                        {
                            centerHorizontally: true,
                            imageSrc: '/assets/images/service.png',
                            imageHeight: '15rem',
                            title: 'Service',
                            children:
                                'A service is a technical capability within a Product that provides value to customers.',
                            to: '#new-service',
                        },
                    ]}
                />
            </Container>
        </Box>
    );
}

export const newResourceDialogInstance: PageDialogInstance = {
    route: 'new-resource',
    children: <NewResourceDialog />,
};
