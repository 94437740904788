import { AccountInfo, BrowserAuthError, BrowserUtils, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLogging } from './LoggingProvider';
import { getScaledSenseBackendUrl } from '../utils/BrowserEnvUtils';
import { AxiosInstance } from '../api/axiosMock';
import { useLoading } from './LoadingProvider';
import { loginRequest } from '../auth/authConfig';
import { randomString } from '../utils/StringUtils';

let authInit = false;
let redirectInProgress = false;
const requestsInProgress: string[] = [];
const backendUrl = getScaledSenseBackendUrl();
const axiosBackend = axios.create({
    baseURL: backendUrl,
});

export class AuthenticationRedirectInProgressError extends Error {
    constructor() {
        super('Authentication redirect in progress');

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, AuthenticationRedirectInProgressError.prototype);
    }
}

type ProviderProps = {
    children?: ReactNode;
};

type AuthenticatedAccountContextType = {
    account?: AccountInfo;
    backendApi: AxiosInstance;
    logoutAccount: () => void;
};

const AuthenticatedAccountContext = createContext<AuthenticatedAccountContextType | undefined>(undefined);

export function AuthenticatedAccountProvider({ children }: ProviderProps) {
    const [account, setAccount] = useState<AccountInfo | undefined>(undefined);
    const { instance } = useMsal();
    const msalAccount = useAccount();
    const { trackTrace, trackException } = useLogging();
    const { showLoading, loadingComplete } = useLoading();

    if (!authInit) {
        trackTrace('Setting backend interceptor..');
        /* eslint-disable no-param-reassign */
        axiosBackend.interceptors.request.use(
            async (config) => {
                if (redirectInProgress) {
                    trackTrace('[RequestInterceptor] redirect in progress, skipping..');
                    throw new AuthenticationRedirectInProgressError();
                }

                let accessToken: string;

                const requestId = randomString();
                try {
                    requestsInProgress.push(requestId);
                    const response = await instance.acquireTokenSilent({
                        ...loginRequest,
                        account,
                        redirectUri: `${window.location.protocol}//${window.location.host}/token-redirect.html`,
                    });
                    accessToken = response.accessToken;
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        trackException(
                            `[RequestInterceptor] acquireTokenSilent failed with InteractionRequiredAuthError ${error}`,
                        );
                        try {
                            redirectInProgress = true;
                            showLoading(requestId);
                            await instance.acquireTokenRedirect({
                                ...loginRequest,
                                account,
                                redirectStartPage: window.location.href,
                            });
                        } catch (err) {
                            setTimeout(() => {
                                // If we haven't redirected after 1 second, clear the loading indicator
                                loadingComplete(requestId);
                            }, 1000);
                            trackException(
                                `[RequestInterceptor] acquireTokenRedirect failed with unhandled error ${err}`,
                            );
                        } finally {
                            redirectInProgress = false;
                        }
                    }
                    if (error instanceof BrowserAuthError) {
                        trackException(`[RequestInterceptor] acquireTokenSilent failed with BrowserAuthError ${error}`);
                        try {
                            redirectInProgress = true;
                            showLoading(requestId);
                            await instance.acquireTokenRedirect({
                                ...loginRequest,
                                account,
                                redirectStartPage: window.location.href,
                            });
                        } catch (err) {
                            setTimeout(() => {
                                // If we haven't redirected after 1 second, clear the loading indicator
                                loadingComplete(requestId);
                            }, 1000);
                            trackException(
                                `[RequestInterceptor] acquireTokenRedirect failed with unhandled error ${err}`,
                            );
                        } finally {
                            redirectInProgress = false;
                        }
                    } else {
                        trackException(`[RequestInterceptor] acquireTokenSilent failed with unhandled error ${error}`);
                    }
                } finally {
                    requestsInProgress.splice(requestsInProgress.indexOf(requestId), 1);
                }

                if (!accessToken!) {
                    if (redirectInProgress || requestsInProgress.length > 0) {
                        trackTrace('[RequestInterceptor] redirect in progress, skipping..');
                        throw new AuthenticationRedirectInProgressError();
                    } else {
                        trackException('[RequestInterceptor] no access token found');
                        throw new Error('[RequestInterceptor] no access token found');
                    }
                }

                const bearer = `Bearer ${accessToken}`;

                if (!config.headers) {
                    trackException('API client headers object not set');
                    throw new Error('API client headers object not set');
                }

                config.headers.Authorization = bearer;

                return config;
            },
            async (error) => {
                if (error instanceof AuthenticationRedirectInProgressError) {
                    trackTrace('[RequestInterceptor] redirect in progress caught, rejecting call..');
                    return Promise.reject(error);
                }
                trackException(`[RequestInterceptor] unhandled error ${error}`);
                return Promise.reject(error);
            },
        );
        authInit = true;
    }

    const logoutAccount = () => {
        trackTrace('Logging account out..');
        instance.logoutRedirect({
            onRedirectNavigate: () => !BrowserUtils.isInIframe(),
        });
    };

    const memoValue = useMemo(
        () => ({
            account,
            backendApi: axiosBackend,
            logoutAccount,
        }),
        [account],
    );

    useEffect(() => {
        trackTrace('Setting platform account..');
        setAccount(msalAccount || undefined);
    }, [msalAccount]);

    return <AuthenticatedAccountContext.Provider value={memoValue}>{children}</AuthenticatedAccountContext.Provider>;
}

export const useAuthenticatedAccount = (): AuthenticatedAccountContextType => {
    const authenticatedAccount = useContext(AuthenticatedAccountContext);
    if (!authenticatedAccount) {
        throw new Error('useAuthenticatedAccount must be used within a AuthenticatedAccount Provider');
    }
    return authenticatedAccount;
};
