import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Box, Breadcrumbs, Link, LinkProps } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useUser } from '../../../providers/UserProvider';
import { platformRoutes } from '../../../platform-pages/platformRoutes';
import { useAuthenticatedAccount } from '../../../providers/AuthenticatedAccountProvider';

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}

export function BasicTopAppBar() {
    const location = useLocation();
    const { user, logoutUser, isPlatformManager } = useUser();
    const { account } = useAuthenticatedAccount();
    const [usersName, setUsersName] = useState<string | undefined>();
    useEffect(() => {
        setUsersName(user?.friendlyName || account?.name || account?.username);
    }, [user, account]);

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isAccountMenuOpen = Boolean(accountMenuAnchorEl);
    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const handleLogout = () => {
        handleAccountMenuClose();
        logoutUser();
    };

    const [pageName, setPageName] = useState<string | null>(null);
    useEffect(() => {
        const platformRoutesLocal = [...platformRoutes()];
        platformRoutesLocal.forEach((item) => {
            if (item.breadcrumbPath) {
                /* eslint-disable-next-line no-param-reassign */
                item.path = item.breadcrumbPath;
            }
        });

        const currentRouteObj = platformRoutesLocal
            .sort((a, b) => (a.path.length < b.path.length ? 1 : -1))
            .find((item) => location.pathname.match(`^${item.path.replaceAll(':', '[a-zA-Z0-9-]*')}[/]?$`));
        setPageName(currentRouteObj?.name || 'Not Found');
    }, [location.pathname]);

    const renderAccountMenu = (
        <Menu
            anchorEl={accountMenuAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id="account-menu"
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isAccountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <Box p={(theme) => theme.spacing(1, 2)}>
                {usersName && (
                    <Typography align="center" variant="h6" sx={{ paddingBottom: (theme) => theme.spacing(1) }}>
                        Hi, {usersName}
                    </Typography>
                )}
                {isPlatformManager && (
                    <LinkRouter underline="none" color="inherit" to="/organizations" onClick={handleAccountMenuClose}>
                        <MenuItem>
                            <ListItemIcon>
                                <CorporateFareIcon fontSize="small" />
                            </ListItemIcon>
                            Organizations
                        </MenuItem>
                    </LinkRouter>
                )}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Box>
        </Menu>
    );

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
            <Toolbar>
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon fontSize="small" />}
                    sx={{
                        lineHeight: '1',
                        marginRight: 2,
                        marginLeft: 2,
                        flexGrow: 1,
                    }}
                >
                    <LinkRouter to="/" underline="none" lineHeight="1">
                        <img alt="Logo" src="/assets/images/LogoRightTextBlue.svg" height="45" />
                    </LinkRouter>
                    {user?.orgId && (
                        <Typography color="text.primary" fontSize="1.3rem">
                            {pageName}
                        </Typography>
                    )}
                </Breadcrumbs>
                <IconButton
                    color="inherit"
                    onClick={(e) => setAccountMenuAnchorEl(e.currentTarget)}
                    sx={{ marginLeft: 1 }}
                >
                    <Avatar>{usersName ? usersName[0].toUpperCase() : null}</Avatar>
                </IconButton>
            </Toolbar>
            {renderAccountMenu}
        </AppBar>
    );
}
