import { EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { ReactNode, useEffect } from 'react';
import { loginRequest, msalConfig } from '../../../auth/authConfig';
import { ErrorComponent } from '../ErrorComponent';
import { LoadingComponent } from '../LoadingComponent';
import { useLogging } from '../../../providers/LoggingProvider';

type Props = {
    children: ReactNode | null;
};

export const msalInstance = new PublicClientApplication(msalConfig);

export function MsalInit({ children }: Props) {
    const { trackTrace } = useLogging();

    useEffect(() => {
        trackTrace('Initializing msal..');
        msalInstance.initialize().then(() => {
            // Default to using the first account if no account is active on page load
            console.log('Active Account:');
            console.log(msalInstance.getActiveAccount());

            console.log('All Accounts:');
            console.log(msalInstance.getAllAccounts());
            if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
                trackTrace('Setting active account as last option..');
                const acc = msalInstance.getAllAccounts()[0];
                acc.username = acc.username !== '' ? acc.username : (acc.idTokenClaims as any)?.email || '';
                msalInstance.setActiveAccount(acc);
            }
            msalInstance.enableAccountStorageEvents();

            trackTrace('Setting msal callbacks..');
            const callbackId = msalInstance.addEventCallback(async (event: any) => {
                trackTrace(`Msal event callback: ${JSON.stringify(event)}`);

                // @TODO: fix back to actual type
                if (event && event.payload && event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                    trackTrace('Setting new active account after LOGIN_SUCCESS..');
                    const acc = event.payload.account;
                    // TODO: testing if we are actually using this, if not remove.
                    // acc.username = acc.username !== '' ? acc.username : (acc.idTokenClaims as any)?.email || '';
                    // Account selection logic is app dependent. Adjust as needed for different use cases.
                    msalInstance.setActiveAccount(acc);
                }

                // 11/11/2024 - I think this was causing "double login" issues when a new token is being acquired silently, or at least is unnecessary.
                // Leaving commented out for now in case it's absence causes issues
                // if (event && event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                //     const loadingId = randomString();
                //     showLoading(loadingId);
                //     trackTrace('Acquiring new token via redirect after ACQUIRE_TOKEN_FAILURE..');
                //     msalInstance
                //         .acquireTokenRedirect({
                //             ...loginRequest,
                //             redirectStartPage: window.location.href,
                //         })
                //         .catch(() => {
                //             setTimeout(() => {
                //                 // If we haven't redirected after 1 second, clear the loading indicator
                //                 loadingComplete(loadingId);
                //             }, 1000);
                //         });
                // }

                if (event && event.payload && event.eventType === EventType.ACCOUNT_ADDED && event.payload.account) {
                    trackTrace('New account added, setting as active..');
                    const acc = event.payload.account;
                    msalInstance.setActiveAccount(acc);
                }

                if (event && event.payload && event.eventType === EventType.ACCOUNT_REMOVED && event.payload.account) {
                    trackTrace('Account removed, checking if active..');
                    if (msalInstance.getActiveAccount()?.localAccountId === event.payload.account?.localAccountId) {
                        trackTrace('Account removed is active, unsetting account..');
                        msalInstance.setActiveAccount(null);
                    }
                }
            });

            return () => {
                if (callbackId) {
                    msalInstance.removeEventCallback(callbackId);
                }
            };
        });
    }, [msalInstance]);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={{ ...loginRequest }}
                errorComponent={ErrorComponent}
                loadingComponent={LoadingComponent}
            >
                {children}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
}
