import { useParams } from 'react-router-dom';
import {
    DynamicListWithCallback,
    DynamicListWithCallbackItems,
} from '../../../components/elements/input/DynamicListWithCallback';
import { useLayout } from '../../../providers/LayoutProvider';

import { ManageCriticalityTier } from './ManageCriticalityTier';
import { useLogging } from '../../../providers/LoggingProvider';

export interface Props {
    value?: DynamicListWithCallbackItems;
}

export function CriticalityTiersControl(props: Props) {
    // eslint-disable-next-line react/destructuring-assignment
    const initialValues = props.value;
    const { organizationId } = useParams();
    const { setAndOpenRightContextDrawer, closeAndClearRightContextDrawer } = useLayout();
    const { trackTrace } = useLogging();

    let newCriticalityFromClonedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnCloneCriticalityPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!newCriticalityFromClonedCallbackForListUi) {
            trackTrace('newCriticalityFromClonedCallbackForListUi was not defined after the item was was persisted');
            return;
        }

        newCriticalityFromClonedCallbackForListUi(label, value, helpDialogText);
        newCriticalityFromClonedCallbackForListUi = undefined;
    };

    const handleOnCloneCriticality = (
        label: string,
        value: string,
        newItemFromClonedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newCriticalityFromClonedCallbackForListUi = newItemFromClonedCallback;

        setAndOpenRightContextDrawer(
            <ManageCriticalityTier
                organizationId={organizationId!}
                cloneAsNew
                existingCriticalityTierId={value}
                actionCompleteCallback={handleOnCloneCriticalityPersisted}
            />,
            'Clone Criticality Tier',
        );
    };

    let criticalityEditedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnEditedCriticalityPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!criticalityEditedCallbackForListUi) {
            trackTrace('criticalityEditedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        criticalityEditedCallbackForListUi(label, value, helpDialogText);
        criticalityEditedCallbackForListUi = undefined;
    };

    const handleOnEditCriticality = (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        criticalityEditedCallbackForListUi = itemEditedCallback;

        setAndOpenRightContextDrawer(
            <ManageCriticalityTier
                organizationId={organizationId!}
                existingCriticalityTierId={value}
                actionCompleteCallback={handleOnEditedCriticalityPersisted}
            />,
            'Manage Criticality Tier',
        );
    };

    let newCriticalityPersistedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnNewCriticalityPersisted = (label: string, value: string, helpDialogText?: string) => {
        if (!newCriticalityPersistedCallbackForListUi) {
            trackTrace('newItemPersistedCallbackForListUi was not defined after the new item was was persisted');
            return;
        }

        newCriticalityPersistedCallbackForListUi(label, value, helpDialogText);
        newCriticalityPersistedCallbackForListUi = undefined;

        closeAndClearRightContextDrawer();
    };

    const handleOnAddCriticality = (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newCriticalityPersistedCallbackForListUi = newItemPersistedCallback;

        setAndOpenRightContextDrawer(
            <ManageCriticalityTier
                organizationId={organizationId!}
                criticalityTierName={newItemLabel}
                actionCompleteCallback={handleOnNewCriticalityPersisted}
            />,
            'Define New Criticality Tier',
        );
    };

    return (
        <DynamicListWithCallback
            name="criticalityTiers"
            label="Business Criticality Tiers"
            helpDialogText="For example: Mission Critical, Business Critical, Business Operational, Administrative, etc. You will define priority and additional details later."
            newItemLabel="New Tier Name"
            value={initialValues}
            onAddItem={handleOnAddCriticality}
            onEditItem={handleOnEditCriticality}
            onCloneItem={handleOnCloneCriticality}
        />
    );
}
