import { apiSlice, RtkTagType } from '../../api/apiSlice';
import { Result } from '../../../models/Result';
import { AddOnWorkload } from '../../../models/services/AddOnWorkload';
import { GetAddOnsForServiceByIdRequest } from '../../../models/services/requests/GetAddOnsForServiceByIdRequest';
import { CreateServiceAddOnsRequest } from '../../../models/services/requests/CreateServiceAddOnRequest';
import { RemoveServiceAddOnRequest } from '../../../models/services/requests/RemoveServiceAddOnRequest';

export const serviceAddOnsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        serviceAddOnsByServiceId: builder.query<AddOnWorkload[], GetAddOnsForServiceByIdRequest>({
            query: (request) =>
                `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/add-ons`,
            transformResponse: (responseData: Result<AddOnWorkload[]>) => {
                responseData.value.sort((a, b) => {
                    if (a.enabled && !b.enabled) {
                        return -1;
                    }

                    if (!a.enabled && b.enabled) {
                        return 1;
                    }

                    return 0;
                });

                return responseData.value;
            },
            providesTags: (result, error, request) =>
                result ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceAddOnsNew: builder.mutation<void, CreateServiceAddOnsRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/add-ons`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceAddOnRemove: builder.mutation<void, RemoveServiceAddOnRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/add-ons/${request.addOnWorkload}`,
                method: 'DELETE',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
    }),
});

export const { useServiceAddOnsByServiceIdQuery, useServiceAddOnsNewMutation, useServiceAddOnRemoveMutation } =
    serviceAddOnsApiSlice;
