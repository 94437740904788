import { Formik, Form, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { ToggleButton } from '../../../components/elements/input/ToggleButton';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';
import { FlowUtils } from '../../../utils/FlowUtils';
import { stringSequentialValidation } from '../../../utils/validation/StringSequentialValidation';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const YES_NO_OPTIONS = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
];

type Props = {
    organizationId: string;
    cloneAsNew?: boolean;
    existingEnvironmentTypeId?: string;
    environmentTypeName?: string;
    actionCompleteCallback: (label: string, value: string, helpDialogText?: string) => void;
};

export function ManageEnvironmentType({
    organizationId,
    cloneAsNew,
    existingEnvironmentTypeId,
    environmentTypeName = '',
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE = {
        name: environmentTypeName,
        businessKey: '',
        description: '',
        isProductionEnvironment: 'NO',
        deploymentPriority: '',
    };

    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const {
        getEnvironmentTypeById,
        newEnvironmentType,
        updateEnvironmentType,
        validateEnvironmentTypeBusinessKeyIsUnique,
    } = useBackendService();
    const { trackTrace } = useLogging();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Environment Type Name is required'),
        businessKey: stringSequentialValidation([
            Yup.string()
                .matches(/^[a-z]*$/, 'Environment Type Business Key can only contain lowercase letters')
                .min(2, 'Environment Type Business Key must be at least 1 character')
                .max(3, 'Environment Type Business Key must be 3 characters or less')
                .required('Environment Type Business Key is required'),
            Yup.string().test(
                'is-unique',
                'Environment Type Business Key must be unique for this Organization',
                (value: string | undefined) => {
                    if (existingEnvironmentTypeId) {
                        // wait until parent product is set before validating
                        return true;
                    }

                    if (!value) {
                        return false;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateEnvironmentTypeBusinessKeyIsUnique(
                                {
                                    organizationId,
                                    businessKey: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        description: Yup.string().required('Description is required'),
        isProductionEnvironment: Yup.string().ensure().required('Make a selection'),
        deploymentPriority: Yup.number()
            .required('Deployment Order is required')
            .min(0, 'Deployment Order must be a number equal to or greater than 0')
            .integer('Deployment Order must be an integer'),
    });

    const fetchData = async () => {
        trackTrace(`Loading existing environment type data to edit '${existingEnvironmentTypeId}'`);
        const response = await getEnvironmentTypeById({
            organizationId,
            id: existingEnvironmentTypeId!,
        });
        setInitialFormState({
            name: response.name,
            businessKey: response.businessKey,
            description: response.description,
            isProductionEnvironment: response.isProductionEnvironment ? 'YES' : 'NO',
            deploymentPriority: response.deploymentPriority.toString(),
        });
    };

    useEffect(() => {
        if (existingEnvironmentTypeId) {
            fetchData();
        } else {
            // reset form
            setInitialFormState(INITIAL_FORM_STATE);
        }

        setRightContextDrawerActions([
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: existingEnvironmentTypeId ? 'Save' : 'Create',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ]);
    }, [existingEnvironmentTypeId]);

    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        if (existingEnvironmentTypeId && !cloneAsNew) {
            // Update existing environment type
            trackTrace(`Updating environment type  '${existingEnvironmentTypeId}'..`);
            const envRes = await updateEnvironmentType({
                organizationId,
                id: existingEnvironmentTypeId,
                ...values,
                isProductionEnvironment: values.isProductionEnvironment === 'YES',
                deploymentPriority: parseInt(values.deploymentPriority, 10),
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        } else {
            // Create new environment type
            trackTrace('New environment type..');
            const envRes = await newEnvironmentType({
                organizationId,
                ...values,
                isProductionEnvironment: values.isProductionEnvironment === 'YES',
                deploymentPriority: parseInt(values.deploymentPriority, 10),
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        }

        enqueueSnackbar('Environment type saved');
        trackTrace(`Success: '${resId}'`);
        actionCompleteCallback(`${values.name} (${values.businessKey.toUpperCase()})`, resId, values.description);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            label="Environment Type Name"
                                            required
                                            autoFocus={!existingEnvironmentTypeId ? true : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="businessKey"
                                            label="Environment Type Business Key"
                                            disabled={!!(existingEnvironmentTypeId && !cloneAsNew)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            helpDialogText="A business description to assist users in understanding the purpose and differentiate this environment type"
                                            multiline
                                            rows="3"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ToggleButton
                                            name="isProductionEnvironment"
                                            label="Will this environment contain Production data?"
                                            helpDialogText="It is common to have a staging or similar environment that contains production data for validation"
                                            options={YES_NO_OPTIONS}
                                            disabled={!!(existingEnvironmentTypeId && !cloneAsNew)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="deploymentPriority"
                                            label="Deployment Order"
                                            helpDialogText="A numerical value that indicates the sequence in which this environment type should be deployed. Lower numbers signify earlier deployment."
                                            inputProps={{ min: '1' }}
                                            required
                                            type="number"
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
