import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
    Box,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { User, RoleAssignmentDetails } from '../../../models/users/User';
import { Role, RoleUtility } from '../../../models/users/Role';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { RoleAssignment } from '../../../models/users/RoleAssignment';
import { useLogging } from '../../../providers/LoggingProvider';

type Props = {
    organizationId: string;
    user: User;
    roles: Role[];
    actionCompleteCallback: (user: User, updatedRoleAssignments: RoleAssignmentDetails[]) => void;
};

export function ManageUserRoles({ organizationId, user, roles, actionCompleteCallback }: Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { addUserToRole, removeUserFromRole } = useBackendService();
    const { trackTrace } = useLogging();

    const [roleAssignmentData, setRoleAssignmentData] = useState<RoleAssignmentDetails[]>([]);

    useEffect(() => {
        setRoleAssignmentData(user.roleAssignments);
    }, [user.roleAssignments]);

    const handleRoleChange = async (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        trackTrace('Updating user roles...');
        const roleId: string = event.target.value;

        if (isChecked) {
            const roleAssignment: RoleAssignment = await addUserToRole({
                userId: user.id,
                roleId,
                organizationId,
                scope: '/',
            });

            const roleAssignmentDetails: RoleAssignmentDetails = {
                id: roleAssignment.id,
                roleId,
                scope: '/',
                actionKeys: roles.find((r) => r.id === roleId)?.actions.map((a) => a.key) || [],
            };

            const updatedAssignments = [...roleAssignmentData, roleAssignmentDetails];
            setRoleAssignmentData(updatedAssignments);
            actionCompleteCallback(user, updatedAssignments);

            enqueueSnackbar(`Role Added for ${user.friendlyName}`);
        } else {
            const removedRoleAssignment = roleAssignmentData.find((ra) => ra.roleId === roleId);
            const updatedAssignments = [...roleAssignmentData].filter((ra) => {
                return ra.roleId !== roleId;
            });

            if (removedRoleAssignment) {
                await removeUserFromRole({ roleAssignmentId: removedRoleAssignment.id, organizationId });
                setRoleAssignmentData(updatedAssignments);
                actionCompleteCallback(user, updatedAssignments);

                enqueueSnackbar(`Role Removed for ${user.friendlyName}`);
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>User:</strong> {user.friendlyName}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <strong>Email Address:</strong> {user.email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <Typography>
                                    <strong>User Role Assignments:</strong>
                                </Typography>
                                <FormGroup>
                                    {RoleUtility.copyAndSort(roles).map((role) => (
                                        /* eslint-disable react/jsx-wrap-multilines */
                                        <Box key={role.id} display="flex" alignItems="center">
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        disabled={role.defaultOrgRole}
                                                        checked={
                                                            !!roleAssignmentData.find((ra) => ra.roleId === role.id)
                                                        }
                                                        onChange={handleRoleChange}
                                                        value={role.id}
                                                    />
                                                }
                                                label={role.name}
                                            />
                                            <Tooltip title={role.description} sx={{ marginLeft: 'auto' }}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Box>
                                        /* eslint-enable react/jsx-wrap-multilines */
                                    ))}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Grid>
    );
}
