import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { Fab, Tooltip, useTheme, Zoom } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLayout } from '../../../providers/LayoutProvider';
import { PageDialog } from '../../elements/PageDialog';
import { newResourceDialogInstance } from '../../../features/journeys/new-resource/NewResourceDialog';
import { contextMapDialogInstance } from '../../../features/context-map/ContextMapDialog';
import { shortcutsDialogInstance } from '../../../features/shortcuts/ShortcutsDialog';
import { PageDialogInstance } from '../../elements/PageDialog/PageDialogInstance';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { newServiceDialogInstance } from '../../../features/journeys/new-service/NewServiceDialog';
import { newProductDialogInstance } from '../../../features/journeys/new-product/NewProductDialog';

export function Dialogs() {
    const { dialogState, setAndOpenDialog, closeAndClearDialog } = useLayout();
    const { isDesignModule } = useEntityContext();
    const theme = useTheme();
    const location = useLocation();

    const toggleDialog = (dialogInstance: PageDialogInstance) => {
        if (!dialogState.isOpen) {
            setAndOpenDialog(dialogInstance);
            // Uncomment if also uncommenting the below functionality
            // return;
        }

        // Disable this for now, determine if we want to conditionally allow changing dialogs
        // if there is not state contained within the dialog (e.g. Journeys)
        // if (dialogState.dialogInstance?.route === dialogInstance.route) {
        //     closeAndClearDialog();
        // } else {
        //     setAndOpenDialog(dialogInstance);
        // }
    };

    useHotkeys('n', () => toggleDialog(newResourceDialogInstance));
    useHotkeys('c', () => toggleDialog(contextMapDialogInstance));
    useHotkeys('s', () => toggleDialog(shortcutsDialogInstance));

    useEffect(() => {
        const hashSplit = location.hash.split('#');
        if (hashSplit.length < 2 && dialogState.isOpen) {
            closeAndClearDialog();
            return;
        }

        if (hashSplit.length > 1) {
            const modal = hashSplit[1].split('/')[0];
            switch (modal) {
                case contextMapDialogInstance.route:
                    setAndOpenDialog(contextMapDialogInstance);
                    break;
                case shortcutsDialogInstance.route:
                    setAndOpenDialog(shortcutsDialogInstance);
                    break;
                case newResourceDialogInstance.route:
                    setAndOpenDialog(newResourceDialogInstance);
                    break;
                case newProductDialogInstance.route:
                    setAndOpenDialog(newProductDialogInstance);
                    break;
                case newServiceDialogInstance.route:
                    setAndOpenDialog(newServiceDialogInstance);
                    break;
                default:
                    break;
            }
        }
    }, [location.hash]);

    return (
        <>
            <PageDialog />
            {isDesignModule && (
                <Tooltip title="Define a new resource" aria-label="add">
                    <Zoom
                        in
                        timeout={{
                            enter: theme.transitions.duration.enteringScreen,
                            exit: theme.transitions.duration.leavingScreen,
                        }}
                        style={{
                            transitionDelay: `${theme.transitions.duration.leavingScreen}ms`,
                        }}
                        unmountOnExit
                    >
                        <Fab
                            color="primary"
                            onClick={() => setAndOpenDialog(newResourceDialogInstance)}
                            sx={{
                                position: 'fixed',
                                bottom: '4rem',
                                right: '4rem',
                            }}
                        >
                            <AddIcon />
                        </Fab>
                    </Zoom>
                </Tooltip>
            )}
        </>
    );
}
