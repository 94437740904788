import { useState } from 'react';
import { PageDialogInstance } from '../../../components/elements/PageDialog/PageDialogInstance';
import StepperJourney from '../../../components/elements/StepperJourney';
import { CreateServiceRequest } from '../../../models/services/requests/CreateServiceRequest';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useLayout } from '../../../providers/LayoutProvider';
import { useJourneyServiceNewMutation } from '../journeysApiSlice';
import StepperStep from '../../../components/elements/StepperStep';
import NewServiceStepType from './NewServiceStepType';
import NewServiceStepAccess from './NewServiceStepAccess';
import NewServiceStepAddOns from './NewServiceStepAddOns';
import NewServiceStepDetails from './NewServiceStepDetails';
import NewServiceStepEnvironments, { CreateServiceEnvironmentsState } from './NewServiceStepEnvironments';
import NewServiceStepDeploy from './NewServiceStepDeploy';
import { CreateServiceAddOnsRequest } from '../../../models/services/requests/CreateServiceAddOnRequest';
import { EnableServiceEnvironmentInstanceRequest } from '../../../models/services/requests/EnableServiceEnvironmentInstanceRequest';
import { CreateServiceEnvironmentInstanceRequest } from '../../../models/services/requests/CreateServiceEnvironmentInstanceRequest';
import { JourneyStageRequest } from '../../../models/journeys/requests/ExecuteJourneyRequest';
import { EnableServiceRequest } from '../../../models/services/requests/EnableServiceRequest';

function NewServiceDialog() {
    const [createServiceRequest, setCreateServiceRequest] = useState<Partial<CreateServiceRequest>>({});
    const [createAddOnsRequest, setCreateAddOnsRequest] = useState<Partial<CreateServiceAddOnsRequest>>({});
    const [environmentsToCreate, setEnvironmentsToCreate] = useState<CreateServiceEnvironmentsState>({
        environments: [],
    });
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [activeStepIsValid, setActiveStepIsValid] = useState(true);
    // eslint-disable-next-line no-spaced-func
    const [activeStepValidate, setActiveStepValidate] = useState<(() => boolean | Promise<boolean>) | undefined>();
    const [executeJourney, { isLoading: isSubmitting }] = useJourneyServiceNewMutation();
    const { entityContext, changeEntityContext } = useEntityContext();
    const { closeAndClearDialog } = useLayout();

    const steps: JSX.Element[] = [];

    const resetStepState = () => {
        setActiveStepIsValid(true);
        setActiveStepValidate(undefined);
    };

    const handleGoToNextStep = async () => {
        if (activeStepValidate && !(await activeStepValidate())) {
            return;
        }
        if (activeStepIsValid && activeStepIndex < steps.length - 1) {
            resetStepState();
            setActiveStepIndex(activeStepIndex + 1);
        }
    };

    const handleGoToPreviousStep = async () => {
        if (activeStepIndex > 0) {
            resetStepState();
            setActiveStepIndex(activeStepIndex - 1);
        }
    };

    const handleFinish = async (deployNow: boolean) => {
        const stages: JourneyStageRequest[] = [
            {
                id: 'createService',
                useCase: 'CreateServiceUseCase',
                request: {
                    ...createServiceRequest,
                    // if short name is omitted, use the business key
                    shortName: createServiceRequest.shortName || createServiceRequest.businessKey,
                    skipEnableForDeploy: true,
                } as CreateServiceRequest,
            },
            {
                id: 'createAddOns',
                useCase: 'CreateServiceAddOnWorkloadsUseCase',
                request: {
                    organizationId: entityContext.organizationSlug!,
                    productId: createServiceRequest.productId!,
                    serviceId: '@{createService|id}',
                    addOnWorkloads: createAddOnsRequest.addOnWorkloads || [],
                } as CreateServiceAddOnsRequest,
            },
        ];

        environmentsToCreate.environments.forEach((env) => {
            const createEnv: CreateServiceEnvironmentInstanceRequest = {
                organizationId: entityContext.organizationSlug!,
                productId: createServiceRequest.productId!,
                serviceId: '@{createService|id}',
                environmentTypeId: env,
            };
            stages.push({
                id: `createEnvironment-${env.toLowerCase()}`,
                useCase: 'CreateServiceEnvironmentInstanceUseCase',
                request: createEnv,
            });
        });

        const enableService: EnableServiceRequest = {
            organizationId: entityContext.organizationSlug!,
            productId: createServiceRequest.productId!,
            serviceId: '@{createService|id}',
        };
        stages.push({
            id: 'enableService',
            useCase: 'EnableServiceUseCase',
            request: enableService,
        });

        environmentsToCreate.environments.forEach((env) => {
            if (deployNow) {
                const enableEnv: EnableServiceEnvironmentInstanceRequest = {
                    organizationId: entityContext.organizationSlug!,
                    productId: createServiceRequest.productId!,
                    serviceId: '@{createService|id}',
                    environmentInstanceId: `@{createEnvironment-${env.toLowerCase()}|id}`,
                };
                stages.push({
                    id: `enableEnvironment-${env.toLowerCase()}`,
                    useCase: 'EnableServiceEnvironmentInstanceUseCase',
                    request: enableEnv,
                });
            }
        });

        const newServiceJourneyResponse = await executeJourney({
            organizationId: entityContext.organizationSlug!,
            stages,
        }).unwrap();

        changeEntityContext({
            organizationSlug: entityContext.organizationSlug!,
            productSlug: newServiceJourneyResponse.results[0].response.productId,
            serviceSlug: newServiceJourneyResponse.results[0].response.id,
        });
        closeAndClearDialog();
    };

    steps.push(
        ...[
            <StepperStep label="Choose a Service type">
                <NewServiceStepType
                    onGoToNextStep={handleGoToNextStep}
                    setCreateServiceRequest={setCreateServiceRequest}
                    createServiceRequest={createServiceRequest}
                    setCreateAddOnsRequest={setCreateAddOnsRequest}
                />
            </StepperStep>,
            // <StepperStep label="Access needs">
            //     <NewServiceStepAccess
            //         onGoToNextStep={handleGoToNextStep}
            //         setCreateServiceRequest={setCreateServiceRequest}
            //     />
            // </StepperStep>,
            <StepperStep label="Add-on selection" onNextStep={handleGoToNextStep}>
                <NewServiceStepAddOns
                    workloadType={createServiceRequest.workloadType}
                    createAddOnsRequest={createAddOnsRequest}
                    setCreateAddOnsRequest={setCreateAddOnsRequest}
                />
            </StepperStep>,
            <StepperStep
                label="Additional details"
                onNextStep={handleGoToNextStep}
                nextStepDisabled={!activeStepIsValid}
            >
                <NewServiceStepDetails
                    setIsValid={setActiveStepIsValid}
                    setValidate={setActiveStepValidate}
                    createServiceRequest={createServiceRequest}
                    setCreateServiceRequest={setCreateServiceRequest}
                />
            </StepperStep>,
            <StepperStep label="Environments" onNextStep={handleGoToNextStep}>
                <NewServiceStepEnvironments
                    environmentsToCreate={environmentsToCreate}
                    setEnvironmentsToCreate={setEnvironmentsToCreate}
                />
            </StepperStep>,
            <StepperStep label="Enablement">
                <NewServiceStepDeploy
                    createServiceRequest={createServiceRequest}
                    createAddOnsRequest={createAddOnsRequest}
                    environmentsToCreate={environmentsToCreate}
                    onFinish={handleFinish}
                    isSubmitting={isSubmitting}
                />
            </StepperStep>,
        ],
    );

    return <StepperJourney activeStepIndex={activeStepIndex} onPreviousStep={handleGoToPreviousStep} steps={steps} />;
}

export const newServiceDialogInstance: PageDialogInstance = {
    route: 'new-service',
    children: <NewServiceDialog />,
};
