import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDialogInstance } from '../components/elements/PageDialog/PageDialogInstance';
import { ProductListResponse } from '../models/products/ProductListResponse';
import { Service } from '../models/services/Service';

type ProviderProps = {
    children?: ReactNode;
};

type Cache = {
    id: string;
    name: string;
};

export type DrawerFooterAction = {
    action: () => void;
    actionText?: string;
    actionDisabled?: boolean;
    color?: 'primary' | 'warning' | 'error';
    variant?: 'contained' | 'outlined';
};

type LayoutContextType = {
    closeAndClearDialog: () => void;
    setAndOpenDialog: (dialogInstance: PageDialogInstance) => void;
    dialogState: {
        isOpen: boolean;
        dialogInstance?: PageDialogInstance;
    };
    toggleLeftNavDrawer: () => void;
    isLeftNavDrawerOpen: boolean;
    closeAndClearRightContextDrawer: () => void;
    setAndOpenRightContextDrawer: (
        content?: ReactNode,
        header?: string,
        footerActions?: DrawerFooterAction[],
        showBackdrop?: boolean,
    ) => void;
    setRightContextDrawerActions: (footerActions: DrawerFooterAction[]) => void;
    rightContextDrawerState: {
        content?: ReactNode;
        showBackdrop?: boolean;
        header?: string;
        footerActions?: DrawerFooterAction[];
        isOpen: boolean;
    };
    hideNewButton: () => void;
    setAndShowNewButton: (func: () => void, text?: string) => void;
    newButtonAction: (() => void) | undefined;
    newButtonText: string;
    togglePreviewFeatureVisibility: () => void;
    isPreviewFeatureVisible: boolean;
    extraToolbarButtons: JSX.Element[];
    showAdditionalToolbarButtons: (buttons: JSX.Element[]) => void;
    clearAdditionalToolbarButtons: () => void;
    customTabs: JSX.Element | undefined;
    setCustomTabContainer: (tabs: JSX.Element) => void;
    clearCustomTabContainer: () => void;
    updateProductCache: (productsResponse: ProductListResponse) => void;
    updateServiceCache: (services: Service[]) => void;
    getProductNameFromCache: (id?: string) => string | undefined;
    getServiceNameFromCache: (id?: string) => string | undefined;
    isPlatformManagerMode: boolean;
    togglePlatformManagerMode: () => void;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export function LayoutProvider({ children }: ProviderProps) {
    const navigate = useNavigate();

    // Dialogs
    const [dialogState, setDialogState] = useState<{
        isOpen: boolean;
        dialogInstance?: PageDialogInstance;
    }>({ isOpen: false });
    const closeAndClearDialog = () => {
        setDialogState({
            isOpen: false,
        });
        navigate(window.location.pathname, { replace: true });
    };
    const setAndOpenDialog = (dialogInstance: PageDialogInstance) => {
        if (dialogState.dialogInstance?.route === dialogInstance.route) {
            // Already open
            return;
        }
        setDialogState({ dialogInstance, isOpen: true });
        // if we are't already at that hash from a deep link load
        if (!window.location.hash.startsWith(`#${dialogInstance.route}`)) {
            navigate(`${window.location.pathname}#${dialogInstance.route}`);
        }
    };

    // Left Nav Bar
    const [isLeftNavDrawerOpen, setIsLeftDrawerOpen] = useState(true);
    const toggleLeftNavDrawer = () => {
        setIsLeftDrawerOpen(!isLeftNavDrawerOpen);
    };

    // Right Context Drawer
    // eslint-disable-next-line no-spaced-func
    const [rightContextDrawerState, setRightContextDrawerState] = useState<{
        content?: ReactNode;
        header?: string;
        footerActions?: DrawerFooterAction[];
        showBackdrop?: boolean;
        isOpen: boolean;
    }>({ isOpen: false });

    const closeAndClearRightContextDrawer = () => {
        setRightContextDrawerState({
            isOpen: false,
        });
    };

    const setAndOpenRightContextDrawer = (
        content?: ReactNode,
        header?: string,
        footerActions?: DrawerFooterAction[],
        showBackdrop: boolean = false,
    ) => {
        setRightContextDrawerState({ content, header, footerActions, showBackdrop, isOpen: true });
    };

    const setRightContextDrawerActions = (footerActions: DrawerFooterAction[]) => {
        setRightContextDrawerState({ ...rightContextDrawerState, footerActions });
    };

    // Left Nav Bar New Button
    const [newButtonAction, setNewButtonAction] = useState<(() => void) | undefined>(undefined);
    const [newButtonText, setNewButtonText] = useState<string>('Create');

    const hideNewButton = () => {
        setNewButtonAction(undefined);
    };

    const setAndShowNewButton = (func: () => void, text?: string) => {
        setNewButtonAction(func);
        setNewButtonText(text || 'Create');
    };

    const [extraToolbarButtons, setExtraToolbarButtons] = useState<JSX.Element[]>([]);
    const showAdditionalToolbarButtons = (buttons: JSX.Element[]) => {
        setExtraToolbarButtons(buttons);
    };
    const clearAdditionalToolbarButtons = () => {
        setExtraToolbarButtons([]);
    };

    const [customTabs, setCustomTabs] = useState<JSX.Element>();
    const setCustomTabContainer = (tabs: JSX.Element) => {
        setCustomTabs(tabs);
    };
    const clearCustomTabContainer = () => {
        setCustomTabs(undefined);
    };

    // Toggle for hiding and showing components
    const [isPreviewFeatureVisible, setIsPreviewFeatureVisible] = useState(() => {
        const cachedValue = sessionStorage.getItem('showPreviewFeatures');
        return cachedValue ? cachedValue === 'true' : false;
    });
    const togglePreviewFeatureVisibility = () => {
        sessionStorage.setItem('showPreviewFeatures', (!isPreviewFeatureVisible).toString());
        setIsPreviewFeatureVisible(!isPreviewFeatureVisible);
    };

    // Toggle for enabling / disabling platform manager features
    const [isPlatformManagerMode, setIsPlatformManagerMode] = useState(() => {
        const cachedValue = sessionStorage.getItem('showPlatformManagerMode');
        return cachedValue ? cachedValue === 'true' : false;
    });
    const togglePlatformManagerMode = () => {
        sessionStorage.setItem('showPlatformManagerMode', (!isPlatformManagerMode).toString());
        setIsPlatformManagerMode(!isPlatformManagerMode);
    };

    const [productCache, updateProductCacheState] = useState<string | undefined>(
        localStorage.getItem('productCache') || undefined,
    );

    const updateProductCache = (productsResponse: ProductListResponse) => {
        const updatedCache: Cache[] = [];

        const currentCache = productCache ? (JSON.parse(productCache) as Cache[]) : [];

        productsResponse.products.forEach((product) => {
            const index = currentCache.findIndex((x) => x.id === product.id);
            if (index !== -1) {
                updatedCache.push({ id: currentCache[index].id, name: currentCache[index].name });
            } else {
                updatedCache.push({ id: product.id, name: product.name });
            }
        });

        const updatedJson = JSON.stringify(updatedCache);
        updateProductCacheState(updatedJson);
        localStorage.setItem('productCache', updatedJson);
    };

    const getProductNameFromCache = (id?: string) => {
        if (productCache && id) {
            const json = JSON.parse(productCache) as Cache[];
            const index = json.findIndex((x) => x.id === id);
            if (index !== -1) {
                return json[index].name;
            }
        }

        return undefined;
    };

    const [serviceCache, updateServiceCacheState] = useState<string | undefined>(
        localStorage.getItem('serviceCache') || undefined,
    );

    const updateServiceCache = (services: Service[]) => {
        const updatedCache: Cache[] = [];
        const currentCache = serviceCache ? (JSON.parse(serviceCache) as Cache[]) : [];

        services.forEach((service) => {
            const index = currentCache.findIndex((x) => x.id === service.id);
            if (index !== -1) {
                updatedCache.push({ id: currentCache[index].id, name: currentCache[index].name });
            } else {
                updatedCache.push({ id: service.id, name: service.name });
            }
        });

        const updatedJson = JSON.stringify(updatedCache);
        updateServiceCacheState(updatedJson);
        localStorage.setItem('serviceCache', updatedJson);
    };

    const getServiceNameFromCache = (id?: string) => {
        if (serviceCache && id) {
            const json = JSON.parse(serviceCache) as Cache[];
            const index = json.findIndex((x) => x.id === id);
            if (index !== -1) {
                return json[index].name;
            }
        }

        return undefined;
    };

    const memoValue = useMemo(
        () => ({
            dialogState,
            closeAndClearDialog,
            setAndOpenDialog,
            toggleLeftNavDrawer,
            isLeftNavDrawerOpen,
            closeAndClearRightContextDrawer,
            setAndOpenRightContextDrawer,
            setRightContextDrawerActions,
            rightContextDrawerState,
            hideNewButton,
            setAndShowNewButton,
            newButtonAction,
            newButtonText,
            togglePreviewFeatureVisibility,
            isPreviewFeatureVisible,
            extraToolbarButtons,
            showAdditionalToolbarButtons,
            clearAdditionalToolbarButtons,
            customTabs,
            setCustomTabContainer,
            clearCustomTabContainer,
            updateProductCache,
            updateServiceCache,
            getProductNameFromCache,
            getServiceNameFromCache,
            togglePlatformManagerMode,
            isPlatformManagerMode,
        }),
        [
            dialogState,
            rightContextDrawerState,
            newButtonAction,
            isLeftNavDrawerOpen,
            isPreviewFeatureVisible,
            extraToolbarButtons,
            customTabs,
            productCache,
            serviceCache,
            isPlatformManagerMode,
        ],
    );

    return <LayoutContext.Provider value={memoValue}>{children}</LayoutContext.Provider>;
}

export const useLayout = (): LayoutContextType => {
    const user = useContext(LayoutContext);
    if (!user) {
        throw new Error('useLayout must be used within a Layout Provider');
    }
    return user;
};
