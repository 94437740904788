import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { NotificationMessage } from '../models/notification/Notification';
import { useLogging } from './LoggingProvider';

type ProviderProps = {
    children?: ReactNode;
};

type NotificationContextType = {
    addNotification: (notification: NotificationMessage) => void;
    removeNotification: (notification: NotificationMessage) => void;
    notifications: NotificationMessage[];
};

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export function NotificationProvider({ children }: ProviderProps) {
    const [notifications, setNotifications] = useState<NotificationMessage[]>([]);
    const { trackTrace } = useLogging();

    const addNotification = (notification: NotificationMessage) => {
        const existing = notifications.find((n) => n.id === notification.id);
        if (existing) {
            trackTrace('notification already sent');
            return;
        }

        const updatedList = [...notifications, notification];
        setNotifications(updatedList);
    };

    const removeNotification = (notification: NotificationMessage) => {
        const updatedList = [...notifications.filter((n) => n.id !== notification.id)];
        setNotifications(updatedList);
    };

    const memoValue = useMemo(
        () => ({
            notifications,
            addNotification,
            removeNotification,
        }),
        [notifications],
    );

    return <NotificationContext.Provider value={memoValue}>{children}</NotificationContext.Provider>;
}

export const useNotification = (): NotificationContextType => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a Notification Provider');
    }
    return context;
};
