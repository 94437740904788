import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const StyledArrowUp = styled(Typography)(({ theme }) => ({
    display: 'block',
    boxSizing: 'border-box',
    width: '6px',
    position: 'relative',
    transform: 'scale(var(--ggs,1))',
    borderRight: '2px solid transparent',
    borderLeft: '2px solid transparent',
    boxShadow: 'inset 0 0 0 2px',
    height: '48px',
    '&::after': {
        display: 'block',
        boxSizing: 'border-box',
        width: '6px',
        content: '""',
        position: 'absolute',
        height: '6px',
        borderTop: '2px solid',
        borderLeft: '2px solid',
        transform: 'rotate(45deg)',
        top: 0,
        left: '-2px',
    },
}));
