const appConfig = {
    server: {
        backendUrl: process.env.REACT_APP_BACKEND_URL,
    },
    environment: {
        isProduction:
            process.env.REACT_APP_IS_PRODUCTION === undefined || process.env.REACT_APP_IS_PRODUCTION === 'true',
    },
    payments: {
        stripePubKey: '',
    },
    auth: {
        portalClientId: process.env.REACT_APP_B2C_PORTAL_CLIENT_ID,
        tenantId: process.env.REACT_APP_B2C_TENANT_ID,
        tenantName: process.env.REACT_APP_B2C_TENANT_NAME,
        policyName: process.env.REACT_APP_B2C_POLICY_NAME,
        resourceScopes: process.env.REACT_APP_RESOURCE_SCOPES_COMMA_SEPARATED?.split(','),
        scaledSenseAppClientId: '0477b56c-3596-4cb3-b8b4-dd322bf70198',
    },
    roles: {
        defaultRoleId: '824050a7-8ff5-4c9e-9d84-ebdac0823f40',
    },
    platforms: {
        githubAppUrl: 'https://github.com/apps/scaled-sense-automation',
    },
    applicationInsights: {
        connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING,
    },
};

export { appConfig };
