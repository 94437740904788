import { Container, Grid, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Form, Formik, FormikProps, FormikValues } from 'formik';
import { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { ComboBox } from '../../../components/elements/input/ComboBox';
import { SSCard } from '../../../components/elements/SSCard';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { stringSequentialValidation } from '../../../utils/validation/StringSequentialValidation';
import { FlowUtils } from '../../../utils/FlowUtils';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useProductsListQuery } from '../../products/productsApiSlice';
import { CreateServiceRequest } from '../../../models/services/requests/CreateServiceRequest';
import { TextField } from '../../../components/elements/input/TextField';

type Props = {
    setIsValid: (isValid: boolean) => void;
    setValidate: Dispatch<SetStateAction<(() => boolean | Promise<boolean>) | undefined>>;
    createServiceRequest: Partial<CreateServiceRequest>;
    setCreateServiceRequest: Dispatch<SetStateAction<Partial<CreateServiceRequest>>>;
};

export default function NewServiceStepDetails({
    setIsValid,
    setValidate,
    createServiceRequest,
    setCreateServiceRequest,
}: Props) {
    const { entityContext } = useEntityContext();
    const formRef = useRef<FormikProps<FormikValues>>(null);
    const { validateServiceBusinessKeyIsUnique, validateServiceShortNameIsUnique } = useBackendService();
    const { data: products } = useProductsListQuery({ organizationId: entityContext.organizationSlug! });
    const [fieldFocusId, setFieldFocusId] = useState<number>(1);

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        businessKey: stringSequentialValidation([
            Yup.string().required('Service Business Key is required'),
            Yup.string().min(2, 'Service Business Key must be at least 2 characters'),
            Yup.string().max(4, 'Service Business Key must be 4 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Service Business Key can only contain lowercase letters or numbers'),
            Yup.string().test(
                'is-unique',
                'Service Business Key must be unique for this Product',
                (value: string | undefined) => {
                    if (!createServiceRequest.productId) {
                        // wait until parent product is set before validating
                        return true;
                    }

                    if (!value) {
                        return false;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateServiceBusinessKeyIsUnique(
                                {
                                    organizationId: entityContext.organizationSlug!,
                                    productId: createServiceRequest.productId!,
                                    businessKey: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        shortName: stringSequentialValidation([
            Yup.string().min(2, 'Service Short Name must be at least 2 characters'),
            Yup.string().max(30, 'Service Short Name must be 30 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Service Short Name can only contain lowercase letters or numbers'),
            Yup.string().test(
                'is-unique',
                'Service Short Name must be unique for this Product',
                (value: string | undefined) => {
                    if (!createServiceRequest.productId) {
                        // wait until parent product is set before validating
                        return true;
                    }

                    // blank value is allowed as this is not a required field
                    if (!value) {
                        return true;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateServiceShortNameIsUnique(
                                {
                                    organizationId: entityContext.organizationSlug!,
                                    productId: createServiceRequest.productId!,
                                    shortName: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        productId: Yup.string().required('Associated Product is required'),
    });

    const validateForm = async () => {
        await formRef.current?.validateForm();
        // eslint-disable-next-line no-loops/no-loops
        while (formRef.current?.isValidating) {
            // eslint-disable-next-line no-await-in-loop
            await new Promise((resolve) => setTimeout(resolve, 1));
        }
        return !!formRef.current?.isValid;
    };

    useEffect(() => {
        // this arrow function is necessary to prevent the state setter from
        // thinking we are just looking for prev state and running validate on render
        setValidate(() => validateForm);
    }, []);

    return (
        <Container>
            <Formik
                innerRef={formRef}
                initialValues={{
                    productId: createServiceRequest.productId || '',
                    name: createServiceRequest.name || '',
                    shortName: createServiceRequest.shortName || '',
                    businessKey: createServiceRequest.businessKey || '',
                    description: createServiceRequest.description || '',
                }}
                validationSchema={FORM_VALIDATION}
                validateOnMount
                onSubmit={() => {}}
            >
                {({ values, isValid }) => {
                    useEffect(() => {
                        setCreateServiceRequest((prev) => ({
                            ...prev,
                            productId: values.productId,
                            name: values.name,
                            shortName: values.shortName,
                            businessKey: values.businessKey,
                            description: values.description,
                        }));
                    }, [values]);
                    useEffect(() => {
                        setIsValid(isValid);
                    }, [isValid]);
                    return (
                        <Form autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" align="center" gutterBottom>
                                        What should we call this service?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <ComboBox
                                        label="Associated Product"
                                        name="productId"
                                        required
                                        autoFocus={!createServiceRequest.productId}
                                        delayOpenForTransition
                                        options={
                                            products?.map((product) => ({
                                                value: product.id,
                                                label: product.name,
                                            })) || []
                                        }
                                        sx={{ paddingBottom: 1 }}
                                        onFocus={() => setFieldFocusId(1)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 1 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Choose a Product would you like to associate this service to.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        label="Service Name"
                                        name="name"
                                        margin="normal"
                                        required
                                        onFocus={() => setFieldFocusId(2)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 2 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            <Typography>
                                                This is a friendly name that can be used to recognize this Service
                                                within its Product.
                                            </Typography>
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        label="Service Business Key"
                                        name="businessKey"
                                        margin="normal"
                                        required
                                        onFocus={() => setFieldFocusId(3)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 3 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            This is an all lowercase name that is at most 4 characters that can be used
                                            to reference this Service in certain naming conventions.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        label="Service Short Name"
                                        name="shortName"
                                        margin="normal"
                                        onFocus={() => setFieldFocusId(4)}
                                        placeholder={createServiceRequest.businessKey}
                                        InputLabelProps={{
                                            shrink:
                                                !!createServiceRequest.shortName || !!createServiceRequest.businessKey,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 4 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            This is a more friendly, all lowercase name that can be used to reference
                                            this Service in certain naming conventions.
                                        </SSCard>
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={7}>
                                    <TextField
                                        label="Service Description"
                                        name="description"
                                        multiline
                                        rows="3"
                                        onFocus={() => setFieldFocusId(5)}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={5}>
                                    {fieldFocusId === 5 && (
                                        <SSCard cardStyle={{ height: '100%' }}>
                                            Describe what this Service will be doing an how it is used.
                                        </SSCard>
                                    )}
                                </Grid>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
}

export const MemoizedNewServiceStepDetails = memo(NewServiceStepDetails);
