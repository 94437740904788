import { Box, CircularProgress, Container, Link, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { BasicLayout } from '../BasicLayout';

export function Waitlist() {
    const [isIn, setIsIn] = useState(false);

    useEffect(() => {
        setTimeout(() => setIsIn(true), 3000);
    }, []);

    return isIn ? (
        <BasicLayout>
            <Container sx={{ padding: 4 }} maxWidth="sm">
                <Stack spacing={2} textAlign="center" alignItems="center">
                    <Typography variant="h4">You&apos;ve been added to the waitlist!</Typography>
                    <Typography variant="body1">
                        We are excited for you to give{' '}
                        <Link underline="none" href="https://www.scaledsense.com">
                            <strong>Scaled Sense</strong>
                        </Link>{' '}
                        a spin. We will shoot you an email as soon as capacity opens up!
                    </Typography>
                </Stack>
            </Container>
        </BasicLayout>
    ) : (
        <Box display="flex" justifyContent="center" flexDirection="column" height="100vh">
            <CircularProgress sx={{ marginX: 'auto' }} />
        </Box>
    );
}
