import { Box, Container, Typography, Link } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';

export function ResourceNotFound() {
    return (
        <Box width="100%" p={2}>
            <Typography textAlign="center" variant="h6" gutterBottom>
                The resource you are looking for was not found.
            </Typography>
            <Typography textAlign="center">
                <Link component={NavLink} to="/" underline="hover">
                    Click here
                </Link>{' '}
                to be redirected.
            </Typography>
        </Box>
    );
}
