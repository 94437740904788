import { FederatedGroupMember } from '../../../models/federation/groups/FederatedGroupMember';
import { GetFederatedUserByQueryRequest } from '../../../models/federation/users/requests/GetFederatedUserByQueryRequest';
import { Result } from '../../../models/Result';
import { apiSlice, RtkTagType } from '../../api/apiSlice';

export const federatedUsersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        federatedUsersSearch: builder.query<FederatedGroupMember[], GetFederatedUserByQueryRequest>({
            query: (request) =>
                `organizations/${request.organizationId}/federation/users/search/${encodeURIComponent(request.query)}`,
            transformResponse: (responseData: Result<FederatedGroupMember[]>) => {
                return responseData.value;
            },
            providesTags: () => [{ type: RtkTagType.FederatedUsers, id: 'SEARCH' }],
        }),
    }),
});

export const { useLazyFederatedUsersSearchQuery } = federatedUsersApiSlice;
