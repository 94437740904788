import { useSnackbar } from 'notistack';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MetadataDisplay } from '../../../components/elements/MetadataDisplay';
import { PageDialogInstance } from '../../../components/elements/PageDialog/PageDialogInstance';
import { Job } from '../../../models/deployments/Job';

type Props = {
    job: Job;
    logs: string;
};

function ViewLogsDialog({ job, logs }: Props) {
    const { enqueueSnackbar } = useSnackbar();

    const metadata = [
        { label: 'Job ID', value: job.id },
        { label: 'Type', value: job.name },
        { label: 'Status', value: job.status },
        { label: 'Product ID', value: job.productId },
        { label: 'Service ID', value: job.requestingServiceId },
        {
            label: 'Status History',
            // TODO: align with approach on handling line breaks
            value: job.statusHistory
                ?.map((history, index) => `${index + 1}: ${history.startTime} - ${history.status}`)
                .join('\n'),
        },
        {
            label: 'Outputs',
            // TODO: align with approach on handling line breaks
            value: job.outputs
                ? Object.keys(job.outputs)
                      .map((key) => `${key}: ${job.outputs![key]}`)
                      .join('\n')
                : undefined,
        },
        { label: 'Root Correlation ID', value: job.rootCorrelationId },
        { label: 'Relative Correlation ID', value: job.relativeCorrelationId },
        { label: 'Absolute Correlation ID', value: job.absoluteCorrelationId },
        {
            label: 'Debug Logs',
            value: (
                <Tooltip title="Copy URL to clipboard">
                    <IconButton
                        sx={{ marginTop: -1 }}
                        onClick={() => {
                            if (job.debugLogOutputUrl) {
                                navigator.clipboard.writeText(job.debugLogOutputUrl);
                                enqueueSnackbar('Debug log URL copied to clipboard');
                            }
                        }}
                    >
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];
    return (
        <Box>
            <Typography variant="h6">Job Metadata</Typography>
            <MetadataDisplay metadataProperties={metadata} />
            <Divider />
            <Typography variant="h6" marginTop={1}>
                Job Logs
            </Typography>
            <pre>{logs}</pre>
        </Box>
    );
}

export const buildViewLogsDialogInstance: (job: Job, logs: string) => PageDialogInstance = (job: Job, logs: string) => {
    return {
        route: 'logs',
        children: <ViewLogsDialog job={job} logs={logs} />,
    };
};
