import { useParams } from 'react-router-dom';
import {
    DynamicListWithCallback,
    DynamicListWithCallbackItems,
} from '../../../components/elements/input/DynamicListWithCallback';
import { useLayout } from '../../../providers/LayoutProvider';
import { ManageProductRole } from './ManageProductRole';
import { useLogging } from '../../../providers/LoggingProvider';

export interface Props {
    value?: DynamicListWithCallbackItems;
}

export function ProductRolesControl(props: Props) {
    // eslint-disable-next-line react/destructuring-assignment
    const initialValues = props.value;
    const { organizationId } = useParams();
    const { setAndOpenRightContextDrawer, closeAndClearRightContextDrawer } = useLayout();
    const { trackTrace } = useLogging();

    let newProductRoleFromClonedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnCloneProductRolePersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!newProductRoleFromClonedCallbackForListUi) {
            trackTrace('newProductRoleFromClonedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        newProductRoleFromClonedCallbackForListUi(label, value, helpDialogText);
        newProductRoleFromClonedCallbackForListUi = undefined;
    };

    const handleOnCloneProductRole = (
        label: string,
        value: string,
        newItemFromClonedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newProductRoleFromClonedCallbackForListUi = newItemFromClonedCallback;

        setAndOpenRightContextDrawer(
            <ManageProductRole
                cloneAsNew
                organizationId={organizationId!}
                existingProductRoleId={value}
                actionCompleteCallback={handleOnCloneProductRolePersisted}
            />,
            'Clone Product Role',
        );
    };

    let productEditedCallbackForListUi: undefined | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnEditedProductRolePersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!productEditedCallbackForListUi) {
            trackTrace('productEditedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        productEditedCallbackForListUi(label, value, helpDialogText);
        productEditedCallbackForListUi = undefined;
    };

    const handleOnEditProductRole = (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        productEditedCallbackForListUi = itemEditedCallback;

        setAndOpenRightContextDrawer(
            <ManageProductRole
                organizationId={organizationId!}
                existingProductRoleId={value}
                actionCompleteCallback={handleOnEditedProductRolePersisted}
            />,
            'Manage Product Role',
        );
    };

    let newProductRolePersistedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnNewProductRolePersisted = (label: string, value: string, helpDialogText?: string) => {
        if (!newProductRolePersistedCallbackForListUi) {
            trackTrace('newItemPersistedCallbackForListUi was not defined after the new item was persisted');
            return;
        }

        newProductRolePersistedCallbackForListUi(label, value, helpDialogText);
        newProductRolePersistedCallbackForListUi = undefined;

        closeAndClearRightContextDrawer();
    };

    const handleOnAddProductRole = (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newProductRolePersistedCallbackForListUi = newItemPersistedCallback;

        setAndOpenRightContextDrawer(
            <ManageProductRole
                organizationId={organizationId!}
                productRoleName={newItemLabel}
                actionCompleteCallback={handleOnNewProductRolePersisted}
            />,
            'Define New Product Role',
        );
    };

    return (
        <DynamicListWithCallback
            name="productRoles"
            label="Product Roles"
            helpDialogText="For example: Business User, Product Manager, Architect, Developer, Operations, Quality Assurance, Business Analyst, Customer Service, etc."
            newItemLabel="New Role Name"
            value={initialValues}
            onAddItem={handleOnAddProductRole}
            onEditItem={handleOnEditProductRole}
            onCloneItem={handleOnCloneProductRole}
        />
    );
}
