import {
    Container,
    LinearProgress,
    Typography,
    TextField,
    List,
    ListItem,
    Divider,
    ListItemButton,
    Tooltip,
    Stack,
    ListItemIcon,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ChangeEvent, useEffect, useState } from 'react';
import {
    useFederatedGroupMembersAddPrincipalMutation,
    useFederatedGroupMembersByGroupIdQuery,
    useFederatedGroupMembersRemoveMemberMutation,
} from './federatedGroupMembersApiSlice';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useLazyFederatedUsersSearchQuery } from '../users/federatedUsersApiSlice';

type Props = {
    federatedGroupId: string;
};

export function GroupMemberList({ federatedGroupId }: Props) {
    const [query, setQuery] = useState('');
    const { entityContext } = useEntityContext();

    const [triggerSearch, searchResults] = useLazyFederatedUsersSearchQuery();
    const {
        data: group,
        refetch,
        isError,
        error,
        isFetching,
        isLoading,
        isSuccess,
    } = useFederatedGroupMembersByGroupIdQuery({
        organizationId: entityContext.organizationSlug!,
        groupId: federatedGroupId,
    });
    const [addPrincipal, { isLoading: isAdding }] = useFederatedGroupMembersAddPrincipalMutation();
    const [removeMember, { isLoading: isRemoving }] = useFederatedGroupMembersRemoveMemberMutation();

    const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setQuery(event.target.value);
    };

    const handleOnClickAddUser = async (principalId: string) => {
        await addPrincipal({
            organizationId: entityContext.organizationSlug!,
            groupId: federatedGroupId,
            principalId,
        });
        await refetch();
    };

    const handleOnClickRemoveMember = async (memberId: string) => {
        await removeMember({
            organizationId: entityContext.organizationSlug!,
            groupId: federatedGroupId,
            memberId,
        });
        await refetch();
    };

    useEffect(() => {
        if (query === '') return;

        const delayDebounceFn = setTimeout(() => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            triggerSearch({ organizationId: entityContext.organizationSlug!, query }, false);
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [query]);

    return (
        <Container maxWidth="md">
            <Typography variant="h6">Add a User</Typography>
            <TextField
                label="Search"
                autoComplete="off"
                id="query"
                onChange={handleOnChange}
                value={query}
                fullWidth
                sx={{ mt: 2 }}
            />
            {(isAdding || searchResults.isFetching || searchResults.isLoading) && <LinearProgress sx={{ mt: 2 }} />}
            <List sx={{ mt: 2 }}>
                {group &&
                    searchResults.isSuccess &&
                    searchResults.currentData &&
                    searchResults.currentData.filter(
                        (user) => !group.members.map((member) => member.objectId).includes(user.objectId),
                    ).length === 0 && (
                        <ListItem>
                            <Typography fontStyle="italic">No results found</Typography>
                        </ListItem>
                    )}
                {group &&
                    searchResults.isSuccess &&
                    searchResults.currentData &&
                    searchResults.currentData
                        .filter((user) => !group.members.map((member) => member.objectId).includes(user.objectId))
                        .map((user) => (
                            <Tooltip title="Add user to group">
                                <ListItemButton
                                    disabled={isAdding || isFetching || isLoading}
                                    onClick={() => handleOnClickAddUser(user.objectId)}
                                    sx={{
                                        '&:hover .MuiListItemIcon-root': {
                                            display: 'flex',
                                        },
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: '40px',
                                            display: 'none',
                                            transition: 'all .2s',
                                        }}
                                    >
                                        <AddIcon />
                                    </ListItemIcon>
                                    <Stack>
                                        <Typography>{user.displayName || user.objectId}</Typography>
                                        <Typography variant="caption">
                                            {user.email ||
                                                user.userPrincipalName ||
                                                (user.displayName && user.objectId)}
                                        </Typography>
                                    </Stack>
                                </ListItemButton>
                            </Tooltip>
                        ))}
                {searchResults.isError && (
                    <ListItem>
                        <Typography fontStyle="italic">{searchResults.error.toString()}</Typography>
                    </ListItem>
                )}
            </List>
            <Divider sx={{ mt: 2 }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
                Group Members
            </Typography>
            {(isRemoving || isFetching || isLoading) && <LinearProgress sx={{ mt: 2 }} />}
            {isSuccess && group && group.members && (
                <List>
                    {group.members.map((member) => (
                        <Tooltip title="Remove member from group">
                            <ListItemButton
                                disabled={isRemoving || isFetching || isLoading}
                                onClick={() => handleOnClickRemoveMember(member.objectId)}
                                sx={{
                                    '&:hover .MuiListItemIcon-root': {
                                        display: 'flex',
                                    },
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: '40px',
                                        display: 'none',
                                        transition: 'all .2s',
                                    }}
                                >
                                    <RemoveIcon />
                                </ListItemIcon>
                                <Stack>
                                    <Typography>{member.displayName || member.objectId}</Typography>
                                    <Typography variant="caption">
                                        {member.email ||
                                            member.userPrincipalName ||
                                            (member.displayName && member.objectId)}
                                    </Typography>
                                </Stack>
                            </ListItemButton>
                        </Tooltip>
                    ))}
                </List>
            )}
            {isSuccess && group && group.members.length === 0 && (
                <List>
                    <ListItem>
                        <Typography fontStyle="italic">No members</Typography>
                    </ListItem>
                </List>
            )}
            {isError && (
                <List>
                    <ListItem>
                        <Typography fontStyle="italic">Something went wrong: {JSON.stringify(error)}</Typography>
                    </ListItem>
                </List>
            )}
        </Container>
    );
}
