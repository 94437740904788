const toLowerCase = (str: string | undefined): string | undefined => {
    return str ? str.toLowerCase() : str;
};

const toProperCase = (str: string | undefined): string | undefined => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
};

const randomString = (): string => {
    return (Math.random() * 16).toString();
};

const startsWithVowel = (str?: string): boolean => {
    if (!str) {
        return false;
    }

    return /^[aeiou].*/i.test(str);
};

export { toLowerCase, toProperCase, randomString, startsWithVowel };
