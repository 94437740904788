import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEntityContext } from '../../providers/EntityContextProvider';
import { useUser } from '../../providers/UserProvider';

export function Default() {
    const { changeEntityContext } = useEntityContext();
    const { user } = useUser();

    useEffect(() => {
        if (user?.orgId) {
            changeEntityContext({ organizationSlug: user.orgId });
        }
    }, [user]);

    return null;
}
