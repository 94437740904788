import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
    ChildNavigationItem,
    NavigationItem,
    organizationRoutes,
} from '../../../organization-pages/organizationRoutes';
import { useLayout } from '../../../providers/LayoutProvider';

export function TabbedContainer() {
    const location = useLocation();
    const navigate = useNavigate();
    const [activeNavObj, setActiveNavObj] = useState<NavigationItem | undefined>();
    const [visibleTabObjects, setVisibleTabObjects] = useState<ChildNavigationItem[]>([]);
    const [currentTabValue, setCurrentTabValue] = useState<string | undefined>();
    const { isPreviewFeatureVisible } = useLayout();
    const previewPages = ['Information'];

    const setCurrentTabValueByOrderIndex = (orderIndex: number) => {
        const index = orderIndex - 1;
        if (visibleTabObjects && visibleTabObjects.length >= index + 1) {
            if (Object.keys(visibleTabObjects[index]).includes('path')) {
                navigate(visibleTabObjects[index].path);
            }
        }
    };
    useHotkeys('1', () => setCurrentTabValueByOrderIndex(1));
    useHotkeys('2', () => setCurrentTabValueByOrderIndex(2));
    useHotkeys('3', () => setCurrentTabValueByOrderIndex(3));
    useHotkeys('4', () => setCurrentTabValueByOrderIndex(4));
    useHotkeys('5', () => setCurrentTabValueByOrderIndex(5));
    useHotkeys('6', () => setCurrentTabValueByOrderIndex(6));
    useHotkeys('7', () => setCurrentTabValueByOrderIndex(7));
    useHotkeys('8', () => setCurrentTabValueByOrderIndex(8));
    useHotkeys('9', () => setCurrentTabValueByOrderIndex(9));

    // Determine what button to highlight as active on left nav bar
    useEffect(() => {
        const currentNavObj = organizationRoutes({ singleTokenPlaceholder: ':' })
            .sort((a, b) => (a.path.length < b.path.length ? 1 : -1))
            .find((item) => location.pathname.match(`^${item.path.replaceAll(':', '[a-zA-Z0-9-]*')}`));
        setActiveNavObj(currentNavObj);
        if (currentNavObj) {
            setVisibleTabObjects([
                ...(currentNavObj.element
                    ? [{ name: currentNavObj.name, path: '', element: currentNavObj.element }]
                    : []),
                ...(currentNavObj.children?.filter(
                    (item: any) => isPreviewFeatureVisible || !previewPages.includes(item.name),
                ) || []),
            ]);
        }

        const numParentSegments = currentNavObj?.path.split('/').length;
        const currentTab = location.pathname.split('/').slice(numParentSegments).join('/');
        setCurrentTabValue(currentTab);
    }, [location]);

    return (
        (activeNavObj?.children && (
            <Tabs value={currentTabValue} allowScrollButtonsMobile>
                {visibleTabObjects.map((item: any) => (
                    <Tab key={item.path} label={item.name} value={item.path} component={Link} to={item.path} />
                ))}
            </Tabs>
        )) ||
        null
    );
}
