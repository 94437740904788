import { Formik, Form, FormikValues, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { ToggleButton } from '../../../components/elements/input/ToggleButton';
import { useLogging } from '../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';
import { FlowUtils } from '../../../utils/FlowUtils';
import { stringSequentialValidation } from '../../../utils/validation/StringSequentialValidation';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const YES_NO_OPTIONS = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
];

type Props = {
    organizationId: string;
    cloneAsNew?: boolean;
    existingProductRoleId?: string;
    productRoleName?: string;
    actionCompleteCallback: (label: string, value: string, helpDialogText?: string) => void;
};

export function ManageProductRole({
    organizationId,
    cloneAsNew,
    existingProductRoleId,
    productRoleName = '',
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE = {
        name: productRoleName,
        businessKey: '',
        description: '',
        productionEnabled: 'NO',
    };

    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { getProductRoleById, newProductRole, updateProductRole, validateProductRoleBusinessKeyIsUnique } =
        useBackendService();
    const { trackTrace } = useLogging();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Role Name is required'),
        businessKey: stringSequentialValidation([
            Yup.string()
                .matches(/^[a-z]*$/, 'Product Role Business Key can only contain lowercase letters')
                .min(2, 'Product Role Business Key must be at least 2 characters')
                .max(16, 'Product Role Business Key must be 16 characters or less')
                .required('Product Role Business Key is required'),
            Yup.string().test(
                'is-unique',
                'Product Role Business Key must be unique for this Organization',
                (value: string | undefined) => {
                    if (existingProductRoleId) {
                        // wait until parent product is set before validating
                        return true;
                    }

                    if (!value) {
                        return false;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateProductRoleBusinessKeyIsUnique(
                                {
                                    organizationId,
                                    businessKey: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        description: Yup.string().required('Role Description is required'),
        productionEnabled: Yup.string().ensure().required('Make a selection'),
    });

    const fetchData = async () => {
        trackTrace(`Loading existing product role data to edit '${existingProductRoleId}'`);
        const response = await getProductRoleById({
            organizationId,
            id: existingProductRoleId!,
        });
        setInitialFormState({
            name: response.name,
            businessKey: response.businessKey,
            description: response.description,
            productionEnabled: response.productionEnabled ? 'YES' : 'NO',
        });
    };

    useEffect(() => {
        if (existingProductRoleId) {
            fetchData();
        } else {
            // reset form
            setInitialFormState(INITIAL_FORM_STATE);
        }

        setRightContextDrawerActions([
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: existingProductRoleId ? 'Save' : 'Create',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ]);
    }, [existingProductRoleId]);

    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        if (existingProductRoleId && !cloneAsNew) {
            // Update existing product role
            trackTrace(`Updating product role  '${existingProductRoleId}'..`);
            const envRes = await updateProductRole({
                organizationId,
                id: existingProductRoleId,
                ...values,
                productionEnabled: values.productionEnabled === 'YES',
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        } else {
            // Create new product role
            trackTrace('New product role..');
            const envRes = await newProductRole({
                organizationId,
                ...values,
                productionEnabled: values.productionEnabled === 'YES',
            });
            formik.setSubmitting(false);
            resId = envRes.id;
        }

        enqueueSnackbar('Product role saved');
        trackTrace(`Success: '${resId}'`);
        actionCompleteCallback(`${values.name} (${values.businessKey.toUpperCase()})`, resId, values.description);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="name"
                                            label="Role Name"
                                            required
                                            autoFocus={!existingProductRoleId ? true : undefined}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="businessKey"
                                            label="Role Business Key"
                                            disabled={!!(existingProductRoleId && !cloneAsNew)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Role Description"
                                            helpDialogText="A business description to assist users in understanding the purpose of this role"
                                            multiline
                                            rows="3"
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ToggleButton
                                            name="productionEnabled"
                                            label="Production Enabled"
                                            helpDialogText="Are users in this role permitted to access production resources and data. This permits more granular permissions to be assigned to the role. No permissions are assigned by default."
                                            options={YES_NO_OPTIONS}
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
