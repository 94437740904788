import { debounce, throttle } from 'lodash';

export class FlowUtils {
    public static delay(ms: number) {
        return new Promise<void>((resolve) => setTimeout(resolve, ms));
    }

    /**
     * Throttles an async function in a way that can be awaited.
     * By default throttle doesn't return a promise for async functions unless it's invoking them immediately. See CUR-4769 for details.
     * @param func async function to throttle calls for.
     * @param wait same function as lodash.throttle's wait parameter.
     *             Call this function at most this often.
     * @returns a promise which will be resolved/rejected only if the function is executed, with the result of the underlying call.
     */
    public static asyncThrottle<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
        const throttled = throttle((resolve, reject, args: Parameters<F>) => {
            func(...args)
                .then(resolve)
                .catch(reject);
        }, wait);
        return (...args: Parameters<F>): ReturnType<F> =>
            new Promise((resolve, reject) => {
                throttled(resolve, reject, args);
            }) as ReturnType<F>;
    }

    /**
     * Debounces an async function in a way that can be awaited.
     * By default debounce doesn't return a promise for async functions unless it's invoking them immediately. See CUR-4769 for details.
     * @param func async function to debounce calls for.
     * @param wait same function as lodash.debounce's wait parameter.
     * @returns a promise which will be resolved/rejected only if the function is executed, with the result of the underlying call.
     */
    public static asyncDebounce<F extends (...args: any[]) => Promise<any>>(func: F, wait?: number) {
        const debounced = debounce((resolve, reject, args: Parameters<F>) => {
            func(...args)
                .then(resolve)
                .catch(reject);
        }, wait);
        return (...args: Parameters<F>): ReturnType<F> =>
            new Promise((resolve, reject) => {
                debounced(resolve, reject, args);
            }) as ReturnType<F>;
    }
}
