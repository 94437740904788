import { InteractionRequiredAuthError, PublicClientApplication, BrowserAuthError } from '@azure/msal-browser';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { getScaledSenseBackendUrl } from '../../utils/BrowserEnvUtils';
import { loginRequest } from '../../auth/authConfig';
import { msalInstance } from '../../components/elements/auth/MsalInit';

const mutex = new Mutex();
const backendUrl = getScaledSenseBackendUrl();

export enum RtkTagType {
    Organizations = 'Organizations',
    Products = 'Products',
    Services = 'Services',
    Workloads = 'Workloads',
    FederatedGroupMembers = 'FederatedGroupMembers',
    FederatedUsers = 'FederatedUsers',
    Deployments = 'Deployments',
    Jobs = 'Jobs',
    Links = 'Links',
}

const getToken = async (): Promise<string> => {
    await mutex.waitForUnlock();

    if (mutex.isLocked()) {
        return getToken();
    }

    const release = await mutex.acquire();
    let accessToken: string;

    try {
        const response = await msalInstance.acquireTokenSilent({
            ...loginRequest,
            redirectUri: `${window.location.protocol}//${window.location.host}/token-redirect.html`,
        });
        accessToken = response.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect({
                ...loginRequest,
                redirectStartPage: window.location.href,
            });
        }
        if (error instanceof BrowserAuthError) {
            await msalInstance.acquireTokenRedirect({
                ...loginRequest,
                redirectStartPage: window.location.href,
            });
        }
    } finally {
        release();
    }

    if (!accessToken!) {
        throw new Error('[RequestInterceptor] no access token found');
    }

    return accessToken;
};

export const apiSlice = createApi({
    reducerPath: 'api', // optional
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrl,
        responseHandler: 'content-type',
        prepareHeaders: async (headers) => {
            const accessToken = await getToken();

            const bearer = `Bearer ${accessToken}`;
            headers.set('Authorization', bearer);

            return headers;
        },
    }),
    tagTypes: Object.values(RtkTagType),
    endpoints: (builder) => ({}),
});
