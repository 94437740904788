import { useState } from 'react';
import { PageDialogInstance } from '../../../components/elements/PageDialog/PageDialogInstance';
import StepperJourney from '../../../components/elements/StepperJourney';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useLayout } from '../../../providers/LayoutProvider';
import { useJourneyProductNewMutation } from '../journeysApiSlice';
import StepperStep from '../../../components/elements/StepperStep';
import { JourneyStageRequest } from '../../../models/journeys/requests/ExecuteJourneyRequest';
import { CreateProductRequest } from '../../../models/products/CreateProductRequest';
import NewProductStepDetails from './NewProductStepDetails';
import NewProductStepRoles from './NewProductStepRoles';
import NewProductStepFinish from './NewProductStepFinish';
import { EnableProductRoleRequest } from '../../../models/products/EnableProductRoleRequest';

function NewProductDialog() {
    const [createProductRequest, setCreateProductRequest] = useState<Partial<CreateProductRequest>>({});
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [activeStepIsValid, setActiveStepIsValid] = useState(true);
    const [rolesToEnable, setRolesToEnable] = useState<string[]>([]);
    // eslint-disable-next-line no-spaced-func
    const [activeStepValidate, setActiveStepValidate] = useState<(() => boolean | Promise<boolean>) | undefined>();
    const [executeJourney, { isLoading: isSubmitting }] = useJourneyProductNewMutation();
    const { entityContext, changeEntityContext } = useEntityContext();
    const { closeAndClearDialog } = useLayout();

    const steps: JSX.Element[] = [];

    const resetStepState = () => {
        setActiveStepIsValid(true);
        setActiveStepValidate(undefined);
    };

    const handleGoToNextStep = async () => {
        if (activeStepValidate && !(await activeStepValidate())) {
            return;
        }
        if (activeStepIsValid && activeStepIndex < steps.length - 1) {
            resetStepState();
            setActiveStepIndex(activeStepIndex + 1);
        }
    };

    const handleGoToPreviousStep = async () => {
        if (activeStepIndex > 0) {
            resetStepState();
            setActiveStepIndex(activeStepIndex - 1);
        }
    };

    const handleFinish = async () => {
        const stages: JourneyStageRequest[] = [
            {
                id: 'createProduct',
                useCase: 'CreateProductUseCase',
                request: {
                    audience: 'N/A',
                    expectedUniqueVisitorDistribution: 'N/A',
                    expectedUniqueVisitorFrequency: 'N/A',
                    expectedUniqueVisitorQuantity: 'N/A',
                    ...createProductRequest,
                    // if short name is omitted, use the business key
                    shortName: createProductRequest.shortName || createProductRequest.businessKey,
                } as CreateProductRequest,
            },
        ];

        rolesToEnable.forEach((role) => {
            const enableRole: EnableProductRoleRequest = {
                organizationId: entityContext.organizationSlug!,
                productId: '@{createProduct|id}',
                productRoleId: role,
            };
            stages.push({
                id: `enableRole-${role.toLowerCase()}`,
                useCase: 'EnableProductRoleUseCase',
                request: enableRole,
            });
        });

        const newProductJourneyResponse = await executeJourney({
            organizationId: entityContext.organizationSlug!,
            stages,
        }).unwrap();

        changeEntityContext({
            organizationSlug: entityContext.organizationSlug!,
            productSlug: newProductJourneyResponse.results[0].response.id,
        });
        closeAndClearDialog();
    };

    steps.push(
        ...[
            <StepperStep label="Product details" onNextStep={handleGoToNextStep} nextStepDisabled={!activeStepIsValid}>
                <NewProductStepDetails
                    setIsValid={setActiveStepIsValid}
                    setValidate={setActiveStepValidate}
                    createProductRequest={createProductRequest}
                    setCreateProductRequest={setCreateProductRequest}
                />
            </StepperStep>,
            <StepperStep label="Enable Roles" onNextStep={handleGoToNextStep}>
                <NewProductStepRoles rolesToEnable={rolesToEnable} setRolesToEnable={setRolesToEnable} />
            </StepperStep>,
            <StepperStep label="Finalize">
                <NewProductStepFinish
                    createProductRequest={createProductRequest}
                    rolesToEnable={rolesToEnable}
                    onFinish={handleFinish}
                    isSubmitting={isSubmitting}
                />
            </StepperStep>,
        ],
    );

    return <StepperJourney activeStepIndex={activeStepIndex} onPreviousStep={handleGoToPreviousStep} steps={steps} />;
}

export const newProductDialogInstance: PageDialogInstance = {
    route: 'new-product',
    children: <NewProductDialog />,
};
