import { useLocation, useNavigate } from 'react-router-dom';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { toLowerCase } from '../utils/StringUtils';
import { OrganizationListItem, OrganizationsListResponse } from '../models/organizations/OrganizationsListResponse';
import { UserActionsDto } from '../models/users/User';
import { useLogging } from './LoggingProvider';
import { useLoading } from './LoadingProvider';
import { useAuthenticatedAccount } from './AuthenticatedAccountProvider';
import { useEntityContext } from './EntityContextProvider';
import { Waitlist } from '../components/parts/Waitlist';

type ProviderProps = {
    children?: ReactNode;
};

export type User = {
    pa: boolean;
    userId: string;
    friendlyName: string;
    orgId?: string;
};

type UserContextType = {
    user?: User;
    isPlatformManager: boolean;
    setUser: (user: User) => void;
    setActiveOrganization: (organizationId: string) => void;
    logoutUser: () => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: ProviderProps) {
    const location = useLocation();
    const { account, backendApi, logoutAccount } = useAuthenticatedAccount();
    const { trackTrace, trackException, clearAuthenticatedUserContext, setAuthenticatedUserContext } = useLogging();
    const { showLoading, loadingComplete } = useLoading();
    const navigate = useNavigate();
    const { entityContext } = useEntityContext();

    const userJson = localStorage.getItem('user');
    let userFromStore: User | undefined;
    if (userJson) {
        userFromStore = JSON.parse(userJson);
    }

    const [user, setUserState] = useState<User | undefined>(userFromStore);
    const [isPlatformManager, setIsPlatformManager] = useState<boolean>(false);

    const setUser = (newUser: User | undefined) => {
        trackTrace('Setting user..');
        if (newUser) {
            localStorage.setItem('user', JSON.stringify(newUser));
        } else {
            localStorage.removeItem('user');
        }
        setUserState(newUser);
    };

    const setActiveOrganization = (organizationId: string) => {
        trackTrace("Setting user's active organization..");
        if (!user) {
            trackException('No user found to set active organization');
            return;
        }

        const updatedUser = { ...user };
        updatedUser.orgId = organizationId;
        setUserState(updatedUser);
    };

    const logoutUser = () => {
        trackTrace('Logging user out..');
        clearAuthenticatedUserContext();
        localStorage.removeItem('productCache');
        localStorage.removeItem('serviceCache');
        localStorage.removeItem('user');
        setUserState(undefined);
        logoutAccount();
    };

    const fetchUserOrganizationInfo = async (): Promise<OrganizationsListResponse | undefined> => {
        trackTrace('Fetching organizations..');
        try {
            const response = await backendApi.get('/platform/users/current');
            return response.data.value as OrganizationsListResponse;
        } catch (err: any) {
            if (err.response) {
                trackTrace(err.response.data);
                trackTrace(err.response.status);
                trackTrace(err.response.headers);
            } else {
                trackTrace(`Error: ${err.message}`);
            }
        }

        return undefined;
    };

    const registerNewUser = async (invitedOrganizations?: OrganizationListItem[]): Promise<User | undefined> => {
        try {
            const organizationToJoin = entityContext.organizationSlug || invitedOrganizations?.[0].id;
            if (!organizationToJoin || !account) {
                trackTrace('Missing information to register user');
                return;
            }

            const response = await backendApi.post(`/organizations/${organizationToJoin}/users/register`, {
                friendlyName: account?.name,
            });

            if (response.status === 204) {
                trackTrace('User successfully registered!');
                const newUser: User = {
                    pa: true,
                    userId: account.localAccountId,
                    friendlyName: account.name!,
                    orgId: organizationToJoin,
                };

                return newUser;
            }

            return;
        } catch (err: any) {
            if (err.response) {
                trackTrace(err.response.data);
                trackTrace(err.response.status);
                trackTrace(err.response.headers);
            } else {
                trackTrace(`Error: ${err.message}`);
            }
        }
    };

    const fetchCurrentUserRoles = async () => {
        const actionsResponse = await backendApi.get(
            `/organizations/${user?.orgId}/users/current/scopes/${encodeURIComponent('/')}`,
        );

        setIsPlatformManager(
            (actionsResponse.data.value as UserActionsDto).actionKeys.includes('Portal.Ui.AdminControls.Write'),
        );
    };

    const refreshUserCache = async () => {
        trackTrace('Refreshing user cache..');
        if (!account) {
            trackException('Unable to get user account while refreshing user cache');
            throw new Error('Unable to get user account while refreshing user cache');
        }

        const existingUser: User = {
            pa: false,
            userId: account.localAccountId,
            friendlyName: account.name!,
        };

        const organizationInfo = await fetchUserOrganizationInfo();

        if (organizationInfo?.organizations && organizationInfo.organizations.length > 0) {
            const orgIds = organizationInfo.organizations.map((o) => o.id);
            existingUser.pa = true;
            trackTrace('User has platform access');

            const targetOrgId = entityContext.organizationSlug;
            const userHasOrgAccess = orgIds.find((o) => toLowerCase(o) === toLowerCase(targetOrgId));
            // !targetOrgId when accessing home page
            if (!targetOrgId || userHasOrgAccess) {
                const initialUserOrg = targetOrgId || orgIds[0];
                existingUser.orgId = initialUserOrg;
                trackTrace(`Org Id match found for user: '${initialUserOrg}'`);

                setUser(existingUser);
            } else {
                trackTrace('User is not member of target org.');
                const newUser = await registerNewUser(organizationInfo.invitedOrganizations);

                if (newUser) {
                    setUser(newUser);
                }
            }
        } else {
            trackTrace('No orgs found for user');
            const newUser = await registerNewUser(organizationInfo?.invitedOrganizations);

            if (newUser) {
                setUser(newUser);
            }
        }
    };

    const memoValue = useMemo(
        () => ({
            user,
            isPlatformManager,
            setUser,
            setActiveOrganization,
            logoutUser,
        }),
        [user, isPlatformManager],
    );

    useEffect(() => {
        trackTrace('Account changed, checking to refresh user cache..');
        if (account && !user) {
            trackTrace('Refreshing user cache..');
            const loadingTaskId = 'user-provider-account-load';
            showLoading(loadingTaskId);

            refreshUserCache().finally(() => {
                loadingComplete(loadingTaskId);
            });
        } else {
            trackTrace(`Not refreshing cache | account is: '${!!account}' and user is: '${!!user}'`);
        }
    }, [account]);

    useEffect(() => {
        trackTrace('User changed, checking to update user details..');
        if (user) {
            if (entityContext.organizationSlug && user.orgId !== entityContext.organizationSlug) {
                trackTrace('Refreshing user cache since targeting new org..');
                const loadingTaskId = 'user-provider-org-update';
                showLoading(loadingTaskId);
                refreshUserCache().finally(() => {
                    loadingComplete(loadingTaskId);
                });
            }

            trackTrace('Updating user details from user change..');
            setAuthenticatedUserContext(user.userId);
            fetchCurrentUserRoles();
        } else {
            trackTrace('User is undefined');
        }
    }, [user]);

    return <UserContext.Provider value={memoValue}>{!user?.pa ? <Waitlist /> : children}</UserContext.Provider>;
}

export const useUser = (): UserContextType => {
    const user = useContext(UserContext);
    if (!user) {
        throw new Error('useUser must be used within a User Provider');
    }
    return user;
};
