import React, { useEffect, useRef, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { Button, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';

import { TextField } from '../../../../../components/elements/input/TextField';
import { PropertyHierarchy } from '../../../../../models/services/PropertyHierarchy';
import { ValueInheritanceTooltip } from '../ValueInheritanceTooltip';

type Props = {
    formProperty: string;
    isReadOnly: boolean;
    readonlyReason: string;
    propertyHierarchy?: PropertyHierarchy;
};

export function ListPropertyControl({ formProperty, isReadOnly, readonlyReason, propertyHierarchy }: Props) {
    const formikProps = useFormikContext<{ [property: string]: any }>();
    const arrayHelperRef = useRef<FieldArrayRenderProps>();
    const [field, meta] = useField(formProperty);

    const isPropertyInherited = !propertyHierarchy?.serviceProperty;
    const disabled = isReadOnly || isPropertyInherited;
    const firstRowId = 'property-first-row';

    const rows: string[] = field.value;

    const addRow = () => {
        arrayHelperRef.current?.unshift('');
        setTimeout(() => {
            // focus on the inserted row
            document.getElementById(firstRowId)?.focus();
        }, 1);
    };

    const inheritedValueGroups = [
        {
            group: 'Organization Governance',
            groupId: 'gov',
            values: propertyHierarchy?.orgGovProperty?.property?.value,
        },
        {
            group: 'Product Management',
            groupId: 'mgmt',
            values: propertyHierarchy?.productMgmtProperty?.property?.value,
        },
    ].filter((group) => (group.values as string[])?.length > 0);

    useEffect(() => {
        if (!isPropertyInherited && rows.length === 0) {
            addRow();
        }

        const allInheritedValues = inheritedValueGroups.flatMap((vg) => vg.values as string[]);
        formikProps.setFieldValue('inheritedValues', allInheritedValues, false);
    }, [propertyHierarchy]);

    return (
        <Grid item xs={12} sx={{ marginBottom: 1 }}>
            {!disabled && (
                <Grid key="add-row" container>
                    <Grid item xs={4} display="flex" alignItems="center">
                        <Button variant="text" color="info" onClick={addRow} disabled={isReadOnly}>
                            <Tooltip title="Add Item" sx={{ paddingY: 0, paddingRight: 1, paddingLeft: 0 }}>
                                <IconButton>
                                    <AddCircleIcon style={{ verticalAlign: 'top' }} />
                                </IconButton>
                            </Tooltip>
                            <Typography>Add Item</Typography>
                        </Button>
                    </Grid>
                </Grid>
            )}
            <FieldArray
                name={formProperty}
                render={(arrayHelpers) => {
                    arrayHelperRef.current = arrayHelpers;

                    const fieldValue = formikProps.values ? formikProps.values[formProperty] : undefined;
                    if (!fieldValue) {
                        return <div />;
                    }

                    return fieldValue.map((val: string, index: number) => (
                        <Grid key={index} container spacing={2} marginTop="1px">
                            <Grid item xs={disabled ? 12 : 11} display="flex" alignItems="center">
                                <TextField
                                    id={index === 0 ? firstRowId : undefined}
                                    label="Value"
                                    name={`${formProperty}.${index}`}
                                    required={!disabled}
                                    disabled={disabled}
                                    helperText={isReadOnly ? readonlyReason : ''}
                                    autoFocus={index === 0}
                                />
                            </Grid>
                            {!disabled && (
                                <Grid item xs={1} display="flex" alignItems="center">
                                    <Tooltip title="Remove">
                                        <IconButton onClick={() => arrayHelpers.remove(index)} disabled={isReadOnly}>
                                            <RemoveCircleIcon style={{ verticalAlign: 'top' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    ));
                }}
            />
            {inheritedValueGroups.map((group) => {
                return (group.values as string[]).map((value, index) => (
                    <Grid key={index} container spacing={2} marginTop="1px">
                        <Grid item xs={disabled ? 12 : 11} display="flex" alignItems="center" position="relative">
                            <ValueInheritanceTooltip
                                valueInheritance={[{ value, serviceOverridden: group.group }]}
                                sx={{ position: 'absolute', marginLeft: '-35px' }}
                            >
                                <InfoIcon fontSize="small" />
                            </ValueInheritanceTooltip>
                            <TextField
                                label="Value"
                                name={`${group.groupId}.${index}`}
                                disabled
                                disableFormik
                                helperText={isReadOnly ? readonlyReason : ''}
                                value={`${value} (Inherited)`}
                            />
                        </Grid>
                    </Grid>
                ));
            })}
        </Grid>
    );
}
