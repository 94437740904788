/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Badge,
    Box,
    Breadcrumbs,
    IconButton,
    Link,
    LinkProps,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import MapIcon from '@mui/icons-material/Map';
import {
    defaultEntityContextModule,
    EntityContextModule,
    useEntityContext,
} from '../../../providers/EntityContextProvider';
import { useLayout } from '../../../providers/LayoutProvider';
import { useOrganizationByIdQuery } from '../../../features/organizations/organizationsApiSlice';
import { useProductsListQuery } from '../../../features/products/productsApiSlice';
import { useServicesByOrgIdQuery } from '../../../features/services/services/servicesApiSlice';
import { contextMapDialogInstance } from '../../../features/context-map/ContextMapDialog';
import { useUser } from '../../../providers/UserProvider';

interface LinkRouterProps extends LinkProps {
    to: string;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink} />;
}

export function TopBreadcrumbs() {
    const {
        entityContext,
        pathToChangeEntityContext,
        pathToNavigateWithCurrentEntityContext,
        isOrganizationLevel,
        isProductLevel,
        isServiceLevel,
    } = useEntityContext();
    const { user } = useUser();
    const { data: organizationData } = useOrganizationByIdQuery(
        { organizationId: entityContext.organizationSlug! },
        { skip: !user?.pa },
    );
    const { data: productsData } = useProductsListQuery(
        { organizationId: entityContext.organizationSlug! },
        { skip: !user?.pa },
    );
    const { data: servicesData } = useServicesByOrgIdQuery(
        { organizationId: entityContext.organizationSlug! },
        { skip: !user?.pa },
    );
    const themeHook = useTheme();
    const matchesMdAndUp = useMediaQuery(themeHook.breakpoints.up('md'));
    const { isPreviewFeatureVisible, setAndOpenDialog } = useLayout();

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" />}
            itemsBeforeCollapse={1}
            itemsAfterCollapse={1}
            maxItems={5}
            sx={(theme) => ({
                lineHeight: '1',
                marginRight: theme.spacing(2),
                marginLeft: theme.spacing(2),
                // TODO: to show search uncomment below and remove root 'flexGrow: 1,' - one more action in search below
                [theme.breakpoints.down('sm')]: {
                    flexGrow: 1,
                },
                flexGrow: isPreviewFeatureVisible ? 0 : 1,
            })}
        >
            <LinkRouter
                to={pathToNavigateWithCurrentEntityContext(defaultEntityContextModule)}
                underline="none"
                lineHeight="1"
            >
                <img alt="Logo" src="/assets/images/LogoRightTextBlue.svg" height="45" />
            </LinkRouter>
            <Tooltip title={`Change context${organizationData?.name && ' within '}${organizationData?.name}`}>
                <IconButton color="inherit" size="small" onClick={() => setAndOpenDialog(contextMapDialogInstance)}>
                    <Badge
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        badgeContent="c"
                    >
                        <MapIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            {isOrganizationLevel && (
                <Typography color="text.primary" fontSize="1.3rem">
                    {(organizationData && organizationData.name) || (
                        <Box component="span" sx={{ filter: 'blur(3px)' }}>
                            ------------------
                        </Box>
                    )}
                </Typography>
            )}
            {isProductLevel && (
                <Typography color="text.primary" fontSize="1.3rem">
                    {(productsData &&
                        productsData.find((product) => product.id === entityContext.productSlug)?.name) || (
                        <Box component="span" sx={{ filter: 'blur(3px)' }}>
                            ------------------
                        </Box>
                    )}
                </Typography>
            )}
            {matchesMdAndUp && isServiceLevel && (
                <LinkRouter
                    color="inherit"
                    underline="hover"
                    to={pathToChangeEntityContext({
                        organizationSlug: entityContext.organizationSlug,
                        productSlug: entityContext.productSlug,
                    })}
                >
                    {(productsData &&
                        productsData.find((product) => product.id === entityContext.productSlug)?.name) || (
                        <Typography sx={{ filter: 'blur(3px)' }}>------------------</Typography>
                    )}
                </LinkRouter>
            )}
            {isServiceLevel && (
                <Typography color="text.primary" fontSize="1.3rem">
                    {(servicesData &&
                        servicesData.find((service) => service.id === entityContext.serviceSlug)?.name) || (
                        <Box component="span" sx={{ filter: 'blur(3px)' }}>
                            ------------------
                        </Box>
                    )}
                </Typography>
            )}
        </Breadcrumbs>
    );
}
