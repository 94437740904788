import React from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { HorizontalRule } from '@mui/icons-material';

import { PropertyDefinition, PropertyControlType } from '../../../../../models/services/PropertyDefinition';
import { useLayout } from '../../../../../providers/LayoutProvider';
import { PropertyHierarchy } from '../../../../../models/services/PropertyHierarchy';

type Props = {
    propertyDefinition: PropertyDefinition;
    propertyHierarchy?: PropertyHierarchy;
    showLabelOnlyAsReadOnly: boolean;
    editProperty: (control: PropertyDefinition, property?: PropertyHierarchy, viewOnly?: boolean) => void;
};

export function PropertyDisplay({
    propertyDefinition,
    propertyHierarchy,
    showLabelOnlyAsReadOnly,
    editProperty,
}: Props) {
    let strValue;
    let propertyVal: JSX.Element = <span />;
    let hasValueToDisplay: boolean = true;
    const labelColor = 'rgb(161, 159, 157)';

    const { rightContextDrawerState } = useLayout();

    const viewOnly = !propertyHierarchy?.serviceProperty;

    const singleValueDisplayPropertyValue =
        propertyHierarchy?.serviceProperty?.property?.value ||
        propertyHierarchy?.productMgmtProperty?.property?.value ||
        propertyHierarchy?.orgGovProperty?.property?.value;

    switch (propertyDefinition.controlMetadata.type) {
        case PropertyControlType.selectProperty:
            strValue =
                propertyDefinition.options?.find((opt) => opt.value === singleValueDisplayPropertyValue)?.label || '';
            hasValueToDisplay = !!strValue;
            propertyVal = (
                <Typography component="span" variant="body2">
                    {strValue}
                </Typography>
            );
            break;
        case PropertyControlType.stringProperty:
            strValue = (singleValueDisplayPropertyValue as string) || '';
            hasValueToDisplay = !!strValue;
            propertyVal = (
                <Typography component="span" variant="body2">
                    {strValue}
                </Typography>
            );
            break;
        case PropertyControlType.keyValuePairProperty:
        case PropertyControlType.platformKeyValuePairProperty:
            const kvVal: { key: string; label?: string; value: string }[] = [];
            [
                propertyHierarchy?.serviceProperty,
                propertyHierarchy?.productMgmtProperty,
                propertyHierarchy?.orgGovProperty,
            ]
                .filter((p) => !!p && p.property.value)
                .forEach((p) => {
                    (p!.property.value as { key: string; label?: string; value: string }[]).forEach((v) => {
                        const keyExists = kvVal.find((kv) => kv.key === v.key);
                        if (!keyExists && v.key) {
                            kvVal.push(v);
                        }
                    });
                });

            hasValueToDisplay = kvVal.length > 0;
            propertyVal = (
                <Typography component="div" variant="body2">
                    {kvVal.map((v, index) => (
                        <React.Fragment key={`${v.key}-${index}`}>
                            <span>{v.label || v.key}</span>
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            );
            break;
        case PropertyControlType.listProperty:
            const listVal: string[] = [];
            [
                propertyHierarchy?.serviceProperty,
                propertyHierarchy?.productMgmtProperty,
                propertyHierarchy?.orgGovProperty,
            ]
                .filter((p) => !!p)
                .forEach((p) => {
                    (p!.property.value as string[]).forEach((v) => {
                        const valExists = listVal.includes(v);
                        if (!valExists && v) {
                            listVal.push(v);
                        }
                    });
                });

            hasValueToDisplay = listVal.length > 0;
            propertyVal = (
                <Typography component="div" variant="body2">
                    {listVal.map((value, index) => (
                        <React.Fragment key={`${value}-${index}`}>
                            <span>{value}</span>
                            <br />
                        </React.Fragment>
                    ))}
                </Typography>
            );
            break;
        default:
            break;
    }

    return (
        <Grid key={propertyDefinition.controlMetadata.businessKey} container alignItems="start" paddingY="8px">
            <Grid item container xs={6} alignItems="center" flexWrap="nowrap">
                <Typography component="span" variant="body2" display="inline-flex" color={labelColor} flex={1}>
                    {propertyDefinition.displayName}
                    {propertyDefinition.hidden ? ' (Hidden)' : ''}
                </Typography>
                {!showLabelOnlyAsReadOnly && !rightContextDrawerState.isOpen && (
                    <Tooltip title={viewOnly ? 'View' : 'Edit'} sx={{ marginLeft: 'auto' }}>
                        <IconButton onClick={() => editProperty(propertyDefinition, propertyHierarchy, viewOnly)}>
                            {viewOnly ? (
                                <VisibilityIcon fontSize="small" style={{ verticalAlign: 'top', color: labelColor }} />
                            ) : (
                                <EditIcon fontSize="small" style={{ verticalAlign: 'top', color: labelColor }} />
                            )}
                        </IconButton>
                    </Tooltip>
                )}
                <Typography component="span" variant="body2" display="inline-flex" paddingRight={3}>
                    :
                </Typography>
            </Grid>
            {!showLabelOnlyAsReadOnly && (
                <Grid item container xs={6} alignItems="center" alignSelf="center">
                    {hasValueToDisplay ? (
                        <Typography
                            component="span"
                            variant="body2"
                            style={{ whiteSpace: 'pre' }}
                            overflow="hidden"
                            textOverflow="ellipsis"
                        >
                            {propertyVal}
                        </Typography>
                    ) : (
                        <HorizontalRule style={{ verticalAlign: 'top' }} />
                    )}
                </Grid>
            )}
        </Grid>
    );
}
