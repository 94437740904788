import { Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../providers/UserProvider';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { ProgressText } from '../../../components/elements/ProgressText';

export function ScaledSenseAppCallback() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();

    const { enqueueSnackbar } = useSnackbar();
    const { completeOrgAdminConsent } = useBackendService();

    const azureAdTenant = searchParams.get('tenant');
    const organizationIdFromCallback = searchParams.get('state');

    const handleSubmit = async () => {
        const res = await completeOrgAdminConsent({
            organizationId: user?.orgId!,
            userDirectoryAzureTenantId: azureAdTenant!,
        });

        enqueueSnackbar('Admin consent of Scaled Sense App complete');

        navigate(`/organizations/${user?.orgId}`, { replace: true });
    };

    useEffect(() => {
        if (organizationIdFromCallback !== user?.orgId) {
            enqueueSnackbar('Admin consent failed. Flow was initiated by a different organization context', {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
            });

            navigate(`/organizations/${user?.orgId}`, { replace: true });
        } else {
            handleSubmit();
        }
    }, []);

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Stack spacing={2}>
                <ProgressText variant="h5" textAlign="center">
                    Completing Admin Consent for the Scaled Sense Portal
                </ProgressText>
            </Stack>
        </Container>
    );
}
