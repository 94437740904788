import { Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useUser } from '../../../providers/UserProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { ProgressText } from '../../../components/elements/ProgressText';

export function AzureAdCallback() {
    const navigate = useNavigate();
    const { user } = useUser();
    const [searchParams, setSearchParams] = useSearchParams();

    const { enqueueSnackbar } = useSnackbar();
    const { updateTenantIdForAzureAdPlatform } = useBackendService();
    const { trackTrace } = useLogging();

    const azureAdTenant = searchParams.get('tenant');
    const organizationIdFromCallback = searchParams.get('state');

    const handleSubmit = async () => {
        // Create new platform
        trackTrace('Creating new platform..');
        const platRes = await updateTenantIdForAzureAdPlatform({
            organizationId: user?.orgId!,
            azureTenantId: azureAdTenant!,
        });
        enqueueSnackbar('New platform created');
        trackTrace(`Success: '${platRes.id}'`);

        navigate(`/organizations/${user?.orgId}/configure/platforms`);
    };

    useEffect(() => {
        if (organizationIdFromCallback !== user?.orgId) {
            enqueueSnackbar(
                'Azure AD Platform connection failed. Flow was initiated by a different organization context',
                {
                    variant: 'error',
                    style: { whiteSpace: 'pre-line' },
                },
            );

            navigate(`/organizations/${user?.orgId}/configure/platforms`);
        } else {
            handleSubmit();
        }
    }, []);

    return (
        <Container maxWidth="md" sx={{ marginTop: 4 }}>
            <Stack spacing={2}>
                <ProgressText variant="h5" textAlign="center">
                    Connecting to Entra ID
                </ProgressText>
            </Stack>
        </Container>
    );
}
