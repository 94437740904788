import { Container, Grid, LinearProgress, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { MetadataDisplay } from '../../../components/elements/MetadataDisplay';
import { CreateServiceRequest } from '../../../models/services/requests/CreateServiceRequest';
import { CreateServiceAddOnsRequest } from '../../../models/services/requests/CreateServiceAddOnRequest';
import { WORKLOAD_TYPES } from '../../../models/services/WorkloadTypes';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useOrganizationMetadataByIdQuery } from '../../organizations/organizationsApiSlice';
import { useProductsListQuery } from '../../products/productsApiSlice';
import { useWorkloadsLatestAddonsByTypeQuery } from '../../services/workloads/workloadsApiSlice';
import { CreateServiceEnvironmentsState } from './NewServiceStepEnvironments';

type Props = {
    createServiceRequest: Partial<CreateServiceRequest>;
    createAddOnsRequest: Partial<CreateServiceAddOnsRequest>;
    environmentsToCreate: CreateServiceEnvironmentsState;
    onFinish: (deployNow: boolean) => void;
    isSubmitting: boolean;
};

export default function NewServiceStepDeploy({
    createServiceRequest,
    createAddOnsRequest,
    environmentsToCreate,
    onFinish,
    isSubmitting,
}: Props) {
    const { entityContext } = useEntityContext();
    const { data: organization } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });
    const { data: products } = useProductsListQuery({ organizationId: entityContext.organizationSlug! });
    const { data: addOns } = useWorkloadsLatestAddonsByTypeQuery(
        {
            organizationId: entityContext.organizationSlug!,
            workloadType: createServiceRequest.workloadType!,
        },
        { skip: !createServiceRequest.workloadType },
    );

    return (
        <Container maxWidth="md">
            <Typography variant="h3" align="center" gutterBottom>
                Finalize your{' '}
                {WORKLOAD_TYPES.find((w) => w.value === createServiceRequest.workloadType)?.label || 'Service'}
            </Typography>
            <Typography variant="h5" gutterBottom>
                Service Summary
            </Typography>
            <MetadataDisplay
                metadataProperties={[
                    { label: 'Name', value: createServiceRequest.name },
                    { label: 'Short Name', value: createServiceRequest.shortName || createServiceRequest.businessKey },
                    { label: 'Business Key', value: createServiceRequest.businessKey },
                    {
                        label: 'Associated Product',
                        value: products?.find((p) => p.id === createServiceRequest.productId)?.name,
                    },
                    {
                        label: 'Add-Ons Enabled',
                        value: addOns
                            ?.filter((a) => createAddOnsRequest.addOnWorkloads?.includes(a.type))
                            ?.map((a) => a.friendlyName.replaceAll(' Add-on', ''))
                            .join('\n'),
                    },
                    // { label: 'Access', value: '' },
                    {
                        label: 'Environments',
                        value: environmentsToCreate.environments
                            .map((envTypeId) => organization?.environmentTypes?.find((et) => et.id === envTypeId)?.name)
                            .join('\n'),
                    },
                ]}
            />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <SubmitButton
                        variant="contained"
                        color="primary"
                        onClick={() => onFinish(true)}
                        disableSubmit
                        sx={{ height: '5rem' }}
                        disabled={isSubmitting}
                    >
                        Deploy Environments Now
                    </SubmitButton>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SubmitButton
                        variant="contained"
                        color="inherit"
                        onClick={() => onFinish(false)}
                        disableSubmit
                        sx={{ height: '5rem' }}
                        disabled={isSubmitting}
                    >
                        Create and Deploy Later
                    </SubmitButton>
                </Grid>
                <Grid item xs={12} visibility={isSubmitting ? 'initial' : 'hidden'}>
                    <LinearProgress />
                </Grid>
            </Grid>
        </Container>
    );
}
