import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
    FormControl,
    FormGroup,
    Grid,
    Paper,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Tabs } from '../../../components/elements/input/Tabs';
import { AssignedAction, RoleActions } from '../../../models/security/RoleActions';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { Action } from '../../../models/security/Action';
import { useLayout } from '../../../providers/LayoutProvider';

interface Row {
    category: string;
    actions: Action[];
}

export function ConfigurePermissions() {
    const [currentRole, setCurrentRole] = useState<string>();
    const [roles, setRoles] = useState<string[]>([]);
    const [environments, setEnvironments] = useState<string[]>([]);
    const [availableActions, setAvailableActions] = useState<Action[]>([]);
    const [roleActions, setRoleActions] = useState<RoleActions[]>([]);

    const { organizationId } = useParams();
    const { getOrgRoleActions, getOrgAvailableActions, getOrganizationById } = useBackendService();
    const { setCustomTabContainer, clearCustomTabContainer } = useLayout();

    useEffect(() => {
        const fetchData = async () => {
            if (organizationId) {
                const requests = await Promise.all([
                    getOrganizationById({ organizationId }),
                    getOrgRoleActions({ organizationId }),
                    getOrgAvailableActions({ organizationId }),
                ]);

                const org = requests[0];
                const envKeys = org.environmentTypes
                    .filter((envType) => !envType.managed)
                    .sort((a, b) => {
                        // temporary sort until we can sort based on environment progression
                        if (!a.isProductionEnvironment && b.isProductionEnvironment) {
                            return -1;
                        }
                        if (a.isProductionEnvironment && !b.isProductionEnvironment) {
                            return 1;
                        }

                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((envType) => envType.businessKey);
                setEnvironments(envKeys);

                const roleActionsData = requests[1];
                const rolesData = roleActionsData.map((ra) => ra.role);
                setRoleActions(roleActionsData);
                setRoles(rolesData);
                setCurrentRole(rolesData.at(0));

                const availableActionsData = requests[2];
                setAvailableActions(availableActionsData);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setCustomTabContainer(
            <Tabs
                tabs={roles}
                numberOfTabsToDisplay={5}
                onClickTab={(ev, index, label) => {
                    setCurrentRole(label);
                }}
            />,
        );

        return () => {
            clearCustomTabContainer();
        };
    }, [roles]);

    const rows = availableActions.reduce((accumulator: Row[], action) => {
        let row = accumulator.find((r) => r.category === action.category);
        if (!row) {
            row = {
                category: action.category,
                actions: [],
            };

            accumulator.push(row);
        }

        row.actions.push(action);

        return accumulator;
    }, []);

    // sort alphabetically
    rows.forEach((row) => {
        row.actions.sort((a, b) => {
            if (a.friendlyName < b.friendlyName) {
                return -1;
            }
            if (a.friendlyName > b.friendlyName) {
                return 1;
            }
            return 0;
        });
    });

    const renderAction = (action: Action, environmentKey: string | null, assignedActions: AssignedAction[]) => {
        const assignedAction = assignedActions?.find((aa) => aa.value === action.value);

        const isEnabled =
            !!assignedAction &&
            (action.environmentAgnostic
                ? true
                : !!assignedAction.environments?.find(
                      (environment) => environment.toLowerCase() === environmentKey?.toLowerCase(),
                  ));

        return (
            <Stack alignItems="center">
                <Grid container>
                    {action.environmentAgnostic && (
                        <Grid xs={5} item display="flex" alignItems="center">
                            <hr
                                style={{ width: '100%', borderColor: 'rgba(224,224,224, 0.5)', borderStyle: 'dashed' }}
                            />
                        </Grid>
                    )}
                    <Grid xs={action.environmentAgnostic ? 2 : 12} item display="flex" justifyContent="center">
                        <FormControl>
                            <FormGroup>
                                <Switch
                                    checked={isEnabled}
                                    onChange={(ev, isChecked) => {
                                        if (isChecked) {
                                            if (action.environmentAgnostic) {
                                                assignedActions.push({
                                                    value: action.value,
                                                    enabled: true,
                                                });
                                            } else {
                                                let assignment = assignedActions.find(
                                                    (aa) => aa.value === action.value,
                                                );
                                                if (!assignment) {
                                                    assignment = {
                                                        value: action.value,
                                                        environments: [],
                                                        enabled: true,
                                                    };

                                                    assignedActions.push(assignment);
                                                }

                                                if (!assignment.environments) {
                                                    assignment.environments = [];
                                                }

                                                assignment.environments?.push(environmentKey!.toLowerCase());
                                            }

                                            setRoleActions([...roleActions]);
                                        } else {
                                            const actionIndex = assignedActions.findIndex(
                                                (aa) => aa.value === action.value,
                                            );
                                            if (actionIndex > -1) {
                                                if (!action.environmentAgnostic) {
                                                    const assignment = assignedActions.at(actionIndex)!;
                                                    const envKeyIndex = assignment.environments?.findIndex(
                                                        (envKey) =>
                                                            envKey.toLowerCase() === environmentKey?.toLowerCase(),
                                                    );
                                                    if (envKeyIndex !== undefined && envKeyIndex > -1) {
                                                        assignment.environments!.splice(envKeyIndex, 1);
                                                    }

                                                    if (
                                                        !assignment.environments ||
                                                        assignment.environments.length === 0
                                                    ) {
                                                        assignedActions.splice(actionIndex, 1);
                                                    }
                                                } else {
                                                    assignedActions.splice(actionIndex, 1);
                                                }

                                                setRoleActions([...roleActions]);
                                            }
                                        }
                                    }}
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    {action.environmentAgnostic && (
                        <Grid xs={5} item display="flex" alignItems="center">
                            <hr
                                style={{ width: '100%', borderColor: 'rgba(224,224,224, 0.5)', borderStyle: 'dashed' }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Stack>
        );
    };

    return (
        <TableContainer component={Paper} sx={{ marginTop: 3, maxHeight: 'calc(100vh - 220px)' }}>
            <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Module</TableCell>
                        <TableCell>Action</TableCell>
                        {environments.map((envKey) => (
                            <TableCell key={envKey} sx={{ textAlign: 'center' }}>
                                {envKey.toUpperCase()}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <React.Fragment key={row.category}>
                            <TableRow key={row.category} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row" rowSpan={row.actions.length + 1}>
                                    {row.category}
                                </TableCell>
                            </TableRow>

                            {row.actions.map((action, index) => {
                                const isLastActionInGroup: boolean = index >= row.actions.length - 1;

                                return (
                                    <TableRow key={`${row.category}-actions-${index}`} sx={{ border: 0 }}>
                                        <TableCell
                                            sx={
                                                !isLastActionInGroup
                                                    ? { borderBlockColor: 'rgba(256,256,256, 0.1)' }
                                                    : {}
                                            }
                                        >
                                            {action.friendlyName}
                                        </TableCell>
                                        {action.environmentAgnostic ? (
                                            <TableCell
                                                colSpan={environments.length}
                                                sx={
                                                    !isLastActionInGroup
                                                        ? { borderBlockColor: 'rgba(256,256,256, 0.1)' }
                                                        : {}
                                                }
                                            >
                                                {renderAction(
                                                    action,
                                                    null,
                                                    roleActions.find((ra) => ra.role === currentRole)?.assignedActions!,
                                                )}
                                            </TableCell>
                                        ) : (
                                            environments.map((envKey) => (
                                                <TableCell
                                                    key={`${row.category}-actions-${index}-${envKey}`}
                                                    colSpan={1}
                                                    width={environments.length > 3 ? '100px' : '150px'}
                                                    sx={
                                                        !isLastActionInGroup
                                                            ? { borderBlockColor: 'rgba(256,256,256, 0.1)' }
                                                            : {}
                                                    }
                                                >
                                                    {renderAction(
                                                        action,
                                                        envKey,
                                                        roleActions.find((ra) => ra.role === currentRole)
                                                            ?.assignedActions!,
                                                    )}
                                                </TableCell>
                                            ))
                                        )}
                                    </TableRow>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
