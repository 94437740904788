import { useParams } from 'react-router-dom';
import {
    DynamicListWithCallback,
    DynamicListWithCallbackItems,
} from '../../../components/elements/input/DynamicListWithCallback';
import { useLayout } from '../../../providers/LayoutProvider';

import { ManageEnvironmentType } from './ManageEnvironmentType';
import { useLogging } from '../../../providers/LoggingProvider';

export interface Props {
    value?: DynamicListWithCallbackItems;
}

export function EnvironmentTypesControl(props: Props) {
    // eslint-disable-next-line react/destructuring-assignment
    const initialValues = props.value;
    const { organizationId } = useParams();
    const { setAndOpenRightContextDrawer, closeAndClearRightContextDrawer } = useLayout();
    const { trackTrace } = useLogging();

    let newEnvironmentFromClonedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnCloneEnvironmentPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!newEnvironmentFromClonedCallbackForListUi) {
            trackTrace('newEnvironmentFromClonedCallbackForListUi was not defined after the item was was persisted');
            return;
        }

        newEnvironmentFromClonedCallbackForListUi(label, value, helpDialogText);
        newEnvironmentFromClonedCallbackForListUi = undefined;
    };

    const handleOnCloneEnvironment = (
        label: string,
        value: string,
        newItemFromClonedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newEnvironmentFromClonedCallbackForListUi = newItemFromClonedCallback;

        setAndOpenRightContextDrawer(
            <ManageEnvironmentType
                cloneAsNew
                organizationId={organizationId!}
                existingEnvironmentTypeId={value}
                actionCompleteCallback={handleOnCloneEnvironmentPersisted}
            />,
            'Clone Environment Type',
        );
    };

    let environmentEditedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnEditedEnvironmentPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!environmentEditedCallbackForListUi) {
            trackTrace('environmentEditedCallbackForListUi was not defined after the item was was persisted');
            return;
        }

        environmentEditedCallbackForListUi(label, value, helpDialogText);
        environmentEditedCallbackForListUi = undefined;
    };

    const handleOnEditEnvironment = (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        environmentEditedCallbackForListUi = itemEditedCallback;

        setAndOpenRightContextDrawer(
            <ManageEnvironmentType
                organizationId={organizationId!}
                existingEnvironmentTypeId={value}
                actionCompleteCallback={handleOnEditedEnvironmentPersisted}
            />,
            'Manage Environment Type',
        );
    };

    let newEnvironmentPersistedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnNewEnvironmentPersisted = (label: string, value: string, helpDialogText?: string) => {
        if (!newEnvironmentPersistedCallbackForListUi) {
            trackTrace('newItemPersistedCallbackForListUi was not defined after the new item was was persisted');
            return;
        }

        newEnvironmentPersistedCallbackForListUi(label, value, helpDialogText);
        newEnvironmentPersistedCallbackForListUi = undefined;

        closeAndClearRightContextDrawer();
    };

    const handleOnAddEnvironment = (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newEnvironmentPersistedCallbackForListUi = newItemPersistedCallback;

        setAndOpenRightContextDrawer(
            <ManageEnvironmentType
                organizationId={organizationId!}
                environmentTypeName={newItemLabel}
                actionCompleteCallback={handleOnNewEnvironmentPersisted}
            />,
            'Define New Environment Type',
        );
    };

    return (
        <DynamicListWithCallback
            name="environmentTypes"
            label="Environment Types"
            helpDialogText="For example: Dev, QA, UAT, Staging, Prod, etc. Do not add itterations such as UAT1, UAT2 here."
            newItemLabel="New Environment Name"
            value={initialValues}
            onAddItem={handleOnAddEnvironment}
            onEditItem={handleOnEditEnvironment}
            onCloneItem={handleOnCloneEnvironment}
        />
    );
}
