import { Route, Routes } from 'react-router-dom';
import { organizationRoutes } from '../../organization-pages/organizationRoutes';
import { platformRoutes } from '../../platform-pages/platformRoutes';
import { Layout } from '../../components/parts/Layout';
import { BasicLayout } from '../../components/parts/BasicLayout';
import { MainContentArea } from '../../components/parts/MainContentArea';

export function AppRoutes() {
    return (
        <Routes>
            <Route path="/organizations/:organizationId" element={<Layout />}>
                {organizationRoutes().map((item) => (
                    <Route key={item.path} path={item.path} element={<MainContentArea />}>
                        {item.element && <Route index element={item.element} />}
                        {item.children?.map((childItem, index) => (
                            <Route
                                index={!item.element && index === 0 ? true : undefined}
                                key={childItem.path}
                                path={childItem.path}
                                element={childItem.element}
                                id={childItem.path}
                            />
                        ))}
                    </Route>
                ))}
            </Route>
            <Route element={<BasicLayout />}>
                {platformRoutes().map((item) => (
                    <Route key={item.path} path={item.path} element={item.element} />
                ))}
            </Route>
        </Routes>
    );
}
