import { Card, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';

type NodeData = {
    label: string;
    type?: string;
};

export default memo(({ data }: NodeProps<NodeData>) => {
    return (
        <>
            <Handle type="target" position={Position.Left} style={{ visibility: 'hidden' }} isConnectable />
            <Card elevation={24} sx={{ padding: 3 }}>
                <Typography variant="body2" fontSize="0.75rem">
                    {data.label}
                </Typography>
                <Typography variant="body2" fontSize="0.5rem">
                    {data.type}
                </Typography>
            </Card>
            <Handle type="source" position={Position.Right} style={{ visibility: 'hidden' }} isConnectable />
        </>
    );
});
