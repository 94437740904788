import { CreateProductRequest } from '../../models/products/CreateProductRequest';
import { CreateProductResponse } from '../../models/products/CreateProductResponse';
import { GetProductByIdRequest } from '../../models/products/GetProductByIdRequest';
import { Product } from '../../models/products/Product';
import { ProductListRequest } from '../../models/products/ProductListRequest';
import { ProductListResponse } from '../../models/products/ProductListResponse';
import { UpdateProductRequest } from '../../models/products/UpdateProductRequest';
import { Result } from '../../models/Result';
import { apiSlice, RtkTagType } from '../api/apiSlice';

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        productsList: builder.query<Product[], ProductListRequest>({
            query: (request) => `organizations/${request.organizationId}/products`,
            transformResponse: (responseData: Result<ProductListResponse>) =>
                responseData.value.products.sort((a, b) => {
                    if (a.name === 'Organization Management') {
                        return -1;
                    }
                    if (b.name === 'Organization Management') {
                        return 1;
                    }
                    return a.name.localeCompare(b.name);
                }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: RtkTagType.Products, id })),
                          { type: RtkTagType.Products, id: 'LIST' },
                      ]
                    : [{ type: RtkTagType.Products, id: 'LIST' }],
        }),
        productById: builder.query<Product, GetProductByIdRequest>({
            query: (request) => `organizations/${request.organizationId}/products/${request.id}`,
            transformResponse: (responseData: Result<Product>) => responseData.value,
            providesTags: (result) => (result ? [{ type: RtkTagType.Products, id: result.id }] : []),
        }),
        productNew: builder.mutation<Product, CreateProductRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<Product>) => responseData.value,
            invalidatesTags: () => [{ type: RtkTagType.Products, id: 'LIST' }],
        }),
        productUpdate: builder.mutation<Product, UpdateProductRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.id}`,
                method: 'PUT',
                body: request,
            }),
            transformResponse: (responseData: Result<Product>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Products, id: request.id }] : [],
        }),
    }),
});

export const { useProductsListQuery, useProductByIdQuery, useProductNewMutation, useProductUpdateMutation } =
    productsApiSlice;
