import { useState, useEffect, ChangeEvent, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSnackbar } from 'notistack';
import AppBar from '@mui/material/AppBar';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListItemIcon from '@mui/material/ListItemIcon';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import BoltIcon from '@mui/icons-material/Bolt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Logout from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Link,
    LinkProps,
    TextField,
    Tooltip,
} from '@mui/material';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import InfoIcon from '@mui/icons-material/Info';
import { useUser } from '../../../providers/UserProvider';
import { useLayout } from '../../../providers/LayoutProvider';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useNotification } from '../../../providers/NotificationProvider';
import { NotificationMessage } from '../../../models/notification/Notification';
import { TopBreadcrumbs } from './TopBreadcrumbs';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { shortcutsDialogInstance } from '../../../features/shortcuts/ShortcutsDialog';

const SearchTextField = styled(TextField)(() => ({
    width: '100%',
}));

const SearchBar = styled('div')(({ theme }) => ({
    position: 'relative',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    flexGrow: 1,
}));

interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}

export function TopAppBar() {
    const searchInputRef = useRef<HTMLInputElement>();
    useHotkeys('/', (e) => {
        e.preventDefault();
        searchInputRef.current?.focus();
    });
    const { user, logoutUser, isPlatformManager } = useUser();
    const { entityContext } = useEntityContext();
    const {
        toggleLeftNavDrawer,
        isLeftNavDrawerOpen,
        isPreviewFeatureVisible,
        togglePreviewFeatureVisibility,
        isPlatformManagerMode,
        togglePlatformManagerMode,
        setAndOpenDialog,
    } = useLayout();
    const { sendFeedback } = useBackendService();
    const { enqueueSnackbar } = useSnackbar();
    const { notifications, removeNotification } = useNotification();
    const { organizationId } = useParams();

    const [name, setName] = useState<string | null>(null);
    useEffect(() => {
        if (user) {
            setName(user.friendlyName);
        } else {
            setName(null);
        }
    }, [user]);

    const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isAccountMenuOpen = Boolean(accountMenuAnchorEl);
    const handleAccountMenuClose = () => {
        setAccountMenuAnchorEl(null);
    };

    const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isNotificationMenuOpen = Boolean(notificationMenuAnchorEl);
    const handleNotificationMenuClose = () => {
        setNotificationMenuAnchorEl(null);
    };

    const handleNotificationMenuDismissItemClick = (
        event: React.MouseEvent<HTMLElement>,
        notification: NotificationMessage,
    ) => {
        removeNotification(notification);
    };

    const handleTogglePreview = () => {
        togglePreviewFeatureVisibility();
        handleAccountMenuClose();
    };

    const handleTogglePlatformManagerMode = () => {
        togglePlatformManagerMode();
        handleAccountMenuClose();
    };

    const handleLogout = () => {
        handleAccountMenuClose();
        logoutUser();
    };

    const renderNotificationMenu = (
        <Menu
            anchorEl={notificationMenuAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id="notification-menu"
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isNotificationMenuOpen}
            onClose={handleNotificationMenuClose}
        >
            {notifications.map((notification) => (
                <Card
                    key={notification.id}
                    square
                    elevation={0}
                    sx={{
                        padding: (theme) => theme.spacing(0, 1),
                        width: 400,
                        backgroundColor: 'transparent',
                        '&.MuiPaper-root:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        },
                    }}
                >
                    <CardContent>
                        <Typography variant="h5">{notification.title}</Typography>
                        <Typography>{notification.text}</Typography>
                    </CardContent>
                    <CardActions>
                        <Grid container justifyContent="flex-end">
                            {notification.action ? (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    onClick={() => {
                                        notification.action!();
                                        removeNotification(notification);
                                    }}
                                    sx={{ marginRight: '5px' }}
                                >
                                    {notification.actionText ? notification.actionText : 'OK'}
                                </Button>
                            ) : null}
                            <Button
                                color="secondary"
                                size="small"
                                onClick={(event) => handleNotificationMenuDismissItemClick(event, notification)}
                            >
                                Dismiss
                            </Button>
                        </Grid>
                    </CardActions>
                </Card>
            ))}
            {(!notifications || !notifications.length) && (
                <Card
                    square
                    elevation={0}
                    sx={{
                        padding: '5px',
                        width: 400,
                        backgroundColor: 'transparent',
                    }}
                    onClick={handleNotificationMenuClose}
                >
                    <CardContent sx={{ padding: '0 !important' }}>
                        <Grid justifyContent="center" alignContent="center" display="flex">
                            <InfoIcon />
                            <Typography marginLeft={1}>No new notifications</Typography>
                        </Grid>
                    </CardContent>
                </Card>
            )}
        </Menu>
    );

    const renderAccountMenu = (
        <Menu
            anchorEl={accountMenuAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id="account-menu"
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isAccountMenuOpen}
            onClose={handleAccountMenuClose}
        >
            <Box p={(theme) => theme.spacing(1, 2)}>
                <Typography align="center" variant="h6" sx={{ paddingBottom: (theme) => theme.spacing(1) }}>
                    Hi, {name}
                </Typography>

                <LinkRouter
                    underline="none"
                    color="inherit"
                    to={`/organizations/${entityContext.organizationSlug}/account`}
                    onClick={handleAccountMenuClose}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <AccountCircle fontSize="small" />
                        </ListItemIcon>
                        My account
                    </MenuItem>
                </LinkRouter>
                {isPlatformManager && (
                    <>
                        <LinkRouter
                            underline="none"
                            color="inherit"
                            to="/organizations"
                            onClick={handleAccountMenuClose}
                        >
                            <MenuItem>
                                <ListItemIcon>
                                    <CorporateFareIcon fontSize="small" />
                                </ListItemIcon>
                                Organizations
                            </MenuItem>
                        </LinkRouter>
                        <MenuItem onClick={handleTogglePreview}>
                            <ListItemIcon>
                                {isPreviewFeatureVisible ? (
                                    <ToggleOnIcon fontSize="small" />
                                ) : (
                                    <ToggleOffIcon fontSize="small" />
                                )}
                            </ListItemIcon>
                            Toggle preview
                        </MenuItem>
                        <MenuItem onClick={handleTogglePlatformManagerMode}>
                            <ListItemIcon>
                                {isPlatformManagerMode ? (
                                    <ToggleOnIcon fontSize="small" />
                                ) : (
                                    <ToggleOffIcon fontSize="small" />
                                )}
                            </ListItemIcon>
                            Toggle Platform Manager mode
                        </MenuItem>
                    </>
                )}

                {/* <Divider /> */}

                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Box>
        </Menu>
    );

    const [openFeedback, setOpenFeedback] = useState(false);
    const [feedbackSending, setFeedbackSending] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState('');

    const handleOpenFeedback = () => {
        setOpenFeedback(true);
    };

    const handleCloseFeedback = () => {
        setOpenFeedback(false);
        setFeedbackValue('');
    };

    const handleOnFeedbackChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFeedbackValue(event.currentTarget.value);
    };

    const handleSendFeedback = async () => {
        setFeedbackSending(true);
        await sendFeedback({
            organizationId: organizationId!,
            feedback: feedbackValue,
        })
            .then(() => {
                enqueueSnackbar('Thanks for the feedback!');
                handleCloseFeedback();
                setFeedbackSending(false);
            })
            .finally(() => {
                setFeedbackSending(false);
            });
    };

    const renderFeedbackDialog = (
        <Dialog open={openFeedback} onClose={handleCloseFeedback}>
            <DialogTitle>We Value Your Feedback</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please share any feedback that we can use to improve Scaled Sense. Additionally, if you have any
                    images you would like to share, please email them to contact@scaledsense.com.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="feedback"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    variant="standard"
                    value={feedbackValue}
                    onChange={handleOnFeedbackChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseFeedback}>Cancel</Button>
                <Button disabled={feedbackSending} onClick={handleSendFeedback}>
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
            <Toolbar>
                <IconButton edge="start" aria-label="open drawer" color="inherit" onClick={toggleLeftNavDrawer}>
                    {isLeftNavDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
                <TopBreadcrumbs />
                {isPreviewFeatureVisible && (
                    <SearchBar
                        sx={(theme) => ({
                            flexGrow: 1,
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        })}
                    >
                        <SearchTextField
                            placeholder="Search"
                            variant="standard"
                            type="search"
                            inputRef={searchInputRef}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Badge
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            badgeContent="/"
                                        >
                                            <SearchIcon />
                                        </Badge>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: 'transparent',
                                },
                                '& .MuiInput-underline:after': { borderBottomColor: '#9c9c9c' },
                            }}
                        />
                    </SearchBar>
                )}
                <Tooltip title="Shortcuts">
                    <IconButton color="inherit" onClick={() => setAndOpenDialog(shortcutsDialogInstance)}>
                        <Badge
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            overlap="circular"
                            badgeContent="s"
                        >
                            <BoltIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Notifications">
                    <IconButton color="inherit" onClick={(e) => setNotificationMenuAnchorEl(e.currentTarget)}>
                        <Badge badgeContent={notifications.length} color="secondary">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Provide feedback">
                    <IconButton
                        color="inherit"
                        onClick={handleOpenFeedback}
                        sx={{ marginLeft: (theme) => theme.spacing(1) }}
                    >
                        <ChatBubbleOutlineIcon />
                    </IconButton>
                </Tooltip>
                <IconButton
                    color="inherit"
                    onClick={(e) => setAccountMenuAnchorEl(e.currentTarget)}
                    sx={{ marginLeft: (theme) => theme.spacing(1) }}
                >
                    <Avatar>{name ? name[0].toUpperCase() : null}</Avatar>
                </IconButton>
            </Toolbar>
            {renderAccountMenu}
            {renderNotificationMenu}
            {renderFeedbackDialog}
        </AppBar>
    );
}
