import { Card, Typography, Link } from '@mui/material';
import React, { memo } from 'react';
import { Link as NavLink } from 'react-router-dom';
import { Handle, Position, NodeProps } from 'reactflow';
import { useEntityContext } from '../../../../providers/EntityContextProvider';

type NodeData = {
    label: string;
    type?: string;
    productId: string;
    serviceId: string;
};

export default memo(({ data }: NodeProps<NodeData>) => {
    const { entityContext, pathToChangeEntityContext, changeEntityContext } = useEntityContext();

    return (
        <>
            <Handle type="target" position={Position.Left} style={{ visibility: 'hidden' }} isConnectable />
            <Link
                component={NavLink}
                to={pathToChangeEntityContext({
                    organizationSlug: entityContext.organizationSlug!,
                    productSlug: data.productId,
                    serviceSlug: data.serviceId,
                })}
                color="inherit"
                underline="hover"
                sx={{
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    e.preventDefault();

                    changeEntityContext({
                        organizationSlug: entityContext.organizationSlug!,
                        productSlug: data.productId,
                        serviceSlug: data.serviceId,
                    });
                }}
            >
                <Card elevation={6} sx={{ padding: 1 }}>
                    <Typography variant="body2" fontSize="0.75rem">
                        {data.label}
                    </Typography>
                    <Typography variant="body2" fontSize="0.5rem">
                        {data.type}
                    </Typography>
                </Card>
            </Link>
            <Handle type="source" position={Position.Right} style={{ visibility: 'hidden' }} isConnectable />
        </>
    );
});
