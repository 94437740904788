import { useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { AlertActionableDialog } from '../../components/AlertActionableDialog';
import { Products } from '../products';
import { useLayout } from '../../providers/LayoutProvider';
import { EntityContextSlugs, useEntityContext } from '../../providers/EntityContextProvider';
import { useOrganizationByIdQuery } from '../organizations/organizationsApiSlice';
import { ssBlue } from '../../utils/theme';
import { PageDialogInstance } from '../../components/elements/PageDialog/PageDialogInstance';

function ContextMapDialogTitle() {
    const { closeAndClearDialog } = useLayout();
    const { changeEntityContext, entityContext } = useEntityContext();
    const { data: organizationData } = useOrganizationByIdQuery({ organizationId: entityContext.organizationSlug! });

    const handleChangeToOrgContext = () => {
        changeEntityContext({ organizationSlug: entityContext.organizationSlug });
        closeAndClearDialog();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Tooltip title="Change to Organization context">
                <IconButton
                    onClick={handleChangeToOrgContext}
                    sx={{
                        marginRight: 2,
                        backgroundColor: ssBlue[500],
                        boxShadow:
                            '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                    }}
                    tabIndex={-1}
                >
                    <CorporateFareIcon />
                </IconButton>
            </Tooltip>
            {organizationData?.name ? `${organizationData.name} – Context Map` : 'Organization Context Map'}
        </Box>
    );
}

function ContextMapDialog() {
    const [changeContextAlertOpen, setChangeContextAlertOpen] = useState(false);
    const [proposedNewContext, setProposedNewContext] = useState<EntityContextSlugs>({});
    const { closeAndClearDialog } = useLayout();
    const { changeEntityContext, entityContext } = useEntityContext();

    const handleProductClick = (productSlug: string, serviceSlug?: string) => {
        if (!entityContext.module && productSlug) {
            setProposedNewContext({ organizationSlug: entityContext.organizationSlug, productSlug, serviceSlug });
            setChangeContextAlertOpen(true);
        } else {
            changeEntityContext({ organizationSlug: entityContext.organizationSlug, productSlug, serviceSlug });
            closeAndClearDialog();
        }
    };

    const handleConfirmContextChange = () => {
        changeEntityContext({
            organizationSlug: proposedNewContext.organizationSlug,
            productSlug: proposedNewContext.productSlug,
            serviceSlug: proposedNewContext.serviceSlug,
        });
        setChangeContextAlertOpen(false);
        closeAndClearDialog();
    };

    const handleCancelContextChange = () => {
        setProposedNewContext({});
        setChangeContextAlertOpen(false);
    };

    return (
        <>
            <Products onProductClick={handleProductClick} />
            <AlertActionableDialog
                title="Want to continue?"
                text="The page you are currently at does not exist for your selection. You will instead be redirected to the default module. Do you want to continue?"
                closeAlert={handleCancelContextChange}
                isOpen={changeContextAlertOpen}
                actionButtonAction={handleConfirmContextChange}
                actionButtonText="Confirm"
            />
        </>
    );
}

export const contextMapDialogInstance: PageDialogInstance = {
    route: 'context-map',
    title: <ContextMapDialogTitle />,
    children: <ContextMapDialog />,
};
