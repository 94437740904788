import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLayout } from '../../../providers/LayoutProvider';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
}));

const DrawerFooter = styled('div')(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
}));

const DrawerButtonItem = styled(Grid)(({ theme }) => ({
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    ':last-of-type': {
        marginBottom: '1rem',
    },
    ':first-of-type': {
        marginTop: '1rem',
    },
}));

type Props = {
    drawerWidth: number;
    forceTemporary?: boolean;
};

export function RightContextDrawer({ drawerWidth, forceTemporary }: Props) {
    const { rightContextDrawerState, closeAndClearRightContextDrawer } = useLayout();

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                },
                zIndex: rightContextDrawerState.isOpen ? (theme) => theme.zIndex.drawer : -999,
                position: 'relative',
            }}
            variant={rightContextDrawerState.showBackdrop || forceTemporary ? 'temporary' : 'permanent'}
            anchor="right"
            open={rightContextDrawerState.isOpen}
        >
            <Toolbar />
            <div style={{ position: 'sticky', top: 0 }}>
                <DrawerHeader>
                    <IconButton onClick={closeAndClearRightContextDrawer}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>
                {rightContextDrawerState.header && (
                    <Typography variant="h5" marginBottom={2} marginLeft={6}>
                        {rightContextDrawerState.header}
                    </Typography>
                )}
            </div>
            <Box p={(theme) => theme.spacing(3)} sx={{ overflowY: 'auto', height: '100vw' }}>
                {rightContextDrawerState.content}
            </Box>
            {rightContextDrawerState.footerActions?.length && (
                <DrawerFooter>
                    <Grid container justifyContent="center" display="flex" marginTop={3}>
                        {rightContextDrawerState.footerActions.map((action, index) => (
                            <DrawerButtonItem key={index} item xs={10}>
                                <Button
                                    variant={action.variant || 'contained'}
                                    color={action.color || 'primary'}
                                    fullWidth
                                    disabled={action.actionDisabled}
                                    onClick={() => {
                                        action.action();
                                    }}
                                >
                                    {action.actionText || 'Save'}
                                </Button>
                            </DrawerButtonItem>
                        ))}
                    </Grid>
                </DrawerFooter>
            )}
        </Drawer>
    );
}
