import React, { useEffect, useState } from 'react';
import MuiTabs, { TabsProps as MuiTabsProps } from '@mui/material/Tabs';
import { MenuItem, Popover, Tab } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Modify } from '../../../../utils/types/Modify';

export type TabsProps = Modify<
    Partial<MuiTabsProps>,
    {
        tabs: string[];
        numberOfTabsToDisplay: number;
        onClickTab: (event: React.MouseEvent<HTMLElement, MouseEvent>, tabIndex: number, tabLabel: string) => void;
    }
>;

export function Tabs({ tabs, numberOfTabsToDisplay, onClickTab }: TabsProps) {
    const [tabData, setTabData] = useState<string[]>([]);
    const [currentTab, setCurrentTab] = useState<number>(0);
    const [moreIsOpen, setIsMoreOpen] = useState<boolean>(false);
    const [moreAnchorEl, setMoreAnchorEl] = useState<Element | undefined>(undefined);

    useEffect(() => {
        setTabData(tabs);
    }, [tabs]);

    return (
        <MuiTabs value={currentTab} variant="scrollable" allowScrollButtonsMobile>
            {tabData.slice(0, numberOfTabsToDisplay).map((tabLabel, index) => (
                <Tab
                    key={index}
                    label={tabLabel}
                    value={index}
                    onClick={(event) => {
                        setCurrentTab(index);
                        onClickTab(event, index, tabLabel);
                    }}
                    sx={{ minHeight: '24px' }}
                />
            ))}
            {tabData.length > numberOfTabsToDisplay && (
                <Tab
                    key={numberOfTabsToDisplay + 1}
                    label="More"
                    value={numberOfTabsToDisplay + 1}
                    icon={<ArrowDropDownIcon />}
                    iconPosition="end"
                    onClick={(ev) => {
                        setMoreAnchorEl(ev.currentTarget);
                        setIsMoreOpen(true);
                    }}
                    sx={{ minHeight: '24px' }}
                />
            )}
            <Popover
                open={moreIsOpen}
                anchorEl={moreAnchorEl}
                onClose={() => setIsMoreOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {tabData.slice(numberOfTabsToDisplay).map((tabLabel, index) => (
                    <MenuItem
                        key={index}
                        onClick={(ev) => {
                            const copiedTabs = [...tabData];
                            const selectedTab = copiedTabs.splice(numberOfTabsToDisplay + index, 1);
                            const insertIndex = numberOfTabsToDisplay - 1; // insert to left of "More"
                            copiedTabs.splice(insertIndex, 0, selectedTab[0]);
                            setIsMoreOpen(false);
                            setTabData(copiedTabs);
                            setCurrentTab(insertIndex);

                            onClickTab(ev, insertIndex, selectedTab[0]);
                        }}
                    >
                        {tabLabel}
                    </MenuItem>
                ))}
            </Popover>
        </MuiTabs>
    );
}
