import { Container, Switch } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import CardSelection from '../../../components/elements/CardSelection';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useOrganizationMetadataByIdQuery } from '../../organizations/organizationsApiSlice';

export interface CreateServiceEnvironmentsState {
    environments: string[];
    isConfigured?: boolean;
}

type Props = {
    environmentsToCreate: CreateServiceEnvironmentsState;
    setEnvironmentsToCreate: Dispatch<SetStateAction<CreateServiceEnvironmentsState>>;
};

export default function NewServiceStepEnvironments({ environmentsToCreate, setEnvironmentsToCreate }: Props) {
    const { entityContext } = useEntityContext();
    const { data: organization } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const handleToggleEnvironment = (environmentTypeId: string) => {
        const environmentFound = environmentsToCreate.environments.find((a) => a === environmentTypeId);

        if (environmentFound) {
            setEnvironmentsToCreate((prev) => ({
                ...prev,
                environments: prev.environments.filter((a) => a !== environmentTypeId),
            }));
        } else {
            setEnvironmentsToCreate((prev) => ({
                ...prev,
                environments: [
                    ...prev.environments,
                    ...(!prev.environments.includes(environmentTypeId) ? [environmentTypeId] : []),
                ],
            }));
        }
    };

    useEffect(() => {
        if (!environmentsToCreate.isConfigured) {
            ['dev', 'prd'].forEach((environment) => {
                const envTypeId = organization?.environmentTypes?.find((et) => et.businessKey === environment)?.id;
                if (envTypeId) {
                    setEnvironmentsToCreate((prev) => ({
                        ...prev,
                        environments: [
                            ...prev.environments,
                            ...(!prev.environments.includes(envTypeId) ? [envTypeId] : []),
                        ],
                        isConfigured: true,
                    }));
                }
            });
        }
    }, [organization]);

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardSelection
                centerHorizontally
                title="What environments would you like to start with?"
                cards={
                    organization?.environmentTypes
                        ?.filter((et) => !['p', 'np', 'org'].includes(et.businessKey))
                        .map((et) => ({
                            subTitle: et.name,
                            action: (
                                <Switch
                                    onChange={() => handleToggleEnvironment(et.id)}
                                    checked={environmentsToCreate.environments.includes(et.id)}
                                />
                            ),
                        }))
                        .reverse() || []
                }
            />
        </Container>
    );
}
