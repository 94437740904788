import { Link } from '../../models/links/Link';
import { CreateLinkUseCaseRequest } from '../../models/links/requests/CreateLinkUseCaseRequest';
import { LinkListByRequestingServiceIdRequest } from '../../models/links/requests/LinkListByRequestingServiceIdRequest';
import { MarkLinkForRemovalRequest } from '../../models/links/requests/MarkLinkForRemovalRequest';
import { RefreshLinkResourceCacheRequest } from '../../models/links/requests/RefreshLinkResourceCacheRequest';
import { Result } from '../../models/Result';
import { apiSlice, RtkTagType } from '../api/apiSlice';

export const linksApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        linksListByRequestingServiceId: builder.query<Link[], LinkListByRequestingServiceIdRequest>({
            query: (request) =>
                `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/links`,
            transformResponse: (responseData: Result<Link[]>) => responseData.value,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: RtkTagType.Links, id })),
                          { type: RtkTagType.Links, id: 'LIST' },
                      ]
                    : [{ type: RtkTagType.Links, id: 'LIST' }],
        }),
        linkReloadResourceCache: builder.mutation<Link, RefreshLinkResourceCacheRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/links/${request.linkId}/refresh-resource-cache`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Links, id: request.linkId }] : [],
        }),
        linkMarkForRemoval: builder.mutation<Link, MarkLinkForRemovalRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/links/${request.linkId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Links, id: request.linkId }] : [],
        }),
        linkNew: builder.mutation<Link, CreateLinkUseCaseRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/links`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<Link>) => responseData.value,
            invalidatesTags: () => [{ type: RtkTagType.Links, id: 'LIST' }],
        }),
    }),
});

export const {
    useLinksListByRequestingServiceIdQuery,
    useLinkReloadResourceCacheMutation,
    useLinkMarkForRemovalMutation,
    useLinkNewMutation,
} = linksApiSlice;
