import { ReactElement } from 'react';

type Props = {
    label: string;
    children: ReactElement;
    onPreviousStep?: () => void;
    onNextStep?: () => void;
    nextStepDisabled?: boolean;
};

export default function StepperStep({ label, children, onPreviousStep, onNextStep, nextStepDisabled }: Props) {
    const getLabel = () => {
        return label;
    };
    return children;
}
