import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { TopAppBar } from '../TopAppBar';
import { LeftNavDrawer } from '../LeftNavDrawer';
import { LayoutProvider } from '../../../providers/LayoutProvider';
import { useProductsListQuery } from '../../../features/products/productsApiSlice';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useOrganizationByIdQuery } from '../../../features/organizations/organizationsApiSlice';
import { useServicesByOrgIdQuery } from '../../../features/services/services/servicesApiSlice';
import { Dialogs } from '../Dialogs';

export function Layout() {
    const { entityContext } = useEntityContext();
    // Prefetch global data
    const [skipOrgFetch, setSkipOrgFetch] = useState(true);
    useOrganizationByIdQuery({ organizationId: entityContext.organizationSlug! }, { skip: skipOrgFetch });
    useProductsListQuery({ organizationId: entityContext.organizationSlug! }, { skip: skipOrgFetch });
    useServicesByOrgIdQuery({ organizationId: entityContext.organizationSlug! }, { skip: skipOrgFetch });

    // Only fetch data when we have context
    useEffect(() => {
        if (entityContext.organizationSlug) {
            setSkipOrgFetch(false);
        }
    }, [entityContext]);

    return (
        <LayoutProvider>
            <Dialogs />
            <TopAppBar />
            <LeftNavDrawer />
            <Outlet />
        </LayoutProvider>
    );
}
