import { Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Organization } from '../../../../models/organizations/Organization';
import { useBackendService } from '../../../../providers/BackendServiceProvider';
import { ProductRolesControl } from '../ProductRolesControl';

export function DesignOrganizationProductRoles() {
    const [orgData, setOrgData] = useState<Organization | undefined>();
    const { organizationId } = useParams();
    const { getOrganizationById } = useBackendService();

    const fetchOrgData = async () => {
        const response = await getOrganizationById({ organizationId: organizationId! });
        setOrgData(response);
    };

    useEffect(() => {
        fetchOrgData();
    }, []);

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                {orgData && (
                    <ProductRolesControl
                        value={orgData?.productRoles.map((role) => {
                            return {
                                label: `${role.name} (${role.businessKey.toUpperCase()})`,
                                value: role.id,
                                helpDialogText: role.description,
                            };
                        })}
                    />
                )}
            </Stack>
        </Container>
    );
}
