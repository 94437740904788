import { Formik, Form, FormikValues, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';
import { InviteUserRequest } from '../../../models/users/requests/InviteUserRequest';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().required('Required').email('Please enter a valid email address'),
    friendlyName: Yup.string().required('Required'),
});

type Props = {
    organizationId: string;
    actionCompleteCallback: () => void;
};

export function InviteUser({ organizationId, actionCompleteCallback }: Props) {
    const INITIAL_FORM_STATE: Partial<InviteUserRequest> = {
        email: '',
        friendlyName: '',
    };

    const [initialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { inviteUser } = useBackendService();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const handleSubmit = async (values: any, formik: any) => {
        // Invite new user
        const prodRes = await inviteUser({ ...values, organizationId } as InviteUserRequest);
        formik.setSubmitting(false);
        const resId: string = prodRes.id;

        enqueueSnackbar('User Invited');
        actionCompleteCallback();
    };

    useEffect(() => {
        const actions: DrawerFooterAction[] = [
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: 'Invite',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ];

        setRightContextDrawerActions(actions);
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField name="friendlyName" label="Name" required autoFocus />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="email" label="Email Address" required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2">
                                            The invited user must already be a member of your organization&#39;s Entra
                                            tenant with a valid email address.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
