import { isValidElement } from 'react';
import { Grid, Paper, PaperProps, SxProps, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { HorizontalRule } from '@mui/icons-material';
import { mutedColor } from '../../utils/theme';

type Props = {
    metadataProperties: { label: string; value?: string | JSX.Element }[];
} & PaperProps;

export function MetadataDisplay({ metadataProperties, ...args }: Props) {
    const themeHook = useTheme();
    const isMediumDesktopView = useMediaQuery(themeHook.breakpoints.up('md'));

    return (
        <Paper {...args} sx={{ padding: 2, marginBottom: 2, ...args.sx }}>
            <Grid container>
                <Grid container alignItems="center">
                    {metadataProperties.map((item, index) => (
                        <Grid
                            key={item.label}
                            item
                            xs={12}
                            md={6}
                            paddingX={1}
                            paddingLeft={isMediumDesktopView && index % 2 !== 0 ? 3 : undefined}
                            paddingRight={isMediumDesktopView && index % 2 === 0 ? 5 : undefined}
                            overflow="hidden"
                        >
                            <Grid container alignItems="start" paddingY="8px">
                                <Grid item container xs={6} alignItems="center" flexWrap="nowrap">
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        display="inline-flex"
                                        color={mutedColor}
                                        flex={1}
                                    >
                                        {item.label}
                                    </Typography>
                                    <Typography component="span" variant="body2" display="inline-flex" paddingRight={3}>
                                        :
                                    </Typography>
                                </Grid>
                                {isValidElement(item.value) && (
                                    <Grid item container xs={6} alignItems="center" alignSelf="center">
                                        {item.value}
                                    </Grid>
                                )}
                                {!isValidElement(item.value) && (
                                    <Grid item container xs={6} alignItems="center" alignSelf="center">
                                        {item.value ? (
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{ whiteSpace: 'pre' }}
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                            >
                                                {item.value}
                                            </Typography>
                                        ) : (
                                            <HorizontalRule style={{ verticalAlign: 'top' }} />
                                        )}
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
}
