import { Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Organization } from '../../../models/organizations/Organization';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useLayout } from '../../../providers/LayoutProvider';

export function DesignOrganization() {
    const [orgData, setOrgData] = useState<Organization | undefined>();
    const { organizationId } = useParams();
    const { getOrganizationById } = useBackendService();
    const { isPlatformManagerMode } = useLayout();

    const fetchOrgData = async () => {
        const response = await getOrganizationById({ organizationId: organizationId! });
        setOrgData(response);
    };

    useEffect(() => {
        fetchOrgData();
    }, []);

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                {orgData && (
                    <>
                        <Typography variant="h4">{orgData?.name}</Typography>
                        <Typography variant="body1">
                            <strong>Business Key: </strong>
                            {orgData.businessKey}
                        </Typography>
                        <Typography variant="body1">
                            <strong>External Tenant Type: </strong>
                            {`${orgData.externalTenant.type === 'azureAd' ? 'Entra ID' : 'Unknown'}`}
                        </Typography>
                        <Typography variant="body1">
                            <strong>External Tenant ID: </strong>
                            {orgData.externalTenant.id}
                        </Typography>
                        {isPlatformManagerMode && (
                            <>
                                <Typography variant="body1">
                                    <strong>Organization ID: </strong>
                                    {orgData.id}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Owner ID: </strong>
                                    {orgData.owner?.id}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Compliance Requirements: </strong>
                                    {orgData.complianceReqs || 'None'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Require Private Networking Across Organization: </strong>
                                    {orgData.privateNetworking ? 'Yes' : 'No'}
                                </Typography>
                            </>
                        )}
                    </>
                )}
            </Stack>
        </Container>
    );
}
