import { useParams } from 'react-router-dom';
import {
    DynamicListWithCallback,
    DynamicListWithCallbackItems,
} from '../../../components/elements/input/DynamicListWithCallback';
import { useLayout } from '../../../providers/LayoutProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { ManageBusinessUnit } from './ManageBusinessUnit';

export interface Props {
    value?: DynamicListWithCallbackItems;
}

export function BusinessUnitControl(props: Props) {
    // eslint-disable-next-line react/destructuring-assignment
    const initialValues = props.value;
    const { organizationId } = useParams();
    const { setAndOpenRightContextDrawer, closeAndClearRightContextDrawer } = useLayout();
    const { trackTrace } = useLogging();

    let newBusinessUnitFromClonedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnCloneCriticalityPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!newBusinessUnitFromClonedCallbackForListUi) {
            trackTrace('newBusinessUnitFromClonedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        newBusinessUnitFromClonedCallbackForListUi(label, value, helpDialogText);
        newBusinessUnitFromClonedCallbackForListUi = undefined;
    };

    const handleOnCloneBusinessUnit = (
        label: string,
        value: string,
        newItemFromClonedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newBusinessUnitFromClonedCallbackForListUi = newItemFromClonedCallback;

        setAndOpenRightContextDrawer(
            <ManageBusinessUnit
                organizationId={organizationId!}
                cloneAsNew
                existingBusinessUnitId={value}
                actionCompleteCallback={handleOnCloneCriticalityPersisted}
            />,
            'Clone Business Unit',
        );
    };

    let businessUnitEditedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnEditedCriticalityPersisted = (label: string, value: string, helpDialogText?: string) => {
        closeAndClearRightContextDrawer();

        if (!businessUnitEditedCallbackForListUi) {
            trackTrace('businessUnitEditedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        businessUnitEditedCallbackForListUi(label, value, helpDialogText);
        businessUnitEditedCallbackForListUi = undefined;
    };

    const handleOnEditBusinessUnit = (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        businessUnitEditedCallbackForListUi = itemEditedCallback;

        setAndOpenRightContextDrawer(
            <ManageBusinessUnit
                organizationId={organizationId!}
                existingBusinessUnitId={value}
                actionCompleteCallback={handleOnEditedCriticalityPersisted}
            />,
            'Manage Business Unit',
        );
    };

    let newBusinessUnitPersistedCallbackForListUi:
        | undefined
        | ((label: string, value: string, helpDialogText?: string) => void);

    const handleOnNewBusinessUnitPersisted = (label: string, value: string, helpDialogText?: string) => {
        if (!newBusinessUnitPersistedCallbackForListUi) {
            trackTrace('newBusinessUnitPersistedCallbackForListUi was not defined after the new item was persisted');
            return;
        }

        newBusinessUnitPersistedCallbackForListUi(label, value, helpDialogText);
        newBusinessUnitPersistedCallbackForListUi = undefined;

        closeAndClearRightContextDrawer();
    };

    const handleOnAddBusinessUnit = (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => {
        newBusinessUnitPersistedCallbackForListUi = newItemPersistedCallback;

        setAndOpenRightContextDrawer(
            <ManageBusinessUnit
                organizationId={organizationId!}
                businessUnitName={newItemLabel}
                actionCompleteCallback={handleOnNewBusinessUnitPersisted}
            />,
            'Define New Business Unit',
        );
    };

    return (
        <DynamicListWithCallback
            name="businessUnits"
            label="Business Units"
            helpDialogText="For example: Information Technology, Accounting, Marketing, etc. You will define priority and additional details later."
            newItemLabel="New Business Unit Name"
            value={initialValues}
            onAddItem={handleOnAddBusinessUnit}
            onEditItem={handleOnEditBusinessUnit}
            onCloneItem={handleOnCloneBusinessUnit}
        />
    );
}
