import { NavLink } from 'react-router-dom';
import { Tooltip, Chip, SxProps, Theme } from '@mui/material';
import { EntityContextModule, useEntityContext } from '../../providers/EntityContextProvider';
import { getServiceState, Service } from '../../models/services/Service';
import { transientBackground } from '../../utils/theme';

type Props = {
    service: Service;
    sxProps?: SxProps<Theme>;
};

export function ServiceDeploymentStatus({ service, sxProps }: Props) {
    const { entityContext, pathToChangeEntityContext } = useEntityContext();

    return (
        <NavLink
            to={pathToChangeEntityContext(
                {
                    organizationSlug: service.organizationId,
                    productSlug: service.productId,
                    serviceSlug: service.id,
                },
                EntityContextModule.Observe,
            )}
            style={{ width: '100%' }}
        >
            <Tooltip title={getServiceState(service).description}>
                <Chip
                    label={getServiceState(service).text}
                    color={getServiceState(service).color}
                    size="small"
                    sx={{
                        ...(getServiceState(service).isTransient &&
                            transientBackground[getServiceState(service).color]),
                        ...sxProps,
                    }}
                />
            </Tooltip>
        </NavLink>
    );
}
