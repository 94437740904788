import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

export type SelectProps = {
    name: string;
    options: { label: string; value: string }[];
} & TextFieldProps;

export function Select({ name, options, ...otherProps }: SelectProps) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        await setFieldValue(name, value);
    };

    const configSelect: TextFieldProps = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
    };

    if (meta && meta.error && (meta.touched || meta.value)) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <TextField {...configSelect}>
            {options.map((item, pos) => (
                <MenuItem key={item.value} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
