import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Grid, Typography, styled, Card, CardHeader, useMediaQuery, CardContent, Box } from '@mui/material';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useBackendService } from 'providers/BackendServiceProvider';
import { Shortcut } from '../../models/shortcuts/Shortcut';
import { useEntityContext } from '../../providers/EntityContextProvider';
import { PageDialogInstance } from '../../components/elements/PageDialog/PageDialogInstance';

function groupBy<T>(objectArray: T[], property: string) {
    return objectArray.reduce((acc: { [key: string]: T[] }, obj: any) => {
        const key: string = obj[property];
        if (!acc[key]) {
            acc[key] = [];
        }
        // Add object to list for given key's value
        acc[key].push(obj);
        return acc;
    }, {});
}

function ShortcutsDialog() {
    const { entityContext } = useEntityContext();

    const [data, setData] = useState<Shortcut[]>([]);
    const { getShortcuts } = useBackendService();
    const isDesktopView = useMediaQuery('(min-width:1200px)');

    const fetchData = async () => {
        await getShortcuts({ organizationId: entityContext.organizationSlug! }).then((x: Shortcut[]) => setData(x));
    };

    // Card styles
    const StyledCard = styled(Card)(({ theme }) => ({
        '&:hover': {
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        marginTop: '1rem',
        padding: '1rem',
        flexGrow: 1,
    }));

    const StyledCardContent = styled(CardContent)(({ theme }) => ({
        ':last-child': {
            paddingBottom: '8px',
        },
        padding: '0.5rem 0 0.5rem 0',
    }));

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box px={2}>
            {data.length < 1 && (
                <Typography textAlign="center" variant="body1">
                    No shortcuts have been created yet.
                </Typography>
            )}
            <Grid container display="flex" flexDirection="column">
                {data &&
                    Object.keys(groupBy<Shortcut>(data, 'category')).map((categoryKey, i) => (
                        <React.Fragment key={i}>
                            <Grid item xs={12} marginTop={i !== 0 ? 2 : 0}>
                                <Typography variant="h5">
                                    {categoryKey !== 'undefined' ? categoryKey : 'General'}
                                </Typography>
                            </Grid>
                            <Grid container alignItems="stretch">
                                {groupBy<Shortcut>(data, 'category')[categoryKey].map((shortcut, index) => (
                                    <Grid key={shortcut.id} item xs={6} lg={3} display="flex">
                                        <Link
                                            to={shortcut.action.url}
                                            target="_blank"
                                            rel="noopener"
                                            style={{ textDecoration: 'none', flexGrow: 1 }}
                                        >
                                            <StyledCard
                                                sx={{
                                                    marginTop: 1,
                                                    marginRight:
                                                        ((index + 1) % 4 && isDesktopView) ||
                                                        ((index + 1) % 2 && !isDesktopView)
                                                            ? 2
                                                            : 0,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                <CardHeader
                                                    titleTypographyProps={{ variant: 'h6', fontWeight: '500' }}
                                                    title={shortcut.name}
                                                    sx={{ padding: 0 }}
                                                    action={
                                                        // eslint-disable-next-line react/jsx-wrap-multilines
                                                        <OpenInNewIcon
                                                            sx={{
                                                                // position: 'relative',
                                                                // top: '4px',
                                                                // right: '8px',
                                                                marginTop: 1,
                                                                marginRight: 1,
                                                            }}
                                                        />
                                                    }
                                                />
                                                {shortcut.description && (
                                                    <StyledCardContent sx={{ paddingRight: 1 }}>
                                                        <Typography variant="body2">{shortcut.description}</Typography>
                                                    </StyledCardContent>
                                                )}
                                            </StyledCard>
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </Box>
    );
}

export const shortcutsDialogInstance: PageDialogInstance = {
    route: 'shortcuts',
    title: 'Shortcuts',
    children: <ShortcutsDialog />,
};
