import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import { Badge } from '@mui/material';
import SensorsIcon from '@mui/icons-material/Sensors';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import TerminalIcon from '@mui/icons-material/Terminal';
import CodeIcon from '@mui/icons-material/Code';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import { organizationRoutes } from '../../../organization-pages/organizationRoutes';
import { useLayout } from '../../../providers/LayoutProvider';
import { EntityContextModule, useEntityContext } from '../../../providers/EntityContextProvider';
import { activeNavBackgroundColor } from '../../../utils/theme';

const drawerWidth: number = 200;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

export function LeftNavDrawer() {
    const { entityContext, pathToNavigateWithCurrentEntityContext, navigateWithCurrentEntityContext } =
        useEntityContext();
    const { isLeftNavDrawerOpen, isPreviewFeatureVisible } = useLayout();
    const location = useLocation();
    const [activeNavPath, setActiveNavPath] = useState('');
    const pagesToHide: string[] = [];

    const navigateWithCurrentEntityContextIfPreview = (modulePath: string) => {
        if (isPreviewFeatureVisible) {
            navigateWithCurrentEntityContext(modulePath);
        }
    };

    useHotkeys('d', () => navigateWithCurrentEntityContext(EntityContextModule.Design));
    useHotkeys('b', () => navigateWithCurrentEntityContextIfPreview(EntityContextModule.Build));
    useHotkeys('o', () => navigateWithCurrentEntityContext(EntityContextModule.Observe));
    useHotkeys('a', () => navigateWithCurrentEntityContextIfPreview(EntityContextModule.Act));
    useHotkeys('i', () => navigateWithCurrentEntityContextIfPreview(EntityContextModule.Inspect));

    // Determine what button to highlight as active on left nav bar
    useEffect(() => {
        let maxIndex = -1;
        let maxNavPath = '';
        if (location.pathname === '/') {
            maxNavPath = '/';
        } else {
            organizationRoutes().forEach((page) => {
                if (page.navActivePath && page.navActivePath !== '/') {
                    const lastIndex = location.pathname.lastIndexOf(page.navActivePath);
                    if (lastIndex > maxIndex) {
                        maxIndex = lastIndex;
                        maxNavPath = page.navActivePath;
                    }
                }
            });
        }
        setActiveNavPath(maxNavPath);
    }, [location]);

    return (
        <Drawer variant="permanent" open={isLeftNavDrawerOpen} sx={{ display: 'flex', flexDirection: 'column' }}>
            <Toolbar />
            <List component="nav">
                <NavLink
                    to={pathToNavigateWithCurrentEntityContext(EntityContextModule.Design)}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                >
                    <ListItemButton
                        sx={(theme) => ({
                            backgroundColor: activeNavPath === '/design' ? activeNavBackgroundColor : 'inherit',
                            '&:hover': { backgroundColor: theme.palette.primary.dark },
                        })}
                    >
                        <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
                            <Badge
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent="d"
                            >
                                <DesignServicesIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Design" />
                    </ListItemButton>
                </NavLink>
                {isPreviewFeatureVisible && (
                    <NavLink
                        to={pathToNavigateWithCurrentEntityContext(EntityContextModule.Build)}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                        <ListItemButton
                            sx={(theme) => ({
                                backgroundColor: activeNavPath === '/build' ? activeNavBackgroundColor : 'inherit',
                                '&:hover': { backgroundColor: theme.palette.primary.dark },
                            })}
                        >
                            <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent="b"
                                >
                                    <CodeIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Build" />
                        </ListItemButton>
                    </NavLink>
                )}
                <NavLink
                    to={pathToNavigateWithCurrentEntityContext(EntityContextModule.Observe)}
                    style={{ color: 'inherit', textDecoration: 'none' }}
                >
                    <ListItemButton
                        sx={(theme) => ({
                            backgroundColor: activeNavPath === '/observe' ? activeNavBackgroundColor : 'inherit',
                            '&:hover': { backgroundColor: theme.palette.primary.dark },
                        })}
                    >
                        <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
                            <Badge
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                badgeContent="o"
                            >
                                <SensorsIcon />
                            </Badge>
                        </ListItemIcon>
                        <ListItemText primary="Observe" />
                    </ListItemButton>
                </NavLink>
                {isPreviewFeatureVisible && (
                    <NavLink
                        to={pathToNavigateWithCurrentEntityContext(EntityContextModule.Act)}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                        <ListItemButton
                            sx={(theme) => ({
                                backgroundColor: activeNavPath === '/act' ? activeNavBackgroundColor : 'inherit',
                                '&:hover': { backgroundColor: theme.palette.primary.dark },
                            })}
                        >
                            <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent="a"
                                >
                                    <DeviceHubIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Act" />
                        </ListItemButton>
                    </NavLink>
                )}
                {isPreviewFeatureVisible && (
                    <NavLink
                        to={pathToNavigateWithCurrentEntityContext(EntityContextModule.Inspect)}
                        style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                        <ListItemButton
                            sx={(theme) => ({
                                backgroundColor: activeNavPath === '/inspect' ? activeNavBackgroundColor : 'inherit',
                                '&:hover': { backgroundColor: theme.palette.primary.dark },
                            })}
                        >
                            <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>
                                <Badge
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    badgeContent="i"
                                >
                                    <TerminalIcon />
                                </Badge>
                            </ListItemIcon>
                            <ListItemText primary="Inspect" />
                        </ListItemButton>
                    </NavLink>
                )}
                {organizationRoutes({ organizationSlug: entityContext.organizationSlug })
                    .filter((item) => item.navMain && (isPreviewFeatureVisible || !pagesToHide.includes(item.name)))
                    .map((item) => (
                        <NavLink key={item.name} to={item.path} style={{ color: 'inherit', textDecoration: 'none' }}>
                            <ListItemButton
                                sx={(theme) => ({
                                    backgroundColor: activeNavPath === '/build' ? activeNavBackgroundColor : 'inherit',
                                    '&:hover': { backgroundColor: theme.palette.primary.dark },
                                })}
                            >
                                <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </NavLink>
                    ))}
            </List>
            <List component="nav" sx={{ marginTop: 'auto' }}>
                {organizationRoutes({ organizationSlug: entityContext.organizationSlug })
                    .filter(
                        (item) => item.navMainBottom && (isPreviewFeatureVisible || !pagesToHide.includes(item.name)),
                    )
                    .map((item) => (
                        <NavLink
                            key={item.name}
                            to={
                                !item.element && item.children && item.children.length > 0
                                    ? `${item.path}/${item.children[0].path}`
                                    : item.path
                            }
                            style={{ color: 'inherit', textDecoration: 'none' }}
                        >
                            <ListItemButton
                                sx={(theme) => ({
                                    backgroundColor:
                                        activeNavPath === item.navActivePath ? activeNavBackgroundColor : 'inherit',
                                    '&:hover': { backgroundColor: theme.palette.primary.dark },
                                })}
                            >
                                <ListItemIcon sx={{ minWidth: 0, paddingRight: 2 }}>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </NavLink>
                    ))}
            </List>
        </Drawer>
    );
}
