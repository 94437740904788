import { NewOrganization } from './NewOrganization';
import { Waitlist } from '../components/parts/Waitlist';
import { GitHubCallback } from './Callbacks/GitHubCallback';
import { AzureAdCallback } from './Callbacks/AzureAzCallback';
import { ScaledSenseAppCallback } from './Callbacks/ScaledSenseAppCallback';
import { Organizations } from './Organizations';
import { Default } from './Default';
import { ResourceNotFound } from '../features/routing/ResourceNotFound';

type NavigationItem = {
    name: string;
    children?: NavigationItem[];
    path: string;
    breadcrumbPath?: string;
    element: any;
};

export const platformRoutes = () => {
    const routes: NavigationItem[] = [
        {
            name: 'Create Organization',
            path: '/organizations/new',
            element: <NewOrganization />,
        },
        {
            name: 'Organizations',
            path: '/organizations',
            element: <Organizations />,
        },
        {
            name: 'GitHub Connection',
            path: '/callbacks/platforms/github-app-authorization-callback',
            element: <GitHubCallback />,
        },
        {
            name: 'Azure AD Connection',
            path: '/callbacks/platforms/azure-ad-admin-consent-callback',
            element: <AzureAdCallback />,
        },
        {
            name: 'Scaled Sense Admin Consent',
            path: '/callbacks/scaled-sense-app/admin-consent-callback',
            element: <ScaledSenseAppCallback />,
        },
        {
            name: '',
            path: '/',
            breadcrumbPath: '/',
            element: <Default />,
        },
        {
            name: 'Not Found',
            path: '*',
            breadcrumbPath: '/',
            element: <ResourceNotFound />,
        },
    ];
    return routes;
};
