import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useLayout } from '../../../providers/LayoutProvider';

export function PageDialog() {
    const { dialogState, closeAndClearDialog } = useLayout();

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={dialogState.isOpen}
            PaperProps={{
                elevation: 8,
            }}
            sx={{
                '& .MuiDialog-paper': {
                    height: '100%',
                },
            }}
        >
            {dialogState?.dialogInstance?.title && (
                <DialogTitle sx={{ m: 0, p: 2 }}>{dialogState.dialogInstance.title}</DialogTitle>
            )}
            <IconButton
                onClick={closeAndClearDialog}
                tabIndex={-1}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                    zIndex: (theme) => theme.zIndex.modal + 50,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ padding: 2, position: 'relative' }}>
                {dialogState?.dialogInstance?.children}
            </DialogContent>
        </Dialog>
    );
}
