import Typography, { TypographyProps } from '@mui/material/Typography';
import { styled, keyframes } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Modify } from '../../utils/types/Modify';

type TextProps = Modify<
    TypographyProps,
    {
        children: ReactNode;
    }
>;

export function ProgressText({ children, ...otherProps }: TextProps) {
    const ellipsis = keyframes`
        0%   { content: ''; }
        25%  { content: '.'; }
        50%  { content: '..'; }
        75%  { content: '...'; }
        100% { content: ''; }
    `;

    const ShowProgressTypography = styled(Typography)(({ theme }) => ({
        ':after': {
            display: 'inline-block',
            position: 'absolute',
            '-webkit-animation': `${ellipsis} steps(1, end) 2s infinite`,
            animation: `${ellipsis} steps(1, end) 2s infinite`,
            content: '""',
        },
    })) as typeof Typography;

    return <ShowProgressTypography {...otherProps}>{children}</ShowProgressTypography>;
}
