import { useEffect, useRef, useState } from 'react';
import { FieldArray, FieldArrayRenderProps, useField, useFormikContext } from 'formik';
import { Button, Divider, Grid, IconButton, styled, Tooltip, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InfoIcon from '@mui/icons-material/Info';

import { TextField } from '../../../../../components/elements/input/TextField';
import { PropertyHierarchy } from '../../../../../models/services/PropertyHierarchy';
import { Service } from '../../../../../models/services/Service';
import { ValueInheritanceTooltip } from '../ValueInheritanceTooltip';

type Props = {
    formProperty: string;
    localFormProperty: string;
    inheritedFormProperty: string;
    isPlatformDefined: boolean;
    isReadOnly: boolean;
    readonlyReason: string;
    propertyHierarchy?: PropertyHierarchy;
    serviceHierarchy?: Service[];
};

export function KeyValuePairPropertyControl({
    formProperty,
    localFormProperty,
    inheritedFormProperty,
    isPlatformDefined,
    isReadOnly,
    readonlyReason,
    propertyHierarchy,
    serviceHierarchy,
}: Props) {
    const [valueInheritance, setValueInheritance] = useState<{
        [key: string]: {
            value: string;
            serviceOverridden: string;
        }[];
    }>({});

    const formikProps = useFormikContext<{ [property: string]: any }>();
    const arrayHelperRef = useRef<FieldArrayRenderProps>();
    const [localValuesField] = useField(localFormProperty);
    const [inheritedValuesField] = useField(inheritedFormProperty);
    const [formValuesField] = useField(formProperty);

    const isPropertyInherited = !propertyHierarchy?.serviceProperty;
    const disabled = isReadOnly || isPropertyInherited;
    const firstRowId = 'property-first-row';

    const addRow = () => {
        arrayHelperRef.current?.unshift({
            key: '',
            value: '',
        });
        setTimeout(() => {
            // focus on the inserted row
            document.getElementById(firstRowId)?.focus();
        }, 1);
    };

    const textBoxColumns = isPlatformDefined || disabled ? 12 : 11;

    useEffect(() => {
        // ordered intentionally for service => productMgmt => orgGov hierarchy
        const inheritedValueGroups = [
            {
                group: 'Product Management',
                groupId: 'mgmt',
                values: propertyHierarchy?.productMgmtProperty?.property?.value,
                inheritedService: serviceHierarchy?.find((service) => service.businessKey === 'mgmt'),
            },
            {
                group: 'Organization Governance',
                groupId: 'gov',
                values: propertyHierarchy?.orgGovProperty?.property?.value,
                inheritedService: serviceHierarchy?.find((service) => service.businessKey === 'gov'),
            },
        ].filter((group) => (group.values as { key: string; value: string }[])?.length > 0);

        const inherited: { key: string; label?: string; value?: string; placeholder: string }[] = [];
        const locals: { key: string; value: string }[] = [];
        const valueInheritanceData: {
            [key: string]: {
                value: string;
                serviceOverridden: string;
            }[];
        } = {};

        // iterate through inherited values and build up arrays for state
        inheritedValueGroups.forEach((group) => {
            (group.values as { key: string; label?: string; value: string }[])?.forEach((value) => {
                valueInheritanceData[value.key] = valueInheritanceData[value.key] || [];
                if (!valueInheritanceData[value.key].find((v) => v.serviceOverridden === group.group) && value.value) {
                    valueInheritanceData[value.key].push({
                        value: value.value,
                        serviceOverridden: group.group,
                    });
                }

                const inheritedValue = inherited.find((row) => row.key === value.key);
                if (!inheritedValue && value.value) {
                    inherited.push({
                        key: value.key,
                        label: value.label,
                        placeholder: value.value,
                    });
                }
            });
        });

        // take the provided value to the form and populate the local and inherited arrays with the provided values
        (formValuesField.value || []).forEach((element: { key: string; value: string }) => {
            const inheritedRow = inherited.find((row) => row.key === element.key);
            if (inheritedRow) {
                inheritedRow.value = element.value;
            } else {
                locals.push(element);
            }
        });

        setValueInheritance(valueInheritanceData);

        formikProps.setFieldValue(localFormProperty, locals, false);
        formikProps.setFieldValue(inheritedFormProperty, inherited, false);

        if (!isPlatformDefined && !isPropertyInherited && locals.length === 0) {
            addRow();
        }
    }, [propertyHierarchy]);

    return (
        <Grid item xs={12} sx={{ marginBottom: 1 }}>
            {!isPlatformDefined && !disabled && (
                <Grid key="add-row" container>
                    <Grid item xs={4} display="flex" alignItems="center">
                        <Button variant="text" color="info" onClick={addRow} disabled={isReadOnly}>
                            <Tooltip title="Add Item" sx={{ paddingY: 0, paddingRight: 1, paddingLeft: 0 }}>
                                <IconButton>
                                    <AddCircleIcon style={{ verticalAlign: 'top' }} />
                                </IconButton>
                            </Tooltip>
                            <Typography>Add Item</Typography>
                        </Button>
                    </Grid>
                </Grid>
            )}
            <FieldArray
                name={localFormProperty}
                render={(arrayHelpers) => {
                    arrayHelperRef.current = arrayHelpers;

                    const fieldValue = formikProps.values ? formikProps.values[localFormProperty] : undefined;
                    if (!fieldValue) {
                        return <div />;
                    }

                    return fieldValue.map((row: { key: string; value: string }, index: number) => (
                        <Grid key={index} container spacing={2} marginTop="1px">
                            <Grid item xs={textBoxColumns} display="flex" alignItems="center">
                                <Grid container>
                                    {!isPlatformDefined && (
                                        <Grid item xs={12} display="flex" alignItems="center">
                                            <TextField
                                                label="Key"
                                                id={!isPlatformDefined && index === 0 ? firstRowId : undefined}
                                                name={`${localFormProperty}.${index}.key`}
                                                required={!disabled}
                                                disabled={disabled}
                                                autoFocus={!isPlatformDefined && index === 0}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} display="flex" alignItems="center" marginTop={1}>
                                        <TextField
                                            label={
                                                isPlatformDefined
                                                    ? localValuesField.value[index].label ||
                                                      localValuesField.value[index].key
                                                    : 'Value'
                                            }
                                            id={isPlatformDefined && index === 0 ? firstRowId : undefined}
                                            name={`${localFormProperty}.${index}.value`}
                                            required={!disabled}
                                            disabled={disabled}
                                            helperText={isReadOnly ? readonlyReason : ''}
                                            autoFocus={isPlatformDefined && index === 0}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!isPlatformDefined && !disabled && (
                                <Grid item xs={1} display="flex" alignItems="center">
                                    <Tooltip title="Remove">
                                        <IconButton onClick={() => arrayHelpers.remove(index)} disabled={isReadOnly}>
                                            <RemoveCircleIcon style={{ verticalAlign: 'top' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            {!isPlatformDefined && <Divider sx={{ marginTop: 2, marginBottom: 0.5, width: '100%' }} />}
                        </Grid>
                    ));
                }}
            />
            <FieldArray
                name={inheritedFormProperty}
                render={(arrayHelpers) => {
                    const fieldValue = formikProps.values ? formikProps.values[inheritedFormProperty] : undefined;
                    if (!fieldValue) {
                        return <div />;
                    }

                    return fieldValue.map((row: { key: string; value: string; placeholder: string }, index: number) => (
                        <Grid key={index} container spacing={2} marginTop="1px">
                            <Grid item xs={textBoxColumns} display="flex" alignItems="center">
                                <Grid container>
                                    {!isPlatformDefined && (
                                        <Grid item xs={12} display="flex" alignItems="center">
                                            <TextField
                                                label="Key"
                                                name={`${inheritedFormProperty}.${index}.key`}
                                                disabled
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        item
                                        xs={12}
                                        display="flex"
                                        alignItems="center"
                                        marginTop={1}
                                        position="relative"
                                    >
                                        <ValueInheritanceTooltip
                                            value={row.value}
                                            valueInheritance={valueInheritance[row.key]}
                                            sx={{ position: 'absolute', marginLeft: '-35px' }}
                                        >
                                            <InfoIcon fontSize="small" />
                                        </ValueInheritanceTooltip>
                                        <TextField
                                            label={
                                                isPlatformDefined
                                                    ? inheritedValuesField.value[index].label ||
                                                      inheritedValuesField.value[index].key
                                                    : 'Value'
                                            }
                                            InputLabelProps={{ shrink: true }}
                                            name={`${inheritedFormProperty}.${index}.value`}
                                            disabled={disabled}
                                            helperText={isReadOnly ? readonlyReason : ''}
                                            placeholder={`${row.placeholder} (Inherited)`}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {!isPlatformDefined && <Divider sx={{ marginTop: 2, marginBottom: 0.5, width: '100%' }} />}
                        </Grid>
                    ));
                }}
            />
        </Grid>
    );
}
