import { apiSlice, RtkTagType } from '../../api/apiSlice';
import { Result } from '../../../models/Result';
import { AddOnWorkload } from '../../../models/services/AddOnWorkload';
import { GetLatestAddOnsForWorkloadTypeRequest } from '../../../models/services/requests/GetLatestAddOnsForWorkloadTypeRequest';

export const workloadsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        workloadsLatestAddonsByType: builder.query<AddOnWorkload[], GetLatestAddOnsForWorkloadTypeRequest>({
            query: (request) => `/organizations/${request.organizationId}/workloads/${request.workloadType}/add-ons`,
            transformResponse: (responseData: Result<AddOnWorkload[]>) => {
                responseData.value.sort((a, b) => {
                    if (a.enabled && !b.enabled) {
                        return -1;
                    }

                    if (!a.enabled && b.enabled) {
                        return 1;
                    }

                    return 0;
                });

                return responseData.value;
            },
            providesTags: (result, error, request) =>
                result ? [{ type: RtkTagType.Workloads, id: request.workloadType }] : [],
        }),
    }),
});

export const { useWorkloadsLatestAddonsByTypeQuery } = workloadsApiSlice;
