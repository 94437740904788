import { ReactNode, useEffect, useState } from 'react';
import {
    Button as MuiButton,
    Container,
    Grid,
    IconButton,
    InputLabel,
    Paper,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField } from '../TextField';
import { ConditionallyWrapWithTooltip } from '../../ConditionallyWrapWithTooltip';
import { useLogging } from '../../../../providers/LoggingProvider';

export type DynamicListWithCallbackItem = {
    label: string;
    value: string;
    helpDialogText?: string;
    disableEdit?: boolean;
    disableClone?: boolean;
    disableRemove?: boolean;
};

export type DynamicListWithCallbackItems = DynamicListWithCallbackItem[];

export type DynamicListWithCallbackProps = {
    name: string;
    label: string;
    newItemLabel?: string;
    helpDialogText?: string;
    noItemsFoundText?: string;
    value?: DynamicListWithCallbackItems;
    simpleAddButton?: boolean;
    preventAddOrRemove?: boolean;
    onChange?: (items: DynamicListWithCallbackItems) => void;
    onEditItem?: (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => void;
    onCloneItem?: (
        label: string,
        value: string,
        newItemFromClonedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => void;
    onRemoveItem?: (label: string, value: string, removeItemPersistedCallback: (value: string) => void) => void;
    onAddItem: (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string, helpDialogText?: string) => void,
    ) => void;
};

export function DynamicListWithCallback({
    name,
    label,
    newItemLabel,
    helpDialogText,
    noItemsFoundText,
    value,
    simpleAddButton,
    preventAddOrRemove,
    onChange,
    onEditItem,
    onCloneItem,
    onRemoveItem,
    onAddItem,
}: DynamicListWithCallbackProps) {
    const { trackTrace } = useLogging();
    const [newItemField, setNewItemField] = useState('');
    const [newItemFieldError, setNewItemFieldError] = useState<string | undefined>();
    const [listItems, setListItems] = useState<DynamicListWithCallbackItems>([]);

    useEffect(() => {
        setListItems(value || []);
    }, [value]);

    useEffect(() => {
        if (onChange) {
            onChange(listItems);
        }
    }, [listItems]);

    const newItemPersistedCallback = (
        labelReturned: string,
        valueReturned: string,
        helpDialogTextReturned?: string,
    ) => {
        const duplicateItem = listItems.find((item) => item.value === valueReturned);

        if (duplicateItem) {
            trackTrace(`Item being added cannot have a duplicate value ${valueReturned}`);
            return;
        }

        const listItemsCopy = Array.from(listItems);
        listItemsCopy.push({ label: labelReturned, value: valueReturned, helpDialogText: helpDialogTextReturned });
        setListItems(listItemsCopy);

        setNewItemField('');
    };

    const handleAdd = () => {
        if (simpleAddButton) {
            onAddItem('', newItemPersistedCallback);
        } else {
            if (!newItemField || newItemField === '') {
                setNewItemFieldError('This cannot be blank');
                return;
            }

            onAddItem(newItemField, newItemPersistedCallback);
        }
    };

    const newFromItemClonedCallback = (
        labelReturned: string,
        valueReturned: string,
        helpDialogTextReturned?: string,
    ) => {
        const itemToAddFromClone = listItems.find((item) => item.value === valueReturned);

        if (itemToAddFromClone) {
            trackTrace(`Item with the provided value already exists ${valueReturned}`);
            return;
        }

        const listItemsCopy = Array.from(listItems);
        listItemsCopy.push({ label: labelReturned, value: valueReturned, helpDialogText: helpDialogTextReturned });
        setListItems(listItemsCopy);
    };

    const handleClone = (itemValue: string) => {
        if (!onCloneItem) {
            trackTrace('Control does not have a onCloneAction defined');
            return;
        }

        const itemToClone = listItems.find((item) => item.value === itemValue);

        if (!itemToClone) {
            trackTrace(`Item to clone not found ${itemValue}`);
            return;
        }

        onCloneItem(itemToClone.label, itemToClone.value, newFromItemClonedCallback);
    };

    const itemEditedCallback = (labelReturned: string, valueReturned: string, helpDialogTextReturned?: string) => {
        const itemToEdit = listItems.find((item) => item.value === valueReturned);

        if (!itemToEdit) {
            trackTrace(`Item to edit not found ${valueReturned}`);
            return;
        }

        if (labelReturned !== itemToEdit.label || helpDialogTextReturned !== itemToEdit.helpDialogText) {
            const listItemsCopy = Array.from(listItems);
            const itemToUpdate = listItemsCopy.find((item) => item.value === itemToEdit.value);

            if (!itemToUpdate) {
                trackTrace(`Item to update not found ${itemToEdit.value}`);
                return;
            }

            itemToUpdate.label = labelReturned;
            itemToUpdate.helpDialogText = helpDialogTextReturned;
            setListItems(listItemsCopy);
        }
    };

    const handleEdit = (itemValue: string) => {
        if (!onEditItem) {
            trackTrace('Control does not have a onEditAction defined');
            return;
        }

        const itemToEdit = listItems.find((item) => item.value === itemValue);

        if (!itemToEdit) {
            trackTrace(`Item to edit not found ${itemValue}`);
            return;
        }

        onEditItem(itemToEdit.label, itemToEdit.value, itemEditedCallback);
    };

    const itemRemovedCallback = (valueReturned: string) => {
        const itemToRemove = listItems.find((item) => item.value === valueReturned);

        if (!itemToRemove) {
            trackTrace(`Item to remove not found ${valueReturned}`);
            return;
        }

        const listItemsCopy = Array.from(listItems);
        const listItemsCopyItemRemoved = listItemsCopy.filter((item) => item.value !== itemToRemove.value);
        setListItems(listItemsCopyItemRemoved);
    };

    const handleRemove = (itemValue: string) => {
        if (!onRemoveItem) {
            trackTrace('Control does not have a onRemoveItem defined');
            return;
        }

        const itemToRemove = listItems.find((item) => item.value === itemValue);

        if (!itemToRemove) {
            trackTrace(`Item to remove not found ${itemValue}`);
            return;
        }

        onRemoveItem(itemToRemove.label, itemToRemove.value, itemRemovedCallback);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewItemFieldError(undefined);
        setNewItemField(event.target.value);
    };

    // if (meta && meta.touched && meta.error) {
    //   configSelect.error = true;
    //   configSelect.helperText = meta.error;
    // }

    return (
        <Container maxWidth={false} disableGutters>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {/* <InputLabel> */}
                    <Typography>
                        {label}
                        {helpDialogText && (
                            <>
                                {' '}
                                <Tooltip title={helpDialogText}>
                                    <HelpIcon style={{ verticalAlign: 'bottom' }} />
                                </Tooltip>
                            </>
                        )}
                        {simpleAddButton && !preventAddOrRemove && (
                            <>
                                {' '}
                                <Tooltip title="Add New">
                                    <IconButton onClick={handleAdd}>
                                        <AddCircleIcon style={{ verticalAlign: 'bottom' }} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Typography>
                    {/* </InputLabel> */}
                </Grid>
                {listItems.length === 0 && noItemsFoundText && (
                    <Grid item xs={12}>
                        <Typography variant="body2" color="primary.main" ml={2} mb={1}>
                            {noItemsFoundText}
                        </Typography>
                    </Grid>
                )}
                {listItems.map((item) => (
                    <Grid item xs={12} key={item.value}>
                        <ConditionallyWrapWithTooltip text={item.helpDialogText}>
                            <Paper>
                                <Toolbar>
                                    <Typography flexGrow={1}>{item.label} </Typography>
                                    {onEditItem && !item.disableEdit && (
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => handleEdit(item.value)}>
                                                <EditIcon style={{ verticalAlign: 'bottom' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {onCloneItem && !item.disableClone && (
                                        <Tooltip title="Clone">
                                            <IconButton onClick={() => handleClone(item.value)}>
                                                <ContentCopyIcon style={{ verticalAlign: 'bottom' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {onRemoveItem && !item.disableRemove && (
                                        <Tooltip title="Remove">
                                            <IconButton onClick={() => handleRemove(item.value)}>
                                                <ClearIcon style={{ verticalAlign: 'bottom' }} />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </Toolbar>
                            </Paper>
                        </ConditionallyWrapWithTooltip>
                    </Grid>
                ))}
                {!simpleAddButton && (
                    <>
                        <Grid item xs={12} sm={10}>
                            <TextField
                                name="newItem"
                                label={newItemLabel}
                                value={newItemField}
                                onChange={handleChange}
                                helperText={newItemFieldError}
                                error={!!newItemFieldError}
                                disableFormik
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <MuiButton fullWidth variant="outlined" sx={{ height: '56px' }} onClick={handleAdd}>
                                Add
                            </MuiButton>
                        </Grid>
                    </>
                )}
            </Grid>
        </Container>
    );
}
