import {
    FormHelperText,
    InputLabel,
    ToggleButton as MuiToggleButton,
    ToggleButtonGroup,
    ToggleButtonGroupProps,
    Tooltip,
    Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import HelpIcon from '@mui/icons-material/Help';
import { Modify } from '../../../../utils/types/Modify';

export type SelectProps = Modify<
    ToggleButtonGroupProps,
    {
        name: string;
        label: string;
        helpDialogText?: string;
        options: { label: string; value: string | boolean }[];
        disabled?: boolean;
    }
>;

export function ToggleButton({ name, label, helpDialogText, options, disabled, ...otherProps }: SelectProps) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (event: any, value: string) => {
        setFieldValue(name, value);
    };

    // if (meta && meta.touched && meta.error) {
    //   configSelect.error = true;
    //   configSelect.helperText = meta.error;
    // }

    return (
        <>
            <InputLabel>
                <Typography mb={1}>
                    {label}
                    {helpDialogText && (
                        <>
                            {' '}
                            <Tooltip disableFocusListener title={helpDialogText}>
                                <HelpIcon style={{ verticalAlign: 'bottom' }} />
                            </Tooltip>
                        </>
                    )}
                </Typography>
            </InputLabel>
            <ToggleButtonGroup
                value={field.value}
                fullWidth
                color="primary"
                exclusive
                onChange={handleChange}
                disabled={disabled}
            >
                {options.map((option) => (
                    <MuiToggleButton key={option.value.toString()} value={option.value} disabled={disabled}>
                        {option.label}
                    </MuiToggleButton>
                ))}
            </ToggleButtonGroup>
            <FormHelperText error={!!meta.error}>{meta.error}</FormHelperText>
        </>
    );
}
