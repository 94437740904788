import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SSCard, SSCardProps } from './SSCard';

type Props = {
    title?: string;
    subTitle?: string;
    cards: SSCardProps[];
    matchHeight?: boolean;
    commonHeight?: number | string;
    centerVertically?: boolean;
    centerHorizontally?: boolean;
};

export default function CardSelection({
    title,
    subTitle,
    cards,
    matchHeight,
    commonHeight,
    centerVertically,
    centerHorizontally,
}: Props) {
    let xs = 12;
    let md = 6;
    let lg = 4;
    let xl = 3;
    switch (cards.length) {
        case 1:
            xs = 12;
            md = 12;
            lg = 12;
            xl = 12;
            break;
        case 2:
            xs = 12;
            md = 6;
            lg = 6;
            xl = 6;
            break;
        case 3:
            xs = 12;
            md = 6;
            lg = 4;
            xl = 4;
            break;
        default:
            break;
    }

    return (
        <Grid
            container
            spacing={2}
            sx={{
                margin: 0,
                ...(centerHorizontally && { justifyContent: 'center' }),
                ...(centerVertically && { alignItems: 'center' }),
            }}
        >
            {title && (
                <Grid xs={12}>
                    <Typography variant="h3" align="center" gutterBottom={!subTitle}>
                        {title}
                    </Typography>
                </Grid>
            )}
            {subTitle && (
                <Grid xs={12}>
                    <Typography variant="h5" align="center" gutterBottom>
                        {subTitle}
                    </Typography>
                </Grid>
            )}
            {cards.map((cardProps, index) => (
                <Grid key={index} xs={xs} md={md} lg={lg} xl={xl} style={matchHeight ? { display: 'flex' } : undefined}>
                    <SSCard
                        {...cardProps}
                        cardStyle={{
                            ...(matchHeight
                                ? {
                                      display: 'flex',
                                      flexDirection: 'column',
                                      flexGrow: 1,
                                  }
                                : {}),
                            height: commonHeight,
                        }}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
