import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
    Box,
    Card,
    CardActionArea,
    CardActionAreaProps,
    CardContent,
    CardHeader,
    CardMedia,
    styled,
} from '@mui/material';
import { ConditionallyWrapWithTooltip } from './ConditionallyWrapWithTooltip';

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
    paddingBottom: 0,
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2),
    ':last-child': { padding: theme.spacing(2) },
}));

type ConditionalCardActionAreaProps = {
    children: JSX.Element | (JSX.Element | undefined | string)[];
    onClick?: () => void;
    to?: string;
} & CardActionAreaProps;
function ConditionalCardActionArea({ children, onClick, to, ...args }: ConditionalCardActionAreaProps): JSX.Element {
    if (onClick || to) {
        if (to) {
            return (
                <CardActionArea
                    onClick={onClick}
                    component={Link}
                    to={to}
                    {...args}
                    sx={{
                        font: 'inherit',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'start',
                        '&:hover > .MuiCardActionArea-focusHighlight': {
                            opacity: 0.03,
                        },
                        ...args.sx,
                    }}
                >
                    {children}
                </CardActionArea>
            );
        }
        return (
            <CardActionArea
                onClick={onClick}
                {...args}
                sx={{
                    font: 'inherit',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    '&:hover > .MuiCardActionArea-focusHighlight': {
                        opacity: 0.03,
                    },
                    ...args.sx,
                }}
            >
                {children}
            </CardActionArea>
        );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
}

export type SSCardProps = {
    imageSrc?: string;
    imageHeight?: string;
    title?: string;
    subTitle?: string;
    tooltip?: string;
    action?: ReactNode;
    children?: ReactNode;
    onClick?: () => void;
    to?: string;
    cardStyle?: React.CSSProperties;
    centerHorizontally?: boolean;
    centerVertically?: boolean;
};

export function SSCard({
    imageSrc,
    imageHeight,
    title,
    subTitle,
    tooltip,
    action,
    children,
    onClick,
    to,
    cardStyle,
    centerHorizontally,
    centerVertically,
}: SSCardProps) {
    return (
        <ConditionallyWrapWithTooltip text={tooltip}>
            <Card
                style={cardStyle}
                sx={{
                    ...(centerHorizontally && { textAlign: 'center' }),
                }}
            >
                <ConditionalCardActionArea onClick={onClick} to={to}>
                    <Box
                        sx={{
                            height: '100%',
                            ...(centerVertically && {
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                            }),
                        }}
                    >
                        {imageSrc && (
                            <CardMedia sx={{ height: imageHeight || '100%', marginBottom: 2 }} image={imageSrc} />
                        )}
                        {(title || subTitle || action) && (
                            <StyledCardHeader title={title} subheader={subTitle} action={action} />
                        )}
                        <StyledCardContent sx={!children ? { ':last-child': { paddingTop: 0 } } : {}}>
                            {children}
                        </StyledCardContent>
                    </Box>
                </ConditionalCardActionArea>
            </Card>
        </ConditionallyWrapWithTooltip>
    );
}
