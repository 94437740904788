import { Box, CircularProgress, LinearProgress, styled, Tooltip, Typography, useTheme } from '@mui/material';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import AddLinkIcon from '@mui/icons-material/AddLink';
import CachedIcon from '@mui/icons-material/Cached';
import { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import ReactFlow, {
    addEdge,
    Background,
    useNodesState,
    useEdgesState,
    MarkerType,
    Connection,
    Edge,
    Node,
    Controls,
    ControlButton,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useSnackbar } from 'notistack';

import LinkEdge from './LinkEdge';
import FloatingConnectionLine from '../../../../components/elements/react-flow/FloatingConnectionLine';

import { Link } from '../../../../models/links/Link';
import {
    invalidateServiceInCache,
    useServicesByOrgIdQuery,
} from '../../../../features/services/services/servicesApiSlice';
import { useEntityContext } from '../../../../providers/EntityContextProvider';
import { Port } from '../../../../models/ports/Port';
import { useLayout } from '../../../../providers/LayoutProvider';
import { Service } from '../../../../models/services/Service';

import { useLogging } from '../../../../providers/LoggingProvider';
import LinkedNode from './LinkedNode';
import CurrentNode from './CurrentNode';
import { ConfirmRemoveDialog } from '../../../../components/ConfirmRemoveDialog';
import {
    useLinkMarkForRemovalMutation,
    useLinkNewMutation,
    useLinkReloadResourceCacheMutation,
    useLinksListByRequestingServiceIdQuery,
} from '../../../../features/links/linksApiSlice';
import { Workload } from '../../../../models/services/Workload';
import { ssBlue } from '../../../../utils/theme';

const nodeTypes = {
    linkedNode: LinkedNode,
    currentNode: CurrentNode,
};

const edgeTypes = {
    floating: LinkEdge,
};

type ConnectedPort = {
    link: Link;
    port: Port;
};

const ResizeHandle = styled(PanelResizeHandle)(({ theme }) => ({
    width: '1px',
    height: '100%',
    top: 0,
    right: 0,
    cursor: 'col-resize',
    zIndex: 100,
    background: 'rgba(255, 255, 255, 0.12)',
    '&:hover': {
        width: '3px',
        background: theme.palette.primary.main,
    },
}));

export function DesignServicePorts() {
    const [availablePorts, setAvailablePorts] = useState<Port[] | undefined>(undefined);
    const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
    const [linkToRemove, setLinkToRemove] = useState<Link>();
    const theme = useTheme();

    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const { enqueueSnackbar } = useSnackbar();
    const { trackTrace } = useLogging();
    const { isPlatformManagerMode } = useLayout();
    const { entityContext } = useEntityContext();

    const [reloadLinkCache, { isLoading: reloadCacheIsProcessing }] = useLinkReloadResourceCacheMutation();
    const [markLinkForRemoval, { isLoading: markRemovalIsProcessing }] = useLinkMarkForRemovalMutation();
    const [newLink, { isLoading: newLinkIsProcessing }] = useLinkNewMutation();

    const {
        data: organizationServiceData,
        isLoading: isLoadingOrgServices,
        isFetching: isFetchingOrgServices,
    } = useServicesByOrgIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const {
        data: linkData,
        isLoading: isLoadingLinks,
        isFetching: isFetchingLinks,
        refetch: refetchLinks,
    } = useLinksListByRequestingServiceIdQuery({
        organizationId: entityContext.organizationSlug!,
        productId: entityContext.productSlug!,
        serviceId: entityContext.serviceSlug!,
    });

    const isLoading = isLoadingOrgServices || isLoadingLinks;
    const isFetching =
        isFetchingOrgServices ||
        isFetchingLinks ||
        reloadCacheIsProcessing ||
        markRemovalIsProcessing ||
        newLinkIsProcessing;

    const handleRefreshResourceCache = async (linkId: string) => {
        if (reloadCacheIsProcessing) {
            return;
        }

        await reloadLinkCache({
            organizationId: entityContext.organizationSlug!,
            linkId,
        }).unwrap();
        enqueueSnackbar('Resource Refresh Requested');
    };

    const markForRemoval = async () => {
        if (markRemovalIsProcessing) {
            return;
        }

        if (linkToRemove) {
            await markLinkForRemoval({
                organizationId: entityContext.organizationSlug!,
                linkId: linkToRemove.id,
            }).unwrap();
            invalidateServiceInCache(entityContext.serviceSlug!);
            setLinkToRemove(undefined);
            enqueueSnackbar('Link Marked For Removal');
        }
    };

    const handleAddNewLink = async (requestedPortName: string, requestingPortResourceId: string) => {
        if (newLinkIsProcessing) {
            return;
        }

        trackTrace('Creating Link for selected ports..');

        const [selectedServiceId, selectedWorkloadId, resourceId, portName] = (requestedPortName as string).split('.');

        const requestingService = organizationServiceData?.find((svc) => svc.id === entityContext.serviceSlug!);
        const requestedService = organizationServiceData?.find((s) => s.id === selectedServiceId);
        const requestedWorkload = requestedService?.workloads.find((w) => w.id === selectedWorkloadId);
        const selectedPort = requestedWorkload?.ports.find((p) => p.resourceId === resourceId && p.type === portName);

        if (requestingService && requestedService && requestedWorkload && selectedPort) {
            await newLink({
                organizationId: entityContext.organizationSlug!,
                requestingServiceId: entityContext.serviceSlug!,
                requestingWorkloadId: requestingService.workloads[0].id,
                requestingResourceId: requestingPortResourceId,
                requestingPortName: selectedPort.type,
                requestedServiceId: requestedService.id,
                requestedWorkloadId: requestedWorkload.id,
                requestedResourceId: selectedPort.resourceId,
                requestedPortName: selectedPort.type,
                type: selectedPort.linkType,
            }).unwrap();

            enqueueSnackbar('Link saved');
            // invalidate the service for rtk to refetch
            invalidateServiceInCache(entityContext.serviceSlug!);
        }
    };

    const createNodesAndEdges = (
        service: Service,
        connectedRequestingLinks: ConnectedPort[],
        connectedRequestedLinks: ConnectedPort[],
        allOrgServices: Service[],
    ) => {
        const newNodes: Node[] = [];
        const newEdges: Edge[] = [];
        const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

        newNodes.push({
            id: 'current',
            data: { label: service.name, type: service.workloads[0].friendlyName },
            position: center,
            type: 'currentNode',
        });

        // Determine the count of non-dead links. "Dead links" are where the service was deleted but the Link is not.
        // This should only happen if something went wrong or if the service data was manually deleted from the database
        const validConnectedRequestingLinks = connectedRequestingLinks.filter((l) =>
            allOrgServices.some((s) => s.id === l.link.requestedServiceId),
        );
        const validConnectedRequestedLinks = connectedRequestedLinks.filter((l) =>
            allOrgServices.some((s) => s.id === l.link.requestingServiceId),
        );

        validConnectedRequestingLinks.forEach((connectedPort, index) => {
            const { link, port } = connectedPort;
            const degrees =
                index * (360 / (validConnectedRequestingLinks.length + validConnectedRequestedLinks.length));
            const radians = degrees * (Math.PI / 180);
            const x = 350 * Math.cos(radians) + center.x;
            const y = 350 * Math.sin(radians) + center.y;

            const requestedService = allOrgServices.find((s) => s.id === link.requestedServiceId);
            if (!requestedService) {
                console.warn(`Service with ID: ${link.requestedServiceId} cannot be found! Skipping render of link.`);
                return;
            }

            newNodes.push({
                id: link.id,
                data: {
                    label: requestedService.name || link.requestedServiceId,
                    type: requestedService.workloads[0].friendlyName,
                    productId: requestedService.productId,
                    serviceId: requestedService.id,
                },
                position: { x, y },
                type: 'linkedNode',
            });

            newEdges.push({
                id: `edge-${link.id}`,
                source: 'current',
                target: link.id,
                type: 'floating',
                style: {
                    strokeWidth: 12,
                    strokeDasharray: link.isMarkedForRemoval ? 40 : undefined,
                    stroke: link.isMarkedForRemoval ? theme.palette.error.dark : ssBlue[500],
                },
                data: {
                    linkType: port.friendlyName,
                    link,
                    isPlatformManagerMode,
                    onReloadCache: (linkId: string) => handleRefreshResourceCache(linkId),
                    onRemoveLink: (theLink: Link) => {
                        setRemoveDialogIsOpen(true);
                        setLinkToRemove(theLink);
                    },
                },
            });
        });

        validConnectedRequestedLinks.forEach((connectedPort, index) => {
            const { link, port } = connectedPort;
            const degrees =
                (index + connectedRequestingLinks.length) *
                (360 / (validConnectedRequestingLinks.length + validConnectedRequestedLinks.length));
            const radians = degrees * (Math.PI / 180);
            const x = 350 * Math.cos(radians) + center.x;
            const y = 350 * Math.sin(radians) + center.y;

            const requestingService = allOrgServices.find((s) => s.id === link.requestingServiceId);
            if (!requestingService) {
                console.warn(`Service with ID: ${link.requestingServiceId} cannot be found! Skipping render of link.`);
                return;
            }

            newNodes.push({
                id: link.id,
                data: {
                    label: requestingService.name || link.requestingServiceId,
                    type: requestingService.workloads[0].friendlyName,
                    productId: requestingService.productId,
                    serviceId: requestingService.id,
                },
                position: { x, y },
                type: 'linkedNode',
            });

            newEdges.push({
                id: `edge-${link.id}`,
                source: link.id,
                target: 'current',
                type: 'floating',
                style: {
                    strokeWidth: 12,
                    strokeDasharray: link.isMarkedForRemoval ? 40 : undefined,
                    stroke: link.isMarkedForRemoval ? theme.palette.error.dark : ssBlue[500],
                },
                data: {
                    linkType: port.friendlyName,
                    link,
                    isPlatformManagerMode,
                    hideActions: true,
                },
            });
        });

        return { newNodes, newEdges };
    };

    const onConnect = useCallback(
        (params: Connection) =>
            setEdges((eds) => addEdge({ ...params, type: 'floating', markerEnd: { type: MarkerType.Arrow } }, eds)),
        [setEdges],
    );

    const filterServicePorts = () => {
        const connectedRequesting: ConnectedPort[] = [];
        const connectedRequested: ConnectedPort[] = [];
        const available: Port[] = [];

        const service = organizationServiceData?.find((svc) => svc.id === entityContext.serviceSlug!);
        if (!service || !linkData) {
            return;
        }

        const portFilter = (workload: Workload, port: Port) => {
            // TODO: Remove once live for users
            if (!isPlatformManagerMode && port.friendlyName === 'Job Deployment') {
                return false;
            }

            // TODO: Remove post port mapping working
            if (
                workload.type === 'DataSourceNodeWorkload' &&
                (port.friendlyName === 'Container Image Repository' || port.friendlyName === 'Eventing')
            ) {
                return false;
            }

            // TODO: Hide Deployment link for data workloads for users but allow for platform managers for removal purposes
            if (
                !isPlatformManagerMode &&
                workload.type === 'DataSourceNodeWorkload' &&
                port.friendlyName === 'Deployment'
            ) {
                return false;
            }

            if (workload.type === 'AppFrontendReactKubernetesWorkload' && port.friendlyName === 'Eventing') {
                return false;
            }

            return true;
        };

        service.workloads.forEach((workload) => {
            workload.ports
                .filter((p) => !p.isTarget)
                .filter((p) => portFilter(workload, p))
                .forEach((port) => {
                    const linkedRequestingPort = linkData.find(
                        (link) => link.requestingServiceId === service.id && link.requestingPort === port.type,
                    );

                    if (linkedRequestingPort) {
                        connectedRequesting.push({ link: linkedRequestingPort, port });
                    } else if (!port.isTarget) {
                        available.push(port);
                    }
                });

            workload.ports
                .filter((p) => p.isTarget)
                .filter((p) => portFilter(workload, p))
                .forEach((port) => {
                    linkData
                        .filter((link) => link.requestedServiceId === service.id && link.requestedPort === port.type)
                        .forEach((link) => {
                            connectedRequested.push({ link, port });
                        });
                });
        });

        setAvailablePorts(available);
        const { newNodes, newEdges } = createNodesAndEdges(
            service,
            connectedRequesting,
            connectedRequested,
            organizationServiceData!,
        );
        setNodes(newNodes);
        setEdges(newEdges);
    };

    // Manually invalidate the service in the cache when the link page loads this solves AB#623 which is a result of resource ID
    // being changed when refresh resource cache has been triggered but the async processing has not completed before RTK refreshes the cache
    useEffect(() => {
        invalidateServiceInCache(entityContext.serviceSlug!);
    }, [entityContext.serviceSlug]);

    useLayoutEffect(() => {
        filterServicePorts();
    }, [organizationServiceData, linkData, isPlatformManagerMode]);

    const availableOrgPortOptions = (selectedPortType: string) => {
        const options: { label: string; value: string }[] = [];
        organizationServiceData?.forEach((service) => {
            service.workloads.forEach((workload) => {
                workload.ports.forEach((port) => {
                    if (port.type === selectedPortType && port.isTarget) {
                        options.push({
                            label: `${service.productBusinessKey} - ${service.name}`,
                            value: `${service.id}.${workload.id}.${port.resourceId}.${port.type}`,
                        });
                    }
                });
            });
        });

        return options;
    };

    return (
        <>
            <PanelGroup direction="horizontal">
                <Panel defaultSize={15} minSize={10}>
                    <Box width="100%" height="100%" paddingRight={2}>
                        <Typography variant="h5" marginBottom={1}>
                            Available Links
                        </Typography>
                        <SimpleTreeView defaultExpandedItems={availablePorts?.map((p) => p.type)} disableSelection>
                            {availablePorts &&
                                availablePorts.map((port: Port) => (
                                    <TreeItem
                                        key={port.type}
                                        itemId={port.type}
                                        label={
                                            // eslint-disable-next-line react/jsx-wrap-multilines
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    p: 0.5,
                                                    pr: 0,
                                                }}
                                            >
                                                <Box
                                                    component={SettingsInputComponentIcon}
                                                    color="inherit"
                                                    sx={{ mr: 1 }}
                                                />
                                                <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1 }}>
                                                    {port.friendlyName}
                                                </Typography>
                                            </Box>
                                        }
                                    >
                                        {availableOrgPortOptions(port.type).map((option) => (
                                            <TreeItem
                                                itemId={option.value}
                                                sx={{
                                                    borderLeft: '1px solid rgba(255, 255, 255, 0.12)',
                                                }}
                                                label={
                                                    // eslint-disable-next-line react/jsx-wrap-multilines
                                                    <Tooltip
                                                        title={`Create ${port.friendlyName} Link to ${option.label}`}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                p: 0.5,
                                                                pr: 0,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="body2"
                                                                sx={{ fontWeight: 'inherit', flexGrow: 1 }}
                                                            >
                                                                {option.label}
                                                            </Typography>
                                                            <Box
                                                                component={AddLinkIcon}
                                                                color="inherit"
                                                                sx={{ ml: 1 }}
                                                            />
                                                        </Box>
                                                    </Tooltip>
                                                }
                                                onClick={() => handleAddNewLink(option.value, port.resourceId)}
                                            />
                                        ))}
                                    </TreeItem>
                                ))}
                        </SimpleTreeView>
                    </Box>
                </Panel>
                <ResizeHandle />
                <Panel>
                    <div style={{ height: '100%', display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress
                                sx={{
                                    backgroundColor: 'transparent',
                                    visibility: !isFetching || isLoading ? 'hidden' : undefined,
                                }}
                            />
                        </Box>
                        {isLoading && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}
                        {!isLoading && (
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                onNodesChange={onNodesChange}
                                onEdgesChange={onEdgesChange}
                                onConnect={onConnect}
                                fitView
                                nodeTypes={nodeTypes}
                                edgeTypes={edgeTypes}
                                connectionLineComponent={FloatingConnectionLine}
                                proOptions={{ hideAttribution: true }}
                            >
                                <Background />
                                <Controls showInteractive={false}>
                                    <ControlButton onClick={() => refetchLinks()}>
                                        <Tooltip title="Refresh Link Data">
                                            <CachedIcon style={{ color: theme.palette.background.default }} />
                                        </Tooltip>
                                    </ControlButton>
                                </Controls>
                            </ReactFlow>
                        )}
                    </div>
                </Panel>
            </PanelGroup>
            <ConfirmRemoveDialog
                resourceName={linkToRemove?.concreteType || ''}
                isOpen={removeDialogIsOpen}
                closeAlert={() => {
                    setRemoveDialogIsOpen(false);
                    setLinkToRemove(undefined);
                }}
                onConfirmRemoval={markForRemoval}
            />
        </>
    );
}
