import { Typography } from '@mui/material';

export function ErrorComponent({ error }: any) {
    // @TODO: Figure out this type
    console.error(error);
    return (
        <Typography variant="h6">
            An Error Occurred:
            {error.errorCode}
        </Typography>
    );
}
