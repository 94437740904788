import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Container,
    Paper,
    Stack,
    Switch,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useBackendService } from '../../../../providers/BackendServiceProvider';
import { GetProductByIdResponse } from '../../../../models/products/GetProductByIdResponse';
import { FlowUtils } from '../../../../utils/FlowUtils';
import { useLogging } from '../../../../providers/LoggingProvider';
import { useServicesByProductIdQuery } from '../../../../features/services/services/servicesApiSlice';
import { useEntityContext } from '../../../../providers/EntityContextProvider';
import { useProductByIdQuery } from '../../../../features/products/productsApiSlice';

export function DesignProductRoles() {
    const { trackTrace } = useLogging();
    const { organizationId, productId } = useParams();
    const [productRoleSwitchState, setProductRoleSwitchState] = useState<{ [key: string]: boolean }>({});
    const { enableProductRole } = useBackendService();
    const { enqueueSnackbar } = useSnackbar();

    const { entityContext } = useEntityContext();
    const { data: productData } = useProductByIdQuery({
        organizationId: entityContext.organizationSlug!,
        id: entityContext.productSlug!,
    });
    const { data: serviceData, refetch: refetchServices } = useServicesByProductIdQuery({
        organizationId: entityContext.organizationSlug!,
        productId: entityContext.productSlug!,
    });

    const fetchServiceData = async () => {
        const circuitBreakerLimit = 10;
        let circuitBreakerCount = 0;

        while (
            circuitBreakerCount < circuitBreakerLimit &&
            serviceData &&
            serviceData.length &&
            serviceData[0].deploymentState.isDeployEnabled &&
            !serviceData[0].deploymentState.lastDeployed
        ) {
            // eslint-disable-next-line no-await-in-loop
            await FlowUtils.delay(30000);

            // eslint-disable-next-line no-await-in-loop
            await refetchServices();

            circuitBreakerCount++;
        }
    };

    useEffect(() => {
        if (productData) {
            const roleSwitchStates: { [key: string]: boolean } = {};
            productData.productRoles.forEach((possibleRole) => {
                roleSwitchStates[possibleRole.id] = productData.productRolesEnabled.indexOf(possibleRole.id) >= 0;
            });
            setProductRoleSwitchState(roleSwitchStates);

            fetchServiceData();
        }
    }, [productData]);

    const handleProductRoleSwichChange = async (event: React.ChangeEvent<HTMLInputElement>, productRoleId: string) => {
        if (!event.target.checked) {
            trackTrace('Do nothing if unchecked');
            return;
        }

        const newState = { ...productRoleSwitchState };
        newState[productRoleId] = true;
        setProductRoleSwitchState(newState);

        const response = await enableProductRole({
            organizationId: organizationId!,
            productId: productData!.id,
            productRoleId,
        });
        enqueueSnackbar('Product Role Enabled');
    };

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                {productData && (
                    <Accordion defaultExpanded elevation={0} sx={{ marginTop: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h5">Product Roles</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack spacing={2}>
                                {productData?.productRoles.map((role) => (
                                    <Paper key={role.id}>
                                        <Toolbar>
                                            <Tooltip
                                                title={
                                                    productRoleSwitchState[role.id]
                                                        ? 'Enabled Product Roles cannot be disabled'
                                                        : 'Enable for this Product'
                                                }
                                            >
                                                <span>
                                                    <Switch
                                                        checked={productRoleSwitchState[role.id]}
                                                        disabled={productRoleSwitchState[role.id]}
                                                        onChange={(event) =>
                                                            handleProductRoleSwichChange(event, role.id)
                                                        }
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        sx={{ marginRight: 2 }}
                                                    />
                                                </span>
                                            </Tooltip>
                                            <Typography
                                                flexGrow={1}
                                                color={productRoleSwitchState[role.id] ? 'initial' : 'GrayText'}
                                            >
                                                {role.name}
                                                {productRoleSwitchState[role.id] &&
                                                    serviceData &&
                                                    serviceData.length &&
                                                    serviceData[0].deploymentState.isDeployEnabled &&
                                                    !serviceData[0].deploymentState.lastDeployed &&
                                                    ' (Provisioning...)'}
                                            </Typography>
                                        </Toolbar>
                                    </Paper>
                                ))}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                )}
            </Stack>
        </Container>
    );
}
