import { useEffect } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Box, Container, useMediaQuery, useTheme } from '@mui/material';
import { Outlet, redirect, useLocation } from 'react-router-dom';
import { useAccount } from '@azure/msal-react';
import { RightContextDrawer } from '../RightContextDrawer';
import { ContentTopActionBar } from '../ContentTopActionBar';
import { useLayout } from '../../../providers/LayoutProvider';
import { TabbedContainer } from '../TabbedContainer';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useNotification } from '../../../providers/NotificationProvider';
import { UserAccount } from '../../../utils/types/User';
import { appConfig } from '../../../config';
import { useEntityContext } from '../../../providers/EntityContextProvider';

const rightContextDrawerWidth: number = 640;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -rightContextDrawerWidth,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    }),
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
}));

export function MainContentArea() {
    const { entityContext } = useEntityContext();
    const { getOrgAdminConsentStatus } = useBackendService();
    const {
        rightContextDrawerState,
        closeAndClearRightContextDrawer,
        customTabs,
        dialogState,
        setAndOpenDialog,
        closeAndClearDialog,
    } = useLayout();
    const { addNotification } = useNotification();
    const location = useLocation();
    const account = useAccount();
    const theme = useTheme();
    const forceTemporaryRightContext = useMediaQuery(theme.breakpoints.down('xl'));

    // prevent right drawer from persisting when changing / navigating between tabs
    useEffect(() => {
        closeAndClearRightContextDrawer();
    }, [location]);

    useEffect(() => {
        const fetchAdminConsentStatus = async (orgId: string) => {
            const consentStatus = await getOrgAdminConsentStatus(
                {
                    organizationId: orgId,
                },
                true,
            );

            if (!consentStatus.adminConsentGranted) {
                addNotification({
                    id: 'admin-consent',
                    title: 'Azure AD Admin Consent Required',
                    text:
                        'The Scaled Sense application has not been approved for all users in your organization. ' +
                        'If you are an Azure AD administrator for your organization, click here to complete the admin consent process',
                    action: () => {
                        const { protocol, host } = window.location;

                        redirect(
                            `https://login.microsoftonline.com/${
                                (account?.idTokenClaims as UserAccount).tid
                            }/v2.0/adminconsent?client_id=${appConfig.auth.scaledSenseAppClientId}&scope=${
                                appConfig.auth.scaledSenseAppClientId
                            }/.default&redirect_uri=${protocol}//${host}/callbacks/scaled-sense-app/admin-consent-callback&state=${orgId}`,
                        );
                    },
                    actionText: 'Complete Admin Consent',
                });
            }
        };

        if (entityContext.organizationSlug) {
            fetchAdminConsentStatus(entityContext.organizationSlug);
        }
    }, []);

    return (
        <>
            <Main
                open={
                    (rightContextDrawerState.isOpen && !rightContextDrawerState.showBackdrop) ||
                    forceTemporaryRightContext
                }
            >
                <Toolbar />
                <TabbedContainer />
                {customTabs !== undefined && customTabs}
                <ContentTopActionBar />
                <Box
                    position="relative"
                    sx={{
                        my: 3,
                        width: '100%',
                        flexGrow: 1,
                        display: 'flex',
                    }}
                >
                    <Container disableGutters maxWidth={false}>
                        <Outlet />
                    </Container>
                </Box>
            </Main>
            <RightContextDrawer drawerWidth={rightContextDrawerWidth} forceTemporary={forceTemporaryRightContext} />
        </>
    );
}
