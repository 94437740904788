import { appConfig } from '../config';

const getDomainInfo = (): { domain: string; subDomain: string } => {
    const hostSegments = window.location.host.split('.');
    let subDomain = '';
    let domain = '';
    if (hostSegments.length > 2) {
        subDomain = `${hostSegments[0]}`;
        domain = hostSegments.slice(1).join('.');
    } else if (hostSegments[0].toLowerCase().startsWith('localhost')) {
        subDomain = 'dev';
        domain = 'scaled.ai';
    } else {
        domain = hostSegments.join('.');
    }

    return {
        domain,
        subDomain,
    };
};

const getScaledSenseBackendUrl = (): string => {
    const { domain, subDomain } = getDomainInfo();

    let backendUrl = '';
    if (window.location.host.toLowerCase().startsWith('localhost')) {
        backendUrl = appConfig.server.backendUrl || '';
    } else if (subDomain && subDomain.length > 0) {
        backendUrl = `${window.location.protocol}//${subDomain}.api.${domain}`;
    } else {
        backendUrl = `${window.location.protocol}//api.${domain}`;
    }

    return backendUrl;
};

export { getDomainInfo, getScaledSenseBackendUrl };
