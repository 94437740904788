import { useEffect } from 'react';
import { defaultEntityContextModule, useEntityContext } from '../../providers/EntityContextProvider';

export function DefaultEntity() {
    const { navigateWithCurrentEntityContext } = useEntityContext();

    // Redirect to default module if no route is matched
    useEffect(() => {
        navigateWithCurrentEntityContext(defaultEntityContextModule, true);
    }, []);

    return null;
}
