import { FederatedGroup } from '../../../models/federation/groups/FederatedGroup';
import { FederatedGroupMember } from '../../../models/federation/groups/FederatedGroupMember';
import { AddFederatedPrincipalToGroupRequest } from '../../../models/federation/groups/requests/AddFederatedPrincipalToGroupRequest';
import { FederatedGroupMembersListRequest } from '../../../models/federation/groups/requests/FederatedGroupMembersListRequest';
import { RemoveFederatedMemberFromGroupRequest } from '../../../models/federation/groups/requests/RemoveFederatedMemberFromGroupRequest';
import { Result } from '../../../models/Result';
import { apiSlice, RtkTagType } from '../../api/apiSlice';

export const federatedGroupMembersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        federatedGroupMembersByGroupId: builder.query<FederatedGroup, FederatedGroupMembersListRequest>({
            query: (request) => `organizations/${request.organizationId}/federation/groups/${request.groupId}/members`,
            transformResponse: (responseData: Result<FederatedGroupMember[]>, meta, args) => ({
                groupId: args.groupId,
                members: responseData.value,
            }),
            providesTags: (result) => (result ? [{ type: RtkTagType.FederatedGroupMembers, id: result.groupId }] : []),
        }),
        federatedGroupMembersAddPrincipal: builder.mutation<FederatedGroup, AddFederatedPrincipalToGroupRequest>({
            query: (request) => ({
                url: `organizations/${request.organizationId}/federation/groups/${request.groupId}/members`,
                method: 'PATCH',
                body: request,
            }),
            transformResponse: (responseData: Result<FederatedGroupMember[]>, meta, args) => ({
                groupId: args.groupId,
                members: responseData.value,
            }),
            invalidatesTags: (result) =>
                result ? [{ type: RtkTagType.FederatedGroupMembers, id: result.groupId }] : [],
        }),
        federatedGroupMembersRemoveMember: builder.mutation<FederatedGroup, RemoveFederatedMemberFromGroupRequest>({
            query: (request) => ({
                url: `organizations/${request.organizationId}/federation/groups/${request.groupId}/members/${request.memberId}`,
                method: 'DELETE',
            }),
            transformResponse: (responseData: Result<FederatedGroupMember[]>, meta, args) => ({
                groupId: args.groupId,
                members: responseData.value,
            }),
            invalidatesTags: (result) =>
                result ? [{ type: RtkTagType.FederatedGroupMembers, id: result.groupId }] : [],
        }),
    }),
});

export const { useFederatedGroupMembersByGroupIdQuery } = federatedGroupMembersApiSlice;
export const { useFederatedGroupMembersAddPrincipalMutation, useFederatedGroupMembersRemoveMemberMutation } =
    federatedGroupMembersApiSlice;
