import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export function LoadingComponent() {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.tooltip * 10 }} open>
            <CircularProgress />
        </Backdrop>
    );
}
