import { Formik, Form, FormikValues, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, FormHelperText, Grid, Typography } from '@mui/material';
import {
    PropertyDefinition,
    PropertyControlType,
    PropertyScopeType,
    ControlValidator,
} from '../../../../models/services/PropertyDefinition';
import { Property, PropertyValue } from '../../../../models/services/Property';
import { StringPropertyControl } from './StringPropertyControl';
import { SelectPropertyControl } from './SelectPropertyControl';
import { KeyValuePairPropertyControl } from './KeyValuePairPropertyControl';
import { ListPropertyControl } from './ListPropertyControl';
import { ToggleButton } from '../../../../components/elements/input/ToggleButton';
import { PropertyHierarchy } from '../../../../models/services/PropertyHierarchy';
import { Service } from '../../../../models/services/Service';
import { DrawerFooterAction, useLayout } from '../../../../providers/LayoutProvider';
import {
    useServicePropertyUpdateMutation,
    useServicePropertyUpdateScopeMutation,
} from '../../../../features/services/properties/propertiesApiSlice';
import { useServicesByOrgIdQuery } from '../../../../features/services/services/servicesApiSlice';
import { BlobPropertyControl } from './BlobPropertyControl';

const buildCustomValidator = (
    validators: ControlValidator[] | undefined,
    requiredMessage?: string,
    isNotRequired?: boolean,
) => {
    let validationSchema = isNotRequired ? Yup.string() : Yup.string().required(requiredMessage);
    if (validators?.length) {
        validators.forEach((validator) => {
            validationSchema = validationSchema.matches(new RegExp(validator.validator), validator.validationMessage);
        });
    }

    return validationSchema;
};

const getFormValidation = (propertyControl: PropertyDefinition) => {
    switch (propertyControl.controlMetadata.type) {
        case PropertyControlType.selectProperty:
        case PropertyControlType.stringProperty:
            return Yup.object().shape({
                value: buildCustomValidator(propertyControl.validators, 'A property value is required', true),
                propertyScope: Yup.string().required(),
            });
        case PropertyControlType.listProperty:
            return Yup.object().shape({
                value: Yup.array().of(
                    buildCustomValidator(propertyControl.validators, 'A property value is required').test(
                        'unique',
                        'Items in this list must be unique',
                        (value, context: any) => {
                            const { parent } = context;
                            const [parent1] = context.from;
                            if (!value?.length || !parent?.length) {
                                return true;
                            }

                            // if value is in array more than once, this will resolve as false
                            return (
                                parent.indexOf(value) === parent.lastIndexOf(value) &&
                                // ensure value being validated is not already inherited
                                parent1.value.inheritedValues.findIndex((val: string) => val === value) < 0
                            );
                        },
                    ),
                ),
                propertyScope: Yup.string().required(),
            });
        case PropertyControlType.keyValuePairProperty:
            return Yup.object().shape({
                localKeyValues: Yup.array().of(
                    Yup.object().shape({
                        key: buildCustomValidator(propertyControl.keyValidators, 'A property key is required').test(
                            'unique',
                            'Keys in this list must be unique',
                            (value, context: any) => {
                                const [parent1, parent2] = context.from;
                                if (!value?.length || !parent2?.value?.localKeyValues?.length) {
                                    return true;
                                }

                                // if value is in array more than once, this will resolve as false
                                return (
                                    parent2.value.localKeyValues.findIndex(
                                        (kv: { key: string; value: string }) => kv.key === value,
                                    ) ===
                                        parent2.value.localKeyValues.findLastIndex(
                                            (kv: { key: string; value: string }) => kv.key === value,
                                        ) &&
                                    // ensure key being validated is not already inherited
                                    parent2.value.inheritedKeyValues.findIndex(
                                        (kv: { key: string; value: string }) => kv.key === value,
                                    ) < 0
                                );
                            },
                        ),
                        value: buildCustomValidator(propertyControl.validators, 'A property value is required'),
                    }),
                ),
                inheritedKeyValues: Yup.array().of(
                    Yup.object().shape({
                        key: buildCustomValidator(propertyControl.keyValidators, 'A property key is required'),
                        value: buildCustomValidator(propertyControl.validators, '', true),
                    }),
                ),
                propertyScope: Yup.string().required(),
            });
        case PropertyControlType.platformKeyValuePairProperty:
            return Yup.object().shape({
                localKeyValues: Yup.array().of(
                    Yup.object().shape({
                        value: buildCustomValidator(propertyControl.validators, 'A property value is required'),
                    }),
                ),
                inheritedKeyValues: Yup.array().of(
                    Yup.object().shape({
                        value: buildCustomValidator(propertyControl.validators, '', true),
                    }),
                ),
                propertyScope: Yup.string().required(),
            });
        default:
            return Yup.object();
    }
};

const PROPERTY_SCOPE_OPTIONS = [
    { label: 'Service', value: PropertyScopeType.service },
    { label: 'Production Tier', value: PropertyScopeType.productionTier },
    { label: 'Environment Type', value: PropertyScopeType.environmentType },
    { label: 'Environment Instance', value: PropertyScopeType.environmentInstance },
];

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

type Props = {
    organizationId: string;
    productId: string;
    serviceId: string;
    propertyDefinition: PropertyDefinition;
    propertyHierarchy?: PropertyHierarchy;
    serviceHierarchy?: Service[];
    propertyScopeType: string;
    propertyScopeFilter: string;
    viewOnly: boolean;
    actionCompleteCallback: (propertyControl: PropertyDefinition, value: PropertyValue) => void;
};

export function ManageProperty({
    organizationId,
    productId,
    serviceId,
    propertyDefinition,
    propertyHierarchy,
    serviceHierarchy,
    propertyScopeType,
    propertyScopeFilter,
    viewOnly,
    actionCompleteCallback,
}: Props) {
    const FORM_VALIDATION = getFormValidation(propertyDefinition);

    const INITIAL_FORM_STATE = {
        // the service property is the only one that is editable, inherited properties are not
        value:
            propertyDefinition.controlMetadata.type === PropertyControlType.blobProperty &&
            propertyHierarchy?.serviceProperty?.property?.value
                ? JSON.stringify(JSON.parse(propertyHierarchy!.serviceProperty!.property!.value as string), null, 3)
                : propertyHierarchy?.serviceProperty?.property?.value,
        propertyScope: propertyScopeType,
    };

    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const [updateProperty] = useServicePropertyUpdateMutation();
    const [updatePropertyScope] = useServicePropertyUpdateScopeMutation();
    const { data: allServicesData } = useServicesByOrgIdQuery({ organizationId });
    const { setRightContextDrawerActions } = useLayout();
    const managePropertyFormRef = useRef<FormikProps<FormikValues>>(null);

    const handleSubmit = async (values: any, formik: any) => {
        const propertyScopeFromForm = values.propertyScope;

        let newPropertyValue =
            propertyDefinition.controlMetadata.type === PropertyControlType.blobProperty
                ? JSON.stringify(JSON.parse(values.value))
                : values.value;

        if (values.localKeyValues) {
            // special case for key value pairs where we need to assemble the final value for submission
            newPropertyValue = values.localKeyValues;
            if (values.inheritedKeyValues && values.inheritedKeyValues.length > 0) {
                values.inheritedKeyValues.forEach((inheritedKeyValue: { key: string; value: string }) => {
                    if (inheritedKeyValue.value) {
                        newPropertyValue.push({ key: inheritedKeyValue.key, value: inheritedKeyValue.value });
                    }
                });
            }
        }

        if (propertyScopeFromForm !== propertyScopeType) {
            await updatePropertyScope({
                organizationId,
                serviceId,
                productId,
                propertyBusinessKey: propertyDefinition.controlMetadata.businessKey,
                propertyScopeType: propertyScopeFromForm,
                value: newPropertyValue,
            }).unwrap();

            enqueueSnackbar('Property Scope Updated');
        } else {
            const result = await updateProperty({
                organizationId,
                serviceId,
                productId,
                propertyBusinessKey: propertyDefinition.controlMetadata.businessKey,
                propertyScopeType: propertyScopeFromForm,
                propertyScopeFilter,
                value: newPropertyValue,
            }).unwrap();

            enqueueSnackbar('Property Saved');
        }

        formik.setSubmitting(false);

        actionCompleteCallback(propertyDefinition, values.value);
    };

    // determine the scope that the property is defined for to build the filter for services to see if anything in that scope is enabled
    const thisService = serviceHierarchy?.find((s) => s.id === serviceId);
    let scopeFilter: (s: Service) => boolean;
    if (thisService?.businessKey === 'gov') {
        scopeFilter = (s: Service) => true;
    } else if (thisService?.businessKey === 'mgmt') {
        scopeFilter = (s: Service) => s.productId === productId;
    } else {
        scopeFilter = (s: Service) => s.id === serviceId;
    }

    const isLockedAfterDeployment =
        !!propertyDefinition.lockedAfterDeployment &&
        // this is not right
        allServicesData?.filter(scopeFilter).some((service) => service.deploymentState.isDeployEnabled) === true;
    const isReadOnly =
        (propertyDefinition.writeOnce && propertyDefinition.configured) === true || isLockedAfterDeployment;
    const readonlyReason = isLockedAfterDeployment
        ? 'This property cannot be changed - services in the current scope exist that are enabled for deployment'
        : 'This property has already been set and cannot be changed';

    let editPropertyControl: JSX.Element;
    switch (propertyDefinition.controlMetadata.type) {
        case PropertyControlType.stringProperty:
            editPropertyControl = (
                <StringPropertyControl
                    formProperty="value"
                    propertyControl={propertyDefinition}
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                />
            );
            break;
        case PropertyControlType.selectProperty:
            editPropertyControl = (
                <SelectPropertyControl
                    displayName={propertyDefinition.displayName}
                    formProperty="value"
                    options={propertyDefinition.options}
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                />
            );
            break;
        case PropertyControlType.keyValuePairProperty:
            editPropertyControl = (
                <KeyValuePairPropertyControl
                    formProperty="value"
                    localFormProperty="localKeyValues"
                    inheritedFormProperty="inheritedKeyValues"
                    isPlatformDefined={false}
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                    serviceHierarchy={serviceHierarchy}
                />
            );
            break;
        case PropertyControlType.platformKeyValuePairProperty:
            editPropertyControl = (
                <KeyValuePairPropertyControl
                    formProperty="value"
                    localFormProperty="localKeyValues"
                    inheritedFormProperty="inheritedKeyValues"
                    isPlatformDefined
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                />
            );
            break;
        case PropertyControlType.listProperty:
            editPropertyControl = (
                <ListPropertyControl
                    formProperty="value"
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                />
            );
            break;
        case PropertyControlType.blobProperty:
            editPropertyControl = (
                <BlobPropertyControl
                    formProperty="value"
                    propertyControl={propertyDefinition}
                    isReadOnly={isReadOnly}
                    readonlyReason={readonlyReason}
                    propertyHierarchy={propertyHierarchy}
                />
            );
            break;
        default:
            editPropertyControl = <div />;
    }

    useEffect(() => {
        if (!viewOnly) {
            const actions: DrawerFooterAction[] = [
                {
                    action: () => {
                        managePropertyFormRef.current?.submitForm();
                    },
                    actionDisabled: isReadOnly || managePropertyFormRef.current?.isSubmitting,
                },
            ];

            setRightContextDrawerActions(actions);
        }
    }, []);

    return (
        <Grid container position="relative">
            <Container maxWidth="md">
                <FormikWrapped
                    innerRef={managePropertyFormRef}
                    initialValues={initialFormState}
                    enableReinitialize
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, formik) => handleSubmit(values, formik)}
                >
                    {({ isSubmitting, initialValues, values }) => (
                        <Form autoComplete="off">
                            <Grid container spacing={2}>
                                {!propertyDefinition.hasFixedScope && !viewOnly && (
                                    <Grid item xs={12}>
                                        <ToggleButton
                                            name="propertyScope"
                                            label="Property Scope"
                                            helpDialogText="Use this toggle to change this property's management scope"
                                            options={PROPERTY_SCOPE_OPTIONS}
                                        />
                                        {values.propertyScope !== initialValues.propertyScope && (
                                            <FormHelperText error>
                                                NOTE: Changing the scope for this property will update the value for
                                                this property for all environment instances to the value specified below
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                )}
                                {editPropertyControl}

                                {!isReadOnly && !viewOnly && propertyDefinition.writeOnce && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            This property, once configured, cannot be changed
                                        </Typography>
                                    </Grid>
                                )}
                                {!isReadOnly && !viewOnly && propertyDefinition.lockedAfterDeployment && (
                                    <Grid item xs={12}>
                                        <Typography variant="body1">
                                            This property can only be modified until a Service in the current scope is
                                            enabled for deployment.
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    )}
                </FormikWrapped>
            </Container>
        </Grid>
    );
}
