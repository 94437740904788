import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { useField, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';
import { Modify } from '../../../../utils/types/Modify';

export type CheckboxProps = Modify<
    MuiCheckboxProps,
    {
        name: string;
        label: string;
        legend: string;
    }
>;

export function Checkbox({ name, label, legend, ...otherProps }: CheckboxProps) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setFieldValue(name, checked);
    };

    const configCheckbox: MuiCheckboxProps = {
        ...field,
        ...otherProps,
        onChange: handleChange,
    };

    const configFormControl: FormControlProps = {};
    if (meta && meta.touched && meta.error) {
        configFormControl.error = true;
    }

    return (
        <FormControl {...configFormControl}>
            <FormLabel component="legend">{legend}</FormLabel>
            <FormGroup>
                <FormControlLabel control={<MuiCheckbox {...configCheckbox} />} label={label} />
            </FormGroup>
        </FormControl>
    );
}
