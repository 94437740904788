import { TreeItem, SimpleTreeView, treeItemClasses } from '@mui/x-tree-view';
import { alpha, Box, Button, Container, Stack, styled, SvgIcon, SvgIconProps, Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CachedIcon from '@mui/icons-material/Cached';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import UndoIcon from '@mui/icons-material/Undo';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useSnackbar } from 'notistack';
import { ConditionallyWrapWithTooltip } from '../../../../components/elements/ConditionallyWrapWithTooltip';
import {
    useResetDeploymentStatusMutation,
    useServiceByIdQuery,
    useServiceMarkAllowDestructionMutation,
    useServiceReloadLinkMetadataMutation,
    useServiceReloadResourceCacheMutation,
    useServiceRevertMarkForRemovalMutation,
} from '../../../../features/services/services/servicesApiSlice';
import { useEntityContext } from '../../../../providers/EntityContextProvider';

function MinusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props: SvgIconProps) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

export interface AdminAction {
    text: string;
    icon: JSX.Element;
    onClick: () => void;
    align?: 'left' | 'right';
    disabled?: boolean;
    tooltip?: string;
}

export function DesignServiceAdmin() {
    const { enqueueSnackbar } = useSnackbar();
    const { entityContext } = useEntityContext();
    const [refreshLinkMetadata] = useServiceReloadLinkMetadataMutation();
    const [refreshResourceCache] = useServiceReloadResourceCacheMutation();
    const [markAllowDestruction] = useServiceMarkAllowDestructionMutation();
    const [revertMarkForRemoval] = useServiceRevertMarkForRemovalMutation();
    const [resetDeploymentStatus] = useResetDeploymentStatusMutation();
    const {
        data: service,
        isLoading,
        isFetching,
    } = useServiceByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );

    const handleRefreshResourceCache = async (upgradeWorkloads: boolean) => {
        await refreshResourceCache({
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
            upgradeWorkloads,
        }).unwrap();
        enqueueSnackbar('Resource Refresh Requested');
    };

    const handleRefreshLinkMetadata = async () => {
        await refreshLinkMetadata({
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        }).unwrap();

        enqueueSnackbar('Link Metadata Refreshed');
    };

    const handleMarkToAllowResourceDestruction = async () => {
        await markAllowDestruction({
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        }).unwrap();
        enqueueSnackbar('Service Marked to Allow Resource Destruction');
    };

    const handleRevertMarkForRemoval = async () => {
        await revertMarkForRemoval({
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        }).unwrap();
        enqueueSnackbar('Reverted Mark for Removal for Service');
    };

    const handleResetDeploymentState = async () => {
        await resetDeploymentStatus({
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        }).unwrap();
        enqueueSnackbar('Reset of Deployment State completed');
    };

    const adminActions: AdminAction[] = [
        {
            text: 'Reload Cache',
            icon: <CachedIcon fontSize="small" />,
            onClick: () => handleRefreshResourceCache(false),
            disabled: service?.deploymentState.isMarkedForRemoval,
            tooltip: service?.deploymentState.isMarkedForRemoval
                ? 'Service is Marked for Removal and cannot be updated'
                : undefined,
        },
        {
            text: 'Reload Link Metadata',
            icon: <CachedIcon fontSize="small" />,
            onClick: () => handleRefreshLinkMetadata(),
            disabled: service?.deploymentState.isMarkedForRemoval,
            tooltip: service?.deploymentState.isMarkedForRemoval
                ? 'Service is Marked for Removal and cannot be updated'
                : undefined,
        },
        {
            text: 'Upgrade Version',
            icon: <UpgradeIcon fontSize="small" />,
            onClick: () => handleRefreshResourceCache(true),
            disabled: !service?.hasNewerVersion || service?.deploymentState.isMarkedForRemoval,
            // eslint-disable-next-line no-nested-ternary
            tooltip: service?.deploymentState.isMarkedForRemoval
                ? 'Service is Marked for Removal and cannot be updated'
                : !service?.hasNewerVersion
                ? 'Service is up to date'
                : undefined,
        },
        {
            text: 'Allow Resource Destruction',
            icon: <WarningIcon fontSize="small" />,
            onClick: () => handleMarkToAllowResourceDestruction(),
            disabled: service?.isResourceDestructionAllowed || service?.deploymentState.isMarkedForRemoval,
            // eslint-disable-next-line no-nested-ternary
            tooltip: service?.deploymentState.isMarkedForRemoval
                ? 'Service is Marked for Removal and cannot be updated'
                : service?.isResourceDestructionAllowed
                ? 'Resource destruction is already allowed for next deployment'
                : undefined,
        },
        {
            text: 'Reset Deployment State',
            icon: <RestartAltIcon fontSize="small" />,
            onClick: () => handleResetDeploymentState(),
            disabled: service?.deploymentState.isMarkedForRemoval,
            tooltip: service?.deploymentState.isMarkedForRemoval
                ? 'Service is Marked for Removal and cannot be updated'
                : undefined,
        },
        {
            text: 'Revert Mark for Removal',
            icon: <UndoIcon fontSize="small" />,
            onClick: () => handleRevertMarkForRemoval(),
            disabled:
                !service?.deploymentState.isMarkedForRemoval &&
                !service?.environmentInstances?.some((env) => env.isMarkedForRemoval),
            tooltip:
                !service?.deploymentState.isMarkedForRemoval &&
                !service?.environmentInstances?.some((env) => env.isMarkedForRemoval)
                    ? 'Service is not marked for removal'
                    : undefined,
        },
    ];

    const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.groupTransition}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
        },
    }));

    const treeViewNode = (object: any, parentIndex?: string) => {
        return Object.keys(object).map((key, index) => {
            const isObjectNode = typeof object[key] === 'object';
            const isArrayNode = Array.isArray(object[key]);
            const value = isObjectNode
                ? treeViewNode(object[key], `${parentIndex ? `${parentIndex}-` : ''}${index}`)
                : object[key];
            const id = `${parentIndex ? `${parentIndex}-` : ''}${index}`;
            return isObjectNode ? (
                <StyledTreeItem key={id} itemId={id} label={<Typography fontWeight="bold">{key}</Typography>}>
                    <Typography>{isArrayNode ? '[' : '{'}</Typography>
                    {value}
                    <Typography>{isArrayNode ? ']' : '}'}</Typography>
                </StyledTreeItem>
            ) : (
                <StyledTreeItem
                    key={id}
                    itemId={id}
                    label={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Typography sx={{ whiteSpace: 'nowrap' }}>
                            <Typography component="span" fontWeight="bold">{`${key}: `}</Typography>
                            {value?.toString()}
                        </Typography>
                    }
                />
            );
        });
    };

    return (
        <Container maxWidth="md">
            <Stack spacing={3}>
                {adminActions.map((action) => (
                    <ConditionallyWrapWithTooltip key={action.text} text={action.tooltip}>
                        <div>
                            <Button
                                variant="contained"
                                onClick={action.onClick}
                                disabled={action.disabled}
                                startIcon={action.icon}
                                fullWidth
                            >
                                {action.text}
                            </Button>
                        </div>
                    </ConditionallyWrapWithTooltip>
                ))}
                <Box>
                    <Box overflow="auto">
                        <Typography variant="h6">Service JSON</Typography>
                        <Typography>&#123;</Typography>
                        <SimpleTreeView
                            slots={{
                                expandIcon: PlusSquare,
                                collapseIcon: MinusSquare,
                            }}
                            disableSelection
                        >
                            {treeViewNode(service)}
                        </SimpleTreeView>
                        <Typography>&#125;</Typography>
                    </Box>
                </Box>
            </Stack>
        </Container>
    );
}
