import { useParams } from 'react-router-dom';
import { DynamicListWithCallback } from '../../../../components/elements/input/DynamicListWithCallback';
import { useLayout } from '../../../../providers/LayoutProvider';
import { ManageEnvironment } from './ManageEnvironment';
import { ServiceEnvironmentInstance } from '../../../../models/services/ServiceEnvironmentInstance';
import { useLogging } from '../../../../providers/LoggingProvider';
import { useServiceEnvironmentInstanceNewMutation } from '../../../../features/services/service-environment-instances/serviceEnvironmentInstancesApiSlice';

type Props = {
    serviceId: string;
    serviceDeployEnabled: boolean;
    serviceIsShared: boolean;
    serviceHasBeenDeployed: boolean;
    environmentInstances: ServiceEnvironmentInstance[];
    environmentTypeId: string;
    environmentTypeName: string;
    serviceHasChangesToDeploy: boolean;
};

export function EnvironmentsControl({
    serviceId,
    serviceDeployEnabled,
    serviceHasBeenDeployed,
    serviceIsShared,
    environmentInstances,
    environmentTypeId,
    environmentTypeName,
    serviceHasChangesToDeploy,
}: Props) {
    const { setAndOpenRightContextDrawer, closeAndClearRightContextDrawer } = useLayout();
    const { organizationId, productId } = useParams();
    const { trackTrace } = useLogging();
    const [newServiceEnvironmentInstance] = useServiceEnvironmentInstanceNewMutation();

    const envInstList = environmentInstances.map((inst) => ({
        label: `${inst.businessKey.toUpperCase()}${
            !inst.isDeployEnabled && !inst.isMarkedForRemoval ? ' (Deployment Disabled)' : ''
        }${inst.isMarkedForRemoval ? ' (Pending Removal)' : ''}`,
        value: inst.id,
    }));

    let environmentEditedCallbackForListUi: undefined | ((label: string, value: string) => void);

    const handleOnEditedEnvironmentPersisted = async (label: string, value: string) => {
        closeAndClearRightContextDrawer();

        if (!environmentEditedCallbackForListUi) {
            trackTrace('environmentEditedCallbackForListUi was not defined after the item was persisted');
            return;
        }

        environmentEditedCallbackForListUi(label, value);
        environmentEditedCallbackForListUi = undefined;
    };

    const handleOnEditEnvironment = (
        label: string,
        value: string,
        itemEditedCallback: (label: string, value: string) => void,
    ) => {
        environmentEditedCallbackForListUi = itemEditedCallback;

        const envInst = environmentInstances.find((inst) => inst.id === value);

        setAndOpenRightContextDrawer(
            <ManageEnvironment
                organizationId={organizationId!}
                productId={productId!}
                serviceId={serviceId}
                serviceDeployEnabled={serviceDeployEnabled}
                serviceHasBeenDeployed={serviceHasBeenDeployed}
                existingEnvironmentInstance={envInst!}
                actionCompleteCallback={handleOnEditedEnvironmentPersisted}
                serviceIsShared={serviceIsShared}
                serviceHasChangesToDeploy={serviceHasChangesToDeploy}
            />,
            'Manage Environment Instance',
        );
    };

    const handleOnAddEnvironment = async (
        newItemLabel: string,
        newItemPersistedCallback: (label: string, value: string) => void,
    ) => {
        const res = await newServiceEnvironmentInstance({
            organizationId: organizationId!,
            productId: productId!,
            serviceId,
            environmentTypeId,
        }).unwrap();

        newItemPersistedCallback(res.businessKey.toUpperCase(), res.id);
    };

    return (
        <DynamicListWithCallback
            simpleAddButton
            preventAddOrRemove={serviceIsShared}
            name="environmentInstances"
            label={`${environmentTypeName} Environment Instances`}
            newItemLabel="New Instance Name"
            noItemsFoundText="No instances"
            value={envInstList}
            onAddItem={handleOnAddEnvironment}
            onEditItem={handleOnEditEnvironment}
        />
    );
}
