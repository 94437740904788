import { OptionsObject, useSnackbar, VariantType, WithSnackbarProps } from 'notistack';

/**
 * Utility to access notistack snackbar outside of ProviderContext
 */
let useSnackbarRef: WithSnackbarProps;
export function SnackbarUtilsConfigurator() {
    useSnackbarRef = useSnackbar();
    return null;
}

export default {
    success(msg: string) {
        this.toast(msg, 'success');
    },
    warning(msg: string) {
        this.toast(msg, 'warning');
    },
    info(msg: string) {
        this.toast(msg, 'info');
    },
    error(msg: string, options?: OptionsObject) {
        this.toast(msg, 'error', options);
    },
    toast(msg: string, variant: VariantType = 'default', options: OptionsObject = {}) {
        useSnackbarRef.enqueueSnackbar(msg, { ...options, variant });
    },
};
