import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { appConfig } from '../config';
import { getDomainInfo } from '../utils/BrowserEnvUtils';

type ProviderProps = {
    children?: ReactNode;
};

type LoggingContextType = {
    trackTrace: (message: string) => void;
    trackException: (error: string) => void;
    clearAuthenticatedUserContext: () => void;
    setAuthenticatedUserContext: (userId: string) => void;
};

const reactPlugin = new ReactPlugin();

const LoggingContext = createContext<LoggingContextType | undefined>(undefined);

export function LoggingProvider({ children }: ProviderProps) {
    const { subDomain } = getDomainInfo();
    let appInsights: ApplicationInsights;

    const loadAppInsights = false; // subDomain === 'dev';

    if (loadAppInsights) {
        appInsights = new ApplicationInsights({
            config: {
                connectionString: appConfig.applicationInsights.connectionString,
                extensions: [reactPlugin],
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                enableDebug: true,
            },
        });

        try {
            appInsights.loadAppInsights();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.log(err);
        }
    }

    const trackTrace = (message: string) => {
        if (appInsights) {
            appInsights.trackTrace({ message });
        } else {
            // eslint-disable-next-line no-console
            console.log(message);
        }
    };

    const trackException = (error: string) => {
        if (appInsights) {
            appInsights.trackException({ exception: new Error(error) });
        } else {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    };

    const clearAuthenticatedUserContext = () => {
        if (appInsights) {
            appInsights.clearAuthenticatedUserContext();
        }
    };

    const setAuthenticatedUserContext = (userId: string) => {
        if (appInsights) {
            appInsights.setAuthenticatedUserContext(userId, userId, true);
        }
    };

    const memoValue = useMemo(
        () => ({
            trackTrace,
            trackException,
            clearAuthenticatedUserContext,
            setAuthenticatedUserContext,
        }),
        [],
    );
    return (
        <LoggingContext.Provider value={memoValue}>
            {loadAppInsights && (
                <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
            )}
            {!loadAppInsights && children}
        </LoggingContext.Provider>
    );
}

export const useLogging = (): LoggingContextType => {
    const context = useContext(LoggingContext);
    if (!context) {
        throw new Error('useLogging must be used within a Logging Provider');
    }
    return context;
};
