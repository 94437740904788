import { BaseEntity } from 'models/BaseEntity';
import { ServiceDeploymentState } from 'models/services/ServiceDeploymentState';
import { ServiceEnvironmentInstance } from './ServiceEnvironmentInstance';
import { Workload } from './Workload';
import { PropertyDefinition } from './PropertyDefinition';

export interface AzSecurityGroup {
    friendlyName: string;
    objectId: string;
}

export interface Service extends BaseEntity {
    organizationId: string;
    organizationBusinessKey: string;
    productId: string;
    productBusinessKey: string;
    parentServiceId?: string;
    name: string;
    businessKey: string;
    shortName: string;
    description?: string;
    isShared?: boolean;
    isLimitedToProdNonProd?: boolean;
    deploymentState: ServiceDeploymentState;
    workloads: Workload[];
    primaryWorkloadVersion?: string;
    workloadVersions: string[];
    hasNewerVersion: boolean;
    environmentInstances: ServiceEnvironmentInstance[];
    propertyDefinitions: PropertyDefinition[];
    azSecurityGroups: { [key: string]: AzSecurityGroup };
    isResourceDestructionAllowed?: boolean;
    lastRefresh?: Date;
    latestRefreshHashDiff?: string;
    hasChangesToDeploy: boolean;
    hasPendingDeployment: boolean;
}

export const getServiceState = (
    service: Service,
): {
    text: string;
    description: string;
    color: 'warning' | 'success' | 'default' | 'primary' | 'info' | 'error' | 'secondary';
    isTransient?: boolean;
} => {
    if (service.deploymentState.isMarkedForRemoval) {
        return {
            text: 'Removing',
            description: 'This service is in the process of being deprovisioned.',
            color: 'error',
            isTransient: true,
        };
    }

    if (!service.deploymentState.isDeployEnabled) {
        return {
            text: 'Not Deployed',
            description: 'This service has not been deployed yet.',
            color: 'default',
        };
    }

    if (service.hasPendingDeployment && !service.deploymentState.lastDeployed) {
        return {
            text: 'Provisioning Service',
            description: 'This service is in the process of initial provisioning.',
            color: 'primary',
            isTransient: true,
        };
    }

    if (service.hasPendingDeployment) {
        return {
            text: 'Deployment In Progress',
            description: 'This service has a deployment in progress.',
            color: 'primary',
            isTransient: true,
        };
    }

    if (service.hasChangesToDeploy) {
        return {
            text: 'Deployment Available',
            description: 'There are changes to this service available for deployment.',
            color: 'primary',
        };
    }

    if (service.hasNewerVersion) {
        return {
            text: 'Update Available',
            description: 'There is an update available for this service.',
            color: 'warning',
        };
    }

    if (service.deploymentState.isDeployEnabled) {
        return {
            text: 'Up-To-Date',
            description: 'This service is provisioned and up-to-date.',
            color: 'default',
        };
    }

    return {
        text: 'Not Deployed',
        description: 'This service has not been deployed yet.',
        color: 'default',
    };
};

export const defaultServiceListSort = (a: Service, b: Service) => {
    if ((a.businessKey === 'mgmt' || a.businessKey === 'gov') && b.businessKey !== 'mgmt' && b.businessKey !== 'gov') {
        return -1;
    }

    if (a.businessKey !== 'mgmt' && a.businessKey !== 'gov' && (b.businessKey === 'mgmt' || b.businessKey === 'gov')) {
        return 1;
    }

    return a.name.localeCompare(b.name);
};
