import { Container } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CardSelection from '../../../components/elements/CardSelection';
import { CreateServiceRequest } from '../../../models/services/requests/CreateServiceRequest';
import { CreateServiceAddOnsRequest } from '../../../models/services/requests/CreateServiceAddOnRequest';
import { WorkloadType } from '../../../models/services/WorkloadTypes';

type Props = {
    onGoToNextStep: () => void;
    setCreateServiceRequest: Dispatch<SetStateAction<Partial<CreateServiceRequest>>>;
    createServiceRequest: Partial<CreateServiceRequest>;
    setCreateAddOnsRequest: Dispatch<SetStateAction<Partial<CreateServiceAddOnsRequest>>>;
};

export default function NewServiceStepType({
    onGoToNextStep,
    setCreateServiceRequest,
    createServiceRequest,
    setCreateAddOnsRequest,
}: Props) {
    const handleChooseWorkloadType = (workloadType: WorkloadType) => {
        if (createServiceRequest.workloadType !== workloadType) {
            setCreateAddOnsRequest((prev) => {
                return { ...prev, addOnWorkloads: [], isConfigured: false };
            });
        }

        setCreateServiceRequest((prev) => {
            return { ...prev, workloadType };
        });

        onGoToNextStep();
    };

    return (
        <Container>
            <CardSelection
                centerHorizontally
                commonHeight="20rem"
                title="What kind of service would you like to create?"
                cards={[
                    {
                        centerHorizontally: true,
                        centerVertically: true,
                        title: 'User interface',
                        children: 'Frontend service that the end user interacts with',
                        onClick: () => handleChooseWorkloadType(WorkloadType.AppFrontendReactKubernetesWorkload),
                    },
                    {
                        centerHorizontally: true,
                        centerVertically: true,
                        title: 'Backend',
                        children: 'API or event-driven service used to execute business logic',
                        onClick: () => handleChooseWorkloadType(WorkloadType.AppBackendNodeKubernetesWorkload),
                    },
                    {
                        centerHorizontally: true,
                        centerVertically: true,
                        title: 'Data Source Node',
                        children: 'Integrate and make the most of the data in your internal and external systems',
                        onClick: () => handleChooseWorkloadType(WorkloadType.DataSourceNodeWorkload),
                    },
                ]}
            />
        </Container>
    );
}
