import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import { useCallback, CSSProperties, useRef, useState, useLayoutEffect } from 'react';
import { useStore, getBezierPath } from 'reactflow';
import { NavLink } from 'react-router-dom';

import { getEdgeParams } from '../../../../components/elements/react-flow/ReactFlowUtils';
import { getLinkState, Link } from '../../../../models/links/Link';
import { ssBlue, transientBackground } from '../../../../utils/theme';
import { KeyValueDisplay } from '../../../../components/elements/KeyValuePairDisplay';
import { EntityContextModule, useEntityContext } from '../../../../providers/EntityContextProvider';

type Props = {
    id: string;
    source: string;
    target: string;
    markerEnd?: string;
    style?: CSSProperties;
    data?: {
        linkType: string;
        link: Link;
        isPlatformManagerMode: boolean;
        hideActions?: boolean;
        onReloadCache: (linkId: string) => void;
        onRemoveLink: (link: Link) => void;
    };
};

function LinkEdge({ id, source, target, markerEnd, style, data }: Props) {
    const { entityContext, pathToChangeEntityContext } = useEntityContext();
    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
    const [reverse, setReverse] = useState<boolean>(false);
    const [vertical, setVertical] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [detailsVisible, setDetailsVisible] = useState<boolean>(false);
    const pathRef = useRef<SVGPathElement>(null);

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

    const [edgePath] = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });

    const [edgePathReversed] = getBezierPath({
        sourceX: tx,
        sourceY: ty,
        sourcePosition: targetPos,
        targetPosition: sourcePos,
        targetX: sx,
        targetY: sy,
    });

    useLayoutEffect(() => {
        if (sx > tx) {
            // left
            setReverse(true);
        } else {
            // right
            setReverse(false);
        }

        const diffX = Math.abs(sx - tx);
        const diffY = Math.abs(sy - ty);
        if (diffY > diffX) {
            setVertical(true);
        } else {
            setVertical(false);
        }
    }, [sx, tx, sy, ty]);

    return (
        <>
            <path
                id={id}
                ref={pathRef}
                className="react-flow__edge-path"
                d={edgePath}
                markerEnd={markerEnd}
                style={{ ...style, stroke: detailsVisible || tooltipOpen ? ssBlue[300] : ssBlue[500] }}
            />
            <circle r="4" fill={ssBlue[600]} className="circle">
                <animateMotion dur="8s" repeatCount="indefinite" path={edgePath} />
            </circle>
            <circle r="4" fill={ssBlue[600]} className="circle">
                <animateMotion dur="8s" repeatCount="indefinite" path={edgePath} begin="-2s" />
            </circle>
            <circle r="4" fill={ssBlue[600]} className="circle">
                <animateMotion dur="8s" repeatCount="indefinite" path={edgePath} begin="-4s" />
            </circle>
            <circle r="4" fill={ssBlue[600]} className="circle">
                <animateMotion dur="8s" repeatCount="indefinite" path={edgePath} begin="-6s" />
            </circle>
            <text>
                <textPath
                    startOffset={(pathRef.current?.getTotalLength() || 0) / 2}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    href={`#${id}-2`}
                    style={{ fill: '#E3E3E3', fontSize: '0.5rem', fontWeight: 900 }}
                >
                    {data?.link.nounPlural}
                </textPath>
            </text>
            {data?.link && (
                <Tooltip
                    arrow
                    leaveDelay={250}
                    placement={vertical ? 'right' : 'bottom'}
                    open={tooltipOpen}
                    onOpen={() => setTooltipOpen(true)}
                    onClose={() => setTooltipOpen(false)}
                    title={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Box p={1}>
                            <Typography variant="h6" fontWeight={600}>
                                {data?.link.friendlyName} Link
                            </Typography>
                            <Typography variant="caption" gutterBottom>
                                {data?.link.description}
                            </Typography>
                            <KeyValueDisplay
                                inheritColor
                                labelNumColWidth={5}
                                forceCenter
                                item={{
                                    label: 'Status',
                                    value: (
                                        <NavLink
                                            to={pathToChangeEntityContext(
                                                {
                                                    organizationSlug: entityContext.organizationSlug,
                                                    productSlug: entityContext.productSlug,
                                                    serviceSlug: data.link.requestingServiceId,
                                                },
                                                EntityContextModule.Observe,
                                            )}
                                        >
                                            <Tooltip title={getLinkState(data.link).description}>
                                                <Chip
                                                    label={getLinkState(data.link).text}
                                                    color={getLinkState(data.link).color}
                                                    size="small"
                                                    sx={{
                                                        ...(getLinkState(data.link).isTransient &&
                                                            transientBackground[getLinkState(data.link).color]),
                                                    }}
                                                />
                                            </Tooltip>
                                        </NavLink>
                                    ),
                                }}
                            />
                            <Box display="flex">
                                <Box flexGrow={1}>
                                    {!data?.link.isMarkedForRemoval && target !== 'current' && (
                                        <Tooltip title="Remove Link">
                                            <Button
                                                size="small"
                                                color="inherit"
                                                variant="contained"
                                                onClick={() => data?.onRemoveLink(data.link)}
                                                startIcon={<ClearIcon />}
                                            >
                                                Remove
                                            </Button>
                                        </Tooltip>
                                    )}
                                </Box>
                                {!data?.link.isMarkedForRemoval &&
                                    data?.isPlatformManagerMode &&
                                    target !== 'current' && (
                                        <Tooltip title="Reload Cache">
                                            <Button
                                                size="small"
                                                color="inherit"
                                                variant="contained"
                                                onClick={() => data?.onReloadCache(data.link.id)}
                                                style={{ minWidth: '0' }}
                                            >
                                                <CachedIcon />
                                            </Button>
                                        </Tooltip>
                                    )}
                            </Box>
                            {data?.link.isMarkedForRemoval && (
                                <Typography fontStyle="italic" variant="caption">
                                    Pending Removal
                                </Typography>
                            )}
                        </Box>
                    }
                >
                    <path
                        id={`${id}-2`}
                        className="react-flow__edge-path"
                        d={reverse ? edgePathReversed : edgePath}
                        style={{
                            stroke: 'transparent',
                            strokeWidth: 12,
                        }}
                        onMouseOver={() => setDetailsVisible(true)}
                        onMouseOut={() => setDetailsVisible(false)}
                    />
                </Tooltip>
            )}
        </>
    );
}

export default LinkEdge;
