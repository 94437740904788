import TextField, { TextFieldProps } from '@mui/material/TextField';
import { FormikErrors, useField, useFormikContext } from 'formik';
import { Autocomplete, useTheme } from '@mui/material';
import { SyntheticEvent, useState } from 'react';
import { delay } from 'lodash';

export type ComboBoxProps = {
    name: string;
    label: string;
    options: { label: string; value: string }[];
    disableFormik?: boolean;
    delayOpenForTransition?: boolean;
} & TextFieldProps;

export function ComboBox({ name, options, disableFormik, delayOpenForTransition, ...args }: ComboBoxProps) {
    const theme = useTheme();
    const [open, setOpen] = useState<boolean>(false);
    const [renderedOnce, setRenderedOnce] = useState<boolean>(false);
    let config: TextFieldProps = {
        ...args,
        variant: 'outlined',
        fullWidth: true,
    };

    let formikSetFieldValue: (field: string, value: any) => Promise<void | FormikErrors<unknown>>;
    let formikHandleBlur: (e: FocusEvent) => void;

    if (!disableFormik) {
        const formikContext = useFormikContext();
        formikSetFieldValue = formikContext.setFieldValue;
        formikHandleBlur = formikContext.handleBlur;
        const [field, meta] = useField(name);

        config = {
            ...config,
            ...field,
        };

        if (meta && meta.error && (meta.touched || meta.value)) {
            config.error = true;
            config.helperText = meta.error;
        }
    }

    const handleChange = async (e: SyntheticEvent<Element, Event>, value: { label: string; value: string } | null) => {
        if (!disableFormik) {
            await formikSetFieldValue(name, value?.value);
        }
    };

    const handleBlur = async (e: SyntheticEvent<Element, Event>) => {
        if (!disableFormik) {
            formikHandleBlur(e as unknown as FocusEvent);
        }
    };

    return (
        <Autocomplete
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            openOnFocus
            autoSelect
            autoComplete={false}
            onOpen={() => {
                if (delayOpenForTransition && !renderedOnce) {
                    setRenderedOnce(true);
                    // give buffer if transition lags so that dropdown aligns below textfield
                    delay(() => setOpen(true), theme.transitions.duration.standard + 50);
                } else {
                    setOpen(true);
                }
            }}
            onClose={() => setOpen(false)}
            open={open}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            defaultValue={options.find((option) => option.value === config.value)}
            renderInput={(params) => <TextField {...params} {...config} />}
        />
    );
}
