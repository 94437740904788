import { Formik, Form, FormikProps, FormikValues } from 'formik';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Button, Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../../../components/elements/input/TextField';
import { useBackendService } from '../../../../providers/BackendServiceProvider';
import { ConfirmRemoveDialog } from '../../../../components/ConfirmRemoveDialog';
import { ServiceEnvironmentInstance } from '../../../../models/services/ServiceEnvironmentInstance';
import { useLogging } from '../../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../../providers/LayoutProvider';
import { ConditionallyWrapWithTooltip } from '../../../../components/elements/ConditionallyWrapWithTooltip';
import {
    useServiceEnvironmentInstanceEnableMutation,
    useServiceEnvironmentInstanceMarkForRemovalMutation,
    useServiceEnvironmentInstanceMarkAllowResourceDestructionMutation,
    useServiceEnvironmentInstanceRequestDeployMutation,
    useServiceEnvironmentInstanceUpdateDescriptionMutation,
    useServiceEnvironmentInstanceTriggerLinkDeployMutation,
} from '../../../../features/services/service-environment-instances/serviceEnvironmentInstancesApiSlice';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const FORM_VALIDATION = Yup.object().shape({
    description: Yup.string().required('Description is required'),
});

type Props = {
    organizationId: string;
    productId: string;
    serviceId: string;
    serviceDeployEnabled: boolean;
    serviceHasBeenDeployed: boolean;
    serviceIsShared: boolean;
    existingEnvironmentInstance: ServiceEnvironmentInstance;
    serviceHasChangesToDeploy: boolean;
    actionCompleteCallback: (label: string, value: string) => void;
};

export function ManageEnvironment({
    organizationId,
    productId,
    serviceId,
    serviceDeployEnabled,
    existingEnvironmentInstance,
    serviceHasBeenDeployed,
    serviceIsShared,
    serviceHasChangesToDeploy,
    actionCompleteCallback,
}: Props) {
    const INITIAL_FORM_STATE = {
        description: existingEnvironmentInstance.description,
    };

    const [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const [updateServiceEnvironmentInstanceDescription] = useServiceEnvironmentInstanceUpdateDescriptionMutation();
    const [enableServiceEnvironmentInstance] = useServiceEnvironmentInstanceEnableMutation();
    const [requestDeployServiceEnvironmentInstance] = useServiceEnvironmentInstanceRequestDeployMutation();
    const [markServiceEnvironmentInstanceForRemoval] = useServiceEnvironmentInstanceMarkForRemovalMutation();
    const [markServiceEnvironmentInstanceToAllowResourceDestruction] =
        useServiceEnvironmentInstanceMarkAllowResourceDestructionMutation();
    const [triggerLinkDeployForEnvironment] = useServiceEnvironmentInstanceTriggerLinkDeployMutation();
    const { trackTrace } = useLogging();
    const { isPlatformManagerMode, setRightContextDrawerActions } = useLayout();
    const manageEnvInstFormRef = useRef<FormikProps<FormikValues>>(null);
    const handleSubmit = async (values: any, formik: any) => {
        let resId: string;

        // Update existing environment instance
        trackTrace(`Updating environment instance  '${existingEnvironmentInstance.id}'..`);
        const result = await updateServiceEnvironmentInstanceDescription({
            organizationId,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
            description: values.description,
        }).unwrap();
        formik.setSubmitting(false);

        enqueueSnackbar('Environment Instance Saved');
        trackTrace(`Success: '${result.id}'`);
        actionCompleteCallback(
            `${result.businessKey.toUpperCase()}${!result.isDeployEnabled && ' (Deployment Disabled)'}`,
            result.id,
        );
    };

    const enableDeploy = async () => {
        await enableServiceEnvironmentInstance({
            organizationId: organizationId!,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
        });

        enqueueSnackbar('Environment Instance Enabled');
        actionCompleteCallback(
            `${existingEnvironmentInstance.businessKey.toUpperCase()}${
                !existingEnvironmentInstance.isDeployEnabled && ' (Deployment Disabled)'
            }`,
            existingEnvironmentInstance.id,
        );
    };

    const requestDeploy = async () => {
        await requestDeployServiceEnvironmentInstance({
            organizationId: organizationId!,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
        });

        enqueueSnackbar('Environment Instance Deploy Requested');
        actionCompleteCallback(
            `${existingEnvironmentInstance.businessKey.toUpperCase()}${
                !existingEnvironmentInstance.isDeployEnabled && ' (Deployment Disabled)'
            }`,
            existingEnvironmentInstance.id,
        );
    };

    const removeEnvInst = async () => {
        await markServiceEnvironmentInstanceForRemoval({
            organizationId: organizationId!,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
        });

        enqueueSnackbar('Environment Instance Marked For Removal');
        actionCompleteCallback(
            `${existingEnvironmentInstance.businessKey.toUpperCase()}${
                !existingEnvironmentInstance.isDeployEnabled && ' (Pending Removal)'
            }`,
            existingEnvironmentInstance.id,
        );
    };

    const markToAllowResourceDestruction = async () => {
        await markServiceEnvironmentInstanceToAllowResourceDestruction({
            organizationId: organizationId!,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
        });

        enqueueSnackbar('Environment Instance Marked To Allow Resource Destruction');
        actionCompleteCallback(
            `${existingEnvironmentInstance.businessKey.toUpperCase()}${!existingEnvironmentInstance.isDeployEnabled}`,
            existingEnvironmentInstance.id,
        );
    };

    const triggerLinkDeploy = async () => {
        await triggerLinkDeployForEnvironment({
            organizationId: organizationId!,
            productId,
            serviceId,
            environmentInstanceId: existingEnvironmentInstance.id,
        });

        enqueueSnackbar('Link Deploy Triggered');
        actionCompleteCallback(
            `${existingEnvironmentInstance.businessKey.toUpperCase()}${!existingEnvironmentInstance.isDeployEnabled}`,
            existingEnvironmentInstance.id,
        );
    };

    useEffect(() => {
        const actions: DrawerFooterAction[] = [
            {
                action: () => {
                    manageEnvInstFormRef.current?.submitForm();
                },
                actionDisabled: manageEnvInstFormRef.current?.isSubmitting,
            },
        ];
        if (!serviceIsShared) {
            actions.push({
                action: () => setRemoveDialogIsOpen(true),
                variant: 'outlined' as const,
                color: 'error' as const,
                actionText: 'Remove Environment Instance',
            });
        }

        if (existingEnvironmentInstance.isDeployEnabled && isPlatformManagerMode) {
            actions.push({
                action: markToAllowResourceDestruction,
                variant: 'outlined' as const,
                color: 'warning' as const,
                actionText: 'Allow Resources To Be Destroyed',
            });
        }

        if (existingEnvironmentInstance.isDeployEnabled && isPlatformManagerMode) {
            actions.push({
                action: triggerLinkDeploy,
                variant: 'outlined' as const,
                color: 'warning' as const,
                actionText: 'Trigger Links To Be Deployed',
            });
        }

        setRightContextDrawerActions(actions);
    }, []);

    return (
        <Grid container>
            <ConfirmRemoveDialog
                resourceName={existingEnvironmentInstance.businessKey.toUpperCase()}
                isOpen={removeDialogIsOpen}
                closeAlert={() => setRemoveDialogIsOpen(false)}
                onConfirmRemoval={removeEnvInst}
            />
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={manageEnvInstFormRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <strong>Name:</strong>{' '}
                                            {existingEnvironmentInstance.businessKey.toUpperCase()}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <strong>Status:</strong>{' '}
                                            {existingEnvironmentInstance.isDeployEnabled
                                                ? 'Deployment Enabled'
                                                : 'Deployment Disabled'}
                                            {!existingEnvironmentInstance.isMarkedForRemoval &&
                                                !existingEnvironmentInstance.isDeployEnabled &&
                                                serviceHasBeenDeployed &&
                                                ' ' && (
                                                    <Button
                                                        onClick={enableDeploy}
                                                        fullWidth={false}
                                                        variant="contained"
                                                        sx={{ marginLeft: 1 }}
                                                    >
                                                        <Typography variant="body1">Enable</Typography>
                                                    </Button>
                                                )}
                                            {!existingEnvironmentInstance.isMarkedForRemoval &&
                                                existingEnvironmentInstance.isDeployEnabled &&
                                                serviceHasBeenDeployed && (
                                                    <ConditionallyWrapWithTooltip
                                                        text={
                                                            serviceHasChangesToDeploy
                                                                ? 'Service has changes to deploy'
                                                                : undefined
                                                        }
                                                    >
                                                        <span>
                                                            <Button
                                                                onClick={requestDeploy}
                                                                fullWidth={false}
                                                                sx={{ marginLeft: 1 }}
                                                                disabled={serviceHasChangesToDeploy}
                                                            >
                                                                <Typography variant="body1">Re-Deploy</Typography>
                                                            </Button>
                                                        </span>
                                                    </ConditionallyWrapWithTooltip>
                                                )}
                                            {!existingEnvironmentInstance.isDeployEnabled &&
                                                !serviceDeployEnabled &&
                                                ' ' &&
                                                ' (deployments must first be enabled for the Organization)'}
                                            {!existingEnvironmentInstance.isDeployEnabled &&
                                                serviceDeployEnabled &&
                                                !serviceHasBeenDeployed &&
                                                ' ' &&
                                                ' (Service is still provisioning, try again shortly)'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>
                                            <strong>Last deployed:</strong>{' '}
                                            {!!existingEnvironmentInstance.lastDeployed &&
                                                ' ' &&
                                                new Date(existingEnvironmentInstance.lastDeployed).toLocaleString()}
                                            {existingEnvironmentInstance.isDeployEnabled &&
                                                !existingEnvironmentInstance.lastDeployed &&
                                                ' Deploying...'}
                                            {!existingEnvironmentInstance.isDeployEnabled &&
                                                !existingEnvironmentInstance.lastDeployed &&
                                                ' Never'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            name="description"
                                            label="Description"
                                            helpDialogText="A business description to assist users in understanding the purpose and differentiate this environment"
                                            multiline
                                            rows="3"
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
