import { InputAdornment, Tooltip } from '@mui/material';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import HelpIcon from '@mui/icons-material/Help';
import { useField } from 'formik';
import { Modify } from '../../../../utils/types/Modify';

export type TextFieldProps = Modify<
    MuiTextFieldProps,
    {
        name: string;
        helpDialogText?: string;
        disableFormik?: boolean;
    }
>;

export function TextField({ name, helpDialogText, disableFormik = false, ...otherProps }: TextFieldProps) {
    let configTextField: MuiTextFieldProps = {
        ...otherProps,
        fullWidth: true,
        variant: 'outlined',
        InputProps: {
            endAdornment: helpDialogText && (
                <InputAdornment position="end">
                    <Tooltip disableFocusListener title={helpDialogText} sx={{ cursor: 'context-menu' }}>
                        <HelpIcon style={{ verticalAlign: 'bottom' }} />
                    </Tooltip>
                </InputAdornment>
            ),
        },
    };

    if (!disableFormik) {
        const [field, meta] = useField(name);

        configTextField = {
            ...configTextField,
            ...field,
        };

        if (meta && meta.error && (meta.touched || meta.value)) {
            configTextField.error = true;
            configTextField.helperText = meta.error;
        }
    }

    return <MuiTextField autoComplete="off" {...configTextField} />;
}
