import React, { ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { JsxElement } from 'typescript';
import TextField from '@mui/material/TextField';

type Props = {
    resourceName: string;
    isOpen: boolean;
    closeAlert: () => void;
    onConfirmRemoval: () => void;
};

export function ConfirmRemoveDialog({ resourceName, isOpen, closeAlert, onConfirmRemoval }: Props) {
    const [resourceNameConfirmation, setResourceNameConfirmation] = React.useState('');
    const [errorText, setErrorText] = React.useState<string | undefined>();

    const handleResourceNameConfirmationChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResourceNameConfirmation(event.target.value);
        setErrorText(undefined);
    };

    const clearAndCloseAlert = () => {
        setResourceNameConfirmation('');
        setErrorText(undefined);
        closeAlert();
    };

    const handleConfirmRemoval = () => {
        if (resourceNameConfirmation !== resourceName) {
            setErrorText('Resource names do not match');
            return;
        }

        onConfirmRemoval();
        clearAndCloseAlert();
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={closeAlert} fullWidth maxWidth="sm">
                <DialogTitle>Resource Removal Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You are about to permanently delete the following resources, child resources, and any associated
                        data. There is no way to undo this action. Please confirm this action by typing the resource
                        name (<strong>{resourceName}</strong>) below.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="resourceName"
                        label="Confirm resource name to remove"
                        type="resourceName"
                        fullWidth
                        variant="standard"
                        error={!!errorText}
                        helperText={errorText}
                        value={resourceNameConfirmation}
                        onChange={handleResourceNameConfirmationChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={clearAndCloseAlert}>Cancel</Button>
                    <Button onClick={handleConfirmRemoval} color="error">
                        Confirm Removal
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
