import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { Modify } from '../../../../utils/types/Modify';

export type ButtonProps = {
    children: ReactNode;
    disableSubmit?: boolean;
    onClick?: () => void;
} & MuiButtonProps;

export function SubmitButton({ children, disableSubmit: disableFormikSubmit, onClick, ...args }: ButtonProps) {
    const handleClick = async () => {
        if (onClick) {
            onClick();
        }
        if (!disableFormikSubmit) {
            const { submitForm } = useFormikContext();
            await submitForm();
        }
    };

    const configButton: MuiButtonProps = {
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        onClick: handleClick,
        ...args,
    };

    return <MuiButton {...configButton}>{children}</MuiButton>;
}
