import { Tooltip } from '@mui/material';

type Props = {
    children: JSX.Element;
    text?: string;
};

export function ConditionallyWrapWithTooltip({ children, text }: Props) {
    if (text) {
        return (
            <Tooltip title={text}>
                <span>{children}</span>
            </Tooltip>
        );
    }

    return children;
}
