import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { LoadingComponent } from '../components/elements/LoadingComponent';

type ProviderProps = {
    children?: ReactNode;
};

type LoadingContextType = {
    /**
     * Start tracking a task that requires a loading indicator. Omitting the taskId parameter will just show the loader.
     * This can be called by multiple parallel asynchronous operations to append to the stack of tasks to track. Loading will display
     * until all tasks are "completed", or loading is cleared
     * @param taskId
     * @returns
     */
    showLoading: (taskId?: string) => void;
    /**
     * Completes a loading task. When all tasks have been completed, the display of loading will be completed
     * @param taskId
     * @returns
     */
    loadingComplete: (taskId: string) => void;
    /**
     * Clears all outstanding loading tasks and disables the display of loading
     * @returns
     */
    clearLoading: () => void;
    /**
     * Resolves as true if there are one or more loading tasks pending
     */
    isLoading: boolean;
};

const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

export function LoadingProvider({ children }: ProviderProps) {
    const [loadingTasks, setLoadingTasks] = useState<string[]>([]);

    const showLoading = (taskId?: string) => {
        const updatedTasks = [...loadingTasks];
        const loadingTaskId = taskId || 'loading';
        updatedTasks.push(loadingTaskId);
        setLoadingTasks(updatedTasks);
    };

    const loadingComplete = (taskId: string) => {
        const updatedTasks = [...loadingTasks];
        const taskIdIndex = updatedTasks.findIndex((id) => id === taskId);

        if (taskIdIndex > -1) {
            updatedTasks.splice(taskIdIndex, 1);
        }
        setLoadingTasks(updatedTasks);
    };

    const clearLoading = () => {
        setLoadingTasks([]);
    };

    const isLoading = loadingTasks.length > 0;

    const memoValue = useMemo(
        () => ({
            isLoading,
            showLoading,
            loadingComplete,
            clearLoading,
        }),
        [loadingTasks],
    );

    return (
        <LoadingContext.Provider value={memoValue}>
            {isLoading && <LoadingComponent />}
            {children}
        </LoadingContext.Provider>
    );
}

export const useLoading = (): LoadingContextType => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a Loading Provider');
    }
    return context;
};
