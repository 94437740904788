import { Container, Stack } from '@mui/material';
import { useEffect } from 'react';
import { EnvironmentsControl } from './EnvironmentsControl';
import { useLayout } from '../../../../providers/LayoutProvider';
import { useServiceByIdQuery } from '../../../../features/services/services/servicesApiSlice';
import { useEntityContext } from '../../../../providers/EntityContextProvider';
import { useOrganizationByIdQuery } from '../../../../features/organizations/organizationsApiSlice';

export function DesignServiceEnvironments() {
    const { entityContext } = useEntityContext();
    const { data: organizationData } = useOrganizationByIdQuery({ organizationId: entityContext.organizationSlug! });
    const { data: serviceData } = useServiceByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );

    const { closeAndClearRightContextDrawer } = useLayout();

    useEffect(() => {
        return () => {
            closeAndClearRightContextDrawer();
        };
    }, []);

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                {serviceData &&
                    organizationData &&
                    organizationData.environmentTypes
                        .filter((type) =>
                            serviceData.isLimitedToProdNonProd
                                ? type.businessKey === 'p' || type.businessKey === 'np'
                                : !type.managed,
                        )
                        .sort((a, b) => b.deploymentPriority - a.deploymentPriority)
                        .map((type) => (
                            <EnvironmentsControl
                                key={type.id}
                                serviceId={serviceData.id}
                                serviceDeployEnabled={serviceData.deploymentState.isDeployEnabled}
                                serviceHasBeenDeployed={!!serviceData.deploymentState.lastDeployed}
                                serviceIsShared={serviceData.isShared || false}
                                environmentInstances={serviceData.environmentInstances.filter(
                                    (inst) => inst.environmentType.id === type.id,
                                )}
                                environmentTypeId={type.id}
                                environmentTypeName={type.name}
                                serviceHasChangesToDeploy={
                                    serviceData.hasChangesToDeploy || serviceData.hasPendingDeployment
                                }
                            />
                        ))}
            </Stack>
        </Container>
    );
}
