import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Chip, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useAccount } from '@azure/msal-react';
import { useSnackbar } from 'notistack';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { User, RoleAssignmentDetails } from '../../../models/users/User';
import { useLayout } from '../../../providers/LayoutProvider';
import { InviteUser } from './InviteUser';
import { ManageUserRoles } from './ManageUserRoles';
import { Role, RoleUtility } from '../../../models/users/Role';
import { AlertActionableDialog } from '../../../components/AlertActionableDialog';
import { appConfig } from '../../../config';
import { UserAccount } from '../../../utils/types/User';
import { getAssignedRoleLabels } from '../../../utils/AccessUtils';

export function ConfigureUsers() {
    const { organizationId } = useParams();
    const { getUsersByOrgId, getRolesByOrgId, removeUser, getOrgAdminConsentStatus } = useBackendService();
    const {
        setAndShowNewButton,
        hideNewButton,
        rightContextDrawerState,
        setAndOpenRightContextDrawer,
        closeAndClearRightContextDrawer,
        showAdditionalToolbarButtons,
        clearAdditionalToolbarButtons,
    } = useLayout();
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const [userListData, setUserListData] = useState<User[]>([]);
    const [roleData, setRoleData] = useState<Role[]>([]);
    const [removeDialogOpen, setToggleRemoveDialog] = useState<boolean>(false);
    const [userToRemove, setUserToRemove] = useState<User>();

    const fetchUserData = async () => {
        const users = await getUsersByOrgId({ organizationId: organizationId! });
        const roles = await getRolesByOrgId({ organizationId: organizationId! });

        const usersWithRoles = users.map((u) => {
            return {
                ...u,
                assignedRoleLabels: getAssignedRoleLabels(u.roleAssignments, roles),
            };
        });

        const defaultOrgRole = roles.find((role) => role.id === appConfig.roles.defaultRoleId);
        if (defaultOrgRole) {
            defaultOrgRole.defaultOrgRole = true;
        }

        setRoleData(roles);
        setUserListData(usersWithRoles);
    };

    const userInvited = () => {
        closeAndClearRightContextDrawer();
        fetchUserData();
    };

    const inviteUserAction = () => {
        setAndOpenRightContextDrawer(
            <InviteUser organizationId={organizationId!} actionCompleteCallback={userInvited} />,
            'Invite User',
        );
    };

    useEffect(() => {
        setAndShowNewButton(() => inviteUserAction, 'Invite');

        return () => {
            hideNewButton();
            closeAndClearRightContextDrawer();
        };
    }, []);

    useEffect(() => {
        fetchUserData();

        const fetchAdminConsentStatus = async (orgId: string) => {
            const consentStatus = await getOrgAdminConsentStatus(
                {
                    organizationId: orgId,
                },
                true,
            );

            const adminConsentButton = (
                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AssignmentTurnedInIcon />}
                    onClick={() => {
                        const { protocol, host } = window.location;

                        window.location.replace(
                            `https://login.microsoftonline.com/${
                                (account?.idTokenClaims as UserAccount).tid
                            }/v2.0/adminconsent?client_id=${appConfig.auth.scaledSenseAppClientId}&scope=${
                                appConfig.auth.scaledSenseAppClientId
                            }/.default&redirect_uri=${protocol}//${host}/callbacks/scaled-sense-app/admin-consent-callback&state=${orgId}`,
                        );
                    }}
                >
                    Complete Admin Consent
                </Button>
            );

            if (!consentStatus.adminConsentGranted) {
                showAdditionalToolbarButtons([adminConsentButton]);
            }
        };

        if (organizationId) {
            fetchAdminConsentStatus(organizationId);
        }

        return () => {
            clearAdditionalToolbarButtons();
        };
    }, []);

    const userRolesUpdated = (updatedUser: User, updatedRoleAssignments: RoleAssignmentDetails[]) => {
        // update the user object with the new roles
        const updatedUsers = userListData.map((u) => {
            if (u.id !== updatedUser.id) {
                return u;
            }

            return {
                ...updatedUser,
                roleAssignments: updatedRoleAssignments,
                assignedRoleLabels: getAssignedRoleLabels(updatedRoleAssignments, roleData),
            };
        });

        // update state
        setUserListData(updatedUsers);
    };

    const updateUserRolesAction = (updatedUser: User) => {
        setAndOpenRightContextDrawer(
            <ManageUserRoles
                organizationId={organizationId!}
                user={updatedUser}
                roles={roleData}
                actionCompleteCallback={userRolesUpdated}
            />,
            'Manage User Roles',
        );
    };

    const removeUserAccessAction = (userRemoved: User) => {
        setUserToRemove(userRemoved);
        setToggleRemoveDialog(true);
    };

    const handleCloseRemoveUserAccess = () => {
        setToggleRemoveDialog(false);

        // add slight delay to allow dialog to close before reseting data
        const timer = setTimeout(() => setUserToRemove(undefined), 100);
        clearTimeout(timer);
    };

    const handleRemoveUserAccessConfirmed = async () => {
        const emailForUser = userToRemove?.email;

        // Remove access
        if (userToRemove && userToRemove.id) {
            await removeUser({
                id: userToRemove?.id!,
                organizationId: organizationId!,
            });
        }

        const updatedListOfUsers = userListData.filter((u) => u.id !== userToRemove?.id);
        setUserListData(updatedListOfUsers);

        handleCloseRemoveUserAccess();

        enqueueSnackbar(`User '${emailForUser}' removed from Scaled Sense`);
    };

    const columns: GridColDef[] = [
        {
            field: 'friendlyName',
            headerName: 'Name',
            width: 250,
            editable: false,
        },
        {
            field: 'email',
            headerName: 'Email Address',
            flex: 1,
            editable: false,
        },
        {
            field: 'assignedRoleLabels',
            headerName: 'Role Assignments',
            flex: 1,
            editable: false,
            renderCell: (params: GridRenderCellParams<any>) =>
                RoleUtility.copyAndSort(params.value).map((r: Role) => (
                    <Tooltip title={r.name} key={r.id}>
                        <Chip label={r.name} variant="outlined" size="small" />
                    </Tooltip>
                )),
        },
        {
            field: 'state',
            headerName: 'User Status',
            width: 125,
            editable: false,
            valueFormatter: (params) => {
                if (!params.value || typeof params.value !== 'string') {
                    return params.value;
                }

                // capitalize the first letter
                const strVal: string = params.value;
                return strVal.charAt(0).toUpperCase() + strVal.slice(1);
            },
        },
        {
            field: ' ',
            headerName: 'Actions',
            headerAlign: 'center',
            type: 'actions',
            width: 125,
            renderCell: (params: GridRenderCellParams<any>) => (
                <>
                    <Tooltip title="Edit Roles">
                        <IconButton onClick={() => updateUserRolesAction(params.row as User)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Access">
                        <IconButton onClick={() => removeUserAccessAction(params.row as User)}>
                            <PersonOffIcon />
                        </IconButton>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
        <>
            <DataGrid
                pageSize={100}
                rowsPerPageOptions={[]}
                rows={userListData}
                columns={columns}
                disableSelectionOnClick
                rowSpacingType="border"
                columnVisibilityModel={{
                    assignedRoles: !rightContextDrawerState.isOpen,
                    state: !rightContextDrawerState.isOpen,
                }}
            />
            <AlertActionableDialog
                title={`Remove Access for ${userToRemove?.friendlyName}?`}
                text={`Confirm to proceed with removing all access to the Scaled Sense platform for '${userToRemove?.email}'. To undo
                        this action, the user will require a new invite.`}
                closeAlert={handleCloseRemoveUserAccess}
                isOpen={removeDialogOpen}
                actionButtonAction={handleRemoveUserAccessConfirmed}
                actionButtonText="Confirm"
            />
        </>
    );
}
