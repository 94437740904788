import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import SnackbarUtils from '../../utils/SnackbarUtils';
import { ValidationError } from '../../models/ValidationError';

export const apiErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action: unknown) => {
    if (isRejectedWithValue(action)) {
        const { payload, meta } = action as any;
        const { status } = payload;
        const statusText = meta?.baseQueryMeta?.response?.statusText;

        if (status === 400 && typeof payload === 'object') {
            SnackbarUtils.error(
                `${payload.statusText || 'Unable to complete the request'}
                            ${
                                (payload.data?.validationErrors?.length &&
                                    payload.data.validationErrors
                                        .map((validationError: ValidationError) => `• ${validationError.errorMessage}`)
                                        .join('\n')) ||
                                `• ${payload.data.status}`
                            }`,
                {
                    style: { whiteSpace: 'pre-line' },
                },
            );
        } else if (typeof payload === 'object') {
            SnackbarUtils.error(
                `${statusText || 'Unable to complete the request'}
                            ${
                                (payload &&
                                    payload.data &&
                                    payload.data.messages &&
                                    payload.data.messages.map((messages: any) => `• ${messages}`).join('\n')) ||
                                `• ${statusText}`
                            }`,
                {
                    style: { whiteSpace: 'pre-line' },
                },
            );
        } else {
            SnackbarUtils.error(`${statusText || 'Unable to complete the request'}\n• ${payload?.data}`, {
                style: { whiteSpace: 'pre-line' },
            });
        }
    }

    return next(action);
};
