import { Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { OrganizationListItem, OrganizationsListResponse } from '../../models/organizations/OrganizationsListResponse';
import { useBackendService } from '../../providers/BackendServiceProvider';
import { OrganizationLineItem } from './OrganizationLineItem';

export function Organizations() {
    const [data, setData] = useState<OrganizationsListResponse | undefined>(undefined);
    const { getOrganizations } = useBackendService();

    const fetchData = async () => {
        const response = await getOrganizations();
        setData(response);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        // Can be used to center vertically
        // <Box
        //     sx={{
        //         display: 'flex',
        //         alignItems: 'center',
        //         height: '100%',
        //         width: '100%',
        //     }}
        // >
        <Container maxWidth="md" sx={{ marginTop: 6 }}>
            <Stack spacing={3}>
                {data && data.organizations?.length < 1 && (
                    <Typography textAlign="center" variant="body1">
                        No organizations have been created yet.
                    </Typography>
                )}
                {data &&
                    data.organizations?.length > 0 &&
                    data.organizations.map((organization: OrganizationListItem, index: number) => (
                        <OrganizationLineItem key={index} organization={organization} />
                    ))}
            </Stack>
        </Container>
        // </Box>
    );
}
