import { PaletteMode } from '@mui/material';
import { green, red, yellow, grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const generateTransientBackgroundStyle = (color1: string, color2: string) => ({
    '@keyframes cycle': {
        from: {
            backgroundPosition: '100% 50%',
        },
        to: { backgroundPosition: '-100% 50%' },
    },
    background: `repeating-linear-gradient(135deg, ${color1}, ${color1} 20px, ${color2} 10px, ${color2} 40px)`,
    animation: 'cycle 60s linear infinite',
    backgroundSize: '500% 100%',
});

// TODO: figure out how to build a theme
export const ssBlue = {
    50: '#8EA4D8',
    100: '#7B95D2',
    200: '#6886CC',
    300: '#5577C6',
    400: '#4268C0',
    500: '#3A5DAE',
    600: '#2E4A8B',
    700: '#284179',
    800: '#223768',
    900: '#1D2E57',
};
export const ssOrange = {
    50: '#E2A295',
    100: '#DC9081',
    200: '#D67E6D',
    300: '#D16C59',
    400: '#CB5B45',
    500: '#C14C36',
    600: '#AD4430',
    700: '#9A3C2B',
    800: '#873525',
    900: '#732D20',
};
export const hoverNavBackgroundColor = '#314471';
export const mutedColor = 'rgb(161, 159, 157)';
export const activeNavBackgroundColor = '#2f2f2f';
export const transientBackground: {
    [key in 'warning' | 'success' | 'default' | 'primary' | 'info' | 'error' | 'secondary']: object;
} = {
    success: generateTransientBackgroundStyle(green[700], green[800]),
    warning: generateTransientBackgroundStyle(yellow[800], yellow[900]),
    error: generateTransientBackgroundStyle(red[700], red[800]),
    primary: generateTransientBackgroundStyle(ssBlue[500], ssBlue[600]),
    secondary: generateTransientBackgroundStyle(ssOrange[500], ssOrange[600]),
    default: generateTransientBackgroundStyle('rgba(255, 255, 255, 0.14)', 'rgba(255, 255, 255, 0.1)'),
    info: generateTransientBackgroundStyle('rgba(255, 255, 255, 0.14)', 'rgba(255, 255, 255, 0.1)'),
};

// TODO: Create theme https://mui.com/customization/theming/
const themeOptions = {
    palette: {
        mode: 'dark' as PaletteMode,
        primary: {
            light: ssBlue[50],
            main: ssBlue[500],
            dark: ssBlue[700],
        },
        secondary: {
            light: ssOrange[300],
            main: ssOrange[500],
            dark: ssOrange[700],
        },
        warning: {
            main: yellow[800],
            dark: yellow[900],
        },
        error: {
            light: red[400],
            main: red[700],
            dark: red[900],
        },
        success: {
            light: green[400],
            main: green[700],
            dark: green[900],
        },
        info: {
            light: '#FFFFFF',
            main: '#FFFFFF',
            dark: '#FFFFFF',
        },
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 700,
        h6: {
            fontWeight: 400,
        },
    },
};

export const theme = createTheme(themeOptions);
