import { Box, Button, Container, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAccount } from '@azure/msal-react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useSnackbar } from 'notistack';
import { useLayout } from '../../../providers/LayoutProvider';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { UserAccount } from '../../../utils/types/User';
import {
    AzureAdConnection,
    GitHubConnection,
    MicrosoftCustomerConnection,
    Platform,
} from '../../../models/platforms/Platform';
import { NewMicrosoftCustomerPlatform } from './NewMicrosoftCustomerPlatform';
import { appConfig } from '../../../config';
import { getDomainInfo } from '../../../utils/BrowserEnvUtils';

export function ConfigurePlatforms() {
    const [gitHubData, setGitHubData] = useState<Platform | undefined>(undefined);
    const [msData, setMsData] = useState<Platform | undefined>(undefined);
    const [azureAdData, setAzureAdData] = useState<Platform | undefined>(undefined);
    const { closeAndClearRightContextDrawer, setAndOpenRightContextDrawer } = useLayout();
    const { enqueueSnackbar } = useSnackbar();
    const { organizationId } = useParams();
    const { getPlatforms } = useBackendService();
    const account = useAccount();

    const fetchPlatformData = async () => {
        const response = await getPlatforms({ organizationId: organizationId! });
        response?.forEach((platform) => {
            if (platform.type === 'gitHub') {
                setGitHubData(platform);
            }

            if (platform.type === 'microsoftCustomer') {
                setMsData(platform);
            }

            if (platform.type === 'azureAd') {
                setAzureAdData(platform);
            }
        });
    };

    const getAzureAdTenantId = (): string | undefined => {
        return azureAdData && (azureAdData.connectionDetails as AzureAdConnection)?.azureTenantId;
    };

    const isAzureAdTenantIdEmpty = (): boolean => {
        return getAzureAdTenantId() === '00000000-0000-0000-0000-000000000000';
    };

    const newPlatformCreatedHandler = () => {
        closeAndClearRightContextDrawer();
        fetchPlatformData();
    };

    const handleAuthorizeGitHubApp = () => {
        const { subDomain } = getDomainInfo();
        const urlSuffix = subDomain ? `-${subDomain}` : '';
        const githubInstallUrlWithState = `${appConfig.platforms.githubAppUrl}${urlSuffix}/installations/new?state=${organizationId}`;
        window.location.replace(githubInstallUrlWithState);
    };

    const handleAuthorizeAzureAdApp = () => {
        const clientId = (azureAdData?.connectionDetails as AzureAdConnection).azureClientId;
        if (!clientId) {
            enqueueSnackbar('Azure AD Client Id for Scaled Sense Automation not available', {
                variant: 'error',
                style: { whiteSpace: 'pre-line' },
            });
        }

        const scaledSenseAutomationClientId = clientId!;
        const { protocol, host } = window.location;

        window.location.replace(
            `https://login.microsoftonline.com/${
                (account?.idTokenClaims as UserAccount).tid
            }/v2.0/adminconsent?client_id=${scaledSenseAutomationClientId}&scope=${scaledSenseAutomationClientId}/.default&redirect_uri=${protocol}//${host}/callbacks/platforms/azure-ad-admin-consent-callback&state=${organizationId}`,
        );
    };

    const newMicrosoftCustomerAccountPlatformHandler = () => {
        if (organizationId && !isAzureAdTenantIdEmpty()) {
            setAndOpenRightContextDrawer(
                <NewMicrosoftCustomerPlatform
                    organizationId={organizationId}
                    actionCompleteCallback={newPlatformCreatedHandler}
                />,
                'Connect Microsoft Customer Account',
            );
        }
    };

    useEffect(() => {
        fetchPlatformData();
    }, []);

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                <Typography variant="h4">GitHub Connection</Typography>
                {!gitHubData && (
                    <Box>
                        <Button onClick={handleAuthorizeGitHubApp} fullWidth={false}>
                            <Typography variant="body1">
                                <AddCircleIcon style={{ verticalAlign: 'bottom' }} /> New Connection
                            </Typography>
                        </Button>
                    </Box>
                )}
                {gitHubData && (
                    <>
                        {/* <Typography variant="body1">
                            <strong>Nickname: </strong>
                            {gitHubData?.nickname}
                        </Typography> */}
                        <Typography variant="body1">
                            <strong>GitHub App ID: </strong>
                            {(gitHubData.connectionDetails as GitHubConnection)?.gitHubAppId}
                        </Typography>
                        <Typography variant="body1">
                            <strong>GitHub App Installation ID: </strong>
                            {(gitHubData.connectionDetails as GitHubConnection)?.gitHubAppInstallationId}
                        </Typography>
                        <Typography variant="body1">
                            <strong>GitHub Organization or User: </strong>
                            {(gitHubData.connectionDetails as GitHubConnection)?.gitHubOwnerOrOrgName ||
                                '(Updating, please check back soon ..)'}
                        </Typography>
                    </>
                )}
                <Divider />
                <Typography variant="h4">Microsoft Entra ID Connection</Typography>
                {(!azureAdData || isAzureAdTenantIdEmpty()) && (
                    <Box>
                        <Button onClick={handleAuthorizeAzureAdApp} fullWidth={false}>
                            <Typography variant="body1">
                                <AddCircleIcon style={{ verticalAlign: 'bottom' }} /> New Connection
                            </Typography>
                        </Button>
                    </Box>
                )}
                {azureAdData && !isAzureAdTenantIdEmpty() && (
                    <>
                        <Typography variant="body1">
                            <strong>Tenant ID: </strong>
                            {(azureAdData.connectionDetails as AzureAdConnection)?.azureTenantId}
                        </Typography>
                        <Divider />
                        <Typography variant="h4">Azure Customer Connection</Typography>
                        {!msData && (
                            <Box>
                                <Button onClick={newMicrosoftCustomerAccountPlatformHandler} fullWidth={false}>
                                    <Typography variant="body1">
                                        <AddCircleIcon style={{ verticalAlign: 'bottom' }} /> New Connection
                                    </Typography>
                                </Button>
                            </Box>
                        )}
                        {msData &&
                            (msData.connectionDetails as MicrosoftCustomerConnection)?.azureBillingAccountType ===
                                'mcaAccount' && (
                                <>
                                    <Typography variant="body1">
                                        <strong>Billing Account: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureBillingAccountName
                                        }
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Billing Profile: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureBillingProfileName
                                        }
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Invoice Section: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureInvoiceSectionName
                                        }
                                    </Typography>
                                </>
                            )}
                        {msData &&
                            (msData.connectionDetails as MicrosoftCustomerConnection).azureBillingAccountType ===
                                'mpaAccount' && (
                                <>
                                    <Typography variant="body1">
                                        <strong>Billing Account: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureBillingAccountName
                                        }
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Customer: </strong>
                                        {(msData.connectionDetails as MicrosoftCustomerConnection)?.azureCustomerName}
                                    </Typography>
                                </>
                            )}
                        {msData &&
                            (msData.connectionDetails as MicrosoftCustomerConnection).azureBillingAccountType ===
                                'enrollmentAccount' && (
                                <>
                                    <Typography variant="body1">
                                        <strong>Billing Account: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureBillingAccountName
                                        }
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Enrollment Account: </strong>
                                        {
                                            (msData.connectionDetails as MicrosoftCustomerConnection)
                                                ?.azureEnrollmentAccountName
                                        }
                                    </Typography>
                                </>
                            )}
                    </>
                )}
            </Stack>
        </Container>
    );
}
