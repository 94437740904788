import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { theme } from './utils/theme';
import { BackendServiceProvider } from './providers/BackendServiceProvider';
import { UserProvider } from './providers/UserProvider';
import { NotificationProvider } from './providers/NotificationProvider';
import { getDomainInfo } from './utils/BrowserEnvUtils';
import { LoggingProvider } from './providers/LoggingProvider';
import { LoadingProvider } from './providers/LoadingProvider';
import { AuthenticatedAccountProvider } from './providers/AuthenticatedAccountProvider';
import { MsalInit } from './components/elements/auth/MsalInit';
import { EntityContextProvider } from './providers/EntityContextProvider';
import { AppRoutes } from './features/routing/AppRoutes';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';

export function App() {
    useEffect(() => {
        const { subDomain } = getDomainInfo();
        let iconFilePrefix = '';
        switch (subDomain.length > 0 && subDomain[0]) {
            case 'd':
                iconFilePrefix = 'd-';
                break;
            case 'q':
                iconFilePrefix = 'q-';
                break;
            case 'u':
                iconFilePrefix = 'u-';
                break;
            case 's':
                iconFilePrefix = 's-';
                break;
            default:
                break;
        }
        if (iconFilePrefix !== '') {
            const icons = document.querySelectorAll("link[rel~='icon'], link[rel~='manifest']");
            icons.forEach((icon) => {
                const href = icon.getAttribute('href');
                if (href && !href.includes(iconFilePrefix)) {
                    const components = href.split('/');
                    components[components.length - 1] = `${iconFilePrefix}${components[components.length - 1]}`;
                    icon.setAttribute('href', components.join('/'));
                }
            });
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline enableColorScheme />
            <BrowserRouter>
                <EntityContextProvider>
                    <LoggingProvider>
                        <LoadingProvider>
                            <MsalInit>
                                <AuthenticatedAccountProvider>
                                    <UserProvider>
                                        <SnackbarProvider
                                            disableWindowBlurListener
                                            maxSnack={3}
                                            autoHideDuration={5000}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <SnackbarUtilsConfigurator />
                                            <Box sx={{ display: 'flex' }}>
                                                <BackendServiceProvider>
                                                    <NotificationProvider>
                                                        <AppRoutes />
                                                    </NotificationProvider>
                                                </BackendServiceProvider>
                                            </Box>
                                        </SnackbarProvider>
                                    </UserProvider>
                                </AuthenticatedAccountProvider>
                            </MsalInit>
                        </LoadingProvider>
                    </LoggingProvider>
                </EntityContextProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
}
