import { ReactNode } from 'react';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { Account } from './Account';
import { Logout } from './Account/Logout';
import { ConfigureUsers } from './Configure/Users';
import { DesignProduct } from './Design/Product';
import { DesignProductRoles } from './Design/Product/Roles';
import { DesignProductInformation } from './Design/Product/Information';
import { DesignOrganization } from './Design/Organization';
import { DesignOrganizationBusinessUnits } from './Design/Organization/BusinessUnits';
import { DesignOrganizationProductRoles } from './Design/Organization/ProductRoles';
import { DesignOrganizationCriticalityTiers } from './Design/Organization/CriticalityTiers';
import { DesignOrganizationEnvironmentTypes } from './Design/Organization/EnvironmentTypes';
import { DesignService } from './Design/Service';
import { DesignServicePorts } from './Design/Service/Ports';
import { DesignServiceProperties } from './Design/Service/Properties';
import { DesignServiceEnvironments } from './Design/Service/Environments';
import { BuildOrganization } from './Build/Organization';
import { BuildProduct } from './Build/Product';
import { BuildService } from './Build/Service';
import { ActOrganization } from './Act/Organization';
import { ActProduct } from './Act/Product';
import { ActService } from './Act/Service';
import { InspectOrganization } from './Inspect/Organization';
import { InspectProduct } from './Inspect/Product';
import { InspectService } from './Inspect/Service';
import { ConfigurePlatforms } from './Configure/Platforms';
import { ConfigurePermissions } from './Configure/Permissions';
import { DefaultEntity } from '../features/routing/DefaultEntity';
import { ObserveDeployments } from './Observe/Deployments';

export interface NavigationProps {
    organizationSlug?: string;
    productSlug?: string;
    serviceSlug?: string;
    singleTokenPlaceholder?: string;
}

export type NavigationItem = {
    name: string;
    children?: ChildNavigationItem[];
    icon?: ReactNode;
    modulePath?: string;
    path: string;
    element?: ReactNode;
    navMain?: boolean;
    navActivePath?: string;
    navMainBottom?: boolean;
};

export type ChildNavigationItem = {
    name: string;
    children?: NavigationItem[];
    relativePath?: string;
    path: string;
    element: ReactNode;
};

export const organizationRoutes = (navProps?: NavigationProps) => {
    const orgSlugOrPlaceholder = navProps?.organizationSlug || navProps?.singleTokenPlaceholder || ':organizationId';
    const productSlugOrPlaceholder = navProps?.productSlug || navProps?.singleTokenPlaceholder || ':productId';
    const serviceSlugOrPlaceholder = navProps?.serviceSlug || navProps?.singleTokenPlaceholder || ':serviceId';

    const routes: NavigationItem[] = [
        // #region Design
        {
            name: 'Organization Details',
            path: `/organizations/${orgSlugOrPlaceholder}/design`,
            element: <DesignOrganization />,
            navMain: false,
            navActivePath: '/design',
            children: [
                {
                    name: 'Business Units',
                    path: 'business-units',
                    element: <DesignOrganizationBusinessUnits />,
                },
                {
                    name: 'Product Roles',
                    path: 'product-roles',
                    element: <DesignOrganizationProductRoles />,
                },
                {
                    name: 'Criticality Tiers',
                    path: 'criticality-tiers',
                    element: <DesignOrganizationCriticalityTiers />,
                },
                {
                    name: 'Environment Types',
                    path: 'environment-types',
                    element: <DesignOrganizationEnvironmentTypes />,
                },
            ],
        },
        {
            name: 'Product Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/design`,
            element: <DesignProduct />,
            navMain: false,
            navActivePath: '/design',
            children: [
                {
                    name: 'Roles',
                    path: 'roles',
                    element: <DesignProductRoles />,
                },
                {
                    name: 'Information',
                    path: 'information',
                    element: <DesignProductInformation />,
                },
            ],
        },
        {
            name: 'Service Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/services/${serviceSlugOrPlaceholder}/design`,
            element: <DesignService />,
            navMain: false,
            navActivePath: '/design',
            children: [
                {
                    name: 'Properties',
                    path: 'properties',
                    element: <DesignServiceProperties />,
                },
                {
                    name: 'Links',
                    path: 'links',
                    element: <DesignServicePorts />,
                },
                {
                    name: 'Environments',
                    path: 'environments',
                    element: <DesignServiceEnvironments />,
                },
            ],
        },
        // #endregion
        // #region Build
        {
            name: 'Organization Details',
            path: `/organizations/${orgSlugOrPlaceholder}/build`,
            element: <BuildOrganization />,
            navMain: false,
            navActivePath: '/build',
        },
        {
            name: 'Product Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/build`,
            element: <BuildProduct />,
            navMain: false,
            navActivePath: '/build',
        },
        {
            name: 'Service Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/services/${serviceSlugOrPlaceholder}/build`,
            element: <BuildService />,
            navMain: false,
            navActivePath: '/build',
        },
        // #endregion
        // #region Observe
        {
            name: 'Organization Details',
            path: `/organizations/${orgSlugOrPlaceholder}/observe`,
            element: <ObserveDeployments />,
            navMain: false,
            navActivePath: '/observe',
        },
        {
            name: 'Product Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/observe`,
            element: <ObserveDeployments />,
            navMain: false,
            navActivePath: '/observe',
        },
        {
            name: 'Service Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/services/${serviceSlugOrPlaceholder}/observe`,
            element: <ObserveDeployments />,
            navMain: false,
            navActivePath: '/observe',
        },
        // #endregion
        // #region Act
        {
            name: 'Organization Details',
            path: `/organizations/${orgSlugOrPlaceholder}/act`,
            element: <ActOrganization />,
            navMain: false,
            navActivePath: '/act',
        },
        {
            name: 'Product Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/act`,
            element: <ActProduct />,
            navMain: false,
            navActivePath: '/act',
        },
        {
            name: 'Service Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/services/${serviceSlugOrPlaceholder}/act`,
            element: <ActService />,
            navMain: false,
            navActivePath: '/act',
        },
        // #endregion
        // #region Inspect
        {
            name: 'Organization Details',
            path: `/organizations/${orgSlugOrPlaceholder}/inspect`,
            element: <InspectOrganization />,
            navMain: false,
            navActivePath: '/inspect',
        },
        {
            name: 'Product Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/inspect`,
            element: <InspectProduct />,
            navMain: false,
            navActivePath: '/inspect',
        },
        {
            name: 'Service Details',
            path: `/organizations/${orgSlugOrPlaceholder}/products/${productSlugOrPlaceholder}/services/${serviceSlugOrPlaceholder}/inspect`,
            element: <InspectService />,
            navMain: false,
            navActivePath: '/inspect',
        },
        // #endregion
        // #region Other Routes
        {
            name: 'Configure',
            icon: <SettingsIcon />,
            path: `/organizations/${orgSlugOrPlaceholder}/configure`,
            navMainBottom: true,
            navActivePath: '/configure',
            children: [
                { name: 'Platforms', path: 'platforms', element: <ConfigurePlatforms /> },
                {
                    name: 'Users',
                    path: 'users',
                    element: <ConfigureUsers />,
                },
                { name: 'Permissions', path: 'permissions', element: <ConfigurePermissions /> },
            ],
        },
        {
            name: 'Logout',
            icon: <LogoutIcon />,
            path: `/organizations/${orgSlugOrPlaceholder}/account/logout`,
            element: <Logout />,
            navMain: false,
        },
        {
            name: 'Account',
            icon: <QuestionMarkIcon />,
            path: `/organizations/${orgSlugOrPlaceholder}/account`,
            element: <Account />,
            navMain: false,
        },
        // #endregion
        // #region Default Routes
        {
            name: 'Organization',
            path: `/organizations/${orgSlugOrPlaceholder}`,
            element: <DefaultEntity />,
        },
        {
            name: 'Products',
            path: `/organizations/${orgSlugOrPlaceholder}/products`,
            element: <DefaultEntity />,
        },
        {
            name: 'Product',
            path: `/organizations/${orgSlugOrPlaceholder}/products/:productId`,
            element: <DefaultEntity />,
        },
        {
            name: 'Services',
            path: `/organizations/${orgSlugOrPlaceholder}/products/:productId/services`,
            element: <DefaultEntity />,
        },
        {
            name: 'Service',
            path: `/organizations/${orgSlugOrPlaceholder}/products/:productId/services/:serviceId`,
            element: <DefaultEntity />,
        },
        // #endregion
    ];
    return routes;
};
