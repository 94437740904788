import { Box, Button, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { ssBlue } from '../../utils/theme';
import { ConditionallyWrapWithTooltip } from './ConditionallyWrapWithTooltip';

export interface ToolBarButtonSettings {
    text?: string;
    icon: JSX.Element;
    onClick: () => void;
    align?: 'left' | 'right';
    disabled?: boolean;
    tooltip?: string;
    primary?: boolean;
}

type Props = {
    buttons: ToolBarButtonSettings[];
};

const toolBarButtonStyle = (primary?: boolean) => ({
    backgroundColor: primary ? ssBlue[500] : '#313131',
    '&:disabled': {
        backgroundColor: '#1e1e1e',
    },
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    marginRight: '10px',
    textTransform: 'none',
});

const toolBarIconButtonStyle = (primary?: boolean) => ({
    ...toolBarButtonStyle(primary),
    minWidth: 0,
});

export function InPageToolbar({ buttons }: Props) {
    const themeHook = useTheme();
    const isMdDesktopView = useMediaQuery(themeHook.breakpoints.up('md'));
    const forceSmallButtons = !isMdDesktopView || buttons.length > 6;

    const leftAlignedButtons = buttons.filter((button) => button.align !== 'right'); // left or undefined
    const rightAlignedButtons = buttons.filter((button) => button.align === 'right');

    return (
        <Toolbar variant="dense" disableGutters>
            {leftAlignedButtons.map((button, index) => (
                <ConditionallyWrapWithTooltip text={button.tooltip || button.text} key={index}>
                    <span style={{ display: 'flex' }}>
                        {button.text && !forceSmallButtons && (
                            <Button
                                variant="contained"
                                onClick={button.onClick}
                                fullWidth={false}
                                startIcon={button.icon}
                                size="small"
                                sx={toolBarButtonStyle(button.primary)}
                                disabled={button.disabled}
                            >
                                {button.text}
                            </Button>
                        )}
                        {(!button.text || forceSmallButtons) && (
                            <Button
                                variant="contained"
                                onClick={button.onClick}
                                fullWidth={false}
                                size="small"
                                sx={toolBarIconButtonStyle(button.primary)}
                                disabled={button.disabled}
                            >
                                {button.icon}
                            </Button>
                        )}
                    </span>
                </ConditionallyWrapWithTooltip>
            ))}
            {rightAlignedButtons.length > 0 && (
                <>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: 'flex' }}>
                        {rightAlignedButtons.map((button, index) => (
                            <ConditionallyWrapWithTooltip text={button.tooltip || button.text} key={index}>
                                <span style={{ display: 'flex' }}>
                                    {button.text && !forceSmallButtons && (
                                        <Button
                                            variant="contained"
                                            onClick={button.onClick}
                                            fullWidth={false}
                                            startIcon={button.icon}
                                            size="small"
                                            sx={toolBarButtonStyle(button.primary)}
                                            disabled={button.disabled}
                                        >
                                            {button.text}
                                        </Button>
                                    )}
                                    {(!button.text || forceSmallButtons) && (
                                        <Button
                                            variant="contained"
                                            onClick={button.onClick}
                                            fullWidth={false}
                                            size="small"
                                            sx={toolBarIconButtonStyle(button.primary)}
                                            disabled={button.disabled}
                                        >
                                            {button.icon}
                                        </Button>
                                    )}
                                </span>
                            </ConditionallyWrapWithTooltip>
                        ))}
                    </Box>
                </>
            )}
        </Toolbar>
    );
}
