import { Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EnvironmentTypesControl } from '../EnvironmentTypesControl';
import { useBackendService } from '../../../../providers/BackendServiceProvider';
import { Organization } from '../../../../models/organizations/Organization';

export function DesignOrganizationEnvironmentTypes() {
    const [orgData, setOrgData] = useState<Organization | undefined>();
    const { organizationId } = useParams();
    const { getOrganizationById } = useBackendService();

    const fetchOrgData = async () => {
        const response = await getOrganizationById({ organizationId: organizationId! });
        response.environmentTypes = response.environmentTypes
            .filter((x) => !x.name.startsWith('Shared'))
            .sort((a, b) => b.deploymentPriority - a.deploymentPriority);

        setOrgData(response);
    };

    useEffect(() => {
        fetchOrgData();
    }, []);

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                {orgData && (
                    <EnvironmentTypesControl
                        value={orgData?.environmentTypes.map((type) => {
                            return {
                                label: `${type.name} (${type.businessKey.toUpperCase()})`,
                                value: type.id,
                                disableEdit: type.managed,
                                helpDialogText: type.description,
                            };
                        })}
                    />
                )}
            </Stack>
        </Container>
    );
}
