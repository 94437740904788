import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { JsxElement } from 'typescript';

type Props = {
    title: string;
    text: string;
    closeAlert: () => void;
    isOpen: boolean;
    actionButtonText: string;
    actionButtonAction: () => void;
    inputElement?: JsxElement;
};

export function AlertActionableDialog({
    title,
    text,
    closeAlert,
    isOpen,
    actionButtonAction,
    actionButtonText,
    inputElement,
}: Props) {
    return (
        <div>
            <Dialog open={isOpen} onClose={closeAlert} fullWidth maxWidth="sm">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <>
                        <DialogContentText>{text}</DialogContentText>
                        {inputElement}
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlert}>Cancel</Button>
                    <Button onClick={actionButtonAction}>{actionButtonText}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
