import { useEffect } from 'react';
import { useUser } from '../../../providers/UserProvider';

export function Logout() {
    const { logoutUser } = useUser();

    useEffect(() => {
        logoutUser();
    }, []);

    return <p>Logging out..</p>;
}
