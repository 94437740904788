import { Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Select } from '../../../../../components/elements/input/Select';
import { PropertyHierarchy } from '../../../../../models/services/PropertyHierarchy';
import { ValueInheritanceTooltip } from '../ValueInheritanceTooltip';

type Props = {
    formProperty: string;
    displayName: string;
    options?: Array<{ label: string; value: string }> | undefined;
    isReadOnly: boolean;
    readonlyReason: string;
    propertyHierarchy?: PropertyHierarchy;
};

export function SelectPropertyControl({
    formProperty,
    displayName,
    options,
    isReadOnly,
    readonlyReason,
    propertyHierarchy,
}: Props) {
    const isPropertyInherited = !propertyHierarchy?.serviceProperty;
    const disabled = !!(isReadOnly || isPropertyInherited);

    const inheritedOptions =
        isPropertyInherited && options?.length
            ? options.map((opt) => {
                  return { label: `${opt.label} (Inherited)`, value: opt.value };
              })
            : options;

    const valueInheritance = [
        {
            value:
                options?.find(
                    (opt) =>
                        opt.value ===
                        (propertyHierarchy?.productMgmtProperty?.property?.value ||
                            propertyHierarchy?.orgGovProperty?.property?.value),
                )?.label || '',
            serviceOverridden: propertyHierarchy?.productMgmtProperty?.property?.value
                ? 'Product Management'
                : 'Organization Governance',
        },
    ];

    return (
        <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center" position="relative">
                    {isPropertyInherited && valueInheritance.length > 0 && (
                        <ValueInheritanceTooltip
                            valueInheritance={valueInheritance}
                            sx={{ position: 'absolute', marginLeft: '-35px' }}
                        >
                            <InfoIcon fontSize="small" />
                        </ValueInheritanceTooltip>
                    )}
                    {!isPropertyInherited ? (
                        <Select
                            name={formProperty}
                            label={displayName}
                            options={options || []}
                            required={!disabled}
                            disabled={disabled}
                            helperText={isReadOnly ? readonlyReason : ''}
                            autoFocus
                        />
                    ) : (
                        <Grid container>
                            <Select
                                name={formProperty}
                                label={displayName}
                                options={inheritedOptions || []}
                                disabled
                                value={
                                    propertyHierarchy?.productMgmtProperty?.property?.value ||
                                    propertyHierarchy?.orgGovProperty?.property?.value
                                }
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
