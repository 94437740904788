import { ReactElement, useEffect, useRef, useState } from 'react';
import { Grid, Tooltip, TooltipProps, Typography } from '@mui/material';
import { Modify } from '../../../../utils/types/Modify';
import { StyledArrowUp } from '../../../../components/elements/Arrows';

type Props = Modify<
    Partial<TooltipProps>,
    {
        value?: string;
        valueInheritance: {
            value: string;
            serviceOverridden: string;
        }[];
        children: ReactElement;
    }
>;

export function ValueInheritanceTooltip({ value, valueInheritance, children, ...otherProps }: Props): JSX.Element {
    const [inView, setInView] = useState(false);
    const tooltipRef = useRef(null);
    const listItems: { value: string; source?: string }[] = [];

    // Using the IntersectionObserver API, explicitly hide the Popper in the Tooltip when it is scrolled out of view
    // this prevents a scenario where a second scrollbar is rendered because the Popper is still showing but outside the viewport
    const cb = (entries: any) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
            setInView(true);
        } else {
            setInView(false);
        }
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
        };
        const ref = tooltipRef.current;
        const observer = new IntersectionObserver(cb, options);

        if (ref) observer.observe(ref);

        return () => {
            if (ref) observer.unobserve(ref);
        };
    }, [tooltipRef]);
    // end code for hiding Popper when scrolled out of view

    if (value) {
        listItems.push({
            value,
        });
    }

    valueInheritance?.forEach((inheritance) => {
        listItems.push({
            value: inheritance.value,
            source: inheritance.serviceOverridden,
        });
    });

    return (
        <Tooltip
            {...otherProps}
            ref={tooltipRef}
            PopperProps={{
                sx: { display: inView ? 'block' : 'none' },
            }}
            title={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <>
                    <Typography>Value Inheritance</Typography>
                    <Grid container alignItems="center">
                        {listItems.length > 1 && (
                            <Grid item xs={1}>
                                <StyledArrowUp marginLeft={2} />
                            </Grid>
                        )}
                        <Grid item xs={listItems.length > 1 ? 11 : 12}>
                            <ul style={{ paddingLeft: '1.5rem' }}>
                                {listItems.map((li, index) => (
                                    <li key={index}>
                                        <Typography
                                            variant="body2"
                                            component="span"
                                            sx={{
                                                textDecoration: index === 0 ? undefined : 'line-through',
                                            }}
                                        >
                                            {li.value}
                                        </Typography>
                                        {li.source && (
                                            <Typography variant="body2" component="span">
                                                {' '}
                                                - {li.source}
                                            </Typography>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </Grid>
                    </Grid>
                </>
            }
            placement="left"
            arrow
        >
            {children}
        </Tooltip>
    );
}
