import Toolbar from '@mui/material/Toolbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useLayout } from '../../../providers/LayoutProvider';

type Props = { show?: boolean };

export function ContentTopActionBar({ show = true }: Props) {
    const { newButtonAction, newButtonText, extraToolbarButtons } = useLayout();

    return (
        ((newButtonAction || extraToolbarButtons.length) && (
            <Toolbar variant="dense" disableGutters sx={{ display: show ? 'flex' : 'none' }}>
                <Grid container>
                    {/* <Tooltip title="Create new item"> */}
                    {newButtonAction && (
                        <Grid item>
                            <Button
                                color="primary"
                                variant="contained"
                                startIcon={<AddCircleIcon />}
                                onClick={newButtonAction}
                            >
                                {newButtonText}
                            </Button>
                        </Grid>
                    )}
                    {extraToolbarButtons &&
                        extraToolbarButtons.map((button) => (
                            <Grid item style={{ margin: '0 5px 0 5px' }}>
                                {button}
                            </Grid>
                        ))}
                    {/* </Tooltip> */}
                </Grid>
            </Toolbar>
        )) ||
        null
    );
}
