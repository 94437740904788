import '@fontsource/montserrat';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { reportWebVitals } from './reportWebVitals';
import { App } from './App';
import { store } from './state/store';

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(
    <StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitalss
reportWebVitals();
