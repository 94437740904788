import {
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
    LinearProgress,
    Box,
    CircularProgress,
    useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ManageProduct } from './ManageProduct';

import { getServiceState, Service } from '../../../models/services/Service';

import { MetadataDisplay } from '../../../components/elements/MetadataDisplay';
import { InPageToolbar, ToolBarButtonSettings } from '../../../components/elements/InPageToolbar';

import { KeyValueDisplay } from '../../../components/elements/KeyValuePairDisplay';
import { ManageService } from './Services/ManageService';
import { useLayout } from '../../../providers/LayoutProvider';
import { EntityContextModule, useEntityContext } from '../../../providers/EntityContextProvider';
import { useProductByIdQuery } from '../../../features/products/productsApiSlice';
import {
    invalidateOrganizationInCache,
    useOrganizationByIdQuery,
} from '../../../features/organizations/organizationsApiSlice';
import {
    invalidateServiceInCache,
    useServicesByProductIdQuery,
} from '../../../features/services/services/servicesApiSlice';
import { transientBackground } from '../../../utils/theme';
import { ServiceDeploymentStatus } from '../ServiceDeploymentStatus';

export function DesignProduct() {
    const { organizationId, productId } = useParams();
    const { entityContext, pathToChangeEntityContext } = useEntityContext();
    const { data: organizationData } = useOrganizationByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });
    const { data: productData, refetch: refetchProduct } = useProductByIdQuery({
        organizationId: entityContext.organizationSlug!,
        id: entityContext.productSlug!,
    });

    const {
        data: serviceData,
        isLoading: isLoadingServices,
        isFetching: isFetchingServices,
        refetch: refetchServices,
    } = useServicesByProductIdQuery({
        organizationId: entityContext.organizationSlug!,
        productId: entityContext.productSlug!,
    });
    const { closeAndClearRightContextDrawer, setAndOpenRightContextDrawer, isPreviewFeatureVisible } = useLayout();
    const themeHook = useTheme();
    const isMediumDesktopView = useMediaQuery(themeHook.breakpoints.up('md'));

    const handleProductSaved = (productIdSaved: string) => {
        closeAndClearRightContextDrawer();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        refetchProduct();
    };

    const handleEditProduct = () => {
        setAndOpenRightContextDrawer(
            <ManageProduct
                businessUnits={organizationData!.businessUnits}
                criticalityTiers={organizationData!.criticalityTiers}
                organizationId={organizationId!}
                existingProductId={productId!}
                actionCompleteCallback={handleProductSaved}
            />,
            'Manage Product',
        );
    };

    const handleServiceSaved = async (serviceId: string) => {
        closeAndClearRightContextDrawer();
    };

    const refetchPage = () => {
        refetchProduct();
        refetchServices();
    };

    const newServiceHandler = () => {
        if (organizationId) {
            setAndOpenRightContextDrawer(
                <ManageService
                    organizationId={organizationId!}
                    productId={productId!}
                    actionCompleteCallback={handleServiceSaved}
                />,
                'Define New Service',
            );
        }
    };

    const metadata = [
        {
            label: 'Product Name',
            value: productData?.name,
        },
        {
            label: 'Short Name',
            value: productData?.shortName,
        },
        {
            label: 'Business Key',
            value: productData?.businessKey,
        },
        {
            label: 'Business Unit',
            value: organizationData?.businessUnits.find(
                (unit) => unit.businessUnitBusinessKey === productData?.businessUnitBusinessKey,
            )?.name,
        },
        {
            label: 'Criticality Tier',
            value: organizationData?.criticalityTiers.find((tier) => tier.id === productData?.criticalityTier)?.name,
        },
    ];

    if (isPreviewFeatureVisible) {
        metadata.push(
            ...[
                {
                    label: 'Audience',
                    value: productData?.audience,
                },

                {
                    label: 'Expected Number Of Unique Visitors',
                    value: productData?.expectedUniqueVisitorQuantity,
                },
                {
                    label: 'Expected Unique Visitor Return Frequency',
                    value: productData?.expectedUniqueVisitorFrequency,
                },
                {
                    label: 'Expected Unique Visitor Access Timing',
                    value: productData?.expectedUniqueVisitorDistribution,
                },
            ],
        );
    }

    const toolbarButtonSettings: ToolBarButtonSettings[] = [
        {
            tooltip: 'Refresh',
            icon: <RefreshIcon fontSize="small" />,
            onClick: () => refetchPage(),
        },
        {
            text: 'Edit',
            icon: <EditIcon fontSize="small" />,
            onClick: () => handleEditProduct(),
        },
        {
            text: 'Create Service',
            icon: <AddCircleIcon fontSize="small" />,
            onClick: () => newServiceHandler(),
        },
    ];

    let pollingTimeout: NodeJS.Timeout | undefined;
    const pollUntilTransitioned = (servicesInTransition: Service[]) => {
        const pollingInterval = 30000; // 30 seconds
        let circuitBreaker = 0;
        const maxCount = 30; // 30 seconds x 30 tries = 15 minutes

        const pollData = async () => {
            const result = await refetchServices();
            circuitBreaker++;

            const transitioningServices = result.data?.filter((s) => getServiceState(s).isTransient);
            if (transitioningServices && transitioningServices.length > 0 && circuitBreaker < maxCount) {
                pollingTimeout = setTimeout(pollData, pollingInterval);
            } else {
                pollingTimeout = undefined;
            }

            servicesInTransition
                .filter((s) => !transitioningServices?.find((t) => t.id === s.id))
                .forEach((s) => {
                    invalidateServiceInCache(s.id);
                });
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        pollData();
    };

    const pollUntilProvisioned = () => {
        const pollingInterval = 5000; // 5 seconds
        let circuitBreaker = 0;
        const maxCount = 60; // 5 seconds x 60 tries = 5 minutes

        const pollData = async () => {
            const result = await refetchServices();
            circuitBreaker++;

            if ((!result.data || result.data.length === 0) && circuitBreaker < maxCount) {
                pollingTimeout = setTimeout(pollData, pollingInterval);
            } else {
                invalidateServiceInCache('LIST');
                invalidateOrganizationInCache(entityContext.organizationSlug!);
                pollingTimeout = undefined;
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        pollData();
    };

    useEffect(() => {
        if (serviceData?.length) {
            const transitioningServices = serviceData.filter((s) => getServiceState(s).isTransient);
            if (transitioningServices.length > 0) {
                pollUntilTransitioned(transitioningServices);
            }
        } else {
            // if no service data, poll until Product Mgmt service is available
            pollUntilProvisioned();
        }

        return () => {
            if (pollingTimeout) {
                clearTimeout(pollingTimeout);
            }
        };
    }, [serviceData]);

    // Card styles
    const StyledCard = styled(Card)(({ theme }) => ({
        '&:hover': {
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        marginTop: '1rem',
        padding: '1rem',
        flexGrow: 1,
    }));

    const cardMarginSpacingRight = isMediumDesktopView ? 2 : 0;

    const StyledCardContent = styled(CardContent)(({ theme }) => ({
        ':last-child': {
            paddingBottom: '8px',
        },
        padding: '0.5rem 0 0.5rem 0',
    }));

    const mgmtService = serviceData?.find((s) => s.businessKey === 'mgmt' || s.businessKey === 'gov');
    const dataMgmtService = serviceData?.find((s) => s.businessKey === 'data');

    return (
        <Container>
            <Stack spacing={2}>
                {productData && (
                    <>
                        <InPageToolbar buttons={toolbarButtonSettings} />
                        {/* If description exists, put as a separate card here that spans the full width */}
                        {productData.description && (
                            <Paper sx={{ padding: 3, marginBottom: 2 }}>{productData.description}</Paper>
                        )}
                        <MetadataDisplay metadataProperties={metadata} />
                    </>
                )}
            </Stack>
            <Grid container>
                <Grid container alignItems="stretch">
                    {mgmtService && (
                        <Grid
                            key={mgmtService.id}
                            item
                            xs={12}
                            md={dataMgmtService !== undefined ? 6 : 12}
                            display="flex"
                        >
                            <NavLink
                                to={pathToChangeEntityContext({
                                    organizationSlug: mgmtService.organizationId,
                                    productSlug: productId,
                                    serviceSlug: mgmtService.id,
                                })}
                                style={{ textDecoration: 'none', flexGrow: 1 }}
                            >
                                <StyledCard
                                    sx={{
                                        // marginTop: '1rem',
                                        // padding: '1rem',
                                        // flexGrow: 1,
                                        marginRight: dataMgmtService !== undefined ? cardMarginSpacingRight : 0,
                                        cursor: 'pointer',
                                    }}
                                >
                                    <CardHeader
                                        titleTypographyProps={{ variant: 'h6' }}
                                        title={mgmtService.name}
                                        sx={{ padding: 1, paddingBottom: 0 }}
                                        action={
                                            // eslint-disable-next-line react/jsx-wrap-multilines
                                            <NavLink
                                                to={pathToChangeEntityContext(
                                                    {
                                                        organizationSlug: mgmtService.organizationId,
                                                        productSlug: productId,
                                                        serviceSlug: mgmtService.id,
                                                    },
                                                    EntityContextModule.Observe,
                                                )}
                                            >
                                                <Tooltip title={getServiceState(mgmtService).description}>
                                                    <Chip
                                                        label={getServiceState(mgmtService).text}
                                                        color={getServiceState(mgmtService).color}
                                                        size="small"
                                                        sx={{
                                                            ...(getServiceState(mgmtService).isTransient &&
                                                                transientBackground[
                                                                    getServiceState(mgmtService).color
                                                                ]),
                                                            marginTop: 1,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </NavLink>
                                        }
                                    />
                                    <StyledCardContent>
                                        <Grid item xs={12} paddingX={1} overflow="hidden">
                                            <Grid container>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={dataMgmtService === undefined ? 6 : 12}
                                                    paddingRight={
                                                        isMediumDesktopView && dataMgmtService === undefined
                                                            ? 5
                                                            : undefined
                                                    }
                                                >
                                                    <KeyValueDisplay
                                                        item={{
                                                            label: 'Last Deployed',
                                                            value: mgmtService.deploymentState.lastDeployed
                                                                ? new Date(
                                                                      mgmtService.deploymentState.lastDeployed,
                                                                  ).toLocaleString()
                                                                : '',
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StyledCardContent>
                                </StyledCard>
                            </NavLink>
                        </Grid>
                    )}
                    {dataMgmtService && (
                        <Grid key={dataMgmtService.id} item xs={12} md={6} display="flex">
                            <NavLink
                                to={pathToChangeEntityContext({
                                    organizationSlug: dataMgmtService.organizationId,
                                    productSlug: productId,
                                    serviceSlug: dataMgmtService.id,
                                })}
                                style={{ textDecoration: 'none', flexGrow: 1 }}
                            >
                                <StyledCard
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    <CardHeader
                                        titleTypographyProps={{ variant: 'h6' }}
                                        title={dataMgmtService.name}
                                        sx={{ padding: 1, paddingBottom: 0 }}
                                        action={
                                            // eslint-disable-next-line react/jsx-wrap-multilines
                                            <NavLink
                                                to={pathToChangeEntityContext(
                                                    {
                                                        organizationSlug: dataMgmtService.organizationId,
                                                        productSlug: productId,
                                                        serviceSlug: dataMgmtService.id,
                                                    },
                                                    EntityContextModule.Observe,
                                                )}
                                            >
                                                <Tooltip title={getServiceState(dataMgmtService).description}>
                                                    <Chip
                                                        label={getServiceState(dataMgmtService).text}
                                                        color={getServiceState(dataMgmtService).color}
                                                        size="small"
                                                        sx={{
                                                            ...(getServiceState(dataMgmtService).isTransient &&
                                                                transientBackground[
                                                                    getServiceState(dataMgmtService).color
                                                                ]),
                                                            marginTop: 1,
                                                            marginRight: 1,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </NavLink>
                                        }
                                    />
                                    <StyledCardContent>
                                        <Grid item xs={12} paddingX={1} overflow="hidden">
                                            <KeyValueDisplay
                                                item={{
                                                    label: 'Last Deployed',
                                                    value: dataMgmtService.deploymentState.lastDeployed
                                                        ? new Date(
                                                              dataMgmtService.deploymentState.lastDeployed,
                                                          ).toLocaleString()
                                                        : '',
                                                }}
                                            />
                                        </Grid>
                                    </StyledCardContent>
                                </StyledCard>
                            </NavLink>
                        </Grid>
                    )}
                </Grid>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress
                        sx={{
                            backgroundColor: 'transparent',
                            visibility: !isFetchingServices || isLoadingServices ? 'hidden' : undefined,
                        }}
                    />
                </Box>
                {isLoadingServices && (
                    <Box display="flex" width="100%" justifyContent="center">
                        <CircularProgress />
                    </Box>
                )}
                {[
                    {
                        label: 'Functional Services',
                        value: serviceData?.filter(
                            (s) => !s.isShared && !['mgmt', 'gov', 'data'].some((bk) => bk === s.businessKey),
                        ),
                    },
                    {
                        label: 'Infrastructure Services',
                        value: serviceData?.filter(
                            (s) => s.isShared && !['mgmt', 'gov', 'data'].some((bk) => bk === s.businessKey),
                        ),
                    },
                ]
                    .filter((item) => item.value?.length)
                    .map((item, serviceGroupIndex) => (
                        <React.Fragment key={item.label}>
                            <Grid item xs={12} marginTop={serviceGroupIndex === 0 ? 1.5 : 2}>
                                <Typography variant="h5">{item.label}</Typography>
                            </Grid>
                            <Grid container alignItems="stretch">
                                {item.value &&
                                    item.value.map((service, serviceIndex) => {
                                        return (
                                            <Grid key={service.id} item xs={12} md={6} display="flex">
                                                <NavLink
                                                    to={pathToChangeEntityContext({
                                                        organizationSlug: service.organizationId,
                                                        productSlug: productId,
                                                        serviceSlug: service.id,
                                                    })}
                                                    style={{ textDecoration: 'none', flexGrow: 1, display: 'flex' }}
                                                >
                                                    <StyledCard
                                                        sx={{
                                                            marginRight: serviceIndex % 2 ? 0 : cardMarginSpacingRight,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <CardHeader
                                                            titleTypographyProps={{ variant: 'h6' }}
                                                            title={service.name}
                                                            sx={{ padding: 1, paddingBottom: 0 }}
                                                            action={
                                                                // eslint-disable-next-line react/jsx-wrap-multilines
                                                                <ServiceDeploymentStatus
                                                                    service={service}
                                                                    sxProps={{ marginTop: 1, marginRight: 1 }}
                                                                />
                                                            }
                                                        />
                                                        <StyledCardContent>
                                                            <Grid item xs={12} paddingX={1} overflow="hidden">
                                                                <KeyValueDisplay
                                                                    item={{
                                                                        label: 'Type',
                                                                        value: service.workloads.find(
                                                                            (w) => w.isPrimary,
                                                                        )?.friendlyName,
                                                                    }}
                                                                />
                                                                <KeyValueDisplay
                                                                    item={{
                                                                        label: 'Environments Enabled',
                                                                        value: service.environmentInstances
                                                                            .filter(
                                                                                (envInst) => envInst.isDeployEnabled,
                                                                            )
                                                                            .length.toString(),
                                                                    }}
                                                                />
                                                                <KeyValueDisplay
                                                                    item={{
                                                                        label: 'Last Deployed',
                                                                        value: service.deploymentState.lastDeployed
                                                                            ? new Date(
                                                                                  service.deploymentState.lastDeployed,
                                                                              ).toLocaleString()
                                                                            : '',
                                                                    }}
                                                                />
                                                            </Grid>
                                                        </StyledCardContent>
                                                    </StyledCard>
                                                </NavLink>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </React.Fragment>
                    ))}
            </Grid>
        </Container>
    );
}
