import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { JsxElement } from 'typescript';

type Props = {
    title: string;
    text: string;
    closeAlert: () => void;
    isOpen: boolean;
    inputElement?: JsxElement;
};

export function AlertDialog({ title, text, closeAlert, isOpen, inputElement }: Props) {
    return (
        <div>
            <Dialog open={isOpen} onClose={closeAlert} fullWidth maxWidth="sm">
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <>
                        <DialogContentText>{text}</DialogContentText>
                        {inputElement}
                    </>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeAlert}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
