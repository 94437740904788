import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Link as NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';

import {
    Box,
    Chip,
    ChipProps,
    CircularProgress,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDeploymentsRecentByContextQuery } from '../../../features/deployments/deploymentsApiSlice';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { InPageToolbar, ToolBarButtonSettings } from '../../../components/elements/InPageToolbar';
import { useServicesByOrgIdQuery } from '../../../features/services/services/servicesApiSlice';

import { Deployment } from '../../../models/deployments/Deployment';
import { toLowerCase, toProperCase } from '../../../utils/StringUtils';
import { Job } from '../../../models/deployments/Job';
import { Service } from '../../../models/services/Service';
import { Product } from '../../../models/products/Product';
import { useProductsListQuery } from '../../../features/products/productsApiSlice';
import { useJobCancelMutation, useLazyJobLogsQuery } from '../../../features/deployments/jobsApiSlice';
import { useLayout } from '../../../providers/LayoutProvider';
import { buildViewLogsDialogInstance } from './ViewLogsDialog';
import { useOrganizationMetadataByIdQuery } from '../../../features/organizations/organizationsApiSlice';
import { AlertActionableDialog } from '../../../components/AlertActionableDialog';
import { transientBackground } from '../../../utils/theme';

dayjs.extend(relativeTime);
dayjs.extend(calendar);

const getJobName = (job: Job) => {
    let jobName: string;
    switch (job.name) {
        case 'managementDeployment':
            jobName = 'Management Deployment';
            break;
        case 'advanceManagementDeployment':
            jobName = 'Management Pre-Deployment';
            break;
        case 'managementEnvironmentDeployment':
            jobName = 'Environment Deployment';
            break;
        case 'advanceManagementEnvironmentDeployment':
            jobName = 'Environment Pre-Deployment';
            break;
        case 'managementEnvironmentRemovalDeployment':
            jobName = 'Environment Removal';
            break;
        case 'managementRemovalDeployment':
            jobName = 'Management Removal';
            break;
        case 'operationalDeployment':
            jobName = 'Operational Deployment';
            break;
        case 'linkDeployment':
            jobName = 'Link Deployment';
            break;
        case 'linkRemovalDeployment':
            jobName = 'Link Removal';
            break;
        default:
            jobName = job.name;
    }

    if (job.jobMetadata?.additionalDescription) {
        jobName = `${jobName} (${job.jobMetadata.additionalDescription})`;
    }

    return jobName;
};

const formatTimestamp = (date: Date | undefined) => {
    if (!date) {
        return '';
    }

    const now = dayjs();
    const dayJsDate = dayjs(date);

    // greater than 1 hour and less than 24 hours
    if (now.diff(dayJsDate, 'hours') > 1 && now.diff(dayJsDate, 'hours') < 24) {
        return dayjs(date).format('h:mm A');
    }

    // greater than a day but less than a week
    if (now.diff(dayJsDate, 'hours') > 24 && now.diff(dayJsDate, 'days') < 7) {
        return dayjs(date).calendar(undefined, { lastWeek: 'dddd [at] h:mm A' });
    }

    if (now.diff(dayJsDate, 'days') > 7) {
        return dayjs(date).format('YYYY-MM-DD');
    }

    return dayjs(date).fromNow();
};

const formatStatus = (unformattedStatus: string) => {
    let chipProps: ChipProps;
    let styles = {};
    switch (toLowerCase(unformattedStatus)?.replaceAll(' ', '')) {
        case 'inprogress':
        case 'started':
            styles = {
                ...transientBackground.primary,
            };
            chipProps = {
                color: 'primary',
            };
            break;
        case 'requested':
        case 'waiting':
        case 'queued':
        case 'cancelrequested':
            chipProps = {
                color: 'primary',
            };
            break;
        case 'failed':
            chipProps = {
                color: 'error',
            };
            break;
        case 'completed':
            chipProps = {
                color: 'success',
            };
            break;
        case 'cancelled':
        default:
            chipProps = {
                color: 'default',
            };
    }

    return (
        <Chip
            label={unformattedStatus === 'cancelRequested' ? 'Cancel Requested' : toProperCase(unformattedStatus)}
            size="small"
            {...chipProps}
            sx={{ ...styles, width: '6rem' }}
        />
    );
};

const formatDuration = (startDate: Date | undefined, endDate: Date | undefined) => {
    if (!startDate || !endDate) {
        return '';
    }

    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (end.diff(start, 'm') < 1) {
        const value = Math.ceil(end.diff(start, 's', true));
        return `${value} second${value === 1 ? '' : 's'}`;
    }

    if (end.diff(start, 'h') < 1) {
        const value = Math.ceil(end.diff(start, 'm', true));
        return `${value} minute${value === 1 ? '' : 's'}`;
    }

    const value = Math.ceil(end.diff(start, 'h', true));
    return `${value} hour${value === 1 ? '' : 's'}`;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    height: '43px',
}));

const IconTableCell = styled(StyledTableCell)(({ theme }) => ({
    textAlign: 'center',
}));

const CenterAlignedTableCell = styled(StyledTableCell)(({ theme }) => ({
    textAlign: 'center',
}));

function Row(props: {
    row: Deployment;
    products?: Product[];
    services?: Service[];
    isExpanded: boolean;
    onRowExpanded: (rowId: string, newState: boolean) => void;
    onJobCancelRequested: (jobToCancel: Job) => void;
}) {
    const { row, isExpanded, onRowExpanded, onJobCancelRequested } = props;
    const { entityContext, pathToChangeEntityContext, changeEntityContext } = useEntityContext();
    const { setAndOpenDialog, isPlatformManagerMode } = useLayout();

    const [getJobLogs] = useLazyJobLogsQuery();
    const { data: orgMetadata } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const getJobActions = (job: Job) => {
        const actions = [];

        if (job.logOutputUrl && isPlatformManagerMode) {
            actions.push(
                <Tooltip key="logs" title="View Logs">
                    <IconButton
                        size="small"
                        onClick={async () => {
                            const logs = await getJobLogs({
                                organizationId: job.organizationId,
                                productId: job.productId,
                                serviceId: job.requestingServiceId,
                                jobId: job.id,
                            }).unwrap();

                            setAndOpenDialog(buildViewLogsDialogInstance(job, logs));
                        }}
                    >
                        <ManageSearchIcon />
                    </IconButton>
                </Tooltip>,
            );
        }

        if (job.jobMetadata?.externalLogUrl) {
            actions.push(
                <Tooltip key="logs" title="View Logs">
                    <NavLink to={job.jobMetadata.externalLogUrl} target="_blank" rel="noopener noreferrer">
                        <IconButton size="small">
                            <ManageSearchIcon />
                        </IconButton>
                    </NavLink>
                </Tooltip>,
            );
        }

        if (!job.isTerminal && !job.isExternal) {
            actions.push(
                <Tooltip key="cancel" title="Cancel Job">
                    <IconButton
                        size="small"
                        onClick={() => {
                            onJobCancelRequested(job);
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                </Tooltip>,
            );
        }

        return actions;
    };

    const getProductName = (productId: string) => {
        // eslint-disable-next-line react/destructuring-assignment
        return props.products?.find((product) => product.id === productId)?.name || productId;
    };

    const getServiceName = (serviceId: string) => {
        // eslint-disable-next-line react/destructuring-assignment
        return props.services?.find((service) => service.id === serviceId)?.name || serviceId;
    };

    const getEnvironmentName = (businessKey: string) => {
        if (!businessKey) {
            return businessKey;
        }

        if (businessKey.startsWith('svc')) {
            return 'Management';
        }

        const businessKeyWithNoInstanceNumber = businessKey.replace(/\d+/g, '');
        const instanceNumber = businessKey.replace(/\D+/g, '');

        // too much information for now
        // eslint-disable-next-line react/destructuring-assignment
        // return `${
        //     orgMetadata?.environmentTypes.find((env) => env.businessKey === businessKeyWithNoInstanceNumber)?.name ||
        //     businessKeyWithNoInstanceNumber
        // } - ${instanceNumber}`;

        return businessKey.toUpperCase();
    };

    const getProductLink = (productId: string) => {
        return (
            <Link
                component={NavLink}
                to={pathToChangeEntityContext({
                    organizationSlug: entityContext.organizationSlug!,
                    productSlug: productId,
                })}
                color="inherit"
                underline="hover"
                sx={{
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    e.preventDefault();

                    changeEntityContext({
                        organizationSlug: entityContext.organizationSlug!,
                        productSlug: productId,
                    });
                }}
            >
                {getProductName(productId)}
            </Link>
        );
    };

    const getServiceLink = (productId: string, serviceId: string) => {
        return (
            <Link
                component={NavLink}
                to={pathToChangeEntityContext({
                    organizationSlug: entityContext.organizationSlug!,
                    productSlug: productId,
                    serviceSlug: serviceId,
                })}
                color="inherit"
                underline="hover"
                sx={{
                    cursor: 'pointer',
                }}
                onClick={(e) => {
                    e.preventDefault();

                    changeEntityContext({
                        organizationSlug: entityContext.organizationSlug!,
                        productSlug: productId,
                        serviceSlug: serviceId,
                    });
                }}
            >
                {getServiceName(serviceId)}
            </Link>
        );
    };

    // sort jobs from oldest to newest
    const sortedJobs = [...row.jobs];
    sortedJobs.sort((j1, j2) => {
        return dayjs(j1.createdDate).isAfter(dayjs(j2.createdDate)) ? -1 : 1;
    });

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <IconTableCell>
                    <Tooltip title="Collapse Row">
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => onRowExpanded(row.id, !isExpanded)}
                        >
                            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </Tooltip>
                </IconTableCell>
                <StyledTableCell>{row.id}</StyledTableCell>
                <CenterAlignedTableCell>{getProductLink(row.productId)}</CenterAlignedTableCell>
                <CenterAlignedTableCell>{getServiceLink(row.productId, row.serviceId)}</CenterAlignedTableCell>
                <CenterAlignedTableCell />
                <Tooltip title={row.startTime ? dayjs(row.startTime).format('YYYY-MM-DD hh:mm:ss') : ''}>
                    <CenterAlignedTableCell>{formatTimestamp(row.startTime)}</CenterAlignedTableCell>
                </Tooltip>
                <CenterAlignedTableCell>{formatStatus(row.status)}</CenterAlignedTableCell>
                <CenterAlignedTableCell>{formatDuration(row.startTime, row.endTime)}</CenterAlignedTableCell>
            </TableRow>

            {isExpanded &&
                sortedJobs.map((job) => (
                    <TableRow
                        key={job.id}
                        sx={{ backgroundColor: 'rgb(47, 47, 47)' }}
                        className={isExpanded ? 'open' : undefined}
                    >
                        <IconTableCell>{getJobActions(job)}</IconTableCell>
                        <StyledTableCell sx={{ paddingLeft: '2rem' }}>{getJobName(job)}</StyledTableCell>
                        <CenterAlignedTableCell>{getProductLink(job.productId)}</CenterAlignedTableCell>
                        <CenterAlignedTableCell>
                            {getServiceLink(job.productId, job.requestingServiceId)}
                        </CenterAlignedTableCell>
                        <CenterAlignedTableCell>
                            {getEnvironmentName(job.environmentBusinessKey)}
                        </CenterAlignedTableCell>
                        <CenterAlignedTableCell>{formatTimestamp(job.executionStartTime)}</CenterAlignedTableCell>
                        <CenterAlignedTableCell>{formatStatus(job.status)}</CenterAlignedTableCell>
                        <CenterAlignedTableCell>
                            {formatDuration(job.executionStartTime, job.executionEndTime)}
                        </CenterAlignedTableCell>
                    </TableRow>
                ))}
        </>
    );
}

export function ObserveDeployments() {
    const [expandedRowState, setExpandedRowState] = useState<{ [rowId: string]: boolean }>({});
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [cancelDialogOpen, setCancelDialogOpen] = useState<boolean>(false);
    const [jobToCancel, setJobToCancel] = useState<Job>();
    const { entityContext } = useEntityContext();
    const {
        data: organizationServiceData,
        isLoading: isLoadingServices,
        isFetching: isFetchingServices,
    } = useServicesByOrgIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const {
        data: organizationProductData,
        isLoading: isLoadingProducts,
        isFetching: isFetchingProducts,
    } = useProductsListQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const {
        data: deploymentData,
        isFetching: isFetchingDeployments,
        isLoading: isLoadingDeployments,
        refetch: refreshDeploymentList,
        fulfilledTimeStamp: lastFetchedTimestamp,
    } = useDeploymentsRecentByContextQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug,
            serviceId: entityContext.serviceSlug!,
        },
        { refetchOnMountOrArgChange: true },
    );

    const [cancelJob] = useJobCancelMutation();

    const isLoading = isLoadingServices || isLoadingProducts || isLoadingDeployments;
    const isFetching = isFetchingServices || isFetchingProducts || isFetchingDeployments;

    let pollingTimeout: NodeJS.Timeout | undefined;
    const maxPollCount = 10; // 10 * 30000 = 5 minutes
    const pollForJobUpdates = () => {
        const pollingInterval = 30000;
        if (pollingTimeout) {
            clearTimeout(pollingTimeout);
        }

        const pollData = async (count: number) => {
            const result = await refreshDeploymentList();

            if (!result.error && count < maxPollCount) {
                pollingTimeout = setTimeout(() => pollData(count + 1), pollingInterval);
            } else {
                pollingTimeout = undefined;
            }
        };

        pollData(0);
    };

    useEffect(() => {
        pollForJobUpdates();

        return () => {
            if (pollingTimeout) {
                clearTimeout(pollingTimeout);
            }
        };
    }, []);

    const toolbarButtonSettings: ToolBarButtonSettings[] = [
        {
            tooltip: 'Refresh',
            icon: <RefreshIcon fontSize="small" />,
            onClick: () => {
                // immediate fetch and reset of polling for updates
                pollForJobUpdates();
            },
        },
    ];

    const visibleRows = React.useMemo(
        () => (deploymentData || []).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, deploymentData],
    );

    return (
        <>
            <Typography variant="h5" gutterBottom>
                Recent Deployments
            </Typography>
            {lastFetchedTimestamp && Math.abs(dayjs(lastFetchedTimestamp).diff(dayjs(), 'minute')) > 2 && (
                <Typography variant="body2" fontStyle="italic">
                    {`(Last refreshed at ${dayjs(lastFetchedTimestamp).format('h:mm A')})`}
                </Typography>
            )}
            <InPageToolbar buttons={toolbarButtonSettings} />
            <Box sx={{ width: '100%' }}>
                <LinearProgress
                    sx={{ backgroundColor: 'transparent', visibility: !isFetching || isLoading ? 'hidden' : undefined }}
                />
            </Box>
            {isLoading && (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && deploymentData && organizationServiceData && (
                <>
                    <TableContainer component={Paper} sx={{ marginTop: 3, maxHeight: 'calc(100vh - 290px)' }}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <IconTableCell width="88px">
                                        {Object.values(expandedRowState).some((isExpanded) => isExpanded) && (
                                            <Tooltip title="Collapse All">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        const allCollapsed = Object.keys(expandedRowState).reduce(
                                                            (newState: { [rowId: string]: boolean }, rowId: string) => {
                                                                // eslint-disable-next-line no-param-reassign
                                                                newState[rowId] = false;

                                                                return newState;
                                                            },
                                                            {},
                                                        );

                                                        setExpandedRowState(allCollapsed);
                                                    }}
                                                >
                                                    <KeyboardArrowUpIcon />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </IconTableCell>
                                    <StyledTableCell>Deployment</StyledTableCell>
                                    <CenterAlignedTableCell>Product</CenterAlignedTableCell>
                                    <CenterAlignedTableCell>Service</CenterAlignedTableCell>
                                    <CenterAlignedTableCell>Environment</CenterAlignedTableCell>
                                    <CenterAlignedTableCell>Started</CenterAlignedTableCell>
                                    <CenterAlignedTableCell>Status</CenterAlignedTableCell>
                                    <CenterAlignedTableCell>Duration</CenterAlignedTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {visibleRows.map((row) => (
                                    <Row
                                        key={row.id}
                                        row={row}
                                        services={organizationServiceData}
                                        products={organizationProductData}
                                        isExpanded={expandedRowState[row.id]}
                                        onRowExpanded={(rowId: string, newState: boolean) => {
                                            const updatedExpandedState = { ...expandedRowState };
                                            updatedExpandedState[rowId] = newState;
                                            setExpandedRowState(updatedExpandedState);
                                        }}
                                        onJobCancelRequested={(job: Job) => {
                                            setCancelDialogOpen(true);
                                            setJobToCancel(job);
                                        }}
                                    />
                                ))}
                                {!deploymentData ||
                                    (deploymentData.length === 0 && (
                                        <TableRow sx={{ backgroundColor: 'rgb(47, 47, 47)' }}>
                                            <StyledTableCell colSpan={8} sx={{ height: '5rem', borderBottom: 'none' }}>
                                                <Typography variant="body2" color="textSecondary" textAlign="center">
                                                    No recent deployments found
                                                </Typography>
                                            </StyledTableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={deploymentData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        showFirstButton
                        onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
                        onRowsPerPageChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setRowsPerPage(parseInt(event.target.value, 10))
                        }
                    />
                </>
            )}
            <AlertActionableDialog
                title="Are you sure you want to cancel this Job?"
                text="Confirm to proceed with cancelling the job."
                closeAlert={() => {
                    setCancelDialogOpen(false);
                    setJobToCancel(undefined);
                }}
                isOpen={cancelDialogOpen}
                actionButtonAction={async () => {
                    if (!jobToCancel) {
                        return;
                    }

                    await cancelJob({
                        organizationId: jobToCancel.organizationId,
                        productId: jobToCancel.productId,
                        serviceId: jobToCancel.requestingServiceId,
                        jobId: jobToCancel.id,
                    }).unwrap();

                    setCancelDialogOpen(false);
                    setJobToCancel(undefined);

                    refreshDeploymentList();
                }}
                actionButtonText="Confirm"
            />
        </>
    );
}
