import { CancelJobRequest } from '../../models/deployments/requests/CancelJobRequest';
import { GetJobLogsRequest } from '../../models/deployments/requests/GetJobLogsRequest';
import { apiSlice, RtkTagType } from '../api/apiSlice';

export const jobsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        jobCancel: builder.mutation<void, CancelJobRequest>({
            query: (request) => {
                return {
                    url: `organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/jobs/${request.jobId}/cancel`,
                    method: 'PUT',
                };
            },
            invalidatesTags: (result, error, request) =>
                request
                    ? [
                          { type: RtkTagType.Jobs, id: request.jobId },
                          { type: RtkTagType.Services, id: request.serviceId },
                      ]
                    : [],
        }),
        jobLogs: builder.query<any, GetJobLogsRequest>({
            query: (request) => {
                return {
                    url: `organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/jobs/${request.jobId}/logs`,
                };
            },
        }),
    }),
});

export const { useJobCancelMutation, useLazyJobLogsQuery } = jobsApiSlice;
