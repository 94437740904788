import {
    Box,
    Collapse,
    Container,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useProductsListQuery } from './productsApiSlice';
import { Product } from '../../models/products/Product';
import { useEntityContext } from '../../providers/EntityContextProvider';
import { useOrganizationByIdQuery } from '../organizations/organizationsApiSlice';
import { useServicesByOrgIdQuery } from '../services/services/servicesApiSlice';
import { Service } from '../../models/services/Service';

function Row(props: {
    product: Product;
    services: Service[];
    businessUnit?: string;
    criticalityTier?: string;
    rowHref?: string;
    serviceHref?: (serviceSlug: string) => string;
    onClick?: (productId: string, serviceId?: string) => void;
    active?: boolean;
    activeServiceId?: string;
    startTabIndex?: boolean;
}) {
    const { product, services, businessUnit, criticalityTier, rowHref, onClick, active, activeServiceId } = props;
    const [open, setOpen] = useState(active);

    return (
        <>
            <TableRow
                hover
                selected={active}
                sx={{
                    ...(services.length > 0 && { cursor: 'pointer' }),
                    backgroundColor: '#212121',
                    ...(services.length > 0 && {
                        'td, th': {
                            borderBottom: 0,
                        },
                    }),
                    height: '46px',
                }}
                onClick={(e: any) => {
                    console.log(e);
                    if (e.target?.nodeName.toLowerCase() !== 'a') {
                        setOpen(!open);
                    }
                }}
            >
                <TableCell align="center">
                    {services.length > 0 && (
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            className="dropdown-button"
                            onClick={() => {
                                setOpen(!open);
                            }}
                            tabIndex={services.length > 0 ? 1 : undefined}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon className="dropdown-button" />
                            ) : (
                                <KeyboardArrowDownIcon className="dropdown-button" />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        component={NavLink}
                        to={rowHref || ''}
                        color="inherit"
                        underline="hover"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.preventDefault();
                            if (onClick) {
                                onClick(product.id);
                            }
                        }}
                    >
                        {product.name}
                    </Link>
                </TableCell>
                <TableCell align="center">{businessUnit}</TableCell>
                <TableCell align="center">{criticalityTier}</TableCell>
            </TableRow>
            {services.length > 0 && (
                <TableRow
                    sx={{
                        backgroundColor: '#262626',
                        '&:last-child > td': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell style={{ paddingTop: 0, paddingBottom: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Container maxWidth="md" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                {/* <Box display="flex">
                                <Typography variant="h6" component="div" mr={1}>
                                    Services
                                </Typography> */}
                                <Table stickyHeader size="small">
                                    <TableHead
                                        sx={{
                                            th: {
                                                backgroundColor: '#212121',
                                            },
                                        }}
                                    >
                                        <TableRow>
                                            <TableCell style={{ minWidth: '40%' }}>
                                                <Typography fontWeight="700">Service Name</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography fontWeight="700">Workload Type</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {services.map((service) => (
                                            <TableRow
                                                key={service.id}
                                                hover
                                                selected={activeServiceId === service.id}
                                                sx={{
                                                    cursor: 'pointer',
                                                    backgroundColor: '#262626',
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                    <Link
                                                        component={NavLink}
                                                        to={rowHref || ''}
                                                        color="inherit"
                                                        underline="hover"
                                                        sx={{
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (onClick) {
                                                                onClick(product.id, service.id);
                                                            }
                                                        }}
                                                    >
                                                        {service.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {service.workloads.find((w) => w.isPrimary)?.friendlyName}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {/* </Box> */}
                            </Container>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}

type Props = {
    onProductClick?: (productSlug: string, serviceSlug?: string) => void;
};

export function Products({ onProductClick }: Props) {
    const { entityContext, pathToChangeEntityContext } = useEntityContext();
    const { data: organizationData } = useOrganizationByIdQuery({ organizationId: entityContext.organizationSlug! });
    const {
        data: productsData,
        isError,
        isFetching,
        isLoading,
        isSuccess,
    } = useProductsListQuery({ organizationId: entityContext.organizationSlug! });
    const { data: servicesData } = useServicesByOrgIdQuery({ organizationId: entityContext.organizationSlug! });

    return (
        <Container maxWidth="lg">
            <Stack spacing={3}>
                {(isLoading || isFetching) && (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress sx={{ backgroundColor: 'transparent' }} />
                    </Box>
                )}
                {isError && (
                    <Typography textAlign="center" variant="body1">
                        Something went wrong.
                    </Typography>
                )}
                {isSuccess && productsData && productsData.length < 1 && (
                    <Typography textAlign="center" variant="body1">
                        No products have been created yet.
                    </Typography>
                )}

                {isSuccess && productsData && (
                    <TableContainer component={Paper}>
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow sx={{ height: '50px' }}>
                                    <TableCell sx={{ width: '0' }} />
                                    <TableCell style={{ minWidth: '40%' }}>
                                        <Typography fontWeight="700">Product Name</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontWeight="700">Business Unit</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontWeight="700">Criticality Tier</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productsData.length &&
                                    productsData.map((product: Product) => (
                                        <Row
                                            key={product.id}
                                            product={product}
                                            services={
                                                servicesData?.filter((service) => service.productId === product.id) ||
                                                []
                                            }
                                            businessUnit={
                                                organizationData?.businessUnits.find(
                                                    (bu) =>
                                                        bu.businessUnitBusinessKey === product.businessUnitBusinessKey,
                                                )?.name
                                            }
                                            criticalityTier={
                                                product.name !== 'Organization Management'
                                                    ? organizationData?.criticalityTiers.find(
                                                          (ct) => ct.id === product.criticalityTier,
                                                      )?.name
                                                    : ''
                                            }
                                            rowHref={pathToChangeEntityContext({
                                                organizationSlug: entityContext.organizationSlug!,
                                                productSlug: product.id,
                                            })}
                                            serviceHref={(serviceSlug: string) =>
                                                pathToChangeEntityContext({
                                                    organizationSlug: entityContext.organizationSlug!,
                                                    productSlug: product.id,
                                                    serviceSlug,
                                                })
                                            }
                                            onClick={onProductClick}
                                            active={entityContext.productSlug === product.id}
                                            activeServiceId={entityContext.serviceSlug}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
        </Container>
    );
}
