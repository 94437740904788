import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Grid,
    Paper,
    Stack,
    Tooltip,
    Typography,
    styled,
    useMediaQuery,
    LinearProgress,
    Box,
    CircularProgress,
    useTheme,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { delay } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import CachedIcon from '@mui/icons-material/Cached';
import ClearIcon from '@mui/icons-material/Clear';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RocketLaunch } from '@mui/icons-material';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { AzSecurityGroup, getServiceState } from '../../../models/services/Service';
import { ConfirmRemoveDialog } from '../../../components/ConfirmRemoveDialog';
import { AlertDialog } from '../../../components/AlertDialog';
import { AddOnWorkload } from '../../../models/services/AddOnWorkload';
import { mutedColor } from '../../../utils/theme';
import { MetadataDisplay } from '../../../components/elements/MetadataDisplay';
import { InPageToolbar, ToolBarButtonSettings } from '../../../components/elements/InPageToolbar';
import { useLayout } from '../../../providers/LayoutProvider';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { GroupMemberList } from '../../../features/federation/group-members/GroupMemberList';
import {
    useServiceEnableMutation,
    useServiceMarkAllowDestructionMutation,
    useServiceMarkForRemovalMutation,
    useServiceReloadResourceCacheMutation,
    useServiceRequestDeployMutation,
    useServiceByIdQuery,
    invalidateServiceInCache,
} from '../../../features/services/services/servicesApiSlice';
import { DesignServiceAdmin } from './Admin';
import {
    useServiceAddOnsNewMutation,
    useServiceAddOnRemoveMutation,
    useServiceAddOnsByServiceIdQuery,
} from '../../../features/services/service-add-ons/serviceAddOnsApiSlice';
import { useOrganizationMetadataByIdQuery } from '../../../features/organizations/organizationsApiSlice';
import { ServiceDeploymentStatus } from '../ServiceDeploymentStatus';

export function DesignService() {
    const [deployRequestInProgress, setDeployRequestInProgress] = useState(false);
    const [removeServiceDialogIsOpen, setRemoveServiceDialogIsOpen] = useState(false);
    const [removeServiceAddOnDialogIsOpen, setRemoveServiceAddOnDialogIsOpen] = useState(false);
    const [addOnToRemove, setAddOnToRemove] = useState<AddOnWorkload>();
    const [errorDialogIsOpen, setErrorDialogIsOpen] = useState(false);
    const [errorResponse, setErrorResponse] = useState<string>();
    const { enqueueSnackbar } = useSnackbar();
    const { entityContext, changeEntityContext } = useEntityContext();
    const { data: orgMetadata } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });
    const {
        data: serviceData,
        isLoading,
        isFetching,
        refetch: refetchService,
    } = useServiceByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );
    const { data: serviceAddOns } = useServiceAddOnsByServiceIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );

    const [enableService] = useServiceEnableMutation();
    const [requestDeployment] = useServiceRequestDeployMutation();
    const [refreshResourceCache] = useServiceReloadResourceCacheMutation();
    const [markAllowDestruction] = useServiceMarkAllowDestructionMutation();
    const [markForRemoval] = useServiceMarkForRemovalMutation();
    const [newServiceAddOns] = useServiceAddOnsNewMutation();
    const [removeServiceAddOn] = useServiceAddOnRemoveMutation();

    const { isPlatformManagerMode, setAndOpenRightContextDrawer } = useLayout();
    const themeHook = useTheme();
    const isLgDesktopView = useMediaQuery(themeHook.breakpoints.up('lg'));

    const handleManageRoleGroup = (federatedGroupId: string, groupName: string) => {
        setAndOpenRightContextDrawer(<GroupMemberList federatedGroupId={federatedGroupId} />, groupName);
    };

    const removeService = async () => {
        await markForRemoval({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
            isEnabledForDeployment: serviceData!.deploymentState.isDeployEnabled,
        })
            .unwrap()
            .then((result) => {
                enqueueSnackbar('Service Marked For Removal');
                changeEntityContext({
                    organizationSlug: entityContext.organizationSlug!,
                    productSlug: serviceData!.productId,
                });
            })
            .catch((err) => {
                setErrorDialogIsOpen(true);
                if (err.status === 422 && err.data.messages) {
                    setErrorResponse(err.data.messages);
                }
            });
    };

    const handleEnableService = async () => {
        await enableService({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
        }).unwrap();

        enqueueSnackbar('Service Enabled');
    };

    const handleRefreshResourceCache = async (upgradeWorkloads: boolean) => {
        await refreshResourceCache({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
            upgradeWorkloads,
        }).unwrap();
        enqueueSnackbar('Resource Refresh Requested');
    };

    const showAdminPanel = () => {
        setAndOpenRightContextDrawer(<DesignServiceAdmin />, 'Service Admin Panel');
    };

    const handleMarkToAllowResourceDestruction = async () => {
        await markAllowDestruction({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
        }).unwrap();
        enqueueSnackbar('Service Marked to Allow Resource Destruction');
    };

    const handleRequestDeployService = async () => {
        setDeployRequestInProgress(true);
        await requestDeployment({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
        }).unwrap();
        enqueueSnackbar('Service Deploy Requested');
        delay(() => setDeployRequestInProgress(false), 5000);
    };

    const handleNewAddOn = async (addOn: AddOnWorkload) => {
        await newServiceAddOns({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
            addOnWorkloads: [addOn.type],
        }).unwrap();

        enqueueSnackbar('Add-on Workload Enabled');
    };

    const removeAddOn = async (addOn: AddOnWorkload | undefined) => {
        if (!addOn) {
            return;
        }

        await removeServiceAddOn({
            organizationId: entityContext.organizationSlug!,
            productId: serviceData!.productId,
            serviceId: serviceData!.id,
            addOnWorkload: addOn.type,
        }).unwrap();

        enqueueSnackbar('Add-on Workload Removed');
    };

    const getAddOnActions = (addOn: AddOnWorkload) => {
        if (addOn.workloadTrigger.type === 'Link') {
            // If Link Triggered, not enabled and platform manager - allow add of add-on
            if (!addOn.enabled && isPlatformManagerMode) {
                return (
                    <CardActions
                        sx={{
                            display: 'flex',
                            justifySelf: 'flex-end',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            paddingBottom: 0.5,
                        }}
                    >
                        <Button size="small" onClick={(e) => handleNewAddOn(addOn)}>
                            Add to Workload
                        </Button>
                    </CardActions>
                );
            }

            // If Link Triggered, not enabled and not platform manager - instruct user on conditions to enable
            if (!addOn.enabled) {
                return (
                    <CardActions
                        sx={{
                            justifyContent: 'center',
                            justifySelf: 'flex-end',
                            fontStyle: 'italic',
                            paddingBottom: 0.5,
                        }}
                    >
                        <Typography variant="body2" color="text.secondary">
                            {addOn.workloadTrigger.conditionDescription}
                        </Typography>
                    </CardActions>
                );
            }

            // If Link Triggered, enabled and platform manager - allow remove of add-on
            if (addOn.enabled && isPlatformManagerMode) {
                return (
                    <CardActions
                        sx={{
                            display: 'flex',
                            justifySelf: 'flex-end',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            paddingBottom: 0.5,
                        }}
                    >
                        <Button
                            size="small"
                            color="error"
                            onClick={(e) => {
                                setAddOnToRemove(addOn);
                                setRemoveServiceAddOnDialogIsOpen(true);
                            }}
                        >
                            Remove
                        </Button>
                    </CardActions>
                );
            }

            // If Link Triggered, enabled and not platform manager - show nothing, no actions can be taken
            return <div />;
        }

        // If Manual Triggered, not enabled, allow add of add-on
        if (!addOn.enabled) {
            return (
                <CardActions
                    sx={{
                        display: 'flex',
                        justifySelf: 'flex-end',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        paddingBottom: 0.5,
                    }}
                >
                    <Button size="small" onClick={(e) => handleNewAddOn(addOn)}>
                        Add to Workload
                    </Button>
                </CardActions>
            );
        }

        // If Manual Triggered, enabled, allow remove of add-on
        return (
            <CardActions
                sx={{
                    display: 'flex',
                    justifySelf: 'flex-end',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    paddingBottom: 0.5,
                }}
            >
                <Button
                    size="small"
                    color="error"
                    onClick={(e) => {
                        setAddOnToRemove(addOn);
                        setRemoveServiceAddOnDialogIsOpen(true);
                    }}
                >
                    Remove
                </Button>
            </CardActions>
        );
    };

    let lastDeployed = 'Never';
    if (serviceData && serviceData.deploymentState.isDeployEnabled && serviceData.deploymentState.lastDeployed) {
        lastDeployed = new Date(serviceData.deploymentState.lastDeployed).toLocaleString();
    } else if (serviceData && serviceData.deploymentState.isDeployEnabled) {
        lastDeployed = 'Provisioning Service';
    }

    const metadata = [
        {
            label: 'Service Name',
            value: serviceData?.name,
        },
        { label: 'Short Name', value: serviceData?.shortName },
        { label: 'Business Key', value: serviceData?.businessKey },
        { label: 'Version', value: serviceData?.primaryWorkloadVersion },
        {
            label: 'Status',
            value: serviceData ? (
                <ServiceDeploymentStatus service={serviceData} sxProps={{ marginTop: '-4px', marginLeft: '-8px' }} />
            ) : undefined,
        },
        {
            label: 'Last Deployed',
            value: lastDeployed,
        },
    ];

    const toolbarButtonSettings: ToolBarButtonSettings[] = [];

    if (serviceData && isPlatformManagerMode) {
        toolbarButtonSettings.push({
            text: 'Open Admin Panel',
            icon: <AdminPanelSettingsIcon fontSize="small" />,
            onClick: () => showAdminPanel(),
            align: 'right',
        });
    }

    if (serviceData && !serviceData.deploymentState.isMarkedForRemoval) {
        if (isPlatformManagerMode) {
            toolbarButtonSettings.push({
                text: 'Reload Cache',
                icon: <CachedIcon fontSize="small" />,
                onClick: () => handleRefreshResourceCache(false),
                align: 'right',
            });
        }

        toolbarButtonSettings.push({
            tooltip: 'Refresh',
            icon: <RefreshIcon fontSize="small" />,
            onClick: () => {
                refetchService();
            },
        });

        toolbarButtonSettings.push({
            text: 'Remove',
            tooltip: 'Permanently remove service',
            icon: <ClearIcon fontSize="small" />,
            onClick: () => setRemoveServiceDialogIsOpen(true),
        });

        toolbarButtonSettings.push({
            text: 'Upgrade Version',
            icon: <UpgradeIcon fontSize="small" />,
            onClick: () => handleRefreshResourceCache(true),
            disabled: !serviceData?.hasNewerVersion,
            tooltip: !serviceData?.hasNewerVersion ? 'Service is up-to-date' : 'Upgrade workload to the latest version',
        });
    }

    if (serviceData && serviceData.deploymentState.isDeployEnabled && !serviceData.deploymentState.isMarkedForRemoval) {
        if (isPlatformManagerMode) {
            toolbarButtonSettings.push({
                text: 'Allow Resource Destruction',
                icon: <WarningIcon fontSize="small" />,
                onClick: () => handleMarkToAllowResourceDestruction(),
                align: 'right',
                disabled: !!serviceData?.isResourceDestructionAllowed,
                tooltip: serviceData?.isResourceDestructionAllowed
                    ? 'Resource destruction is already allowed for next deployment'
                    : undefined,
            });
        }

        toolbarButtonSettings.push({
            text: 'Deploy',
            icon: <RocketLaunch fontSize="small" />,
            onClick: () => handleRequestDeployService(),
            disabled:
                !serviceData?.hasChangesToDeploy ||
                serviceData?.hasPendingDeployment ||
                deployRequestInProgress ||
                getServiceState(serviceData).isTransient,
            tooltip:
                !serviceData?.hasChangesToDeploy || serviceData?.hasPendingDeployment
                    ? 'No changes to deploy'
                    : 'Deploy pending changes',
            align: 'right',
            primary: true,
        });
    }

    if (serviceData && !serviceData.deploymentState.isDeployEnabled) {
        toolbarButtonSettings.push({
            text: 'Enable Deployment',
            icon: <PowerSettingsNewIcon fontSize="small" />,
            onClick: () => handleEnableService(),
            align: 'right',
        });
    }

    let pollingTimeout: NodeJS.Timeout | undefined;
    const pollUntilTransitioned = () => {
        const pollingInterval = 30000;

        const pollData = async () => {
            const result = await refetchService();
            if (result.error && 'status' in result.error && result.error.status === 404) {
                // Service was removed
                pollingTimeout = undefined;
                invalidateServiceInCache(serviceData!.id);
                enqueueSnackbar('Service Removal Successful');
                changeEntityContext({
                    organizationSlug: entityContext.organizationSlug!,
                    productSlug: serviceData!.productId,
                });
            } else if (result.data && getServiceState(result.data).isTransient) {
                pollingTimeout = setTimeout(pollData, pollingInterval);
            } else {
                pollingTimeout = undefined;
                invalidateServiceInCache(serviceData!.id);
            }
        };

        pollData();
    };

    useEffect(() => {
        if (serviceData) {
            if (getServiceState(serviceData).isTransient) {
                pollUntilTransitioned();
            }
        }

        return () => {
            if (pollingTimeout) {
                clearTimeout(pollingTimeout);
            }
        };
    }, [serviceData]);

    // Card styles
    const StyledCard = styled(Card)(({ theme }) => ({
        '&:hover': {
            backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))',
            boxShadow:
                '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        },
        marginTop: '1rem',
        padding: '1rem',
        flexGrow: 1,
    }));

    const StyledCardContent = styled(CardContent)(({ theme }) => ({
        ':last-child': {
            paddingBottom: '8px',
        },
        padding: '0.5rem 0 0.5rem 0',
    }));

    const primaryWorkload = serviceData?.workloads?.find((x) => x.isPrimary === true);

    const mapSecurityGroupToProductRoles = (azSecurityGroups: { [key: string]: AzSecurityGroup }) => {
        const updatedAzSecurityGroups: AzSecurityGroup[] = [];

        orgMetadata?.productRoles.forEach((role: { businessKey: string | number; name: any }) => {
            if (azSecurityGroups[role.businessKey]) {
                updatedAzSecurityGroups.push({
                    friendlyName: role.name,
                    objectId: azSecurityGroups[role.businessKey].objectId,
                } as AzSecurityGroup);
            }
        });

        return updatedAzSecurityGroups;
    };

    return (
        <Container>
            <Box sx={{ width: '100%' }}>
                <LinearProgress
                    sx={{ backgroundColor: 'transparent', visibility: !isFetching || isLoading ? 'hidden' : undefined }}
                />
            </Box>
            {isLoading && (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            )}
            {!isLoading && serviceData && (
                <>
                    <Stack spacing={2}>
                        <>
                            <InPageToolbar buttons={toolbarButtonSettings} />
                            {/* If description exists, put as a separate card here that spans the full width */}
                            {serviceData.description && (
                                <Paper sx={{ padding: 3, marginBottom: 2 }}>{serviceData.description}</Paper>
                            )}
                            <MetadataDisplay metadataProperties={metadata} />
                        </>
                    </Stack>
                    {/* Confirm Service Removal Dialog */}
                    <ConfirmRemoveDialog
                        resourceName={serviceData.businessKey.toUpperCase()}
                        isOpen={removeServiceDialogIsOpen}
                        closeAlert={() => setRemoveServiceDialogIsOpen(false)}
                        onConfirmRemoval={removeService}
                    />
                    <AlertDialog
                        title="Unable to Remove Service"
                        text={errorResponse!}
                        isOpen={errorDialogIsOpen}
                        closeAlert={() => setErrorDialogIsOpen(false)}
                    />
                    {/* Confirm Add-on Removal Dialog */}
                    <ConfirmRemoveDialog
                        resourceName={addOnToRemove?.friendlyName || ''}
                        isOpen={removeServiceAddOnDialogIsOpen}
                        closeAlert={() => setRemoveServiceAddOnDialogIsOpen(false)}
                        onConfirmRemoval={() => removeAddOn(addOnToRemove)}
                    />
                    {Object.keys(serviceData?.azSecurityGroups || {}).length > 0 && (
                        <Grid container marginTop={2}>
                            <Grid item xs={12}>
                                <Typography>Role Groups</Typography>
                            </Grid>
                            <Grid container alignItems="stretch">
                                {mapSecurityGroupToProductRoles(serviceData?.azSecurityGroups).map((group, index) => (
                                    <Grid key={group.friendlyName} item xs={6} lg={3} display="flex">
                                        <StyledCard
                                            onClick={() => handleManageRoleGroup(group.objectId, group.friendlyName)}
                                            sx={{
                                                marginRight:
                                                    ((index + 1) % 4 && isLgDesktopView) ||
                                                    ((index + 1) % 2 && !isLgDesktopView)
                                                        ? 2
                                                        : 0,
                                                cursor: 'pointer',
                                            }}
                                        >
                                            <CardHeader
                                                titleTypographyProps={{ variant: 'body1' }}
                                                title={group.friendlyName}
                                                sx={{ padding: 0 }}
                                            />
                                        </StyledCard>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
            {primaryWorkload && (
                <Grid container marginTop={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Workload</Typography>
                    </Grid>
                    <Grid container alignItems="stretch">
                        <Grid item xs={12} display="flex">
                            <Card sx={{ marginTop: 2, padding: 2, flexGrow: 1 }}>
                                <CardHeader
                                    title={primaryWorkload.friendlyName}
                                    titleTypographyProps={{ variant: 'h6' }}
                                    action={
                                        /* eslint-disable react/jsx-wrap-multilines */
                                        <Tooltip title="Enabled">
                                            <CheckCircleIcon
                                                color="success"
                                                sx={{ position: 'relative', top: '4px', right: '8px' }}
                                            />
                                        </Tooltip>
                                        /* eslint-enable react/jsx-wrap-multilines */
                                    }
                                    sx={{ padding: 1 }}
                                />
                                <StyledCardContent>
                                    <Chip label="Primary Workload" size="small" sx={{ marginBottom: 1 }} />
                                    <Typography variant="body2">{primaryWorkload.description}</Typography>
                                </StyledCardContent>
                            </Card>
                        </Grid>
                        {serviceAddOns &&
                            serviceAddOns
                                .filter((addOn) => !addOn.hidden || isPlatformManagerMode)
                                .map((addOn, index) => (
                                    <Grid key={addOn.type} item xs={12} md={6} display="flex">
                                        <Card
                                            sx={{
                                                marginTop: 2,
                                                padding: 2,
                                                flexGrow: 1,
                                                display: 'grid',
                                                marginRight: index % 2 ? 0 : 2,
                                            }}
                                        >
                                            <CardHeader
                                                title={addOn.friendlyName}
                                                action={
                                                    addOn.enabled ? (
                                                        <Tooltip title="Enabled">
                                                            <CheckCircleIcon
                                                                color="success"
                                                                sx={{ position: 'relative', top: '4px', right: '8px' }}
                                                            />
                                                        </Tooltip>
                                                    ) : undefined
                                                }
                                                sx={{ padding: 1, color: !addOn.enabled ? mutedColor : undefined }}
                                            />
                                            <StyledCardContent sx={{ color: !addOn.enabled ? mutedColor : undefined }}>
                                                <Chip label={addOn.category} size="small" sx={{ marginBottom: 1 }} />
                                                <Typography variant="body2">{addOn.description}</Typography>
                                            </StyledCardContent>
                                            {getAddOnActions(addOn)}
                                        </Card>
                                    </Grid>
                                ))}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}
