import { Organization } from '../../models/organizations/Organization';
import { OrganizationMetadata } from '../../models/organizations/OrganizationMetadata';
import { GetOrganizationByIdRequest } from '../../models/organizations/requests/GetOrganizationByIdRequest';
import { Result } from '../../models/Result';
import { store } from '../../state/store';
import { apiSlice, RtkTagType } from '../api/apiSlice';

export const organizationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        organizationById: builder.query<Organization, GetOrganizationByIdRequest>({
            query: (request) => `organizations/${request.organizationId}`,
            transformResponse: (responseData: Result<Organization>) => responseData.value,
            providesTags: (result) => (result ? [{ type: RtkTagType.Organizations, id: result.id }] : []),
        }),
        organizationMetadataById: builder.query<OrganizationMetadata, GetOrganizationByIdRequest>({
            query: (request) => `organizations/${request.organizationId}/metadata`,
            transformResponse: (responseData: Result<OrganizationMetadata>) => {
                const res = responseData.value;
                res.productRoles.sort((a, b) => a.name.localeCompare(b.name));
                return res;
            },
            providesTags: (result) => (result ? [{ type: RtkTagType.Organizations, id: result.id }] : []),
        }),
    }),
});

export const invalidateOrganizationInCache = (organizationId: string) => {
    store.dispatch(apiSlice.util.invalidateTags([{ type: RtkTagType.Organizations, id: organizationId }]));
};

export const { useOrganizationByIdQuery, useOrganizationMetadataByIdQuery } = organizationsApiSlice;
