import { ReactElement, useEffect, useRef, useState } from 'react';
import { delay } from 'lodash';
import { Check } from '@mui/icons-material';
import {
    Box,
    IconButton,
    Slide,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepIconProps,
    StepLabel,
    Stepper,
    styled,
    useTheme,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ssBlue } from '../../utils/theme';
import { ConditionallyWrapWithTooltip } from './ConditionallyWrapWithTooltip';

const StyledConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: ssBlue[300],
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: ssBlue[300],
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const StyledStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: ssBlue[300],
    }),
    '& .StyledStepIcon-completedIcon': {
        color: ssBlue[300],
        zIndex: 1,
        fontSize: 18,
    },
    '& .StyledStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function StyledStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
        <StyledStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <Check className="StyledStepIcon-completedIcon" /> : <div className="StyledStepIcon-circle" />}
        </StyledStepIconRoot>
    );
}

type StepDetails = {
    label: string;
    children: ReactElement;
    onPreviousStep?: () => void;
    onNextStep?: () => void;
    nextStepDisabled?: boolean;
};

type Props = {
    steps: ReactElement[];
    activeStepIndex: number;
    onPreviousStep?: () => void;
    onNextStep?: () => void;
    nextStepDisabled?: boolean;
};

export default function StepperJourney({
    steps,
    activeStepIndex,
    onPreviousStep,
    onNextStep,
    nextStepDisabled,
}: Props) {
    const overallViewRef = useRef<HTMLDivElement>(null);
    const theme = useTheme();
    const [delayedActiveStepIndex, setDelayedActiveStepIndex] = useState<number>(activeStepIndex);
    const [outDirection, setOutDirection] = useState<'left' | 'right'>('right');
    const [inDirection, setInDirection] = useState<'left' | 'right'>('left');

    useEffect(() => {
        if (activeStepIndex < delayedActiveStepIndex) {
            setOutDirection('left');
            setInDirection('right');
        } else {
            setOutDirection('right');
            setInDirection('left');
        }
        delay(() => {
            setDelayedActiveStepIndex(activeStepIndex);
        }, theme.transitions.duration.standard);
    }, [activeStepIndex]);

    return (
        <Box height="100%" display="flex" flexDirection="column" ref={overallViewRef}>
            <Box display="flex" flexDirection="row" flexGrow={1} alignItems="center">
                {activeStepIndex !== 0 && (steps[activeStepIndex]?.props.onPreviousStep || onPreviousStep) ? (
                    <IconButton
                        onClick={steps[activeStepIndex]?.props.onPreviousStep || onPreviousStep}
                        sx={{ padding: 0 }}
                    >
                        <NavigateBeforeIcon sx={{ height: 50, width: 50 }} />
                    </IconButton>
                ) : (
                    <Box sx={{ width: 50 }} />
                )}
                <Box sx={{ overflow: 'hidden' }} flexGrow={1} flexBasis={1}>
                    {steps.map((step, index) => (
                        <Slide
                            key={index}
                            in={index === delayedActiveStepIndex && index === activeStepIndex}
                            direction={
                                index === delayedActiveStepIndex && index === activeStepIndex
                                    ? inDirection
                                    : outDirection
                            }
                            container={overallViewRef.current}
                            easing={theme.transitions.easing.easeInOut}
                            timeout={theme.transitions.duration.standard}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Box height="100%" display="flex" flexDirection="column" justifyContent="center">
                                {step}
                            </Box>
                        </Slide>
                    ))}
                </Box>
                {activeStepIndex < steps.length - 1 && (steps[activeStepIndex]?.props.onNextStep || onNextStep) ? (
                    <ConditionallyWrapWithTooltip
                        text={
                            steps[activeStepIndex]?.props.nextStepDisabled || nextStepDisabled
                                ? 'Complete the current step to proceed'
                                : undefined
                        }
                    >
                        <IconButton
                            onClick={steps[activeStepIndex]?.props.onNextStep || onNextStep}
                            sx={{ padding: 0 }}
                            disabled={steps[activeStepIndex]?.props.nextStepDisabled || nextStepDisabled}
                        >
                            <NavigateNextIcon sx={{ height: 50, width: 50 }} />
                        </IconButton>
                    </ConditionallyWrapWithTooltip>
                ) : (
                    <Box sx={{ width: 50 }} />
                )}
            </Box>
            <Stepper
                alternativeLabel
                activeStep={activeStepIndex}
                connector={<StyledConnector />}
                sx={{ marginTop: 2 }}
            >
                {steps.map((step, index) => (
                    <Step key={step.props.label}>
                        {/* <StepButton color="inherit">{step.label}</StepButton> */}
                        <StepLabel StepIconComponent={StyledStepIcon}>{step.props.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
