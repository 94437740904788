import { Box, Container } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { OdsDataset } from '../../../../data/OdsDataset';
import bh_ods_courtapi from '../../../../data/bh_ods_courtapi.json';
import { useLogging } from '../../../../providers/LoggingProvider';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
        field: 'sourceName',
        headerName: 'SourceName',
        width: 120,
        editable: true,
    },
    {
        field: 'datasetName',
        headerName: 'Dataset Name',
        width: 150,
        editable: true,
    },
    {
        field: 'fieldName',
        headerName: 'Field Name',
        width: 150,
        editable: true,
    },
    {
        field: 'fieldType',
        headerName: 'Field Type',
        editable: true,
    },
    {
        field: 'sourceFieldPath',
        headerName: 'Source Field Path',
        width: 300,
        editable: true,
    },
    {
        field: 'annotations',
        headerName: 'Annotations',
        width: 200,
        editable: true,
    },
];

let i = 0;
const rows = bh_ods_courtapi
    .map((dataset, index) => {
        return dataset.fields.map((field, fIndex) => {
            i++;
            return {
                id: i,
                sourceName: dataset.source_name,
                datasetName: dataset.dataset_name,
                fieldName: field.name,
                fieldType: field.type,
                sourceFieldPath: field.source.path,
                annotations: field.annotations?.join(', '),
            };
        });
    })
    .flat(1);

export function DesignProductInformation() {
    const { trackTrace } = useLogging();
    // useEffect(() => {}, []);

    trackTrace(JSON.stringify(rows));

    return (
        <DataGrid
            // autoPageSize
            pageSize={100}
            rowsPerPageOptions={[]}
            rows={rows}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            rowSpacingType="border"
        />
    );
}
