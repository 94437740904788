import { useAccount } from '@azure/msal-react';
import { Container, Stack, Typography } from '@mui/material';
import { UserAccount } from '../../utils/types/User';

export function Account() {
    const account = useAccount();

    return (
        <Container maxWidth="md">
            <Stack spacing={2}>
                <Typography variant="h4">Profile</Typography>
                <Typography variant="body1">
                    <strong>User ID: </strong>
                    {(account?.idTokenClaims as UserAccount).sub}
                </Typography>
                <Typography variant="body1">
                    <strong>Name: </strong>
                    {(account?.idTokenClaims as UserAccount).name}
                </Typography>
                <Typography variant="body1">
                    <strong>Email: </strong>
                    {(account?.idTokenClaims as UserAccount).email}
                </Typography>
                <Typography variant="body1">
                    <strong>
                        Home Tenant
                        {(account?.idTokenClaims as UserAccount).idp?.includes('login.microsoftonline.com')
                            ? ' - Azure AD Work Account'
                            : null}
                        :{' '}
                    </strong>
                    {(account?.idTokenClaims as UserAccount).tid}
                </Typography>
            </Stack>
        </Container>
    );
}
