import { Container, Grid, LinearProgress, Typography } from '@mui/material';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { MetadataDisplay } from '../../../components/elements/MetadataDisplay';
import { CreateProductRequest } from '../../../models/products/CreateProductRequest';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useOrganizationMetadataByIdQuery } from '../../organizations/organizationsApiSlice';

type Props = {
    createProductRequest: Partial<CreateProductRequest>;
    rolesToEnable: string[];
    onFinish: () => void;
    isSubmitting: boolean;
};

export default function NewProductStepFinish({ createProductRequest, rolesToEnable, onFinish, isSubmitting }: Props) {
    const { entityContext } = useEntityContext();
    const { data: organization } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    return (
        <Container maxWidth="md">
            <Typography variant="h3" align="center" gutterBottom>
                Finalize your Product
            </Typography>
            <Typography variant="h5" gutterBottom>
                Product Summary
            </Typography>
            <MetadataDisplay
                metadataProperties={[
                    { label: 'Name', value: createProductRequest.name },
                    { label: 'Short Name', value: createProductRequest.shortName || createProductRequest.businessKey },
                    { label: 'Business Key', value: createProductRequest.businessKey },
                    {
                        label: 'Business Unit',
                        value: organization?.businessUnits?.find(
                            (bu) => bu.businessUnitBusinessKey === createProductRequest.businessUnitBusinessKey,
                        )?.name,
                    },
                    {
                        label: 'Criticality Tier',
                        value: organization?.criticalityTiers?.find(
                            (ct) => ct.id === createProductRequest.criticalityTier,
                        )?.name,
                    },
                    {
                        label: 'Product Roles',
                        value: rolesToEnable
                            .map((roleId) => organization?.productRoles?.find((r) => r.id === roleId)?.name)
                            .join('\n'),
                    },
                ]}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubmitButton
                        variant="contained"
                        color="primary"
                        onClick={() => onFinish()}
                        disableSubmit
                        sx={{ height: '5rem' }}
                        disabled={isSubmitting}
                    >
                        Create Product
                    </SubmitButton>
                </Grid>
                <Grid item xs={12} visibility={isSubmitting ? 'initial' : 'hidden'}>
                    <LinearProgress />
                </Grid>
            </Grid>
        </Container>
    );
}
