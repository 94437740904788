import { apiSlice, RtkTagType } from '../../api/apiSlice';
import { Service } from '../../../models/services/Service';
import { RefreshServiceLinkMetadataRequest } from '../../../models/services/requests/RefreshServiceLinkMetadataRequest';
import { ServicesByProductIdRequest } from '../../../models/services/requests/ServicesByProductIdRequest';
import { Result } from '../../../models/Result';
import { GetServiceByIdRequest } from '../../../models/services/requests/GetServiceByIdRequest';
import { EnableServiceRequest } from '../../../models/services/requests/EnableServiceRequest';
import { RequestDeployServiceRequest } from '../../../models/services/requests/RequestDeployServiceRequest';
import { RefreshServiceResourceCacheRequest } from '../../../models/services/requests/RefreshServiceResourceCacheRequest';
import { MarkServiceToAllowResourceDestruction } from '../../../models/services/requests/MarkServiceToAllowResourceDestruction';
import { MarkServiceForRemovalRequest } from '../../../models/services/requests/MarkServiceForRemovalRequest';
import { CreateServiceRequest } from '../../../models/services/requests/CreateServiceRequest';
import { ServiceHierarchy } from '../../../models/services/ServiceHierarchy';
import { GetServiceHierarchyByIdRequest } from '../../../models/services/requests/GetServiceHierarchyByIdRequest';
import { ServiceListByOrgIdRequest } from '../../../models/ports/requests/PortListByOrgIdRequest';
import { RevertMarkServiceForRemovalRequest } from '../../../models/services/requests/RevertMarkServiceForRemovalRequest';
import { store } from '../../../state/store';
import { ResetServiceDeploymentStatusRequest } from '../../../models/services/requests/ResetServiceDeploymentStatusRequest';

export const servicesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        servicesByProductId: builder.query<Service[], ServicesByProductIdRequest>({
            query: (request) => `/organizations/${request.organizationId}/products/${request.productId}/services`,
            transformResponse: (responseData: Result<Service[]>) =>
                responseData.value.sort((a: Service, b: Service) => {
                    if (
                        (a.businessKey === 'mgmt' || a.businessKey === 'gov') &&
                        b.businessKey !== 'mgmt' &&
                        b.businessKey !== 'gov'
                    ) {
                        return -1;
                    }

                    if (
                        a.businessKey !== 'mgmt' &&
                        a.businessKey !== 'gov' &&
                        (b.businessKey === 'mgmt' || b.businessKey === 'gov')
                    ) {
                        return 1;
                    }

                    if (a.isShared && !b.isShared) {
                        return 1;
                    }

                    if (!a.isShared && b.isShared) {
                        return -1;
                    }

                    return a.name.localeCompare(b.name);
                }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: RtkTagType.Services, id })),
                          { type: RtkTagType.Services, id: 'LIST' },
                      ]
                    : [{ type: RtkTagType.Services, id: 'LIST' }],
        }),
        servicesByOrgId: builder.query<Service[], ServiceListByOrgIdRequest>({
            query: (request) => `/organizations/${request.organizationId}/products/*/services/`,
            transformResponse: (responseData: Result<Service[]>) =>
                responseData.value.sort((a: Service, b: Service) => {
                    if (
                        (a.businessKey === 'mgmt' || a.businessKey === 'gov') &&
                        b.businessKey !== 'mgmt' &&
                        b.businessKey !== 'gov'
                    ) {
                        return -1;
                    }

                    if (
                        a.businessKey !== 'mgmt' &&
                        a.businessKey !== 'gov' &&
                        (b.businessKey === 'mgmt' || b.businessKey === 'gov')
                    ) {
                        return 1;
                    }

                    if (a.isShared && !b.isShared) {
                        return 1;
                    }

                    if (!a.isShared && b.isShared) {
                        return -1;
                    }

                    return a.name.localeCompare(b.name);
                }),
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: RtkTagType.Services, id })),
                          { type: RtkTagType.Services, id: 'LIST' },
                      ]
                    : [{ type: RtkTagType.Services, id: 'LIST' }],
        }),
        serviceNew: builder.mutation<Service, CreateServiceRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<Service>) => responseData.value,
            invalidatesTags: () => [{ type: RtkTagType.Services, id: 'LIST' }],
        }),
        serviceById: builder.query<Service, GetServiceByIdRequest>({
            query: (request) =>
                `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}${
                    request.allEnvironmentInstances ? '?allEnvironmentInstances=true' : ''
                }`,
            transformResponse: (responseData: Result<Service>) => responseData.value,
            providesTags: (result) => (result ? [{ type: RtkTagType.Services, id: result.id }] : []),
        }),
        serviceHierarchyById: builder.query<ServiceHierarchy, GetServiceHierarchyByIdRequest>({
            query: (request) =>
                `/organizations/${request.organizationId}/products/${request.productId}/services/hierarchy/${request.serviceId}`,
            transformResponse: (responseData: Result<ServiceHierarchy>) => responseData.value,
            providesTags: (result, error, request) =>
                result ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnable: builder.mutation<Service, EnableServiceRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/enable`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceRequestDeploy: builder.mutation<Service, RequestDeployServiceRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/deploy`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceReloadResourceCache: builder.mutation<Service, RefreshServiceResourceCacheRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/refresh-resource-cache`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceMarkAllowDestruction: builder.mutation<Service, MarkServiceToAllowResourceDestruction>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/allow-resource-destruction`,
                method: 'PUT',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceMarkForRemoval: builder.mutation<Service, MarkServiceForRemovalRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, request) => {
                if (request?.isEnabledForDeployment) {
                    console.log('enabled for deploy');
                    return [{ type: RtkTagType.Services, id: request.serviceId }];
                }

                if (request) {
                    console.log('not enabled for deploy');
                    return [{ type: RtkTagType.Services, id: 'LIST' }];
                }

                return [];
            },
        }),
        serviceRevertMarkForRemoval: builder.mutation<Service, RevertMarkServiceForRemovalRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/revert-mark-for-removal`,
                method: 'PUT',
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceReloadLinkMetadata: builder.mutation<Service, RefreshServiceLinkMetadataRequest>({
            query: (request) => ({
                url: `organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/refresh-link-metadata`,
                method: 'PUT',
                body: request,
            }),
        }),
        resetDeploymentStatus: builder.mutation<Service, ResetServiceDeploymentStatusRequest>({
            query: (request) => ({
                url: `organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/reset-deployment-status`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
    }),
});

export const invalidateServiceInCache = (serviceId: string) => {
    store.dispatch(apiSlice.util.invalidateTags([{ type: RtkTagType.Services, id: serviceId }]));
};

export const {
    useServicesByProductIdQuery,
    useServicesByOrgIdQuery,
    useServiceNewMutation,
    useServiceByIdQuery,
    useServiceHierarchyByIdQuery,
    useServiceEnableMutation,
    useServiceReloadResourceCacheMutation,
    useServiceRequestDeployMutation,
    useServiceMarkAllowDestructionMutation,
    useServiceMarkForRemovalMutation,
    useServiceRevertMarkForRemovalMutation,
    useServiceReloadLinkMetadataMutation,
    useResetDeploymentStatusMutation,
} = servicesApiSlice;
