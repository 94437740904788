import { BaseEntity } from '../BaseEntity';

export interface Role extends BaseEntity {
    name: string;
    description: string;
    type: string;
    assignableScopes: string[];
    actions: Action[];
    defaultOrgRole?: boolean;
}

export interface Action {
    key: string;
    name: string;
    description: string;
    serviceName: string;
    entityName: string;
    actionName: string;
    actionType: string;
}

export class RoleUtility {
    /**
     * Sorts a list of roles to include default org roles at the top of the list,
     * followed by the remaining roles sorted alphabetically
     * @param roles The list of roles to sort
     * @returns The sorted list of roles
     */
    public static copyAndSort(roles: Role[]): Role[] {
        if (!roles || !roles.length) {
            return roles;
        }

        return roles.slice(0).sort((r1, r2) => {
            if (r1.defaultOrgRole === true) {
                return -1;
            }
            if (r2.defaultOrgRole === true) {
                return 1;
            }
            if (r1.name < r2.name) {
                return -1;
            }
            if (r1.name > r2.name) {
                return 1;
            }

            return 0;
        });
    }
}
