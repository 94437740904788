import { Container, Switch } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import CardSelection from '../../../components/elements/CardSelection';
import { ConditionallyWrapWithTooltip } from '../../../components/elements/ConditionallyWrapWithTooltip';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useOrganizationMetadataByIdQuery } from '../../organizations/organizationsApiSlice';

type Props = {
    rolesToEnable: string[];
    setRolesToEnable: Dispatch<SetStateAction<string[]>>;
};

export default function NewProductStepRoles({ rolesToEnable, setRolesToEnable }: Props) {
    const { entityContext } = useEntityContext();
    const { data: organization } = useOrganizationMetadataByIdQuery({
        organizationId: entityContext.organizationSlug!,
    });

    const handleToggleRole = (roleId: string) => {
        const roleFound = rolesToEnable.find((a) => a === roleId);

        if (roleFound) {
            setRolesToEnable((prev) => prev.filter((a) => a !== roleId));
        } else {
            setRolesToEnable((prev) => [...prev, ...(!prev.includes(roleId) ? [roleId] : [])]);
        }
    };

    useEffect(() => {
        organization?.productRoles
            ?.filter((role) => role.managed)
            ?.forEach((role) => {
                setRolesToEnable((prev) => [...prev, ...(!prev.includes(role.id) ? [role.id] : [])]);
            });
    }, [organization]);

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardSelection
                centerHorizontally
                title="What roles would you like to start with?"
                cards={
                    organization?.productRoles?.map((r) => ({
                        subTitle: r.name,
                        action: (
                            <ConditionallyWrapWithTooltip text={r.managed ? 'This is a required role' : undefined}>
                                <Switch
                                    onChange={() => handleToggleRole(r.id)}
                                    checked={rolesToEnable.includes(r.id)}
                                    disabled={r.managed}
                                />
                            </ConditionallyWrapWithTooltip>
                        ),
                    })) || []
                }
            />
        </Container>
    );
}
