import { Formik, Form, useFormikContext, FormikHelpers, FormikValues, FormikProps } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography } from '@mui/material';
import { CreateServiceRequest } from '../../../../models/services/requests/CreateServiceRequest';
import { useBackendService } from '../../../../providers/BackendServiceProvider';
import { TextField } from '../../../../components/elements/input/TextField';
import { Checkbox } from '../../../../components/elements/input/Checkbox';
import { Select } from '../../../../components/elements/input/Select';
import { WORKLOAD_TYPES } from '../../../../models/services/WorkloadTypes';
import { useLogging } from '../../../../providers/LoggingProvider';
import { stringSequentialValidation } from '../../../../utils/validation/StringSequentialValidation';
import { DrawerFooterAction, useLayout } from '../../../../providers/LayoutProvider';
import { useEntityContext } from '../../../../providers/EntityContextProvider';
import { useServiceNewMutation, useServiceByIdQuery } from '../../../../features/services/services/servicesApiSlice';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

type Props = {
    organizationId: string;
    productId: string;
    existingServiceId?: string;
    actionCompleteCallback?: (serviceId: string) => void;
};

export function ManageService({ organizationId, productId, existingServiceId, actionCompleteCallback }: Props) {
    const INITIAL_FORM_STATE: Partial<CreateServiceRequest> = {
        name: '',
        businessKey: '',
        workloadType: '',
        isLimitedToProdNonProd: false,
        shortName: '',
    };

    const { validateServiceBusinessKeyIsUnique, validateServiceShortNameIsUnique } = useBackendService();
    const { entityContext } = useEntityContext();
    const { data: serviceData } = useServiceByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: existingServiceId!,
        },
        { skip: !existingServiceId },
    );
    const [newService] = useServiceNewMutation();
    const { trackTrace, trackException } = useLogging();
    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_STATE);
    const { enqueueSnackbar } = useSnackbar();
    const { isPlatformManagerMode, setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    // TODO: breakout axiosInstance / API client outside of backend provider so it can be used outside the context of a component
    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        businessKey: stringSequentialValidation([
            Yup.string().required('Service Business Key is required'),
            Yup.string().min(2, 'Service Business Key must be at least 2 characters'),
            Yup.string().max(4, 'Service Business Key must be 4 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Service Business Key can only contain lowercase letters or numbers'),
            Yup.string().test(
                'is-unique',
                'Service Business Key must be unique for this Product',
                async (value: string | undefined) => {
                    if (existingServiceId) {
                        // entity already exists, and this value is readonly. do not run this validation check
                        return true;
                    }

                    if (!value) {
                        return false;
                    }

                    const result = await validateServiceBusinessKeyIsUnique(
                        {
                            organizationId,
                            productId,
                            businessKey: value,
                        },
                        true,
                    );

                    return result;
                },
            ),
        ]),
        workloadType: Yup.string().required('Workload Type is required'),
        isLimitedToProdNonProd: Yup.boolean(),
        description: Yup.string().max(500, 'Service Description must be 500 characters or less'),
        shortName: stringSequentialValidation([
            Yup.string().min(2, 'Service Short Name must be at least 2 characters'),
            Yup.string().max(30, 'Service Short Name must be 30 characters or less'),
            Yup.string().matches(/^[a-z0-9]*$/, 'Service Short Name can only contain lowercase letters or numbers'),
            Yup.string().test(
                'is-unique',
                'Service Short Name must be unique for this Product',
                async (value: string | undefined) => {
                    if (existingServiceId) {
                        // entity already exists, and this value is readonly. do not run this validation check
                        return true;
                    }

                    if (!value) {
                        // blank value is allowed as this is not a required field
                        return true;
                    }

                    const result = await validateServiceShortNameIsUnique(
                        {
                            organizationId,
                            productId,
                            shortName: value,
                        },
                        true,
                    );

                    return result;
                },
            ),
        ]),
    });

    useEffect(() => {
        if (serviceData) {
            setInitialFormState({
                name: serviceData.name,
                businessKey: serviceData.businessKey,
                workloadType: serviceData.workloads.find((w) => w.isPrimary)?.type || '',
                isShared: serviceData.isShared,
                isLimitedToProdNonProd: serviceData.isLimitedToProdNonProd,
                shortName: serviceData.shortName,
                description: serviceData.description,
            });
        }

        const actions: DrawerFooterAction[] = [
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionDisabled: formRef.current?.isSubmitting,
            },
        ];

        setRightContextDrawerActions(actions);
    }, [serviceData]);

    const mapToWorkloadTypeObject = (type: string) => {
        const workload = WORKLOAD_TYPES.find((x) => x.value === type);

        if (!workload) {
            trackException('Cannot find requested workload type');
            throw new Error('Cannot find requested workload type');
        }

        return workload;
    };

    const handleSubmit = async (values: FormikValues, formik: FormikHelpers<FormikValues>) => {
        let resId: string;

        const workloadType = mapToWorkloadTypeObject(values.workloadType);

        if (existingServiceId) {
            formik.setSubmitting(false);
            trackException('Updating existing services not supported');
            throw Error('Updating existing services not supported');
        } else {
            // Create new service
            trackTrace('Creating new service..');

            const serviceRes = await newService({
                organizationId,
                productId,
                ...values,
                // if short name is omitted, use the business key
                shortName: values.shortName ? values.shortName : values.businessKey,
                isShared: workloadType.isShared,
                skipEnableForDeploy: workloadType.skipEnableForDeploy,
                isLimitedToProdNonProd: workloadType.nonProdProdOnly ? true : values.isLimitedToProdNonProd,
            } as CreateServiceRequest).unwrap();
            formik.setSubmitting(false);
            if (serviceRes && serviceRes.id) {
                resId = serviceRes.id;
                enqueueSnackbar('Service created');
                if (actionCompleteCallback) {
                    actionCompleteCallback(resId);
                }
            }
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={initialFormState}
                        enableReinitialize
                        validationSchema={FORM_VALIDATION}
                        validateOnChange={false}
                        validateOnBlur={false}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting, values, validateForm }) => {
                            // debounce the validation of the entire form and execute any time the values change
                            const debouncedValidate = useMemo(() => debounce(validateForm, 400), [validateForm]);
                            useEffect(() => {
                                debouncedValidate(values);
                            }, [values, debouncedValidate]);

                            return (
                                <Form autoComplete="off">
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="name"
                                                label="Name"
                                                required
                                                autoFocus={!existingServiceId ? true : undefined}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="shortName" label="Short Name" />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField name="businessKey" label="Service Business Key" required />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="description"
                                                label="Service Description"
                                                multiline
                                                rows="3"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Select
                                                name="workloadType"
                                                label="Workload Type"
                                                options={WORKLOAD_TYPES.filter(
                                                    (wt) =>
                                                        !wt.platformMgrOnly ||
                                                        (wt.platformMgrOnly && isPlatformManagerMode),
                                                )}
                                                onChange={(e) => {
                                                    // clear the checkbox to ensure nonshared services are not intentionally set to shared
                                                    const { setFieldValue } = useFormikContext();
                                                    setFieldValue('isLimitedToProdNonProd', false);
                                                }}
                                                required
                                            />
                                        </Grid>
                                        {values.workloadType !== '' && (
                                            <Grid
                                                item
                                                xs={12}
                                                display={
                                                    mapToWorkloadTypeObject(values.workloadType).isShared
                                                        ? 'block'
                                                        : 'none'
                                                }
                                            >
                                                <Checkbox
                                                    name="isLimitedToProdNonProd"
                                                    label="Nonprod/Prod Only"
                                                    legend=""
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Form>
                            );
                        }}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
