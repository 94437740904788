import { Box, styled, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { BasicTopAppBar } from './BasicTopAppBar';

const Main = styled('main')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'auto',
}));

type Props = {
    children?: React.ReactNode;
};

export function BasicLayout({ children }: Props) {
    return (
        <>
            <BasicTopAppBar />
            <Main>
                <Toolbar />
                <Box
                    sx={{
                        width: '100%',
                        flexGrow: 1,
                        display: 'flex',
                    }}
                >
                    <Outlet />
                    {children}
                </Box>
            </Main>
        </>
    );
}
