import { BaseEntity } from 'models/BaseEntity';
import { LinkEnvironmentInstance } from 'models/links/LinkEnvironmentInstance';

export interface Link extends BaseEntity {
    friendlyName: string;
    nounPlural: string;
    description: string;
    concreteType: string;
    organizationId: string;
    organizationBusinessKey: string;
    productBusinessKey: string;
    serviceBusinessKey: string;
    environmentInstances: LinkEnvironmentInstance[];
    requestingProductId: string;
    requestingServiceId: string;
    requestingWorkloadId: string;
    requestingPort: string;
    requestedProductId: string;
    requestedServiceId: string;
    requestedWorkloadId: string;
    requestedPort: string;
    isMarkedForRemoval: boolean;
    removedOn?: Date;
}

export const getLinkState = (
    link: Link,
): {
    text: string;
    description: string;
    color: 'warning' | 'success' | 'default' | 'primary' | 'info' | 'error' | 'secondary';
    isTransient?: boolean;
} => {
    if (link.isMarkedForRemoval) {
        return {
            text: 'Removing',
            description: 'This link is in the process of being deprovisioned.',
            color: 'error',
            isTransient: true,
        };
    }

    // TODO: When we start tracking link changes, we can add this logic.
    // if (link.hasPendingDeployment) {
    //     return {
    //         text: 'Deployment In Progress',
    //         description: 'This link has a deployment in progress.',
    //         color: 'primary',
    //         isTransient: true,
    //     };
    // }

    // if (link.hasChangesToDeploy) {
    //     return {
    //         text: 'Deployment Available',
    //         description: 'There are changes to this link available for deployment.',
    //         color: 'primary',
    //     };
    // }

    return {
        text: 'Up-To-Date',
        description: 'This link is provisioned and up-to-date.',
        color: 'default',
    };
};
