import { Role } from '../models/users/Role';
import { RoleAssignmentDetails } from '../models/users/User';

const getAssignedRoleLabels = (roleAssignments: RoleAssignmentDetails[], roles: Role[]): Role[] => {
    const assignedRoles: Role[] = [];
    roleAssignments?.forEach((roleAssignment) => {
        const role = roles.find((r) => r.id === roleAssignment.roleId);
        if (role) {
            assignedRoles.push(role);
        }
    });

    return assignedRoles;
};

export { getAssignedRoleLabels };
