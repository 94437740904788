import { CacheLookupPolicy, LogLevel } from '@azure/msal-browser';
import { appConfig } from '../config';
import { getEntityContext } from '../providers/EntityContextProvider';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: appConfig.auth.portalClientId!,
        authority: `https://${appConfig.auth.tenantName!}.b2clogin.com/${appConfig.auth.tenantId!}/${appConfig.auth
            .policyName!}`,
        knownAuthorities: [`${appConfig.auth.tenantName!}.b2clogin.com`, window.location.hostname],
        redirectUri: `${window.location.protocol}//${window.location.host}`,
        postLogoutRedirectUri: 'https://www.scaledsense.com',
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii && appConfig.environment.isProduction) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message);
                }
            },
            piiLoggingEnabled: !appConfig.environment.isProduction,
        },
    },
};

const currentEntityContext = getEntityContext();

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: appConfig.auth.resourceScopes || [],
    // For testing: Uncomment below line to force a request to Azure AD B2C to get a new token
    // additionally you can delete the b2clogin.com cookie to force a redirect to b2c
    // cacheLookupPolicy: CacheLookupPolicy.Skip,
    state: currentEntityContext.organizationSlug,
};
