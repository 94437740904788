import {
    Container,
    Grid,
    Paper,
    Select as MuiSelect,
    Stack,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    CircularProgress,
    Box,
    LinearProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLayout } from '../../../../providers/LayoutProvider';
import { Service } from '../../../../models/services/Service';
import { PropertyDefinition, PropertyScopeType } from '../../../../models/services/PropertyDefinition';
import { ManageProperty } from './ManageProperty';
import { PropertyValue } from '../../../../models/services/Property';
import { PropertyDisplay } from './PropertyDisplay';
import { PropertyHierarchy } from '../../../../models/services/PropertyHierarchy';
import { mutedColor } from '../../../../utils/theme';
import { useEntityContext } from '../../../../providers/EntityContextProvider';
import { useServiceHierarchyByIdQuery } from '../../../../features/services/services/servicesApiSlice';
import { useServicePropertyHierarchyByIdQuery } from '../../../../features/services/properties/propertiesApiSlice';

interface PropertyBundle {
    propertyDefinition?: PropertyDefinition;
    scopeType: PropertyScopeType;
    propertiesWithKey: { propertyScopeFilter: string; propertyValue: PropertyHierarchy }[];
}

const groupPropertiesByScope = (
    propertyHierarchies: { [envKey: string]: PropertyHierarchy[] },
    service: Service,
    serviceHierarchy: Service[],
) => {
    const bundles: { [businessKey: string]: PropertyBundle } = {};

    Object.keys(propertyHierarchies).forEach((envKey) => {
        propertyHierarchies[envKey].forEach((propertyHierarchy) => {
            const isDefinedForService = !!propertyHierarchy.serviceProperty;
            let effectiveScopeType: PropertyScopeType | undefined;
            let effectiveService: Service | undefined;
            if (isDefinedForService) {
                effectiveScopeType = propertyHierarchy.serviceProperty?.assignedScope.scopeType;
                effectiveService = service;
            } else if (propertyHierarchy.productMgmtProperty) {
                effectiveScopeType = propertyHierarchy.productMgmtProperty.assignedScope.scopeType;
                effectiveService = serviceHierarchy.find((s) => s.businessKey === 'mgmt');
            } else {
                effectiveScopeType = propertyHierarchy.orgGovProperty?.assignedScope.scopeType;
                effectiveService = serviceHierarchy.find((s) => s.businessKey === 'gov');
            }

            if (!effectiveScopeType || !effectiveService) {
                console.error(
                    `Property ${propertyHierarchy.businessKey} is available on the Workload definition, but has not been added to the workload instance. This can be resolved by refreshing the service's cache`,
                );
                return;
            }

            const envInstance = service.environmentInstances.find((e) => e.businessKey === envKey);
            if (!envInstance) {
                console.error(
                    'Property is from an environment instance not available on the service. Contact support to troubleshoot further.',
                );
                return;
            }

            let propertyScopeFilter: string;
            switch (effectiveScopeType) {
                case PropertyScopeType.environmentInstance:
                    propertyScopeFilter = envInstance.id;
                    break;
                case PropertyScopeType.environmentType:
                    propertyScopeFilter = envInstance.environmentType.name;
                    break;
                case PropertyScopeType.productionTier:
                    propertyScopeFilter = envInstance.environmentType.isProductionEnvironment
                        ? 'production'
                        : 'nonproduction';
                    break;
                case PropertyScopeType.service:
                default:
                    propertyScopeFilter = PropertyScopeType.service;
                    break;
            }

            const propertyBundle = bundles[propertyHierarchy.businessKey] || {
                scopeType: effectiveScopeType,
            };
            if (!bundles[propertyHierarchy.businessKey]) {
                bundles[propertyHierarchy.businessKey] = propertyBundle;
            }

            if (!propertyBundle.propertyDefinition) {
                const propertyDefinition = effectiveService.propertyDefinitions.find(
                    (p) => p.controlMetadata.businessKey === propertyHierarchy.businessKey,
                );
                propertyBundle.propertyDefinition = propertyDefinition;
            }

            propertyBundle.propertiesWithKey = propertyBundle.propertiesWithKey || [];
            propertyBundle.propertiesWithKey.push({
                propertyScopeFilter,
                propertyValue: propertyHierarchy,
            });
        });
    });

    return Object.keys(bundles).reduce((bundlesByScopeType: { [scopeType: string]: PropertyBundle[] }, businessKey) => {
        const bundle = bundles[businessKey];
        /* eslint-disable no-param-reassign */
        bundlesByScopeType[bundle.scopeType] = bundlesByScopeType[bundle.scopeType] || [];
        /* eslint-enable no-param-reassign */
        bundlesByScopeType[bundle.scopeType].push(bundle);

        return bundlesByScopeType;
    }, {});
};

export function DesignServiceProperties() {
    const [selectedEnvironmentInstance, setSelectedEnvironmentInstance] = useState<string>('');
    const [selectedProductionTier, setSelectedProductionTier] = useState<string>('');
    const [selectedEnvironmentType, setSelectedEnvironmentType] = useState<string>('');

    const { entityContext } = useEntityContext();
    const {
        data: serviceHierarchyData,
        isFetching,
        isLoading,
    } = useServiceHierarchyByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );
    const {
        data: propertyHierarchyData,
        isFetching: isFetchingProperties,
        isLoading: isLoadingProperties,
        refetch: refetchPropertyHierarchy,
    } = useServicePropertyHierarchyByIdQuery(
        {
            organizationId: entityContext.organizationSlug!,
            productId: entityContext.productSlug!,
            serviceId: entityContext.serviceSlug!,
        },
        { skip: !entityContext.serviceSlug },
    );
    const {
        setAndOpenRightContextDrawer,
        closeAndClearRightContextDrawer,
        rightContextDrawerState,
        isPlatformManagerMode,
    } = useLayout();
    const { enqueueSnackbar } = useSnackbar();

    const serviceData = serviceHierarchyData?.service;
    const inheritedServiceData = [
        serviceHierarchyData?.orgGovService!,
        serviceHierarchyData?.productMgmtService!,
    ].filter((s) => !!s);

    useEffect(() => {
        return () => {
            closeAndClearRightContextDrawer();
        };
    }, []);

    const onPropertyUpdated = (propertyControl: PropertyDefinition, value: PropertyValue) => {
        refetchPropertyHierarchy();
        closeAndClearRightContextDrawer();
    };

    const editProperty = (
        propertyDefinition: PropertyDefinition,
        propertyValue?: PropertyHierarchy,
        viewOnly?: boolean,
    ) => {
        const propertyScopeType = propertyDefinition?.propertyScope.scopeType;
        let propertyScopeFilter: string;
        switch (propertyScopeType) {
            case PropertyScopeType.environmentInstance:
                propertyScopeFilter = selectedEnvironmentInstance;
                break;
            case PropertyScopeType.environmentType:
                propertyScopeFilter = selectedEnvironmentType;
                break;
            case PropertyScopeType.productionTier:
                propertyScopeFilter = selectedProductionTier;
                break;
            case PropertyScopeType.service:
            default:
                propertyScopeFilter = PropertyScopeType.service;
                break;
        }

        if (!propertyScopeFilter) {
            enqueueSnackbar('To edit this property, you must select a property context value first', {
                variant: 'info',
                style: { whiteSpace: 'pre-line' },
            });
            return;
        }

        setAndOpenRightContextDrawer(
            <ManageProperty
                organizationId={entityContext.organizationSlug!}
                productId={entityContext.productSlug!}
                serviceId={entityContext.serviceSlug!}
                propertyDefinition={propertyDefinition}
                propertyHierarchy={propertyValue}
                serviceHierarchy={inheritedServiceData}
                propertyScopeType={propertyScopeType}
                propertyScopeFilter={propertyScopeFilter}
                actionCompleteCallback={onPropertyUpdated}
                viewOnly={!!viewOnly}
            />,
            `${!viewOnly ? 'Update ' : ''} ${propertyDefinition.displayName}`,
        );
    };

    const propertiesGroupedByScope =
        serviceData && propertyHierarchyData
            ? groupPropertiesByScope(propertyHierarchyData, serviceData, inheritedServiceData)
            : undefined;

    const getPropertyScopeSectionConfig = (
        scopeType: PropertyScopeType,
    ): {
        sectionTitle: string;
        selectorLabel: string;
        getMenuItems: () => JSX.Element[];
        selectedValue?: string;
        isSelectorDisabled: () => boolean;
    } => {
        switch (scopeType) {
            case PropertyScopeType.service:
                return {
                    sectionTitle: 'Service Properties',
                    selectorLabel: '',
                    isSelectorDisabled: () => rightContextDrawerState.isOpen,
                    getMenuItems: () => [],
                };
            case PropertyScopeType.productionTier:
                return {
                    sectionTitle: 'Production Tier Properties',
                    selectorLabel: 'Production Tier',
                    selectedValue: selectedProductionTier,
                    isSelectorDisabled: () => rightContextDrawerState.isOpen,
                    getMenuItems: () => {
                        const propsAtCurrentScope = (propertiesGroupedByScope || {})[PropertyScopeType.productionTier];

                        const filters = propsAtCurrentScope
                            .flatMap((p) => p.propertiesWithKey)
                            .map((p) => p.propertyScopeFilter);

                        return [
                            { value: 'production', label: 'Production' },
                            { value: 'nonproduction', label: 'Non-production' },
                        ]
                            .filter((opt) => filters.some((f) => f === opt.value))
                            .map((opt) => (
                                <MenuItem key={opt.value} value={opt.value}>
                                    {opt.label}
                                </MenuItem>
                            ));
                    },
                };
            case PropertyScopeType.environmentType:
                return {
                    sectionTitle: 'Environment Type Properties',
                    selectorLabel: 'Environment Type',
                    selectedValue: selectedEnvironmentType,
                    isSelectorDisabled: () => {
                        const propsAtAboveLevelExist = (propertiesGroupedByScope || {})[
                            PropertyScopeType.productionTier
                        ];

                        return (
                            rightContextDrawerState.isOpen ||
                            !!(!selectedProductionTier && propsAtAboveLevelExist && propsAtAboveLevelExist.length)
                        );
                    },
                    getMenuItems: () => {
                        return [
                            ...new Set(
                                serviceData?.environmentInstances
                                    .filter((environmentInstance) => {
                                        if (environmentInstance.environmentType.businessKey === 'svc') {
                                            return false;
                                        }

                                        const propsAtAboveLevelExist = (propertiesGroupedByScope || {})[
                                            PropertyScopeType.productionTier
                                        ];

                                        if (!propsAtAboveLevelExist || !propsAtAboveLevelExist.length) {
                                            // The default behavior of the dropdowns is to cascade
                                            // However if there are no properties at the above level,
                                            // don't impact managing properties at this level
                                            return true;
                                        }

                                        if (!selectedProductionTier) {
                                            return false;
                                        }

                                        if (selectedProductionTier === 'production') {
                                            return environmentInstance.environmentType.isProductionEnvironment;
                                        }

                                        if (selectedProductionTier !== 'production') {
                                            return !environmentInstance.environmentType.isProductionEnvironment;
                                        }

                                        return false;
                                    })
                                    .map((environmentInstance) => environmentInstance.environmentType.name),
                            ),
                        ].map((envType) => (
                            <MenuItem key={envType} value={envType}>
                                {envType}
                            </MenuItem>
                        ));
                    },
                };
            case PropertyScopeType.environmentInstance:
                return {
                    sectionTitle: 'Environment Instance Properties',
                    selectorLabel: 'Environment Instance',
                    selectedValue: selectedEnvironmentInstance,
                    isSelectorDisabled: () => {
                        const propsAtAboveLevelExist = (propertiesGroupedByScope || {})[
                            PropertyScopeType.environmentType
                        ];

                        return (
                            rightContextDrawerState.isOpen ||
                            !!(!selectedEnvironmentType && propsAtAboveLevelExist && propsAtAboveLevelExist.length)
                        );
                    },
                    getMenuItems: () => {
                        return (serviceData?.environmentInstances || [])
                            .filter((environmentInstance) => {
                                if (environmentInstance.environmentType.businessKey === 'svc') {
                                    return false;
                                }

                                const propsAtAboveLevelExist = (propertiesGroupedByScope || {})[
                                    PropertyScopeType.environmentType
                                ];

                                if (!propsAtAboveLevelExist || !propsAtAboveLevelExist.length) {
                                    // The default behavior of the dropdowns is to cascade
                                    // However if there are no properties at the above level,
                                    // don't impact managing properties at this level
                                    return true;
                                }

                                if (!selectedEnvironmentType) {
                                    return false;
                                }

                                return environmentInstance.environmentType.name === selectedEnvironmentType;
                            })
                            .map((environmentInstance) => (
                                <MenuItem key={environmentInstance.id} value={environmentInstance.id}>
                                    {environmentInstance.businessKey.toUpperCase()}
                                </MenuItem>
                            ));
                    },
                };
            default:
                return {
                    sectionTitle: '',
                    selectorLabel: '',
                    isSelectorDisabled: () => true,
                    getMenuItems: () => [],
                };
        }
    };

    const onScopeBusinessKeyChange = (scopeType: PropertyScopeType, selectedBusinessKey: string) => {
        // cascade changes to property scope when changing higher up the hierarchy
        if (scopeType === PropertyScopeType.environmentInstance) {
            setSelectedEnvironmentInstance(selectedBusinessKey);
        } else if (scopeType === PropertyScopeType.environmentType) {
            setSelectedEnvironmentType(selectedBusinessKey);
            setSelectedEnvironmentInstance('');
        } else if (scopeType === PropertyScopeType.productionTier) {
            setSelectedProductionTier(selectedBusinessKey);
            setSelectedEnvironmentType('');
            setSelectedEnvironmentInstance('');
        }
    };

    const allPropertyScopes = [
        PropertyScopeType.service,
        PropertyScopeType.productionTier,
        PropertyScopeType.environmentType,
        PropertyScopeType.environmentInstance,
    ];

    return (
        <Container>
            {!isLoading && !isLoadingProperties && (isFetching || isFetchingProperties) && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress sx={{ backgroundColor: 'transparent' }} />
                </Box>
            )}
            {isLoading ||
                (isLoadingProperties && (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ))}
            {!isLoading && !isLoadingProperties && (
                <Stack>
                    {allPropertyScopes.map((propertyScope) => {
                        const propertiesInScope = (propertiesGroupedByScope || {})[propertyScope];

                        const sectionConfig = getPropertyScopeSectionConfig(propertyScope);
                        return (
                            <React.Fragment key={sectionConfig.sectionTitle}>
                                <Typography variant="subtitle1" marginBottom={2}>
                                    {sectionConfig.sectionTitle}
                                </Typography>
                                <Paper sx={{ padding: 2, marginBottom: 2 }}>
                                    <Grid container>
                                        <>
                                            <Grid container alignItems="center">
                                                {propertyScope !== PropertyScopeType.service &&
                                                    propertiesInScope?.length > 0 && (
                                                        <>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                                lg={3}
                                                                paddingX={1}
                                                                paddingBottom={2}
                                                            >
                                                                <FormControl size="small" fullWidth>
                                                                    <InputLabel id={`${propertyScope}-selector`}>
                                                                        {sectionConfig.selectorLabel}
                                                                    </InputLabel>
                                                                    <MuiSelect
                                                                        labelId={`${propertyScope}-selector`}
                                                                        label={sectionConfig.selectorLabel}
                                                                        value={sectionConfig.selectedValue}
                                                                        onChange={(event) =>
                                                                            onScopeBusinessKeyChange(
                                                                                propertyScope,
                                                                                event.target.value,
                                                                            )
                                                                        }
                                                                        disabled={sectionConfig.isSelectorDisabled()}
                                                                    >
                                                                        {sectionConfig.getMenuItems()}
                                                                    </MuiSelect>
                                                                </FormControl>
                                                            </Grid>
                                                            {!sectionConfig.isSelectorDisabled() &&
                                                                !sectionConfig.selectedValue && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        paddingX={1}
                                                                        paddingBottom={2}
                                                                    >
                                                                        <Typography
                                                                            variant="body2"
                                                                            fontSize={12}
                                                                            fontStyle="italic"
                                                                            color={mutedColor}
                                                                        >
                                                                            Make a selection to see property values at
                                                                            this scope
                                                                        </Typography>
                                                                    </Grid>
                                                                )}
                                                        </>
                                                    )}
                                                {!propertiesInScope?.length && (
                                                    <Grid item xs={12} paddingX={1} textAlign="center">
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            display="inline-flex"
                                                            color={mutedColor}
                                                        >
                                                            No properties are currently managed at this scope
                                                        </Typography>
                                                    </Grid>
                                                )}
                                            </Grid>
                                            {propertiesInScope?.map((propertyBundle, index) => {
                                                const property = propertyBundle.propertiesWithKey.find(
                                                    (p) =>
                                                        propertyScope === PropertyScopeType.service ||
                                                        p.propertyScopeFilter === sectionConfig.selectedValue,
                                                )?.propertyValue;
                                                const propDefinition = propertyBundle.propertyDefinition;

                                                if (
                                                    !propDefinition ||
                                                    (propDefinition.hidden && !isPlatformManagerMode)
                                                ) {
                                                    return (
                                                        <div
                                                            key={propDefinition?.controlMetadata.businessKey || index}
                                                        />
                                                    );
                                                }

                                                return (
                                                    <Grid
                                                        key={`${propDefinition.controlMetadata.businessKey}`}
                                                        item
                                                        xs={12}
                                                        lg={6}
                                                        paddingX={2}
                                                        overflow="hidden"
                                                    >
                                                        <PropertyDisplay
                                                            propertyDefinition={propDefinition}
                                                            propertyHierarchy={property}
                                                            showLabelOnlyAsReadOnly={
                                                                !sectionConfig.selectedValue &&
                                                                propertyScope !== PropertyScopeType.service
                                                            }
                                                            editProperty={editProperty}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                    </Grid>
                                </Paper>
                            </React.Fragment>
                        );
                    })}
                </Stack>
            )}
        </Container>
    );
}
