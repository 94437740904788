import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type ProviderProps = {
    children?: ReactNode;
};

export type EntityContextSlugs = {
    organizationSlug?: string;
    productSlug?: string;
    serviceSlug?: string;
};

type EntityContext = {
    pathPrefix: string;
    contextLevel: EntityContextLevel;
    module?: EntityContextModule;
} & EntityContextSlugs;

export enum EntityContextModule {
    Design = 'design',
    Build = 'build',
    Observe = 'observe',
    Act = 'act',
    Inspect = 'inspect',
}

export const defaultEntityContextModule = EntityContextModule.Design;

enum EntityContextLevel {
    Platform = 'platform',
    Organization = 'organization',
    Product = 'product',
    Service = 'service',
}

type EntityContextContextType = {
    changeEntityContext: (
        context: EntityContextSlugs,
        modulePath?: EntityContextModule | string,
        replaceHistory?: boolean,
    ) => void;
    pathToChangeEntityContext: (context: EntityContextSlugs, modulePath?: EntityContextModule | string) => string;
    navigateWithCurrentEntityContext: (modulePath: EntityContextModule | string, replaceHistory?: boolean) => void;
    pathToNavigateWithCurrentEntityContext: (modulePath: EntityContextModule | string) => string;
    entityContext: EntityContext;
    isOrganizationLevel: boolean;
    isProductLevel: boolean;
    isServiceLevel: boolean;
    isDesignModule: boolean;
    isBuildModule: boolean;
    isObserveModule: boolean;
    isActModule: boolean;
    isInspectModule: boolean;
};

const EntityContextContext = createContext<EntityContextContextType | undefined>(undefined);

function generatePathPrefix(context: EntityContextSlugs) {
    let pathPrefix = '';

    if (context.organizationSlug) {
        pathPrefix += `/organizations/${context.organizationSlug}`;
    }
    if (context.organizationSlug && context.productSlug) {
        pathPrefix += `/products/${context.productSlug}`;
    }
    if (context.organizationSlug && context.productSlug && context.serviceSlug) {
        pathPrefix += `/services/${context.serviceSlug}`;
    }

    return pathPrefix;
}

function getEntityContextLevel(context: EntityContextSlugs) {
    if (context.serviceSlug && context.productSlug && context.organizationSlug) {
        return EntityContextLevel.Service;
    }
    if (context.productSlug && context.organizationSlug) {
        return EntityContextLevel.Product;
    }
    if (context.organizationSlug) {
        return EntityContextLevel.Organization;
    }

    return EntityContextLevel.Platform;
}

type EntityContextModuleDetails = {
    modulePath: string;
    module?: EntityContextModule;
};

function getModuleDetailsFromPath(path: string): EntityContextModuleDetails {
    let module: EntityContextModule | undefined;
    let indexOfModulePathStart = -1;
    if (indexOfModulePathStart === -1) {
        indexOfModulePathStart = path.indexOf(`/${EntityContextModule.Design}`);
        module = EntityContextModule.Design;
    }
    if (indexOfModulePathStart === -1) {
        indexOfModulePathStart = path.indexOf(`/${EntityContextModule.Build}`);
        module = EntityContextModule.Build;
    }
    if (indexOfModulePathStart === -1) {
        indexOfModulePathStart = path.indexOf(`/${EntityContextModule.Observe}`);
        module = EntityContextModule.Observe;
    }
    if (indexOfModulePathStart === -1) {
        indexOfModulePathStart = path.indexOf(`/${EntityContextModule.Act}`);
        module = EntityContextModule.Act;
    }
    if (indexOfModulePathStart === -1) {
        indexOfModulePathStart = path.indexOf(`/${EntityContextModule.Inspect}`);
        module = EntityContextModule.Inspect;
    }

    if (indexOfModulePathStart !== -1) {
        return { modulePath: path.substring(indexOfModulePathStart), module };
    }
    return { modulePath: '' };
}

function getCurrentModuleDetails(): EntityContextModuleDetails {
    return getModuleDetailsFromPath(window.location.pathname);
}

export function getEntityContext(): EntityContext {
    const newContextMap: EntityContextSlugs = {};

    const [organizationSegment, organizationSlug, productSegment, productSlug, serviceSegment, serviceSlug] =
        window.location.pathname.slice(1).split('/');
    if (organizationSegment === 'organizations' && organizationSlug && organizationSlug !== 'new') {
        newContextMap.organizationSlug = organizationSlug;
    }
    if (productSegment === 'products' && productSlug) {
        newContextMap.productSlug = productSlug;
    }
    if (serviceSegment === 'services' && serviceSlug) {
        newContextMap.serviceSlug = serviceSlug;
    }

    return {
        ...newContextMap,
        pathPrefix: generatePathPrefix(newContextMap),
        contextLevel: getEntityContextLevel(newContextMap),
        module: getCurrentModuleDetails().module,
    };
}

export function EntityContextProvider({ children }: ProviderProps) {
    const [entityContext, setEntityContext] = useState<EntityContext>(getEntityContext());
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const liveEntityContext = getEntityContext();
        if (JSON.stringify(entityContext) !== JSON.stringify(liveEntityContext)) {
            setEntityContext(getEntityContext());
        }
    }, [location.pathname]);

    const pathToChangeEntityContext = (context: EntityContextSlugs, modulePath?: EntityContextModule | string) => {
        const currentContextLevel = entityContext.contextLevel;
        const newContextLevel = getEntityContextLevel(context);
        const currentModuleDetails = getCurrentModuleDetails();

        let slashPrefix = '';
        if (modulePath && !modulePath.startsWith('/')) {
            slashPrefix = '/';
        }

        if (modulePath) {
            return `${generatePathPrefix(context)}${slashPrefix}${modulePath}`;
        }
        if (currentContextLevel === newContextLevel) {
            return `${generatePathPrefix(context)}${currentModuleDetails.modulePath}`;
        }
        const baseModulePath = currentModuleDetails.module
            ? `/${currentModuleDetails.module}`
            : `/${defaultEntityContextModule}`;
        return `${generatePathPrefix(context)}${baseModulePath}`;
    };

    const changeEntityContext = (
        context: EntityContextSlugs,
        modulePath?: EntityContextModule | string,
        replaceHistory?: boolean,
    ) => {
        const newContextLevel = getEntityContextLevel(context);
        const newFullContextPath = pathToChangeEntityContext(context, modulePath);
        const newModuleDetails = getModuleDetailsFromPath(newFullContextPath);

        setEntityContext({
            ...context,
            pathPrefix: generatePathPrefix(context),
            contextLevel: newContextLevel,
            module: newModuleDetails.module,
        });
        navigate(`${newFullContextPath}${window.location.hash}`, { replace: replaceHistory });
    };

    const pathToNavigateWithCurrentEntityContext = (modulePath: EntityContextModule | string) => {
        let slashPrefix = '';
        if (!modulePath.startsWith('/')) {
            slashPrefix = '/';
        }
        return `${entityContext.pathPrefix}${slashPrefix}${modulePath}`;
    };

    const navigateWithCurrentEntityContext = (modulePath: EntityContextModule | string, replaceHistory?: boolean) => {
        navigate(`${pathToNavigateWithCurrentEntityContext(modulePath)}${window.location.hash}`, {
            replace: replaceHistory,
        });
    };

    const memoValue = useMemo(
        () => ({
            pathToChangeEntityContext,
            changeEntityContext,
            pathToNavigateWithCurrentEntityContext,
            navigateWithCurrentEntityContext,
            entityContext,
            isOrganizationLevel: entityContext.contextLevel === EntityContextLevel.Organization,
            isProductLevel: entityContext.contextLevel === EntityContextLevel.Product,
            isServiceLevel: entityContext.contextLevel === EntityContextLevel.Service,
            isDesignModule: entityContext.module === EntityContextModule.Design,
            isBuildModule: entityContext.module === EntityContextModule.Build,
            isObserveModule: entityContext.module === EntityContextModule.Observe,
            isActModule: entityContext.module === EntityContextModule.Act,
            isInspectModule: entityContext.module === EntityContextModule.Inspect,
        }),
        [entityContext],
    );

    return <EntityContextContext.Provider value={memoValue}>{children}</EntityContextContext.Provider>;
}

export const useEntityContext = (): EntityContextContextType => {
    const user = useContext(EntityContextContext);
    if (!user) {
        throw new Error('useEntityContext must be used within a EntityContext Provider');
    }
    return user;
};
