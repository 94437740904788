import { Grid, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import { TextField } from '../../../../../components/elements/input/TextField';
import { PropertyDefinition } from '../../../../../models/services/PropertyDefinition';
import { PropertyHierarchy } from '../../../../../models/services/PropertyHierarchy';
import { ValueInheritanceTooltip } from '../ValueInheritanceTooltip';

type Props = {
    formProperty: string;
    propertyControl: PropertyDefinition;
    isReadOnly: boolean;
    readonlyReason: string;
    propertyHierarchy?: PropertyHierarchy;
};

export function StringPropertyControl({
    formProperty,
    propertyControl,
    isReadOnly,
    readonlyReason,
    propertyHierarchy,
}: Props) {
    const [valueInheritance, setValueInheritance] = useState<{ value: string; serviceOverridden: string }[]>([]);
    const [placeholder, setPlaceholder] = useState<string>();

    const isPropertyInherited = !propertyHierarchy?.serviceProperty;
    const disabled = !!isReadOnly || isPropertyInherited;

    useEffect(() => {
        // ordered intentionally for service => productMgmt => orgGov hierarchy
        const inheritedValueGroups = [
            {
                group: 'Product Management',
                groupId: 'mgmt',
                values: propertyHierarchy?.productMgmtProperty?.property?.value,
            },
            {
                group: 'Organization Governance',
                groupId: 'gov',
                values: propertyHierarchy?.orgGovProperty?.property?.value,
            },
        ].filter((group) => group.values);

        const valueInheritanceData: {
            value: string;
            serviceOverridden: string;
        }[] = [];

        let placeholderVal: string | undefined;
        // iterate through inherited values and build up arrays for state
        inheritedValueGroups.forEach((group) => {
            const value = group.values as string;

            valueInheritanceData.push({
                value,
                serviceOverridden: group.group,
            });

            if (!placeholderVal) {
                placeholderVal = `${value} (Inherited)`;
            }
        });

        setValueInheritance(valueInheritanceData);
        if (placeholderVal) {
            setPlaceholder(placeholderVal);
        }
    }, [propertyHierarchy]);

    return (
        <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center">
                    <Grid container display="flex" alignItems="center" position="relative">
                        {valueInheritance.length > 0 && (
                            <ValueInheritanceTooltip
                                valueInheritance={valueInheritance}
                                sx={{ position: 'absolute', marginLeft: '-35px', top: '20px' }}
                            >
                                <InfoIcon fontSize="small" />
                            </ValueInheritanceTooltip>
                        )}
                        {!isPropertyInherited ? (
                            <TextField
                                label={propertyControl.displayName}
                                name={formProperty}
                                disabled={disabled}
                                placeholder={placeholder}
                                InputLabelProps={placeholder ? { shrink: true } : undefined}
                                helperText={isReadOnly ? readonlyReason : ''}
                                autoFocus
                            />
                        ) : (
                            <TextField
                                name={formProperty}
                                label={propertyControl.displayName}
                                disabled
                                disableFormik
                                helperText={isReadOnly ? readonlyReason : ''}
                                value={`${
                                    propertyHierarchy?.productMgmtProperty?.property?.value ||
                                    propertyHierarchy?.orgGovProperty?.property?.value ||
                                    ''
                                } (Inherited)`}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
