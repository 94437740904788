export interface PropertyDefinition {
    controlMetadata: PropertyControlMetadata;
    propertyScope: PropertyScope;
    configured?: boolean;
    displayName: string;

    writeOnce?: boolean;
    lockedAfterDeployment?: boolean;
    hasFixedScope?: boolean;
    hidden?: boolean;
    validators: ControlValidator[];

    keyValidators?: ControlValidator[];

    options?: Array<{ label: string; value: string }>;
}

export interface PropertyControlMetadata {
    businessKey: string;
    source: string;
    type: PropertyControlType;
}

export enum PropertyControlType {
    stringProperty = 'stringProperty',
    selectProperty = 'selectProperty',
    keyValuePairProperty = 'keyValuePairProperty',
    platformKeyValuePairProperty = 'platformKeyValuePairProperty',
    listProperty = 'listProperty',
    blobProperty = 'blobProperty',
}

export enum PropertyScopeType {
    service = 'service',
    productionTier = 'productionTier',
    environmentType = 'environmentType',
    environmentInstance = 'environmentInstance',
}

export interface PropertyScope {
    scopeType: PropertyScopeType;
}

export interface ControlValidator {
    validator: string;
    validationMessage: string;
}
