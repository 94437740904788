import { apiSlice, RtkTagType } from '../../api/apiSlice';
import { EnableServiceEnvironmentInstanceRequest } from '../../../models/services/requests/EnableServiceEnvironmentInstanceRequest';
import { RequestDeployServiceEnvironmentInstanceRequest } from '../../../models/services/requests/RequestDeployServiceEnvironmentInstanceRequest';
import { CreateServiceEnvironmentInstanceRequest } from '../../../models/services/requests/CreateServiceEnvironmentInstanceRequest';
import { UpdateServiceEnvironmentInstanceDescriptionRequest } from '../../../models/services/requests/UpdateServiceEnvironmentInstanceDescriptionRequest';
import { ServiceEnvironmentInstance } from '../../../models/services/ServiceEnvironmentInstance';
import { Result } from '../../../models/Result';
import { MarkServiceEnvironmentInstanceForRemovalRequest } from '../../../models/services/requests/MarkServiceEnvironmentInstanceForRemovalRequest';
import { MarkServiceEnvironmentInstanceToAllowResourceDestructionRequest } from '../../../models/services/requests/MarkServiceEnvironmentInstanceToAllowResourceDestructionRequest';

export const serviceEnvironmentInstancesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        serviceEnvironmentInstanceEnable: builder.mutation<
            ServiceEnvironmentInstance,
            EnableServiceEnvironmentInstanceRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}/enable`,
                method: 'PUT',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceRequestDeploy: builder.mutation<
            ServiceEnvironmentInstance,
            RequestDeployServiceEnvironmentInstanceRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}/deploy`,
                method: 'PUT',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceNew: builder.mutation<
            ServiceEnvironmentInstance,
            CreateServiceEnvironmentInstanceRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceUpdateDescription: builder.mutation<
            ServiceEnvironmentInstance,
            UpdateServiceEnvironmentInstanceDescriptionRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}`,
                method: 'PUT',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceMarkForRemoval: builder.mutation<
            ServiceEnvironmentInstance,
            MarkServiceEnvironmentInstanceForRemovalRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}`,
                method: 'DELETE',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceMarkAllowResourceDestruction: builder.mutation<
            ServiceEnvironmentInstance,
            MarkServiceEnvironmentInstanceToAllowResourceDestructionRequest
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}/allow-resource-destruction`,
                method: 'PUT',
                body: request,
            }),
            transformResponse: (responseData: Result<ServiceEnvironmentInstance>) => responseData.value,
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        serviceEnvironmentInstanceTriggerLinkDeploy: builder.mutation<
            void,
            {
                organizationId: string;
                productId: string;
                serviceId: string;
                environmentInstanceId: string;
            }
        >({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/environment-instances/${request.environmentInstanceId}/trigger-link-deploy`,
                method: 'POST',
                body: request,
            }),
        }),
    }),
});

export const {
    useServiceEnvironmentInstanceEnableMutation,
    useServiceEnvironmentInstanceRequestDeployMutation,
    useServiceEnvironmentInstanceMarkAllowResourceDestructionMutation,
    useServiceEnvironmentInstanceNewMutation,
    useServiceEnvironmentInstanceMarkForRemovalMutation,
    useServiceEnvironmentInstanceUpdateDescriptionMutation,
    useServiceEnvironmentInstanceTriggerLinkDeployMutation,
} = serviceEnvironmentInstancesApiSlice;
