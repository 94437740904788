import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Typography } from '@mui/material';
import { TextField } from '../../components/elements/input/TextField';
import { SubmitButton } from '../../components/elements/input/SubmitButton';
import { useBackendService } from '../../providers/BackendServiceProvider';
import { useUser } from '../../providers/UserProvider';
import { useLogging } from '../../providers/LoggingProvider';
import { EntityContextModule, useEntityContext } from '../../providers/EntityContextProvider';
import { FlowUtils } from '../../utils/FlowUtils';
import { stringSequentialValidation } from '../../utils/validation/StringSequentialValidation';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const INITIAL_FORM_STATE = {
    name: '',
    businessKey: '',
    complianceReqs: ['N/A'],
    privateNetworking: 'NO',
};

const COMPLIANCE_OPTIONS = [
    { label: 'N/A', value: 'N/A' },
    { label: 'CCPA', value: 'CCPA' },
    { label: 'GDPR', value: 'GDPR' },
    { label: 'SOC 2', value: 'SOC2' },
    { label: 'HIPAA', value: 'HIPAA' },
    { label: 'HITRUST', value: 'HITRUST' },
];

const YES_NO_OPTIONS = [
    { label: 'Yes', value: 'YES' },
    { label: 'No', value: 'NO' },
];

export function NewOrganization() {
    const { changeEntityContext } = useEntityContext();
    const { setUser, user } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const { newOrganization, validateOrganizationBusinessKeyIsUnique } = useBackendService();
    const { trackTrace } = useLogging();

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string().required('Required'),
        businessKey: stringSequentialValidation([
            Yup.string()
                .matches(/^[a-z]*$/, 'Organization Business Key can only contain lowercase letters')
                .min(2, 'Organization Business Key must be at least 2 characters')
                .max(3, 'Organization Business Key must be 3 characters or less')
                .required('Required'),
            Yup.string().test(
                'is-unique',
                'Organization Business Key is already in use',
                (value: string | undefined) => {
                    if (!value) {
                        return false;
                    }

                    return FlowUtils.asyncDebounce(
                        () =>
                            validateOrganizationBusinessKeyIsUnique(
                                {
                                    businessKey: value,
                                },
                                true,
                            ),
                        400,
                    )();
                },
            ),
        ]),
        complianceReqs: Yup.array().of(Yup.string()),
        privateNetworking: Yup.string().required('Required'),
    });

    const handleSubmit = async (values: any, formik: any) => {
        // Create new organization
        trackTrace('Creating new organization..');
        const orgRes = await newOrganization({
            name: values.name,
            businessKey: values.businessKey,
            complianceReqs: values.complianceReqs.join(','),
            privateNetworking: values.privateNetworking === 'YES',
        });
        enqueueSnackbar('New organization created, redirecting in 3 seconds..');
        trackTrace(`Success: '${orgRes.id}'`);

        const updatedUser = user!;
        updatedUser!.orgId = orgRes.id;
        setUser(updatedUser);

        await FlowUtils.delay(3000);
        formik.setSubmitting(false);
        changeEntityContext({ organizationSlug: orgRes.id }, EntityContextModule.Design);
    };

    return (
        <Container maxWidth="md" sx={{ pt: 6 }}>
            <FormikWrapped
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, formik) => handleSubmit(values, formik)}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h4">Organization Profile</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField name="name" label="Organization Name" required autoFocus />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="businessKey"
                                    label="Organization Business Key"
                                    helpDialogText="A short, up to 3 lowercase character, value to identify your organization"
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubmitButton disabled={isSubmitting}>Create</SubmitButton>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </FormikWrapped>
        </Container>
    );
}
