import { Deployment } from '../../models/deployments/Deployment';
import { GetDeploymentsByContextRequest } from '../../models/deployments/requests/GetDeploymentsByContextRequest';
import { GetDeploymentStatusRequest } from '../../models/deployments/requests/GetDeploymentStatusRequest';
import { Result } from '../../models/Result';
import { apiSlice, RtkTagType } from '../api/apiSlice';

export const deploymentsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        DeploymentsRecentByContext: builder.query<Deployment[], GetDeploymentsByContextRequest>({
            query: (request) => {
                let query = `organizations/${request.organizationId}`;
                if (request.productId) {
                    query += `/products/${request.productId}`;
                }

                if (request.productId && request.serviceId) {
                    query += `/services/${request.serviceId}`;
                }

                return `${query}/deployments/recent`;
            },
            transformResponse: (responseData: Result<Deployment[]>) => responseData.value,
            providesTags: (result) =>
                result
                    ? [
                          ...result.map(({ id }) => ({ type: RtkTagType.Deployments, id })),
                          { type: RtkTagType.Deployments, id: 'LIST' },
                      ]
                    : [{ type: RtkTagType.Deployments, id: 'LIST' }],
        }),
    }),
});

export const { useDeploymentsRecentByContextQuery } = deploymentsApiSlice;
