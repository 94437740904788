import { apiSlice, RtkTagType } from '../api/apiSlice';
import { Result } from '../../models/Result';
import { store } from '../../state/store';
import { ExecuteJourneyRequest } from '../../models/journeys/requests/ExecuteJourneyRequest';
import { ExecuteJourneyResponse } from '../../models/journeys/ExecuteJourneyResponse';
import { ExecuteJourneyServiceNewRequest } from '../../models/journeys/requests/ExecuteJourneyServiceNewRequest';

export const journeysApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        journeyProductNew: builder.mutation<ExecuteJourneyResponse, ExecuteJourneyRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/product-journey`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<ExecuteJourneyResponse>) => responseData.value,
            invalidatesTags: () => [
                { type: RtkTagType.Products, id: 'LIST' },
                { type: RtkTagType.Services, id: 'LIST' },
            ],
        }),
        journeyServiceNew: builder.mutation<ExecuteJourneyResponse, ExecuteJourneyRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/service-journey`,
                method: 'POST',
                body: request,
            }),
            transformResponse: (responseData: Result<ExecuteJourneyResponse>) => responseData.value,
            invalidatesTags: () => [{ type: RtkTagType.Services, id: 'LIST' }],
        }),
    }),
});

export const { useJourneyProductNewMutation, useJourneyServiceNewMutation } = journeysApiSlice;
