import { useEffect, useRef } from 'react';
import { Formik, Form, FormikValues, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
import { Container, Grid, Tooltip, Typography } from '@mui/material';
import { TextField } from '../../../components/elements/input/TextField';
import { SubmitButton } from '../../../components/elements/input/SubmitButton';
import { useBackendService } from '../../../providers/BackendServiceProvider';
import { useLogging } from '../../../providers/LoggingProvider';
import { DrawerFooterAction, useLayout } from '../../../providers/LayoutProvider';

const FormikWrapped = styled(Formik)(({ theme }) => ({
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(8),
}));

const INITIAL_FORM_STATE = {
    azureBillingAccountName: '',
    azureBillingProfileName: '',
    azureInvoiceSectionName: '',
};

const FORM_VALIDATION = Yup.object().shape({
    azureBillingAccountName: Yup.string().required('Required'),
    azureBillingProfileName: Yup.string().required('Required'),
    azureInvoiceSectionName: Yup.string().required('Required'),
});

type Props = {
    organizationId: string;
    actionCompleteCallback: () => void;
};

export function NewMicrosoftCustomerPlatform({ organizationId, actionCompleteCallback }: Props) {
    const { trackTrace } = useLogging();
    const { enqueueSnackbar } = useSnackbar();
    const { newMicrosoftCustomerPlatform } = useBackendService();
    const { setRightContextDrawerActions } = useLayout();
    const formRef = useRef<FormikProps<FormikValues>>(null);

    const handleSubmit = async (values: any, formik: any) => {
        // Create new platform
        trackTrace('Creating new Microsoft Customer Account connection...');
        const platRes = await newMicrosoftCustomerPlatform({
            organizationId,
            azureBillingAccountType: 'mcaAccount',
            ...values,
        });
        formik.setSubmitting(false);
        enqueueSnackbar('Microsoft Customer Account connected');
        trackTrace(`Success: '${platRes.id}'`);
        actionCompleteCallback();
    };

    useEffect(() => {
        const actions: DrawerFooterAction[] = [
            {
                action: () => {
                    formRef.current?.submitForm();
                },
                actionText: 'Initiate Connection',
                actionDisabled: formRef.current?.isSubmitting,
            },
        ];

        setRightContextDrawerActions(actions);
    }, []);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Container maxWidth="md">
                    <FormikWrapped
                        innerRef={formRef}
                        initialValues={{
                            ...INITIAL_FORM_STATE,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values, formik) => handleSubmit(values, formik)}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography>
                                            Assign the <strong>Scaled Sense Automation</strong> as an{' '}
                                            <strong>Owner</strong> on at least the Invoice Section of your Microsoft
                                            Customer Account and provide <strong>Management Group Contributor</strong>{' '}
                                            access at the Root Management Group
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="azureBillingAccountName" label="Billing Account" required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="azureBillingProfileName" label="Billing Profile" required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="azureInvoiceSectionName" label="Invoice Section" required />
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </FormikWrapped>
                </Container>
            </Grid>
        </Grid>
    );
}
