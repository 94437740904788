import { Container, Switch } from '@mui/material';
import { Dispatch, SetStateAction, useEffect } from 'react';
import CardSelection from '../../../components/elements/CardSelection';
import { CreateServiceAddOnsRequest } from '../../../models/services/requests/CreateServiceAddOnRequest';
import { useEntityContext } from '../../../providers/EntityContextProvider';
import { useWorkloadsLatestAddonsByTypeQuery } from '../../services/workloads/workloadsApiSlice';

type Props = {
    workloadType: string | undefined;
    createAddOnsRequest: Partial<CreateServiceAddOnsRequest>;
    setCreateAddOnsRequest: Dispatch<SetStateAction<Partial<CreateServiceAddOnsRequest>>>;
};

export default function NewServiceStepAddOns({ workloadType, createAddOnsRequest, setCreateAddOnsRequest }: Props) {
    const { entityContext } = useEntityContext();
    const { data: addOns } = useWorkloadsLatestAddonsByTypeQuery(
        {
            organizationId: entityContext.organizationSlug!,
            workloadType: workloadType!,
        },
        { skip: !workloadType },
    );

    useEffect(() => {
        if (addOns && !createAddOnsRequest.isConfigured) {
            const autoEnableAddons = addOns
                .filter((a) => a.enabledByDefault)
                .map((a) => a.type)
                .filter((a) => !createAddOnsRequest.addOnWorkloads?.includes(a));

            setCreateAddOnsRequest((prev) => ({
                ...prev,
                addOnWorkloads: [...(prev.addOnWorkloads || []), ...autoEnableAddons],
                isConfigured: true,
            }));
        }
    }, [addOns]);

    const handleToggleAddOn = (addOnType: string) => {
        const addOnWorkloadFound = createAddOnsRequest.addOnWorkloads?.find((a) => a === addOnType);

        if (addOnWorkloadFound) {
            setCreateAddOnsRequest((prev) => ({
                ...prev,
                addOnWorkloads: prev.addOnWorkloads!.filter((a) => a !== addOnType),
            }));
        } else {
            setCreateAddOnsRequest((prev) => ({
                ...prev,
                addOnWorkloads: [
                    ...(prev.addOnWorkloads || []),
                    ...(!prev.addOnWorkloads?.includes(addOnType) ? [addOnType] : []),
                ],
            }));
        }
    };

    return (
        <Container>
            <CardSelection
                centerHorizontally
                title="Choose the add-ons you would like to use."
                subTitle="You can always add more later."
                cards={
                    addOns
                        ?.filter((addOn) => addOn.workloadTrigger.type === 'Manual')
                        .map((addOn) => ({
                            subTitle: addOn.friendlyName.replaceAll(' Add-on', ''),
                            tooltip: addOn.description,
                            // eslint-disable-next-line react/jsx-no-undef
                            action: (
                                <Switch
                                    onChange={() => handleToggleAddOn(addOn.type)}
                                    checked={createAddOnsRequest.addOnWorkloads?.includes(addOn.type)}
                                />
                            ),
                        })) || []
                }
            />
        </Container>
    );
}
