import { apiSlice, RtkTagType } from '../../api/apiSlice';
import { PropertyHierarchy } from '../../../models/services/PropertyHierarchy';
import { GetServicePropertyHierarchyByIdRequest } from '../../../models/services/requests/GetServicePropertyHierarchyByIdRequest';
import { Result } from '../../../models/Result';
import { UpdatePropertyRequest } from '../../../models/services/requests/UpdatePropertyRequest';
import { UpdatePropertyScopeRequest } from '../../../models/services/requests/UpdatePropertyScopeRequest';

export const propertiesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        servicePropertyHierarchyById: builder.query<
            { [envKey: string]: PropertyHierarchy[] },
            GetServicePropertyHierarchyByIdRequest
        >({
            query: (request) =>
                `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/properties`,
            transformResponse: (responseData: Result<{ [envKey: string]: PropertyHierarchy[] }>) => responseData.value,
            providesTags: (result, error, request) =>
                result ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        servicePropertyUpdate: builder.mutation<void, UpdatePropertyRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/property`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
        servicePropertyUpdateScope: builder.mutation<void, UpdatePropertyScopeRequest>({
            query: (request) => ({
                url: `/organizations/${request.organizationId}/products/${request.productId}/services/${request.serviceId}/property-scope`,
                method: 'POST',
                body: request,
            }),
            invalidatesTags: (result, error, request) =>
                request ? [{ type: RtkTagType.Services, id: request.serviceId }] : [],
        }),
    }),
});

export const {
    useServicePropertyHierarchyByIdQuery,
    useServicePropertyUpdateMutation,
    useServicePropertyUpdateScopeMutation,
} = propertiesApiSlice;
